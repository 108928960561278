<template>
  <CareosTable
    v-model:editingRows="editingRows"
    v-model:filters="filters"
    :value="store.subdivisions"
    :loading="!!isLoading"
    edit-mode="row"
    :paginator="true"
    :rows="10"
    :rows-per-page-options="[5, 10, 15]"
    filter-display="menu"
    sort-field="createdAt"
    :sort-order="-1"
    @row-edit-init="onRowInit"
    @row-edit-save="onRowEditSave"
    @row-edit-cancel="isEditing = false"
  >
    <Column
      field="name"
      :show-filter-match-modes="false"
      :header="t('admin_board.table.headers.subdivision')"
      sortable
      data-type="string"
    >
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" />
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="t('admin_board.table.headers.subdivision')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          ref="filterCancel"
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="createdAt"
      :show-filter-match-modes="false"
      data-type="date"
      sortable
    >
      <template #header>
        {{ t('admin_board.table.headers.added') }}
        <div
          v-tooltip.right="{
            value: t('admin_board.table.headers.created_at_tooltip'),
            class: 'tooltip-style',
          }"
          class="flex"
        >
          <PrimeIcon icon="INFO_CIRCLE" size="XS" class="ml-2" />
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
      <template #body="{ data }">{{
        dayjs(data.createdAt).format('YYYY-MM-DD')
      }}</template>
    </Column>
    <Column
      :row-editor="true"
      style="width: 10%; min-width: 8rem"
      body-style="text-align:center"
    >
      <template #roweditorcancelicon> X </template>
    </Column>
  </CareosTable>
  <TheModal
    :show-modal="showModal"
    :loading="isLoadingEdit"
    @decline="showModal = false"
    @accept="confirmEditSubdivision"
  >
    <h1 class="mb-8 font-semibold">
      {{ t('admin_board.edit_subdiv_modal.title') }}:
    </h1>
    <p class="mb-4">
      {{ t('admin_board.edit_subdiv_modal.message_1') }}
      <span class="font-bold">{{ currentSubdivisionName?.name }}</span>
      {{ t('admin_board.edit_subdiv_modal.message_2') }}
      <span class="font-bold">{{ updatedSubdivisionName?.name }}</span
      >.
    </p>
  </TheModal>
</template>

<script setup lang="ts">
import CareosTable from '@/components/CareosTable.vue';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import dayjs from 'dayjs';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import Column from 'primevue/column';
import { ComponentPublicInstance, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { useOrganization } from '../../../../stores/organization';
import { useI18n } from 'vue-i18n';
import { usePrimeVue } from 'primevue/config';
import isBetween from 'dayjs/plugin/isBetween';
import { FilterService, FilterMatchMode } from 'primevue/api';
import {
  DataTableFilterMeta,
  DataTableRowEditSaveEvent,
} from 'primevue/datatable';
import { PrimeIcon } from 'careos-vue-components';
import { useToast } from 'primevue/usetoast';
import TheModal from '@/components/TheModal.vue';
import type {
  UpdateSubdivisionRequestDto,
  Subdivision,
} from '@careos/organization-api-types';

dayjs.extend(isBetween);

const store = useOrganization();
const route = useRoute();
const { t, locale } = useI18n();
const primeVue = usePrimeVue();
const filterApply = ref<ComponentPublicInstance | null>(null);
const toast = useToast();

const showModal = ref(false);
const editingRows = ref([]);
const isEditing = ref(false);
const currentSubdivisionName = ref<Subdivision | undefined>();
const updatedSubdivisionName = ref<Subdivision | undefined>();
const updateSubdivisionDto = ref<UpdateSubdivisionRequestDto | undefined>();

FilterService.register(
  'dateInRange',
  (value: string | null, filter: [Date, Date] | null): boolean => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const isRange = filter.at(0) && filter.at(1);
    return isRange
      ? dayjs(value).isBetween(filter[0], filter[1], 'date', '[]')
      : dayjs(value).isSame(filter[0], 'date');
  },
);

const filterDefaults: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: { value: null, matchMode: 'dateInRange' },
};

const filterDefaultClone = () => {
  const defaultFilters: DataTableFilterMeta = {};
  Object.keys(filterDefaults).forEach((key) => {
    defaultFilters[key] = structuredClone(filterDefaults[key]);
  });
  return defaultFilters;
};
const filters = ref<DataTableFilterMeta>(filterDefaultClone());

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('admin_board.table.filters.january'),
      t('admin_board.table.filters.february'),
      t('admin_board.table.filters.march'),
      t('admin_board.table.filters.april'),
      t('admin_board.table.filters.may'),
      t('admin_board.table.filters.june'),
      t('admin_board.table.filters.july'),
      t('admin_board.table.filters.august'),
      t('admin_board.table.filters.september'),
      t('admin_board.table.filters.october'),
      t('admin_board.table.filters.november'),
      t('admin_board.table.filters.december'),
    ],
    dayNamesMin: [
      t('admin_board.table.filters.sunday'),
      t('admin_board.table.filters.monday'),
      t('admin_board.table.filters.tuesday'),
      t('admin_board.table.filters.wednesday'),
      t('admin_board.table.filters.thursday'),
      t('admin_board.table.filters.friday'),
      t('admin_board.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);

const { organizationKey, employerKey } = route.params;
const { exec: fetchSubdivisions, isLoading } = useNetworkRequest(() =>
  store.fetchSubdivisions(employerKey as string, organizationKey as string),
);
fetchSubdivisions();

const onRowInit = () => {
  isEditing.value = true;
};

const isSameValues = (subdivision: Subdivision, newSubdivision: Subdivision) =>
  subdivision.name === newSubdivision.name;

const onRowEditSave = ({
  data: subdivision,
  newData: newSubdivision,
}: DataTableRowEditSaveEvent) => {
  if (isSameValues(subdivision, newSubdivision)) return;
  currentSubdivisionName.value = subdivision;
  updatedSubdivisionName.value = newSubdivision;

  showModal.value = true;
  const { key, name }: Subdivision = newSubdivision;

  updateSubdivisionDto.value = {
    subdivisionKey: key,
    newSubdivisionName: name,
    employerKey: employerKey as string,
    organizationKey: organizationKey as string,
  };
  isEditing.value = false;
};
const editSubdivison = async () => {
  const newSubdivisionName = updateSubdivisionDto?.value?.newSubdivisionName;
  if (!newSubdivisionName?.trim()) {
    toast.add({
      life: 5_000,
      severity: 'error',
      summary: t('admin_board.edit_subdiv_modal.subdiv_edited_toast_msg.error'),
    });
    return;
  }
  if (updateSubdivisionDto.value) {
    await store.updateSubdivision(updateSubdivisionDto.value);
    toast.add({
      life: 5_000,
      severity: 'success',
      summary: t(
        'admin_board.edit_subdiv_modal.subdiv_edited_toast_msg.success',
      ),
    });
  }
  showModal.value = false;
  fetchSubdivisions();
};

const { exec: confirmEditSubdivision, isLoading: isLoadingEdit } =
  useNetworkRequest(async () => {
    await editSubdivison();
  });

onBeforeRouteLeave(() => {
  store.$reset();
});
</script>
