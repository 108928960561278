<template>
  <div>
    <div class="flex flex-wrap gap-4">
      <CareosButton
        class="min-w-fit flex-1 py-6"
        :label="t('summary.hand_sign')"
        data-test-id="sign-button"
        @click="showSignaturePadModal = true"
      />
      <CareosButton
        v-if="hasPersonalNumber"
        class="min-w-fit flex-1 py-6"
        :label="t('summary.bankid_sign')"
        data-test-id="bankid-sign-button"
        @click="showBankidModal = true"
      />
    </div>
    <Dialog
      v-model:visible="showBankidModal"
      :draggable="false"
      pt:mask:class="backdrop-blur-sm"
      pt:dialog:class="!border-radius-none"
    >
      <template #container>
        <BankId
          v-if="showBankidModal && accessToken"
          :transaction-id="transactionId"
          :locale="locale as SupportedLocales"
          :visible-text="bankIdText"
          :access-token="accessToken"
          @cancel="showBankidModal = false"
          @completion="
            ({ signature, user }) =>
              handleSigningCompletion({
                signatureType: 'BANKID',
                signature,
                personalNumber: user.personalNumber,
                signeeFullName: user.name,
              })
          "
        />
      </template>
    </Dialog>

    <SignaturePadModal
      v-model="showSignaturePadModal"
      @update-signature="
        (signature) =>
          handleSigningCompletion({
            signatureType: 'HANDWRITTEN',
            signature,
          })
      "
    />
  </div>
</template>
<script setup lang="ts">
import CareosButton from '@/components/CareosButton.vue';
import SignaturePadModal from './SignaturePadModal.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { BankId } from 'careos-vue-components';
import Dialog from 'primevue/dialog';
import { useAuthStore } from '@/stores/auth';
import { type SignatureType } from '@careos/toxicology-types';
import { type SupportedLocales } from '@/translations/i18n';

const { t, locale } = useI18n();

export type Signing =
  | {
      signature: string;
      signatureType: Extract<SignatureType, 'HANDWRITTEN'>;
    }
  | {
      signature: string;
      personalNumber: string;
      signeeFullName: string;
      signatureType: Extract<SignatureType, 'BANKID'>;
    };

const emit = defineEmits<{
  signingCompletion: [data: Signing];
}>();

const props = defineProps<{
  transactionId: string;
  hasPersonalNumber: boolean;
}>();

const bankIdText = `## ${t('signing.header')}\n --- \n${t('signing.description', { transactionId: props.transactionId })} \n --- \n${t('signing.footer')}`;

const { accessToken } = useAuthStore();

const showSignaturePadModal = ref(false);
const showBankidModal = ref(false);

const handleSigningCompletion = async (data: Signing) => {
  if (data.signatureType === 'BANKID') {
    await new Promise((res) => setTimeout(res, 3000));
    showBankidModal.value = false;
  }
  emit('signingCompletion', data);
};
</script>
