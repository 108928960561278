import { z } from 'zod';

export const CheckUserInDatabaseResponseDtoSchema = z.object({
  isUserExisting: z.boolean(),
  isUserExistingInThisOrg: z.boolean().optional(),
  isUserExistingWithSameName: z.boolean().optional(),
  userNameInDatabase: z.string().optional(),
});

export type CheckUserInDatabaseResponseDto = z.infer<
  typeof CheckUserInDatabaseResponseDtoSchema
>;
