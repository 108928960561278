import type {
  AddEmployerRequestDto,
  AddSubdivisionRequestDto,
  AddTreatmentCenterRequestDto,
  GetEmployersResponseDto,
  GetSubdivisionsResponseDto,
  GetTreatmentCentersResponseDto,
  UpdateEmployerRequestDto,
  UpdateEmployerResponseDto,
  UpdateSubdivisionRequestDto,
  UpdateSubdivisionResponseDto,
  UpdateTreatmentCenterRequestDto,
  UpdateTreatmentCenterResponseDto,
} from '@careos/organization-api-types';

import { OrgHttpClient } from '@/api/organization-client';
import { defineStore } from 'pinia';

interface OrgViewState {
  employers: GetEmployersResponseDto['employers'];
  subdivisions: GetSubdivisionsResponseDto['subdivisions'];
  treatmentCenters: GetTreatmentCentersResponseDto['treatmentCenters'];
  displayNames: {
    organizationDisplayName: string;
    employerDisplayName: string;
  };
  organizationLoginName: string;
}

const initialDisplayNamesState = {
  organizationDisplayName: '',
  employerDisplayName: '',
};

export const useOrganization = defineStore('organization-store', {
  state: (): OrgViewState => ({
    employers: [],
    subdivisions: [],
    treatmentCenters: [],
    displayNames: {
      ...initialDisplayNamesState,
    },
    organizationLoginName: '',
  }),
  actions: {
    async fetchEmployers(organizationKey: string) {
      const res = await OrgHttpClient.get<GetEmployersResponseDto>(
        `/organization/get-employers/${organizationKey}`,
      );
      this.employers = res.data.employers.reverse();
      this.organizationLoginName = res.data.organizationLoginName;
      this.displayNames = { ...initialDisplayNamesState };
      this.displayNames.organizationDisplayName = res.data.organizationName;
    },
    async fetchSubdivisions(employerKey: string, organizationKey: string) {
      const res = await OrgHttpClient.get<GetSubdivisionsResponseDto>(
        `/organization/get-subdivisions/${organizationKey}/${employerKey}`,
      );
      this.displayNames = { ...initialDisplayNamesState };
      this.subdivisions = res.data.subdivisions.reverse();
      this.displayNames.organizationDisplayName = res.data.organizationName;
      this.displayNames.employerDisplayName = res.data.employerName;
      this.organizationLoginName = res.data.organizationLoginName;
    },
    async fetchTreatmentCenters(organizationKey: string) {
      const res = await OrgHttpClient.get<GetTreatmentCentersResponseDto>(
        `/organization/get-treatment-centers/${organizationKey}`,
      );
      this.displayNames = { ...initialDisplayNamesState };
      this.treatmentCenters = res.data.treatmentCenters.reverse();
      this.displayNames.organizationDisplayName = res.data.organizationName;
      this.organizationLoginName = res.data.organizationLoginName;
    },

    async createEmployer(dto: AddEmployerRequestDto) {
      await OrgHttpClient.post('/organization/add-employer', dto);
      await this.fetchEmployers(dto.organizationKey);
    },
    async createSubdivision(dto: AddSubdivisionRequestDto) {
      await OrgHttpClient.post('/organization/add-subdivision', dto);
      await this.fetchSubdivisions(dto.employerKey, dto.organizationKey);
    },
    async createTreatmentCenter(dto: AddTreatmentCenterRequestDto) {
      await OrgHttpClient.post('/organization/add-treatment-center', dto);
      await this.fetchTreatmentCenters(dto.organizationKey);
    },

    async updateEmployer(dto: UpdateEmployerRequestDto) {
      const res = await OrgHttpClient.patch<UpdateEmployerResponseDto>(
        '/organization/update-employer-name',
        dto,
      );
      return res.data;
    },

    async updateSubdivision(dto: UpdateSubdivisionRequestDto) {
      const res = await OrgHttpClient.patch<UpdateSubdivisionResponseDto>(
        '/organization/update-subdivision-name',
        dto,
      );
      return res.data;
    },

    async updateTreatmentCenter(dto: UpdateTreatmentCenterRequestDto) {
      const res = await OrgHttpClient.patch<UpdateTreatmentCenterResponseDto>(
        '/organization/update-tc-name',
        dto,
      );
      return res.data;
    },
  },
});
