import { z } from 'zod';
import { IsoDateTimeSchema, ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ParcelUpdateArrivalTimeEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ParcelUpdateArrivalTimeEvent'),
  eventData: z.object({
    /**
     * Unique id for the parcel
     */
    parcelId: z.string(),
    /**
     * The arrival time of the box to lab (manual input)
     */
    arrivalTime: IsoDateTimeSchema,
    /**
     * The time the update was made
     */
    updatedAt: IsoDateTimeSchema,
    /**
     * {@link Practitioner} whom updated the parcel.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * A transport box has been opened in the lab then had it's arrival time updated
 */
export class ParcelUpdateArrivalTimeEvent extends createSchemaClass(
  ParcelUpdateArrivalTimeEventSchema,
) {}
