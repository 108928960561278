<template>
  <div class="mx-auto flex h-full max-w-4xl items-center justify-center py-4">
    <div
      v-if="!pdf"
      class="flex w-full max-w-96 flex-col rounded-lg border bg-white p-6 shadow-md"
    >
      <FormKit
        v-model="code"
        :label="t('patientConfirmation.passcode.inputLabel')"
        :help="t('patientConfirmation.passcode.help')"
        type="text"
        validation="required"
      />
      <CareosErrorMessage
        v-if="isPasswordInvalid"
        :message="t('patientConfirmation.passcode.codeInvalid')"
      />
      <CareosButton
        class="w-full"
        :label="t('patientConfirmation.passcode.submitButton')"
        @click="submit"
      />
    </div>
    <PdfEmbed v-if="pdf" :pdf-content="pdf" />
  </div>
</template>
<script setup lang="ts">
import { api } from '@/api/client';
import PdfEmbed from '@/components/PdfEmbed.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import CareosErrorMessage from '@/components/CareosErrorMessage.vue';
import CareosButton from '@/components/CareosButton.vue';

const { t } = useI18n();
const route = useRoute();

const pdf = ref<string>();
const code = ref('');
const isPasswordInvalid = ref(false);

const submit = async () => {
  isPasswordInvalid.value = false;
  const requisitionId = `${route.params.partialId}-${code.value}`;

  const { data } = await api.fetchSignedConsentPdf(requisitionId);

  if (!data) {
    isPasswordInvalid.value = true;
    return;
  }

  pdf.value = data;
};
</script>
