import { BarcodeSchema, createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const HandleResultRemarkRequestDtoSchema = z.object({
  barcode: BarcodeSchema,
  requisitionId: z.string().max(36),
  remarkId: z.string().optional(),
  remarkBody: z.string().max(500),
});

export class HandleResultRemarkRequestDto extends createSchemaClass(
  HandleResultRemarkRequestDtoSchema,
) {}
