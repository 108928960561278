<script setup lang="ts">
import type { PendingResponseDto } from '@careos/types';
import { type Locale, useI18n } from '../../composables/i18n';
import QrCode from './QrCode.vue';
import { HintCode } from '@careos/types';

const props = defineProps<{
  pendingData: PendingResponseDto;
  locale: Locale;
}>();
const { t } = useI18n(props.locale);
</script>
<template>
  <p v-if="pendingData.hintCode === 'userSign'">
    {{ t('hintcode-qr-userSign') }}
  </p>
  <template v-else>
    <p class="mb-4">
      {{
        t(`hintcode-qr-${pendingData.hintCode}`) ||
        t(`hintcode-qr-pending-${HintCode.unknown}`)
      }}
    </p>
    <QrCode :qr-text="pendingData.qrText" />
  </template>
</template>
