 
import { getAccessToken } from '@/authentication/auth0';
import { isProd, isStaging } from '@/utils/deployment-environment';
import axios from 'axios';

const baseURL = isProd
  ? 'https://api.careos.io/v1/organization-api'
  : isStaging
    ? 'https://staging-api.careos.io/v1/organization-api'
    : 'http://localhost:5555/v1/organization-api';

const OrgHttpClient = axios.create({
  baseURL,
});

const TokenlessOrgHttpClient = axios.create({
  baseURL,
});

OrgHttpClient.interceptors.request.use(
  async (value) => {
    const token = await getAccessToken();
     
    value.headers.Authorization = `Bearer ${token}`;
    return value;
  },
  (res) => res,
);

export { OrgHttpClient, TokenlessOrgHttpClient };
