import { SamplingKitIdentifierSchema } from '@careos/identifiers';
import { z } from 'zod';
import { OrganizationSchema } from '../../../organization';
import { OrderTypeSchema } from '../../../test-type';

export const OrganizationWithEnabledTestTypesSchema = OrganizationSchema.extend(
  {
    enabledDoaOrderType: OrderTypeSchema.optional(),
    enabledPethOrderType: OrderTypeSchema.optional(),
    enabledDoaSamplingKits: SamplingKitIdentifierSchema.array(),
    enabledPethSamplingKits: SamplingKitIdentifierSchema.array(),
  },
);

export type OrganizationWithEnabledTestTypes = z.infer<
  typeof OrganizationWithEnabledTestTypesSchema
>;

export const GetOrganizationsResponseDtoSchema = z.object({
  abbreviation: z.string().nullish(),
  organizations: z.array(OrganizationWithEnabledTestTypesSchema),
});

export type GetOrganizationsResponseDto = z.infer<
  typeof GetOrganizationsResponseDtoSchema
>;
