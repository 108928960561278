<template>
  <div
    class="mx-2 my-4 max-w-[100vw] rounded-xl border bg-white p-5 drop-shadow-md"
  >
    <RequisitionsTable :loading="isLoading" :doa-requisitions="requisitions" />
  </div>
</template>
<script setup lang="ts">
import type { RequisitionListRowDto } from '@careos/toxicology-types';
import { onMounted, ref } from 'vue';

import { ToxiHttpClient } from '@/api/toxicology-client';

import { notUndefined } from '@/utils/no-undefined';
import RequisitionsTable from './components/RequisitionsTable.vue';
import { AxiosError } from 'axios';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

const requisitions = ref<RequisitionListRowDto[]>([]);
const isLoading = ref(false);
const toast = useToast();
const { t } = useI18n();

const fetchRequisitions = async () => {
  isLoading.value = true;

  try {
    const res =
      await ToxiHttpClient.get<RequisitionListRowDto[]>('requisitions-list');
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      let detail = e.response?.data.message;
      if (detail === 'auth0_mismatch_org_error_key') {
        detail = t(`general.${detail}`);
      }
      toast.add({
        life: 10_000,
        severity: 'error',
        summary: `${t('general.error_occurred')}. ${t(
          'general.error_is_reported_message',
        )}`,
        detail,
      });
    }
    return [];
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  const response = await fetchRequisitions();
  requisitions.value = response
    .map((requisition) => {
      if (!requisition.samplingDate) return;
      return requisition;
    })
    .filter(notUndefined);
});
</script>

<style lang="scss">
.p-datatable-table {
  width: 100%;
}
</style>
