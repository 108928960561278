import { z } from 'zod';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { SimpleQuantitySchema } from './simple-quantity.type';

export enum ReferenceRangeCodingSystem {
  Binary = 'uri:careos.io/reference-ranges/binary',
  Scale = 'uri:careos.io/reference-ranges/scale',
}

export const ReferenceRangeSchema = z.object({
  /**
   * Low Range, if relevant
   *
   * If low exists and high does not, high = infinite.
   */
  low: SimpleQuantitySchema.optional(),
  /**
   * High Range, if relevant
   *
   * If high exists and low does not, low = 0
   */
  high: SimpleQuantitySchema.optional(),
  /**
   * Text based reference range in an observation
   */
  text: z.string().optional(),
  /**
   * 	Codes to indicate what type of reference range it is.
   *  For example, the normal or therapeutic range.
   */
  type: CodeableConceptSchema,
});

/**
 * Guidance on how to interpret the value by comparison to a normal or recommended range.
 * Multiple reference ranges are interpreted as an "OR". In other words, to represent two distinct target populations, two referenceRange elements would be used.
 * Rule: Must have at least a low or a high or text
 */
export type ReferenceRange = z.infer<typeof ReferenceRangeSchema>;
