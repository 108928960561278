import { z } from 'zod';
import { SignatureDataWithDimensionsSchema } from '../../../../common';
import { DonorGetRequisitionByTransactionIdRequestDtoSchema } from './donor-get-requisition-by-transaction-id.request.dto';

export const DonorSignRequisitionRequestDtoSchema =
  DonorGetRequisitionByTransactionIdRequestDtoSchema.extend({
    signature: SignatureDataWithDimensionsSchema,
  });

export type DonorSignRequisitionRequestDto = z.infer<
  typeof DonorSignRequisitionRequestDtoSchema
>;
