import { defineStore } from 'pinia';
import { OrderConfirmation } from '@careos/toxicology-types';

export interface ConfirmationViewState {
  patientConfirmationData: OrderConfirmation | null;
}

export const useConfirmationViewStore = defineStore('confirmation-view-store', {
  state: (): ConfirmationViewState => ({
    patientConfirmationData: null,
  }),
});
