import { z } from 'zod';
import {
  AnnotationSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsMedicallyRejectedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsMedicallyRejectedEvent'),
  eventData: z.object({
    /**
     * Observations which were rejected
     */
    observations: ReferenceSchema(z.literal('Observation')).array(),
    /**
     * When the observations were rejected.
     */
    medicallyRejectedAt: IsoDateTimeSchema,
    /**
     * Who medically rejected these observations
     */
    medicallyRejectedBy: ReferenceSchema(z.literal('Practitioner')).optional(),
    /**
     * Any note or comment made about the rejected observations.
     */
    note: AnnotationSchema.optional(),
    /**
     * Optional reference to the requisition these observations belong to.
     * If omitted, it is assumed that the data was not available or the
     * observations belong to several different requisitions
     */
    requisition: ReferenceSchema(z.literal('Requisition')).optional(),
  }),
});

/**
 * A doctor has medically rejected {@link Observation}s.
 */
export class ObservationsMedicallyRejectedEvent extends createSchemaClass(
  ObservationsMedicallyRejectedEventSchema,
) {}
