<template>
  <div>
    <div v-if="details.isDonorPasswordGenerated">
      <p class="mt-5 text-left font-semibold">
        {{ t('created.requisition_password') }}
      </p>
      <div
        class="flex items-center justify-center gap-4 rounded bg-careos-purple-light"
        data-test-id="signed-consent-password"
      >
        <p class="text-xl font-semibold">
          {{ newGeneratedPassword ? newGeneratedPassword : '**********' }}
        </p>
        <button
          v-tooltip.top="{
            value: t('created.request_new_password.tooltip'),
          }"
          class="pt-1 text-careos-purple"
          @click="showModal = true"
        >
          <PrimeIcon icon="SYNC" size="XS" />
        </button>
      </div>
    </div>

    <div v-else>
      <p class="mt-5 text-left font-semibold">
        {{ t('created.requisition_password') }}
      </p>
      <p
        class="rounded bg-careos-purple-light text-xl font-semibold"
        data-test-id="signed-consent-password"
      >
        {{ details.donorPassword }}
      </p>
    </div>

    <TheModal
      :show-modal="showModal"
      @decline="showModal = false"
      @accept="generateNewPassword"
    >
      <h1 class="font-semibold">
        {{ t('created.request_new_password.modal.title') }}
      </h1>
      <p class="my-8 leading-6">
        {{ t('created.request_new_password.modal.description') }}
      </p>
    </TheModal>
  </div>
</template>

<script setup lang="ts">
import { api } from '@/api/client';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CollectorDetailsResponseDto } from '@careos/toxicology-types';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { PrimeIcon } from 'careos-vue-components';
import TheModal from '@/components/TheModal.vue';

const { t } = useI18n();
const newGeneratedPassword = ref();
const showModal = ref(false);

const props = defineProps<{
  details: Extract<CollectorDetailsResponseDto, { orderType: 'CoC' }>;
  requisitionId: string;
}>();

const { exec: generateNewPassword } = useNetworkRequest(async () => {
  const date = new Date().toISOString();
  const { data } = await api.generateDonorPassword({
    generatedAt: date,
    requisitionId: props.requisitionId,
  });
  newGeneratedPassword.value = data.password;
  showModal.value = false;
});
</script>
