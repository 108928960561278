<template>
  <OrderViewWrapper>
    <OrderViewCard
      :title="t('testType.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!store.testToOrderTypes.selectedTestToOrderType"
      :show-backwards="true"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <div class="grid gap-4" :class="rowClass">
        <div
          v-for="testToOrderType in store.testToOrderTypes.options"
          :key="testToOrderType.orderType"
        >
          <RadioButton
            :label="t(`testType.typeOptions.${testToOrderType.testType}`)"
            :selected="
              testToOrderType === store.testToOrderTypes.selectedTestToOrderType
            "
            @click="selectedTestToOrderType(testToOrderType)"
          />
        </div>

        <TheModal
          :show-modal="showModal"
          @accept="changeChosenTestType"
          @decline="showModal = false"
        >
          {{ t('testType.confirmTestTypeChange') }}</TheModal
        >
      </div>
    </OrderViewCard>
  </OrderViewWrapper>
</template>

<script setup lang="ts">
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import RadioButton from '../../components/RadioButton.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import { TestToOrderType } from '@careos/organization-api-types';
import TheModal from '@/components/TheModal.vue';

import { ref } from 'vue';

const { t } = useI18n();
const store = useOrderViewStore();

const showModal = ref(false);
const selectedOpt = ref();
const rowClass =
  store.testToOrderTypes.options.length < 2 ? 'grid-rows-1' : 'grid-rows-2';

const nextStep = () => {
  router.push({
    path: '/order/personal-details',
  });
};

const previousStep = () => {
  router.go(-1);
};

const selectedTestToOrderType = (selectedOption: TestToOrderType) => {
  if (
    store.selectedTestType &&
    store.selectedTestType !== selectedOption.testType
  ) {
    selectedOpt.value = selectedOption;
    showModal.value = true;
    return;
  }
  store.testToOrderTypes.selectedTestToOrderType = selectedOption;
  nextStep();
};

const changeChosenTestType = () => {
  store.clearPartialOrder();
  store.testToOrderTypes.selectedTestToOrderType = selectedOpt.value;
  showModal.value = false;
  nextStep();
};
</script>
