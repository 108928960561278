import { z } from 'zod';

export const RemoteSignatureEventStatusSchema = z.enum([
  'DONOR_AUTHENTICATED',
  'DONOR_SIGNED',
]);
export type RemoteSignatureEventStatusType = z.infer<
  typeof RemoteSignatureEventStatusSchema
>;
export const RemoteSignatureEventStatusValues =
  RemoteSignatureEventStatusSchema.Values;

export const RemoteSignatureEventMessageDtoSchema = z.object({
  status: RemoteSignatureEventStatusSchema.or(z.literal('HEARTBEAT')),
  transactionId: z.string().uuid(),
});

export type RemoteSignatureEventMessageDto = z.infer<
  typeof RemoteSignatureEventMessageDtoSchema
>;
