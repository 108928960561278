import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { nonEmptyString } from '@careos/types';

import { z } from 'zod';

export class SubmitOrderRequestDto extends createSchemaClass(
  z.object({
    transactionId: nonEmptyString,
  }),
) {}
