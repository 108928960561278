import { z } from 'zod';

export const KnownHintCodeSchema = z.enum([
  'outstandingTransaction',
  'noClient',
  'started',
  'userMrtd',
  'userCallConfirm',
  'userSign',
  'expiredTransaction',
  'certificateErr',
  'userCancel',
  'cancelled',
  'startFailed',
  'userDeclinedCall',
  'notSupportedByUserApp',
]);
export type KnownHintCode = z.infer<typeof KnownHintCodeSchema>;
export const KnownHintCode = KnownHintCodeSchema.options;
