import { z } from 'zod';
import {
  AnnotationSchema,
  IdentifierSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsMarkedForRerunEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsMarkedForRerunEvent'),
  eventData: z.object({
    /**
     * Barcode of the specimen marked for rerun.
     */
    barcode: IdentifierSchema,
    /**
     * Ids of observations that are deemed to need a rerun to validate the results.
     */
    observationIds: z.string().array(),
    /**
     * Technical comment for medical approval.
     */
    note: AnnotationSchema.optional(),
    /**
    /**
     * Reference to who marked the specimen for rerun.
     */
    markedBy: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * This event represents that the specimen's result need
 * to be verified in a rerun.
 */
export class ObservationsMarkedForRerunEvent extends createSchemaClass(
  ObservationsMarkedForRerunEventSchema,
) {}
