<template>
  <CareosTable
    v-model:editingRows="editingRows"
    v-model:filters="filters"
    :loading="!!loading"
    edit-mode="row"
    data-key="id"
    :value="users"
    :paginator="true"
    :rows="10"
    :rows-per-page-options="[5, 10, 15]"
    filter-display="menu"
    sort-field="createdAt"
    :sort-order="-1"
    @row-edit-save="onRowEditSave"
    @row-edit-init="onRowInit"
    @row-edit-cancel="isEditing = false"
  >
    <Column
      field="name"
      :show-filter-match-modes="false"
      :header="t('admin_board.table.headers.name')"
      data-type="string"
      sortable
    >
      <template #editor="{ data, field }">
        <div class="relative flex flex-col">
          <InputText
            v-model="data[field]"
            aria-describedby="edit-username-help"
            :maxlength="80"
          />
          <small
            v-if="data[field]?.length === 80"
            id="edit-username-help"
            class="text-orange-500"
          >
            {{ t('admin_board.add_user_form.max_length_warning') }}</small
          >
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="t('admin_board.table.headers.name')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="createdAt"
      :show-filter-match-modes="false"
      data-type="date"
      sortable
    >
      <template #header>
        {{ t('admin_board.table.headers.added') }}
        <div
          v-tooltip.right="{
            value: t('admin_board.table.headers.created_at_tooltip'),
            class: 'tooltip-style',
          }"
          class="flex"
        >
          <PrimeIcon icon="INFO_CIRCLE" size="XS" class="ml-2" />
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
      <template #body="{ data }">{{
        dayjs(data.createdAt).format('YYYY-MM-DD')
      }}</template>
    </Column>
    <Column
      field="email"
      :show-filter-match-modes="false"
      :header="t('admin_board.table.headers.email')"
      sortable
      data-type="string"
    >
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" />
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="t('admin_board.table.headers.email')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          ref="filterCancel"
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="roles"
      :show-filter-match-modes="false"
      :header="t('admin_board.table.headers.role')"
    >
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown
          v-model="filterModel.value"
          :options="
            [
              ...new Set(
                users.map((it) => it.roles).flatMap(([{ name }]) => name),
              ),
            ].map((it) => ({
              label: t(`admin_board.table.filters.${it}`),
              value: it,
            }))
          "
          option-label="label"
          option-value="value"
          class="p-column-filter"
          :placeholder="t('admin_board.table.headers.role')"
          @change="filterCallback"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
      <template #editor="{ data }">
        <Dropdown
          v-model="data.roles"
          :option-label="
            ({ name }) =>
              t(`admin_board.user_details.role_${name.toLowerCase()}_long`)
          "
          :option-value="(it) => [it]"
          :options="roles"
          class="md:w-14rem w-full"
        >
          <template #value="{ value }">
            {{
              t(
                `admin_board.user_details.role_${value
                  .at(0)
                  .name.toLowerCase()}_long`,
              )
            }}
          </template>
        </Dropdown>
      </template>
      <template #body="{ data }">
        <span v-for="role in data.roles" :key="role">
          {{ t(`admin_board.user_details.role_${role.name.toLowerCase()}`) }}
        </span>
      </template>
    </Column>
    <Column :header="t('admin_board.table.headers.notification')">
      <template #body="{ data }">
        <button
          v-if="shouldShowNotificationButton(data.roles)"
          v-tooltip.top="
            isNotificationEnabled(data.roles)
              ? t('admin_board.notification.tooltip.deactivate')
              : t('admin_board.notification.tooltip.activate')
          "
          class="group cursor-pointer rounded-full"
          @click.capture="handleNotificationClick(data)"
        >
          <PrimeIcon
            icon="BELL"
            :class="[
              'align-center flex cursor-pointer transition group-hover:scale-110',
              isNotificationEnabled(data.roles)
                ? 'text-green-500'
                : 'text-gray-500',
            ]"
          />
        </button>
      </template>
    </Column>
    <Column
      :row-editor="true"
      style="width: 10%; min-width: 8rem"
      body-style="text-align:center"
    >
      <template #roweditorcancelicon>X </template>
    </Column>
    <Column :hidden="!isEditing">
      <template #editor>
        <button
          v-tooltip.top="{
            value: t('admin_board.user_details.reset_auth_tooltip'),
          }"
          class="group cursor-pointer rounded-full"
          @click.capture="showResetMfaModal = true"
        >
          <PrimeIcon
            icon="SHIELD"
            class="cursor-pointer text-yellow-500 transition group-hover:scale-110"
          /></button
      ></template>
    </Column>
    <Column :hidden="!isEditing">
      <template #editor>
        <button
          v-tooltip.top="{
            value: t('admin_board.send_email.tool_tip'),
          }"
          class="group cursor-pointer rounded-full"
          @click.capture="showResendEmailModal = true"
        >
          <PrimeIcon
            icon="INBOX"
            class="cursor-pointer text-blue-500 transition group-hover:scale-110"
          /></button
      ></template>
    </Column>
    <Column :hidden="!isEditing">
      <template #editor>
        <button
          class="group cursor-pointer rounded-full"
          @click.capture="showDeleteModal = true"
        >
          <PrimeIcon
            icon="TRASH"
            class="cursor-pointer text-red-500 transition group-hover:scale-110"
          /></button
      ></template>
    </Column>
  </CareosTable>
  <TheModal
    :show-modal="showDeleteModal"
    :loading="isLoadingDelete"
    @decline="showDeleteModal = false"
    @accept="confirmUserDelete"
  >
    <p>
      {{ t('admin_board.remove_user_heading') }}
    </p>
    <br />
    <p>
      {{ t('admin_board.user_details.name') }}:
      <b>{{ initEditUserData?.name }}</b>
    </p>
    <p>
      {{ t('admin_board.user_details.email') }}:
      <b>{{ initEditUserData?.email }}</b>
    </p>
    <p>
      {{ t('admin_board.user_details.role') }}:
      <b>{{
        t(
          `admin_board.user_details.role_${initEditUserData.roles
            .at(0)
            .name.toLowerCase()}_long`,
        ) || '-'
      }}</b>
    </p>
  </TheModal>
  <TheModal
    :show-modal="showModal"
    :loading="isLoadingEdit"
    @decline="showModal = false"
    @accept="confirmUserEdit"
  >
    <p class="mb-8 font-semibold">
      {{ t('admin_board.user_details.changes') }}:
    </p>

    <div class="flex gap-12">
      <div>
        <h3 class="mb-4">
          {{ t('admin_board.user_details.current') }}
        </h3>
        <UserEditDetailsList
          v-if="currentUserDetails"
          class="bg-slate-100 p-2"
          :user-details="currentUserDetails"
        />
      </div>
      <div>
        <h3 class="mb-4">{{ t('admin_board.user_details.new') }}</h3>
        <UserEditDetailsList
          v-if="updateUserDetails"
          class="bg-slate-100 p-2"
          :user-details="updateUserDetails"
        />
      </div>
    </div>
  </TheModal>
  <TheModal
    :show-modal="showContactModal"
    :hide-accept-button="true"
    @decline="showContactModal = false"
  >
    <p class="mt-4">
      Please contact ABC Labs if you need to edit this user's name or e-mail.
    </p>
  </TheModal>

  <TheModal
    :show-modal="showResetMfaModal"
    :loading="isLoadingResetMfa"
    @decline="showResetMfaModal = false"
    @accept="confirmResetMfa"
  >
    <p class="mt-4">
      {{ t('admin_board.mfa_modal.msg_prefix') }}:
      <b>{{ initEditUserData?.name }}.</b>
    </p>
    <p>
      {{ t('admin_board.mfa_modal.msg_suffix') }}
    </p>
  </TheModal>

  <TheModal
    :show-modal="showNotificationModal"
    :loading="isLoadingNotification"
    @decline="showNotificationModal = false"
    @accept="confirmNotificationUpdate"
  >
    <p class="mt-4">
      {{ t('admin_board.notification.modal.msg_prefix') }}
      <b>
        {{
          isNotificationEnabled(initEditUserData.roles)
            ? t('admin_board.notification.modal.deactivate')
            : t('admin_board.notification.modal.activate')
        }}
      </b>
      {{ t('admin_board.notification.modal.msg_suffix') }}
      <b>{{ initEditUserData?.name }}.</b>
    </p>
  </TheModal>

  <TheModal
    :show-modal="showResendEmailModal"
    :loading="isLoadingReSendEmail"
    @decline="showResendEmailModal = false"
    @accept="confirmReSendWelcomeEmail"
  >
    <p class="mt-4">
      {{ t('admin_board.send_email.modal.msg_prefix') }}
      <b>{{ initEditUserData?.email }}.</b>
    </p>
    <p>{{ t('admin_board.send_email.modal.msg_suffix') }}</p>
  </TheModal>
</template>

<script setup lang="ts">
import CareosTable from '@/components/CareosTable.vue';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import {
  UserViewState,
  useUsersStore,
  UserFromResponseDto,
} from '../../../stores/users';
import dayjs from 'dayjs';
import Column from 'primevue/column';
import { ComponentPublicInstance, ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import {
  OrganizationTypeSchema,
  RemoveUsersFromOrganizationRequestDto,
  UpdateUserRequestDto,
  UpdateUserNotificationRequestDto,
} from '@careos/organization-api-types';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import TheModal from '@/components/TheModal.vue';
import {
  DataTableFilterMeta,
  DataTableRowEditInitEvent,
  DataTableRowEditSaveEvent,
} from 'primevue/datatable';
import isBetween from 'dayjs/plugin/isBetween';
import { FilterService, FilterMatchMode } from 'primevue/api';
import { usePrimeVue } from 'primevue/config';
import Calendar from 'primevue/calendar';
import { useToast } from 'primevue/usetoast';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import UserEditDetailsList from './UserEditDetailsList.vue';
import { useI18n } from 'vue-i18n';
import { Role } from '@careos/organization-api-types/src/user/dto/role';
import { PrimeIcon } from 'careos-vue-components';
import { ToastMessageOptions } from 'primevue/toast';
import { NotificationType } from '@careos/organization-api-types/src/user/types/notification.types';

defineProps<{
  users: UserViewState['users'];
  roles: UserViewState['roles'];
  loading?: boolean;
}>();

dayjs.extend(isBetween);
const toast = useToast();
const route = useRoute();
const { t, locale } = useI18n();
const primeVue = usePrimeVue();
const editingRows = ref([]);
const showModal = ref(false);
const showContactModal = ref(false);
const updateUserDto = ref<UpdateUserRequestDto | undefined>();
const usersStore = useUsersStore();
const filterApply = ref<ComponentPublicInstance | null>(null);

const currentUserDetails = ref<UserFromResponseDto | undefined>();
const updateUserDetails = ref<UserFromResponseDto | undefined>();
const initEditUserData = ref();
const isEditing = ref(false);
const showDeleteModal = ref(false);
const showResetMfaModal = ref(false);
const showNotificationModal = ref(false);
const showResendEmailModal = ref(false);

FilterService.register(
  'dateInRange',
  (value: string | null, filter: [Date, Date] | null): boolean => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const isRange = filter.at(0) && filter.at(1);
    return isRange
      ? dayjs(value).isBetween(filter[0], filter[1], 'date', '[]')
      : dayjs(value).isSame(filter[0], 'date');
  },
);

FilterService.register('rolesMatch', (value: Role[], filter) => {
  if (filter === undefined || filter === null) {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }

  return value.some((it) => it.name === filter);
});

const filterDefaults: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: { value: null, matchMode: 'dateInRange' },
  email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  roles: { value: null, matchMode: 'rolesMatch' },
};

const filterDefaultClone = () => {
  const defaultFilters: DataTableFilterMeta = {};
  Object.keys(filterDefaults).forEach((key) => {
    defaultFilters[key] = structuredClone(filterDefaults[key]);
  });
  return defaultFilters;
};
const filters = ref<DataTableFilterMeta>(filterDefaultClone());

const { organizationKey, organizationType } = route.params;
const orgType = OrganizationTypeSchema.parse(organizationType);

const isSameValues = (
  user: UserFromResponseDto,
  newUser: UserFromResponseDto,
) =>
  user.name === newUser.name &&
  user.email === newUser.email &&
  user.roles?.at(0)?.auth0Id === newUser.roles?.at(0)?.auth0Id;

const nameOrEmailChanged = (
  user: UserFromResponseDto,
  newUser: UserFromResponseDto,
) => user.name !== newUser.name || user.email !== newUser.email;

const onRowInit = ({ data }: DataTableRowEditInitEvent) => {
  isEditing.value = true;
  initEditUserData.value = data;
};

const onRowEditSave = async ({
  data: user,
  newData: newUser,
}: DataTableRowEditSaveEvent) => {
  if (isSameValues(user, newUser)) return;
  currentUserDetails.value = user;
  updateUserDetails.value = newUser;
  if (nameOrEmailChanged(user, newUser)) {
    const editCheckDto = {
      id: newUser.id,
    };
    const { isUserNameOrEmailEditable } =
      await usersStore.checkUserForEditing(editCheckDto);
    if (!isUserNameOrEmailEditable) {
      showContactModal.value = true;
      return;
    }
  }
  showModal.value = true;
  const { email, id, name, roles }: UserFromResponseDto = newUser;

  updateUserDto.value = {
    id,
    organizationKey: organizationKey as string,
    organizationType: orgType,
    updatedRoles: roles,
    currentRoles: currentUserDetails.value?.roles,
    email,
    name,
  };
  isEditing.value = false;
};

const renderToastMsg = ({
  severity,
  summary,
  detail,
}: {
  severity: ToastMessageOptions['severity'];
  summary: ToastMessageOptions['summary'];
  detail?: ToastMessageOptions['detail'];
}) => {
  if (severity === 'success') {
    toast.add({
      life: 5_000,
      severity,
      summary,
    });
    return;
  }
  toast.add({
    life: 12_000,
    severity,
    summary,
    detail,
  });
};

const editUser = async () => {
  if (updateUserDto.value) {
    const { doesEmailAlreadyExist } = await usersStore.updateUser(
      updateUserDto.value,
    );
    if (doesEmailAlreadyExist) {
      renderToastMsg({
        severity: 'error',
        summary: `Unable to edit email: ${updateUserDto.value.email}`,
        detail: `The email: ${updateUserDto.value.email} is already in use. Please create a new user with the new email instead.`,
      });
    }
  }
  renderToastMsg({
    severity: 'success',
    summary: t('admin_board.user_edited_msg'),
  });
  showModal.value = false;
};

const resetMfa = async () => {
  const { mfaReset } = await usersStore.resetMfa({
    id: initEditUserData.value.id,
  });
  if (!mfaReset) {
    renderToastMsg({
      severity: 'error',
      summary: `Unable to reset MFA.`,
      detail: `User was not enrolled to MFA. Use resend-email button to send instructions for activating mfa to user.`,
    });
  } else {
    renderToastMsg({
      severity: 'success',
      summary: 'MFA for user was successfully reset.',
    });
  }
  showResetMfaModal.value = false;
  editingRows.value = [];
  isEditing.value = false;
};

const resendWelcomeEmail = async () => {
  const { emailReSend } = await usersStore.resendWelcomeEmail({
    email: initEditUserData.value.email,
    organizationKey: organizationKey as string,
  });

  if (!emailReSend) {
    renderToastMsg({
      severity: 'error',
      summary: `Unable to send email.`,
    });
  } else {
    renderToastMsg({
      severity: 'success',
      summary: 'Email to user was successfully sent.',
    });
  }

  showResendEmailModal.value = false;
  editingRows.value = [];
  isEditing.value = false;
};

const updateUserNotifications = async () => {
  const { notifications } = initEditUserData.value.roles.at(0);
  const { userOrganizationRoleId, roleNotificationType, enabled } =
    notifications.at(0);

  const requestDto = UpdateUserNotificationRequestDto.schema.safeParse({
    userOrganizationRoleId,
    roleNotificationType,
    enabled: !enabled,
  });

  if (!requestDto.success) {
    const errorMsg = requestDto.error.format();
    renderToastMsg({
      severity: 'error',
      summary: `${t(
        'admin_board.notification.request_update_error',
      )}${errorMsg}`,
    });
    return;
  }

  const { userOrganizationRoleIdUpdated } =
    await usersStore.updateUserNotifications(requestDto.data);

  if (!userOrganizationRoleIdUpdated) {
    renderToastMsg({
      severity: 'error',
      summary: t('admin_board.notification.response_msg.error'),
    });
  } else {
    renderToastMsg({
      severity: 'success',
      summary: t('admin_board.notification.response_msg.success'),
    });
  }
  showNotificationModal.value = false;
  isEditing.value = false;
  editingRows.value = [];
};

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('admin_board.table.filters.january'),
      t('admin_board.table.filters.february'),
      t('admin_board.table.filters.march'),
      t('admin_board.table.filters.april'),
      t('admin_board.table.filters.may'),
      t('admin_board.table.filters.june'),
      t('admin_board.table.filters.july'),
      t('admin_board.table.filters.august'),
      t('admin_board.table.filters.september'),
      t('admin_board.table.filters.october'),
      t('admin_board.table.filters.november'),
      t('admin_board.table.filters.december'),
    ],
    dayNamesMin: [
      t('admin_board.table.filters.sunday'),
      t('admin_board.table.filters.monday'),
      t('admin_board.table.filters.tuesday'),
      t('admin_board.table.filters.wednesday'),
      t('admin_board.table.filters.thursday'),
      t('admin_board.table.filters.friday'),
      t('admin_board.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);

const deleteUser = async () => {
  const { notifications } = initEditUserData.value.roles.at(0);
  const userOrganizationRoleId = notifications?.at(0)?.userOrganizationRoleId;

  const deleteUserDto: RemoveUsersFromOrganizationRequestDto = {
    userDbIds: [initEditUserData.value.id],
    organizationKey: organizationKey as string,
    organizationType: orgType,
    userOrganizationRoleId,
  };

  await usersStore.removeUserFromOrg(deleteUserDto);
  renderToastMsg({
    severity: 'success',
    summary: t('admin_board.user_deleted_msg'),
  });
  showDeleteModal.value = false;
};

const { exec: confirmUserDelete, isLoading: isLoadingDelete } =
  useNetworkRequest(async () => {
    await deleteUser();
  });

const { exec: confirmUserEdit, isLoading: isLoadingEdit } = useNetworkRequest(
  async () => {
    await editUser();
  },
);
const { exec: fetchUsers } = useNetworkRequest(() =>
  usersStore.fetchUsers(orgType, organizationKey as string),
);

const { exec: confirmResetMfa, isLoading: isLoadingResetMfa } =
  useNetworkRequest(async () => {
    await resetMfa();
  });

const shouldShowNotificationButton = (roles: UserViewState['roles']) => {
  const roleNotificationType = roles
    .find((role) => role)
    ?.notifications?.at(0)?.roleNotificationType;

  return roleNotificationType === NotificationType['New results reported'];
};

const isNotificationEnabled = (roles: UserViewState['roles']) =>
  roles.find((role) => role)?.notifications?.at(0)?.enabled;

const { exec: confirmNotificationUpdate, isLoading: isLoadingNotification } =
  useNetworkRequest(async () => {
    await updateUserNotifications();
    await fetchUsers();
  });

const handleNotificationClick = (data: UpdateUserRequestDto) => {
  initEditUserData.value = data;
  showNotificationModal.value = true;
};

const { exec: confirmReSendWelcomeEmail, isLoading: isLoadingReSendEmail } =
  useNetworkRequest(async () => {
    await resendWelcomeEmail();
  });

onBeforeRouteLeave(() => {
  usersStore.$reset();
});
</script>
