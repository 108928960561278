import { KeyNameSchema } from '@careos/types';
import { z } from 'zod';

export const GetAllOrganizationsResponseDtoSchema = z.object({
  organizations: KeyNameSchema.extend({
    customer: KeyNameSchema,
  }).array(),
});

export type GetAllOrganizationsResponseDto = z.infer<
  typeof GetAllOrganizationsResponseDtoSchema
>;
