import { z } from 'zod';

export const SystemSchema = z.enum([
  'CAREOS_MOCKS',
  'CAREOS_PEP_INTEGRATION',
  'ABC_LIMS_SAMPLE_REGISTRATION',
  'CAREOS_TOXICOLOGY',
  'CAREOS_TOXI_PETH',
  'CGM_ANALYTIX',
  'CAREOS_BARCODE_PRINTER',
  'CAREOS_BLINK',
  'CAREOS_ORGANIZATION_API',
  'CAREOS_CGM_ADAPTER',
  'CAREOS_FILE_PERSISTER',
  'CAREOS_OBSERVATIONS_FINALIZER',
  'GOOGLE_CLOUD_STORAGE',
  'CAREOS_TOXI_API',
  'CAREOS_MRO',
  'CAREOS_WORKPLACE_SOLUTIONS',
  'CAREOS_MAESTRO',
  'CAREOS_GATES',
]);

export const System = SystemSchema.Enum;
export type System = z.infer<typeof SystemSchema>;

export const isSystem = (value: unknown): value is System =>
  SystemSchema.safeParse(value).success;

export const isSystemOriginToxicology = (system: unknown): system is System => {
  return system === System.CAREOS_TOXICOLOGY;
};

export const isSystemOriginPeth = (system: unknown): system is System => {
  return system === System.CAREOS_TOXI_PETH;
};

export const isSystemOriginToxiApi = (system: unknown): boolean => {
  return system === System.CAREOS_TOXI_API;
};
