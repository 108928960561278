import { z } from 'zod';

export const SamplingSessionStatusSchema = z.enum([
  'requested',
  'started',
  'finished',
  'reported',
]);
export const SamplingSessionStatus = SamplingSessionStatusSchema.Enum;
export type SamplingSessionStatus = z.infer<typeof SamplingSessionStatusSchema>;
