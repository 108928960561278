import { z } from 'zod';
import { ReferenceSchema } from '../reference.interface';

export const PlanDefinitionSchema = z.object({
  planDefinitionId: z.string(),
  /**
   * Human readable title
   */
  title: z.string(),
  /**
   * An action or group of actions to be taken as part of the plan.
   */
  actions: z.array(
    z.object({
      definition: ReferenceSchema(z.enum(['ActivityDefinition'])),
    }),
  ),
});

/**
 * Pre-defined group of actions to be taken in particular circumstances
 */
export type PlanDefinition = z.infer<typeof PlanDefinitionSchema>;
