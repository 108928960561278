<template>
  <div
    class="flex min-w-[124px] flex-col divide-y divide-careos-purple text-careos-purple"
  >
    <ResultReportDownloadListItem
      :label="t('table.drug_panel')"
      report-type="DoA"
      :reported="true"
      @on-click-show-result="$emit('on-click-show-result', $event)"
    />
    <ResultReportDownloadListItem
      v-if="chiralStatus !== 'not-ordered'"
      report-type="chiral"
      :label="t('table.chiral_analysis')"
      :reported="chiralStatus === 'reported'"
      @on-click-show-result="$emit('on-click-show-result', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ResultReportDownloadListItem from './ResultReportDownloadListItem.vue';
import { ExtraResultStatus } from '@careos/toxicology-types';

const { t } = useI18n();

defineEmits(['on-click-show-result']);
defineProps<{
  chiralStatus: ExtraResultStatus;
}>();
</script>
