import { z } from 'zod';
import { OrganizationSchema } from '../../../organization/organization';
import { UserSchema } from '../user';

export const GetUsersByOrganizationResponseDtoSchema = z.object({
  organizationName: OrganizationSchema.shape.name,
  organizationLoginName: OrganizationSchema.shape.loginName,
  users: z.array(UserSchema),
});

export type GetUsersByOrganizationResponseDto = z.infer<
  typeof GetUsersByOrganizationResponseDtoSchema
>;
