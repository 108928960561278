import {
  SamplingKitIdentifierSchema,
  ToxicologyActivityIdentifierSchema,
} from '@careos/identifiers';
import { TestTypeSchema } from '@careos/organization-api-types';
import { z } from 'zod';
import { PanelSizeSchema } from '../config/panel';

export const ToxicologySamplingDetailsSchema = z.object({
  testType: TestTypeSchema,
  sampleType: ToxicologyActivityIdentifierSchema.nullish(),
  samplingKit: SamplingKitIdentifierSchema.nullish(),
  panelSize: PanelSizeSchema.nullish(),
  additionalSubstances: z.array(z.string()).min(1).nullish(),
});

export type ToxicologySamplingDetails = z.infer<
  typeof ToxicologySamplingDetailsSchema
>;

export const ToxicologySamplingSessionDetailsSchema = z
  .object({
    testType: TestTypeSchema,
    sampleType: ToxicologyActivityIdentifierSchema,
    samplingKit: SamplingKitIdentifierSchema.nullish(),
    panelSize: PanelSizeSchema,
    additionalSubstances: z.array(z.string()).nullish(),
  })
  .refine(
    (data) => data.sampleType === 'DOA_URINE_SAMPLING' || !!data.samplingKit,
    {
      message: 'SamplingKit is required for all sample types except urine.',
    },
  );

export type ToxicologySamplingSessionDetails = z.infer<
  typeof ToxicologySamplingSessionDetailsSchema
>;
