<template>
  <OrderViewWrapper>
    <OrderViewCard
      class="md:min-w-[450px]"
      data-test-id="medical-history"
      :title="t('comment.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!noPrescriptionsCheckbox && !freeTextComment"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <FormKit
        v-model.trim="freeTextComment"
        type="textarea"
        rows="5"
        :placeholder="
          store.selectedTestType === 'DoA'
            ? t('comment.prescriptions')
            : t('comment.placeholder')
        "
        :disabled="noPrescriptionsCheckbox"
      />

      <CareosFormCheckbox
        v-model="noPrescriptionsCheckbox"
        :disabled="!!freeTextComment.length"
        :label="
          isPethOrder ? t('comment.no_comments') : t('comment.no_prescriptions')
        "
      />
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { router } from '@/router';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import CareosFormCheckbox from '@/components/CareosFormCheckbox.vue';

const { t } = useI18n();
const store = useOrderViewStore();
const freeTextComment = ref('');
const noPrescriptionsCheckbox = ref(store.comment.noPrescriptionsCheckbox);

const fetchCommentFromStore = () => {
  if (store.comment.value === null) return;
  if (noPrescriptionsCheckbox.value) return;

  freeTextComment.value = store.comment.value;
};

const isPethOrder = computed(
  () => store.selectedSpecimen === 'PETH_DRIED_BLOOD_SPOTS',
);

const previousStep = () => {
  router.go(-1);
};

const nextStep = () => {
  store.comment.noPrescriptionsCheckbox = noPrescriptionsCheckbox.value;
  const defaultComment =
    store.selectedTestType === 'DoA'
      ? t('comment.no_prescriptions')
      : t('comment.no_comments');

  store.comment.value = noPrescriptionsCheckbox.value
    ? defaultComment
    : freeTextComment.value;

  const view =
    store.selectedSpecimen === 'DOA_URINE_SAMPLING' ? 'temperature' : 'barcode';
  router.push(`/order/${view}`);
};

fetchCommentFromStore();
</script>
