<template>
  <div class="grid min-h-full place-content-center">
    <div class="w-[300px] rounded-md bg-white p-4 sm:p-8">
      <FormKit
        id="randomizerForm"
        type="form"
        :submit-label="t('randomizer_form.button_label')"
        :submit-attrs="{
          inputClass: '!rounded !p-2',
        }"
        @submit="handleSubmit"
      >
        <FormKit
          type="text"
          validation="required|number"
          name="maxNumber"
          :label="t('randomizer_form.total_on_site')"
          inputmode="numeric"
        />
        <FormKit
          type="text"
          validation="required|number"
          name="amountToGenerate"
          inputmode="numeric"
          :label="t('randomizer_form.total_to_test')"
        />
      </FormKit>
      <p
        v-if="randomNumberSeries"
        class="mt-8 rounded bg-slate-100 p-2 text-left"
      >
        <template v-for="number in randomNumberSeries" :key="number">
          {{ number }}{{ ' ' }}
        </template>
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ToxiHttpClient } from '@/api/toxicology-client';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import {
  GenerateRandomNumberSeriesRequestDto,
  GenerateRandomNumberSeriesResponseDto,
} from '@careos/toxicology-types';
import { ref } from 'vue';
import { reset } from '@formkit/core';
const { t } = useI18n();

const ENDPOINT = '/randomizer/generate-random-number-series' as const;

const randomNumberSeries =
  ref<GenerateRandomNumberSeriesResponseDto['randomNumberSeries']>();

const { exec: generateNumbers } = useNetworkRequest(
  async (dto: GenerateRandomNumberSeriesRequestDto) => {
    const res =
      await ToxiHttpClient.post<GenerateRandomNumberSeriesResponseDto>(
        ENDPOINT,
        dto,
      );
    randomNumberSeries.value = res.data.randomNumberSeries;
    reset('randomizerForm');
  },
);

const handleSubmit = ({
  maxNumber,
  amountToGenerate,
}: GenerateRandomNumberSeriesRequestDto) => {
  const dto = {
    maxNumber: Number(maxNumber),
    amountToGenerate: Number(amountToGenerate),
  };
  generateNumbers(dto);
};
</script>
