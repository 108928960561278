<template>
  <div
    class="mx-2 my-4 max-w-[100vw] rounded-xl border bg-white p-5 drop-shadow-md"
  >
    <SessionsTable
      v-if="sessions"
      :loading="isLoadingSessions"
      :sessions="sessions"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ToxiHttpClient } from '@/api/toxicology-client';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import SessionsTable from './components/SessionsTable.vue';
import {
  SamplingSession,
  GetSamplingSessionsResponseDto,
} from '@careos/toxicology-types';

const sessions = ref<SamplingSession[]>();

const { exec: fetchSessions, isLoading: isLoadingSessions } = useNetworkRequest(
  async () => {
    const res = await ToxiHttpClient.get<GetSamplingSessionsResponseDto>(
      '/sampling-sessions/get-sampling-sessions',
    );
    sessions.value = res.data.samplingSessions;
  },
);

fetchSessions();
</script>
