import { z } from 'zod';
import { BaseOrganizationSchema } from '../../../organization';

export const ToxicologyTreatmentCenterSchema = BaseOrganizationSchema;

export type ToxicologyTreatmentCenter = z.infer<
  typeof ToxicologyTreatmentCenterSchema
>;

export const GetToxicologyTreatmentCentersResponseDtoSchema =
  ToxicologyTreatmentCenterSchema.array();

export type GetToxicologyTreatmentCentersResponseDto = z.infer<
  typeof GetToxicologyTreatmentCentersResponseDtoSchema
>;
