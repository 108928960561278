import { z } from 'zod';
import { BankIdCompletionDataSchema } from './bankid-completion-data';
import { BankIdStatusSchema } from './const/status';

export const BankIdCollectResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: BankIdStatusSchema,
  hintCode: z.string().optional(),
  completionData: BankIdCompletionDataSchema.optional(),
});

export type BankIdCollectResponseDto = z.infer<
  typeof BankIdCollectResponseDtoSchema
>;
