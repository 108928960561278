import { z } from 'zod';
import {
  BarcodeSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

// INFO: These reasons are approved by QA and are the only reasons we provide the practitioners to choose from.
export const UserDeregisterSpecimenReasonTypeSchema = z.enum([
  'DUPLICATE_SPECIMEN',
  'OTHER',
]);

export type UserDeregisterSpecimenReasonType = z.infer<
  typeof UserDeregisterSpecimenReasonTypeSchema
>;
export const UserDeregisterSpecimenReasonTypeOptions =
  UserDeregisterSpecimenReasonTypeSchema.options;

export const SpecimenDeregisterReasonTypeSchema = z.enum([
  ...UserDeregisterSpecimenReasonTypeOptions,
  'INCORRECT_REGISTRATION',
  'PARCEL_DEREGISTERED',
]);
export type SpecimenDeregisterReasonType = z.infer<
  typeof SpecimenDeregisterReasonTypeSchema
>;
export const SpecimenDeregisterReasonType =
  SpecimenDeregisterReasonTypeSchema.Enum;

export const SpecimenDeregisteredInLabEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenDeregisteredInLabEvent'),
  eventData: z.object({
    /**
     * {@link Barcode} of the Specimen that was deregistered.
     */
    barcode: BarcodeSchema,
    /**
     * SpecimenId, identifier for this unique specimen.
     */
    specimenId: z.string(),
    /**
     * When the specimen was deregistered by the lab.
     */
    deregisteredAt: IsoDateTimeSchema,
    /**
     * An object holding information of the reason for deregistration.
     */
    reason: z.object({
      /**
       * Message that describes the reason.
       */
      message: z.string(),
      /**
       * {@link ReasonType} that describe the deregistration reason type.
       */
      reasonType: z.string(),
    }),

    /**
     * {@link Practitioner} who deregistered the specimen.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')).optional(),
  }),
});

/**
 * A sample has been deregistered by a lab.
 * For example, when undoing an incorrect scan.
 */
export class SpecimenDeregisteredInLabEvent extends createSchemaClass(
  SpecimenDeregisteredInLabEventSchema,
) {}
