import { z } from 'zod';
import { InterpretationCodableConceptSchema } from '../interpretation-codable-concept.interface';

export const InterpretationSchema = z.object({
  /**
   * The observation which is being interpreted
   */
  observationId: z.string(),
  /**
   * A coded type for the identifier that can be used to determine which
   * identifier to use for a specific purpose.
   */
  interpretation: InterpretationCodableConceptSchema,
});

/**
 * Identifier associated with a single object or entity within a given system.
 */
export type Interpretation = z.infer<typeof InterpretationSchema>;
