import { z } from 'zod';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsAcknowledgedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsAcknowledgedEvent'),
  eventData: z.object({
    observationIds: z.string().array(),
  }),
});

export class ObservationsAcknowledgedEvent extends createSchemaClass(
  ObservationsAcknowledgedEventSchema,
) {}
