import { z } from 'zod';

export const AddressSchema = z.object({
  /**
   * Country (e.g. may be ISO 3166 2 or 3 letter code)
   */
  country: z.string(),
  /**
   * Name of city, town etc.
   */
  city: z.string(),
  /**
   * 	Postal code for area
   */
  postalCode: z.string().optional(),
  /**
   * District name (aka county)
   */
  district: z.string().optional(),
  /**
   * Street name, number and/or other address information.
   */
  line: z.string(),

  /**
   * Full text representation of the address.
   */
  text: z.string().optional(),
});

/**
 * Visiting location or mail delivery location.
 */
export type Address = z.infer<typeof AddressSchema>;

export const Location = {
  ABC_LABS_LAB: {
    organization: 'ABC Labs',
    address: {
      country: 'SE',
      city: 'Solna',
      postalCode: '17165',
      line: 'Solnavägen 9',
    },
    i18n: [
      { locale: 'en-US', name: 'Lab at ABC' },
      { locale: 'sv-SE', name: 'Lab på ABC' },
    ],
  },
} as const;
