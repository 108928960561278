import { z } from 'zod';
import {
  BarcodeSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const AssessmentSchema = z.enum([
  'positive',
  'negative',
  'normal',
  'abnormal',
]);
export type Assessment = z.infer<typeof AssessmentSchema>;
export const Assessment = AssessmentSchema.Values;
export const AssessedObservationSchema = z.object({
  /**
   * Reference to the observation.
   */
  observation: ReferenceSchema(z.literal('Observation')),
  /**
   * Assessment of the observation.
   */
  assessment: AssessmentSchema,
});
export type AssessedObservation = z.infer<typeof AssessedObservationSchema>;
export const ObservationsMedicallyAssessedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsMedicallyAssessedEvent'),
  eventData: z.object({
    /**
     * Observations which were medically assessed.
     */
    assessedObservations: AssessedObservationSchema.array(),
    /**
     * Practitioner who medically assessed these observations.
     */
    assessedBy: ReferenceSchema(z.literal('Practitioner')),
    /**
     * When the observations were assessed.
     */
    assessedAt: IsoDateTimeSchema,
    /**
     * Reference to the requisition these observations belong to.
     */
    requisitionId: z.string(),
    /**
     * Reference to the barcode these observations belong to.
     */
    barcode: BarcodeSchema,
  }),
});

/**
 * A MRO has medically assessed {@link Observation}s.
 */
export class ObservationsMedicallyAssessedEvent extends createSchemaClass(
  ObservationsMedicallyAssessedEventSchema,
) {}
