import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const UpdateSubdivisionRequestDtoSchema = z.object({
  subdivisionKey: nonEmptyString,
  newSubdivisionName: nonEmptyString,
  employerKey: nonEmptyString,
  organizationKey: nonEmptyString,
});
export type UpdateSubdivisionRequestDto = z.infer<
  typeof UpdateSubdivisionRequestDtoSchema
>;
