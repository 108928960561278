import { z } from 'zod';

export const GenerateRandomNumberSeriesRequestDtoSchema = z.object({
  maxNumber: z.number().int().positive(),
  amountToGenerate: z.number().int().positive(),
});

export type GenerateRandomNumberSeriesRequestDto = z.infer<
  typeof GenerateRandomNumberSeriesRequestDtoSchema
>;
