import { z } from 'zod';
import { IdentifierSchema, IsoDateTimeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const OrganizationEntityTypeSchema = z.enum([
  'customer',
  'organization',
  'employer',
  'treatment-center',
  'subdivision',
  'user',
]);

export const ActionTypeSchema = z.enum(['create', 'update', 'delete']);

export const OrganizationManagementEventSchema = BaseEventSchema.extend({
  eventType: z.literal('OrganizationManagementEvent'),
  eventData: z.object({
    /**
     * Reference to the organizational entity that was acted up on. Can either be an organization or an user.
     */
    organizationalEntity: IdentifierSchema,
    /**
     * Type of organizational entity. Can be sub types of the organizations or an user.
     */
    organizationEntityType: OrganizationEntityTypeSchema,
    /**
     * Type of action.
     */
    action: ActionTypeSchema,
    /**
     * Reference to the user who took the action.
     */
    user: IdentifierSchema,
    /**
     * Timestamp of the action.
     */
    registeredAt: IsoDateTimeSchema,
  }),
});

export type OrganizationEntityType = z.infer<
  typeof OrganizationEntityTypeSchema
>;

export const OrganizationEntityType = OrganizationEntityTypeSchema.Enum;

export type ActionType = z.infer<typeof ActionTypeSchema>;
export const ActionType = ActionTypeSchema.Enum;

/**
 * This event is triggered when an action about organization management was taken.
 */
export class OrganizationManagementEvent extends createSchemaClass(
  OrganizationManagementEventSchema,
) {}
