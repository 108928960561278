import { z } from 'zod';
import { TestToOrderTypeSchema } from '../../../test-type';

export const GetEnabledTestTypesForOrganizationResponseDtoSchema = z.object({
  testToOrderTypes: TestToOrderTypeSchema.array(),
});

export type GetEnabledTestTypesForOrganizationResponseDto = z.infer<
  typeof GetEnabledTestTypesForOrganizationResponseDtoSchema
>;
