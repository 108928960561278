import { z } from 'zod';
import { RoleSchema } from './role';

export const UserSchema = z.object({
  id: z.string(),
  name: z.string(),
  roles: z.array(RoleSchema),
  email: z.string(),
  createdAt: z.string(),
});

export type User = z.infer<typeof UserSchema>;
