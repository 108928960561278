import { SignatureDataWithDimensionsSchema } from '@careos/toxicology-types';
import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const SignRequisitionPdfSchema = z.object({
  transactionId: nonEmptyString,
  signeeFullName: nonEmptyString,
  signeePersonalNumber: nonEmptyString.optional(),
  locale: z.union([z.literal('sv-SE'), z.literal('en-US')]),
  signature: SignatureDataWithDimensionsSchema,
});

export type SignRequisitionPdfDto = z.infer<typeof SignRequisitionPdfSchema>;
