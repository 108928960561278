<template>
  <div
    class="mx-2 my-4 max-w-[100vw] rounded-xl border bg-white p-5 drop-shadow-md"
  >
    <ResultsTable
      :loading="isLoading"
      :doa-results="results"
      @requisition-handled="handleRequisition"
    />
  </div>
</template>
<script setup lang="ts">
import type { ResultRowDto } from '@careos/toxicology-types';
import { onMounted, ref } from 'vue';

import { ToxiHttpClient } from '@/api/toxicology-client';

import { notUndefined } from '@/utils/no-undefined';
import ResultsTable from './components/ResultsTable.vue';
import { DoaResult } from './types/dto/results.dto';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';

const results = ref<DoaResult[]>([]);
const isLoading = ref(false);
const toast = useToast();
const { t } = useI18n();

const fetchResults = async () => {
  isLoading.value = true;
  try {
    const res = await ToxiHttpClient.get<ResultRowDto[]>('results-table');
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      let detail = e.response?.data.message;
      if (detail === 'auth0_mismatch_org_error_key') {
        detail = t(`general.${detail}`);
      }
      toast.add({
        life: 10_000,
        severity: 'error',
        summary: `${t('general.error_occurred')}. ${t(
          'general.error_is_reported_message',
        )}`,
        detail,
      });
    }
    return [];
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  const response = await fetchResults();
  results.value = response
    .map(
      ({
        acknowledged,
        customer,
        patientIdentifier,
        requisitionId,
        result,
        deviations,
        resultReceived,
        samplingDate,
        barcode,
        employer,
        subDivision,
        collector,
        hasRequisitionPdf,
        treatmentCenter,
        extraResults,
        testType,
      }) => {
        if (!samplingDate) return;
        const doaResult: DoaResult = {
          acknowledged,
          customer,
          patientIdentifier,
          requisitionId,
          samplingDate: new Date(samplingDate),
          result,
          hasRequisitionPdf,
          deviations,
          resultReceived: resultReceived ? new Date(resultReceived) : undefined,
          barcode,
          employer: employer ?? undefined,
          subDivision: subDivision ?? undefined,
          collector: collector ?? undefined,
          treatmentCenter: treatmentCenter ?? undefined,
          extraResults,
          testType,
        };
        return doaResult;
      },
    )
    .filter(notUndefined);
});

const handleRequisition = async ({
  value,
  requisitionId,
}: {
  value: boolean;
  requisitionId: string;
}) => {
  try {
    const result = results.value.find(
      (requisition) => requisition.requisitionId === requisitionId,
    );
    if (!result) return;
    result.acknowledged = value;
    if (value) {
      ToxiHttpClient.post('/requisitions/acknowledge', {
        requisitionIds: [requisitionId],
      });
    } else {
      ToxiHttpClient.post('/requisitions/retract-acknowledgement', {
        requisitionIds: [requisitionId],
      });
    }
  } catch (e) {
    const result = results.value.find(
      (requisition) => requisition.requisitionId === requisitionId,
    );
    if (!result) return;
    result.acknowledged = !value;
  }
};
</script>

<style lang="scss">
.p-datatable-table {
  width: 100%;
}
</style>
