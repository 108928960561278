import { z } from 'zod';
import { BankIdCollectResponseDtoSchema } from './native-types';

export const ActiveOrderSchema = z.object({
  qrStartToken: z.string(),
  qrStartSecret: z.string(),
  orderTimestamp: z.number(),
  lastPollTimestamp: z.number(),
  autoStartToken: z.string(),
  collectionResult: BankIdCollectResponseDtoSchema.optional(),
});

export type ActiveOrder = z.infer<typeof ActiveOrderSchema>;
