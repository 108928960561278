<template>
  <button
    class="flex cursor-pointer items-center justify-center rounded px-8 py-3 font-medium focus:ring-4 focus:ring-careos-purple-light active:text-careos-lavendel"
    :class="classObj"
    :disabled="disabled"
  >
    <span v-if="loading">
      <i class="pi pi-spin pi-spinner" />
    </span>
    <span v-else>
      {{ label }}
    </span>
  </button>
</template>
@@ -15,18 +13,27 @@

<script lang="ts" setup>
import { computed, ref } from 'vue';

interface Props {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  btnType?: 'normal' | 'outline';
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  disabled: false,
  loading: false,
  btnType: 'normal',
});
const outlineStyle =
  'bg-white text-careos-purple border border-careos-purple hover:bg-careos-purple hover:text-white transition-all active:text-careos-lavendel';
const normalStyle = ref('bg-careos-purple text-white');
const classObj = computed(() =>
  props.btnType === 'outline' ? outlineStyle : normalStyle.value,
);
</script>
