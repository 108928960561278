import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { NotificationTypeSchema } from '../../types/notification.types';

export class UpdateUserNotificationRequestDto extends createSchemaClass(
  z.object({
    userOrganizationRoleId: nonEmptyString.uuid(),
    roleNotificationType: NotificationTypeSchema,
    enabled: z.boolean(),
  }),
) {}
