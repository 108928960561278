import { BarcodeSchema, createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const CreateResultRemarkRequestDtoSchema = z.object({
  barcode: BarcodeSchema,
  requisitionId: z.string().max(36),
  remark: z.string().max(500),
});

export class CreateResultRemarkRequestDto extends createSchemaClass(
  CreateResultRemarkRequestDtoSchema,
) {}
