import { z } from 'zod';

export const UpdateTreatmentCenterResponseDtoSchema = z.object({
  treatmentCenterKey: z.string().trim(),
  name: z.string().trim().min(1),
});

export type UpdateTreatmentCenterResponseDto = z.infer<
  typeof UpdateTreatmentCenterResponseDtoSchema
>;
