import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import { AnnotationSchema, BarcodeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { IdentifierSystem } from '../constants';

export const ResultRemarkMadeEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ResultRemarkMadeEvent'),
  eventData: z.object({
    /**
     * Reference to the barcode associated with the result.
     */
    barcode: BarcodeSchema,
    /**
     * Reference to the requisition associated with the result.
     */
    requisitionId: z.string(),
    /**
     * Remark made about the result.
     */
    remark: AnnotationSchema,
    /**
     *  Unique id of the remark that was made.
     */
    remarkId: z.string().uuid(),
    /**
     * The system in which the remark was made.
     */
    originSystem: z.nativeEnum(IdentifierSystem),
  }),
});

/**
 * A remark has been made about a result.
 */
export class ResultRemarkMadeEvent extends createSchemaClass(
  ResultRemarkMadeEventSchema,
) {}
