<template>
  <div
    class="flex flex-row w-full justify-evenly rounded-[0.25rem] overflow-hidden h-[38px] items-center cursor-pointer border border-gray-400"
  >
    <div
      class="w-full h-full flex items-center justify-center hover:bg-careos-purple hover:text-white focus:bg-careos-purple focus:outline focus:outline-offset-4 focus:drop-shadow-lg focus:text-white active:brightness-75 active:text-white"
      :class="{
        'bg-careos-purple/90 text-white': selected === left.value,
      }"
      tabindex="0"
      @keydown.enter="handleInput(left.value)"
      @click="handleInput(left.value)"
    >
      {{ left.label }}
    </div>
    <div
      class="w-full h-full flex items-center justify-center hover:bg-careos-purple hover:text-white focus:bg-careos-purple focus:text-white active:brightness-75 active:text-white"
      :class="{
        'bg-careos-purple/90 text-white ': selected === right.value,
      }"
      tabindex="0"
      @click="handleInput(right.value)"
      @keydown.enter="handleInput(right.value)"
    >
      {{ right.label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core';
import { ref } from 'vue';

const props = defineProps<{ context: FormKitFrameworkContext }>();

const left = props.context.left as { label: string; value: unknown };
const right = props.context.right as { label: string; value: unknown };

const selected = ref(props.context.value);

function handleInput(e: unknown) {
  selected.value = e;
  props.context.node.input(e);
}
</script>
