<template>
  <div
    ref="containerRef"
    class="h-fit rounded bg-careos-purple-light text-left @container"
  >
    <div
      class="grid grid-cols-[auto_1fr] gap-y-1 p-2 @xl:grid-cols-[repeat(2,auto_auto)] md:p-4"
      v-bind="$attrs"
    >
      <template v-for="{ value, title } in sortedItems" :key="title">
        <p class="font-semibold text-careos-purple">{{ title }}:</p>
        <p class="ml-4 text-left @xl:ml-0">
          {{ value }}
        </p>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PersonalDetailsItem } from '@/models/personal-details-item';

import { computed, ref } from 'vue';
import { useElementSize } from '@vueuse/core';

const containerRef = ref<HTMLElement | null>(null);
const { width } = useElementSize(containerRef);

const props = defineProps<{
  detailsItems: PersonalDetailsItem[];
}>();

const sortForGrid = (
  detailsItems: PersonalDetailsItem[],
): PersonalDetailsItem[] => {
  // This is done in order to let the items of the array flow top to bottom instead of left to right.
  const arr = [...detailsItems];
  const midIndex = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, midIndex);
  const secondHalf = arr.slice(midIndex);

  let startOrderLeftColumn = 1;
  const sortedFirstHalf = firstHalf.map((it) => {
    const obj = { ...it, order: startOrderLeftColumn };
    startOrderLeftColumn += 2;
    return obj;
  });

  let startOrderRightColumn = 2;
  const sortedSecondHalf = secondHalf.map((it) => {
    const obj = { ...it, order: startOrderRightColumn };
    startOrderRightColumn += 2;
    return obj;
  });

  const sortedForGridByOrder = [...sortedFirstHalf, ...sortedSecondHalf].sort(
    (a, b) => a.order - b.order,
  );

  const sortedForGrid = sortedForGridByOrder.map(({ value, title }) => ({
    value,
    title,
  }));

  return sortedForGrid;
};

// INFO: This is the breakpoint for 2 columns
const sortedItems = computed(() =>
  width.value >= 576 ? sortForGrid(props.detailsItems) : props.detailsItems,
);
</script>
