import { z } from 'zod';
import {
  BarcodeSchema,
  DeviationSchema,
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenDeviationsRemovedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenDeviationsRemovedEvent'),
  eventData: z.object({
    /**
     * The {@link Barcode} of the Specimen the removed deviation(s) originated from.
     */
    barcode: BarcodeSchema,
    /**
     * An object holding information of the deviations that was removed.
     */
    deviations: DeviationSchema.array(),
    /**
     * {@link Practitioner} who performed the event preceding the deviation.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')).optional(),
    /**
     * When the deviation(s) removal was/were registered.
     */
    registeredAt: IsoDateTimeSchema,
    /**
     *  Reference to the {@link Specimen} that the event references.
     */
    specimen: ReferenceSchema(z.literal('Specimen')).optional(),
    /**
     * {@link IdentifierSchema} for system that audited the removed deviation(s).
     */
    systemOrigin: IdentifierSchema,
  }),
});

/**
 * A {@link Specimen} deviaiton has been removed in any way.
 */
export class SpecimenDeviationsRemovedEvent extends createSchemaClass(
  SpecimenDeviationsRemovedEventSchema,
) {}
