import { z } from 'zod';

export const UpdateSubdivisionResponseDtoSchema = z.object({
  subdivisionKey: z.string().trim(),
  name: z.string().trim().min(1),
  employerKey: z.string(),
  organizationKey: z.string(),
});

export type UpdateSubdivisionResponseDto = z.infer<
  typeof UpdateSubdivisionResponseDtoSchema
>;
