import { messages as enUS } from '../translations/en-US';
import { messages as svSE } from '../translations/sv-SE';

export type Locale = 'sv-SE' | 'en-US';
export const useI18n = (locale: Locale) => {
  const messages = {
    'en-US': enUS,
    'sv-SE': svSE,
  };

  return {
    t: (key: keyof typeof enUS) => messages[locale][key],
  };
};
