<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="reason-for-testing"
      :title="t('reasons_for_testing.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!store.reason.selected"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <div class="grid-auto-rows: 1fr grid gap-4 lg:grid-cols-3 lg:grid-rows-2">
        <div v-for="reason in reasonsForTesting" :key="reason">
          <RadioButton
            :label="t('reasons_for_testing.reason_options.' + reason)"
            :selected="reason === store.reason.selected"
            @click="selectedReason(reason)"
          />
        </div>
        <CareosSecondaryButton
          v-if="!store.reason.isOtherSelected"
          :label="t('reasons_for_testing.reason_options.other.label')"
          class="text-2xl"
          @click="otherSelected()"
        />
        <input
          v-if="store.reason.isOtherSelected"
          v-model="store.reason.selected"
          autofocus
          type="text"
          class="h-full rounded border border-gray-400 p-2"
          :placeholder="
            t('reasons_for_testing.reason_options.other.placeholder')
          "
        />
      </div>
    </OrderViewCard>
  </OrderViewWrapper>
</template>

<script setup lang="ts">
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { router } from '@/router';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { ReasonsForTesting } from '@careos/toxicology-types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import RadioButton from '../../components/RadioButton.vue';
import { useOrderViewStore } from '../../store/order-view-store';

const { t } = useI18n();
const store = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();

const reasonsForTesting = computed(() => {
  const reasonsOptions = store.reason.options;

  if (samplingLocationStore.selectedRequisitionType === 'workplace') {
    return reasonsOptions;
  }

  return reasonsOptions.filter((reason) => reason !== 'new_employee');
});

const previousStep = () => {
  router.go(-1);
};

const nextStep = () => {
  if (store.selectedTestType === 'PEth') {
    store.selectedSpecimen = 'PETH_DRIED_BLOOD_SPOTS';
    router.push({
      path: '/order/sampling-kit',
    });
    return;
  }
  router.push({
    path: '/order/specimen-type',
  });
};

const selectedReason = (reason: ReasonsForTesting) => {
  store.reason.selected = reason;
  store.reason.isOtherSelected = false;
  nextStep();
};

const otherSelected = () => {
  store.reason.isOtherSelected = true;
  store.reason.selected = null;
};
useNetworkRequest(() => store.fetchReasonOptions()).exec();
</script>
