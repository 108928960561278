<template>
  <Dropdown
    :model-value="locale"
    :options="availableLocales"
    :option-label="languageOptions"
    @update:model-value="updateLocale"
  />
</template>

<script setup lang="ts">
import { SupportedLocales } from '@/translations/i18n';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';
import { useLocaleStorage } from '@/composables/useLocaleStorage';

const { t, locale, availableLocales } = useI18n();
const { updateLocale } = useLocaleStorage();

const languageOptions = (option: SupportedLocales) =>
  option === 'sv-SE' ? t('general.swedish') : t('general.english');
</script>
