import { z } from 'zod';
import {
  OrganizationSchema,
  TreatmentCenterSchema,
} from '../../../organization';

export const GetTreatmentCentersResponseDtoSchema = z.object({
  organizationName: OrganizationSchema.shape.name,
  organizationLoginName: OrganizationSchema.shape.loginName,
  treatmentCenters: z.array(TreatmentCenterSchema),
});

export type GetTreatmentCentersResponseDto = z.infer<
  typeof GetTreatmentCentersResponseDtoSchema
>;
