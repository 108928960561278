import {
  AssessedObservationSchema,
  BarcodeSchema,
  createSchemaClass,
} from '@abc-labs-ab/ts-events';
import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

const MedicallyAssessRequestDtoSchema = z.object({
  requisitionId: nonEmptyString,
  barcode: BarcodeSchema,
  assessedObservations: z.array(AssessedObservationSchema).min(1),
});

export type MedicallyAssessRequest = z.infer<
  typeof MedicallyAssessRequestDtoSchema
>;

export class MedicallyAssessRequestDto extends createSchemaClass(
  MedicallyAssessRequestDtoSchema,
) {}
