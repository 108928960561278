import {
  PlanDefinitionIdentifierSchema,
  ToxicologyActivityIdentifierSchema,
} from '@careos/identifiers';
import { z } from 'zod';
import { OrganizationType, OrganizationTypeSchema } from './organization-type';

export const TestTypeSchema = z.enum(['DoA', 'PEth']);
export type TestType = z.infer<typeof TestTypeSchema>;
export const TestType = TestTypeSchema.Enum;

export const OrderTypeSchema = z.enum(['CoC', 'non-CoC']);
export type OrderType = z.infer<typeof OrderTypeSchema>;
export const OrderType = OrderTypeSchema.Enum;

export const TestToOrderTypeSchema = z.object({
  testType: TestTypeSchema,
  orderType: OrderTypeSchema,
});
export type TestToOrderType = z.infer<typeof TestToOrderTypeSchema>;

export const AvailableTestTypeSchema = z.record(
  OrganizationTypeSchema,
  z.record(TestTypeSchema, z.array(OrderTypeSchema)),
);

export type AvailableTestType = z.infer<typeof AvailableTestTypeSchema>;
export const AvailableTestTypes: AvailableTestType = {
  workplace: {
    DoA: [OrderType.CoC],
    PEth: [OrderType.CoC, OrderType['non-CoC']],
  },
  treatment_center: {
    DoA: [OrderType['non-CoC']],
    PEth: [OrderType['non-CoC']],
  },
};

export const PlanDefinitionToTestTypeSchema = z.record(
  PlanDefinitionIdentifierSchema,
  TestTypeSchema,
);

export type PlanDefinitionToTestType = z.infer<
  typeof PlanDefinitionToTestTypeSchema
>;

export const PlanDefinitionToTestType: PlanDefinitionToTestType = {
  'DoA-PA-LC-MS': TestType.DoA,
  'PEth-LCMS-qDBS': TestType.PEth,
};

export const ActivityDefinitionToTestTypeSchema = z.record(
  ToxicologyActivityIdentifierSchema,
  TestTypeSchema,
);

export type ActivityDefinitionToTestType = z.infer<
  typeof ActivityDefinitionToTestTypeSchema
>;

// TODO: Include DOA_BLOOD_SAMPLING in the following object once the feature is available for WPS
export const ActivityDefinitionToTestType: ActivityDefinitionToTestType = {
  DOA_URINE_SAMPLING: TestType.DoA,
  DOA_SALIVA_SAMPLING: TestType.DoA,
  PETH_DRIED_BLOOD_SPOTS: TestType.PEth,
};

export const isValidOrderType = (orderType: unknown): orderType is OrderType =>
  OrderTypeSchema.safeParse(orderType).success;

export function isValidTestType(
  organizationType: OrganizationType,
  testToOrderType: TestToOrderType,
) {
  const validTestTypesForOrg = AvailableTestTypes[organizationType];

  if (!validTestTypesForOrg) return false;

  const validOrderTypesForTestType =
    validTestTypesForOrg[testToOrderType.testType];

  if (!validOrderTypesForTestType) return false;

  if (!validOrderTypesForTestType.includes(testToOrderType.orderType))
    return false;

  return true;
}
