import { ToxicologyActivityIdentifier } from '@careos/identifiers';
import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const PethPanelSizeSchema = z.enum(['SG']); // SG: Singular. PEth only for now.
export type PethPanelSize = z.infer<typeof PethPanelSizeSchema>;
export const PethPanelSize = PethPanelSizeSchema.Enum;

export const DoaPanelSizeSchema = z.enum(['S', 'M', 'L', 'CHIRAL']);
export type DoaPanelSize = z.infer<typeof DoaPanelSizeSchema>;
export const DoaPanelSize = DoaPanelSizeSchema.Enum;

export const PanelSizeSchema = z.enum([
  ...PethPanelSizeSchema.options,
  ...DoaPanelSizeSchema.options,
]);
export type PanelSize = z.infer<typeof PanelSizeSchema>;
export const PanelSize = PanelSizeSchema.Enum;

export const isPanelSize = (size: unknown): size is PanelSize =>
  PanelSizeSchema.safeParse(size).success;

export type PanelType = `${ToxicologyActivityIdentifier}:${PanelSize}`;

export const CutoffSchema = z.object({
  type: z.string(),
  value: z.number(),
  unit: z.string(),
});

export type Cutoff = z.infer<typeof CutoffSchema>;

export const PanelSubstanceSchema = z.object({
  name: nonEmptyString,
  isSwedacAccredited: z.boolean(),
  cutoffs: CutoffSchema.array().optional(),
  isReportedQualitative: z.boolean(),
});

export type PanelSubstance = z.infer<typeof PanelSubstanceSchema>;

export const PanelRecordSchema = z.record(
  PanelSizeSchema,
  PanelSubstanceSchema.array(),
);
export type Panels = z.infer<typeof PanelRecordSchema>;

export const PanelSchema = z.object({
  size: PanelSizeSchema,
  panelSubstanceObservations: z.array(z.string()).min(1),
  additionalObservations: z.array(nonEmptyString),
});

export const CutoffTypeSchema = z.enum(['low', 'high']); // INFO: If we go deeper with cutoff stuff with more types (example given in the general.json) this needs to be in an identifier package with correct systems as well.
export type CutoffType = z.infer<typeof CutoffTypeSchema>;
export const CutoffType = CutoffTypeSchema.Values;
