import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { NotificationTypeSchema } from '../types/notification.types';

export const NotificationSchema = z.object({
  userOrganizationRoleId: nonEmptyString.uuid(),
  roleNotificationType: NotificationTypeSchema,
  enabled: z.boolean(),
});

export type Notification = z.infer<typeof NotificationSchema>;
