import { z } from 'zod';
import { ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const PatientEmployerChosenEventSchema = BaseEventSchema.extend({
  eventType: z.literal('PatientEmployerChosenEvent'),
  eventData: z.object({
    /**
     * Reference to patient
     */
    patient: ReferenceSchema(z.literal('Patient')),
    /**
     * Reference to requisition
     */
    requisition: ReferenceSchema(z.literal('Requisition')),
    /**
     * The employer of the patient
     */
    employer: z.object({
      /**
       * Reference to the employer organization, eg. ICA
       */
      reference: ReferenceSchema(z.literal('Organization')),
      /**
       * The subdivision in that specific employer the patient is working for
       * eg. ICA Södertälje
       * Is optional because some companies will not have a subdivision
       */
      subdivision: z
        .object({
          /**
           * Reference to the employer subdivision.
           */
          reference: ReferenceSchema(z.literal('Organization')),
          /**
           * If the patient was working for a subcontractor related to the subdivision
           * They are defined here. Free text field.
           * eg. A Cleaning firm employed by ICA Södertälje
           */
          subcontractor: z.string().optional(),
        })
        .optional(),
    }),
  }),
});

/**
 * Represents an employer chosen for the patient when the patient
 * is being registered for sampling.
 */
export class PatientEmployerChosenEvent extends createSchemaClass(
  PatientEmployerChosenEventSchema,
) {}
