import { z } from 'zod';

export const CgmAbnormalInterpretationSchema = z.enum([
  'ONORMALT LÅG',
  'ONORMALT HÖG',
]);

export type CgmAbnormalInterpretation = z.infer<
  typeof CgmAbnormalInterpretationSchema
>;

export const CgmResultTextInterpretationSchema = z.enum([
  'NEGATIVT',
  'POSITIVT',
  'NORMALT',
]);

export const CgmToxiResultTextIdentifierSchema = z.enum([
  ...CgmResultTextInterpretationSchema.options,
  ...CgmAbnormalInterpretationSchema.options,
]);

export const CgmChiralResultTextIdentifierSchema = z.enum([
  'UAMF < 1000 ng/mL&#xA;UCHAMF >= 5%',
  'UAMF < 1000 ng/mL&#xA;UCHAMF < 5%',
  'UAMF >= 1000 ng/mL&#xA;UCHAMF >= 1%',
  'UAMF >= 1000 ng/mL&#xA;UCHAMF < 1%',
]);

export const CgmChiralResultTextIdentifier =
  CgmChiralResultTextIdentifierSchema.Enum;

export const CgmResultTextIdentifierSchema = z.union([
  CgmToxiResultTextIdentifierSchema,
  CgmChiralResultTextIdentifierSchema,
]);

export type CgmResultTextIdentifier = z.infer<
  typeof CgmResultTextIdentifierSchema
>;

export const CgmResultTextIdentifier = CgmToxiResultTextIdentifierSchema.Enum;
export type CgmToxiResultTextIdentifier = z.infer<
  typeof CgmToxiResultTextIdentifierSchema
>;

export const isCgmResultTextIdentifierSchema = (
  value: unknown,
): value is CgmResultTextIdentifier =>
  CgmResultTextIdentifierSchema.safeParse(value).success;
