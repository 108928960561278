import { z } from 'zod';

export const TimeUnitSchema = z.enum(['ms', 's', 'm', 'h', 'd']);
export type TimeUnit = z.infer<typeof TimeUnitSchema>;

export const DurationSchema = z.object({
  value: z.number(),
  unit: TimeUnitSchema,
});

export type Duration = z.infer<typeof DurationSchema>;
