<template>
  <AddUsersForm :roles="usersStore.roles" />
  <Divider />
  <UsersTable
    :users="usersStore.users"
    :roles="usersStore.roles"
    :loading="isLoadingUsers"
  />
</template>
<script setup lang="ts">
import AddUsersForm from './components/AddUsersForm.vue';
import UsersTable from './components/UsersTable.vue';
import Divider from 'primevue/divider';
import { useUsersStore } from '../../stores/users';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { useRoute } from 'vue-router';
import { OrganizationTypeSchema } from '@careos/organization-api-types';

const route = useRoute();
const usersStore = useUsersStore();
const { organizationKey, organizationType } = route.params;

const orgType = OrganizationTypeSchema.parse(organizationType);

const { exec: fetchRoles } = useNetworkRequest(() =>
  usersStore.fetchRoles(orgType),
);
const { exec: fetchUsers, isLoading: isLoadingUsers } = useNetworkRequest(() =>
  usersStore.fetchUsers(orgType, organizationKey as string),
);
fetchRoles();
fetchUsers();
</script>
