<template>
  <ul class="grid grid-cols-[repeat(auto-fill,minmax(155px,1fr))] gap-x-4">
    <SubstanceListItem
      v-for="substance in sortedSubstances"
      :key="substance"
      :substance="{ name: substance }"
    />
  </ul>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import SubstanceListItem from '../SubstanceListItem.vue';

const props = defineProps<{
  substances: string[];
}>();

const sortedSubstances = computed(() => {
  const substanceList = props.substances;
  return substanceList.sort((a, b) => a.localeCompare(b));
});
</script>
