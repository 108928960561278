import { z } from 'zod';
import { HumanNameSchema } from './human-name.interface';
import { IdentifierSchema } from './identifier.interface';

export const PractitionerSchema = z.object({
  /**
   * Unique resource ID.
   */
  id: z.string(),

  /**
   * External practitioner identifiers.
   */
  identifiers: z.array(IdentifierSchema),

  /**
   * Name of the practitioner.
   */
  name: HumanNameSchema,
});

/**
 * An individual who is involved in the provisioning of healthcare.
 */
export type Practitioner = z.infer<typeof PractitionerSchema>;
