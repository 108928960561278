<template>
  <PrimeIcon
    icon="INFO_CIRCLE"
    class="text-careos-purple"
    @click.stop="showDialog = true"
  />

  <Dialog v-model:visible="showDialog" modal dismissable-mask>
    <template #header>
      <div class="mr-8 flex w-full justify-between">
        <h1 class="self-end text-2xl text-careos-purple">
          {{ t('substances.panelHeading') }}
        </h1>
        <SwedacLogo v-if="hasAccreditedSubstance" class="ml-auto" />
      </div>
    </template>
    <OrderSubstanceList
      :substances-with-info="substancesWithInfo"
      :header-name="title"
      :has-accredited-substance="hasAccreditedSubstance"
      :has-qualitative-substance="hasQualitativeSubstance"
    />
  </Dialog>
</template>

<script setup lang="ts">
import SwedacLogo from '@/components/SwedacLogo.vue';
import OrderSubstanceList from '@/views/order-view/components/OrderSubstanceList.vue';
import { DoaPanelSize, Panels } from '@careos/toxicology-types';
import { PrimeIcon } from 'careos-vue-components';
import Dialog from 'primevue/dialog';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
defineProps<{
  substancesWithInfo: Panels[DoaPanelSize];
  title: string;
  hasAccreditedSubstance: boolean;
  hasQualitativeSubstance: boolean;
}>();

const showDialog = ref(false);

defineEmits(['update:showDialog']);
</script>
<style>
.p-dialog-header-icons {
  @apply !self-start;
}
</style>
