<template>
  <vue-tel-input
    v-model="phoneNumber"
    class="h-[38px] !rounded"
    data-test-id="phone-number"
    v-bind="inputProps"
    @on-input="handleInput"
    @validate="(it: unknown) => (validationInfo = it)"
    @blur="handleFocusLost"
    @focus="handleFocusGained"
  />
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { ref } from 'vue';

// @ts-expect-error vue-tel-input is clashing with react types. Couldn't figure out another way to fix it than removing the types package.
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const props = defineProps<{ context: FormKitFrameworkContext }>();
const phoneNumber = ref(props.context.value);
const validationInfo = ref();

const inputProps = ref({
  defaultCountry: 'SE',
  preferredCountries: ['SE', 'GB'],
  autoFormat: false,
  dropdownOptions: {
    showDialCodeInSelection: true,
    showFlags: true,
    showSearchBox: true,
  },
  inputOptions: {
    placeholder: props.context.placeholder,
    type: 'tel',
  },
  styleClasses: '!border-gray-400',
});

const handleInput = (_: void, phoneObject: { number: string }) => {
  props.context.node.input(phoneObject.number);
};

const handleFocusLost = () => {
  inputProps.value.styleClasses = '!border-gray-400';
  props.context.handlers.blur();
};

const handleFocusGained = () => {
  inputProps.value.styleClasses = '!border-careos-lavendel';
};
</script>

<style>
.vue-tel-input:focus-within {
  @apply border-inherit shadow-none;
}
</style>
