<template>
  <form class="mb-8 flex flex-row gap-8" @submit.prevent="createEmployer">
    <span class="flex flex-col text-left">
      <label for="employer-name">{{
        t('admin_board.add_employer_form.employer_name')
      }}</label>
      <InputText
        id="employer-name"
        v-model.trim="employerName"
        class="h-10 min-w-[296px]"
        type="text"
        required
      />
    </span>
    <FormSubmitButton
      :loading="isLoading"
      :disabled="isLoading"
      type="submit"
      :label="t('admin_board.add_employer_form.add_employer')"
      class="h-10 self-end"
    />
  </form>
  <Divider />
  <EmployerTable />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Divider from 'primevue/divider';
import { useOrganization } from '../../../stores/organization';
import EmployerTable from './EmployerTable.vue';
import type { AddEmployerRequestDto } from '@careos/organization-api-types';
import InputText from 'primevue/inputtext';
import { useRoute } from 'vue-router';
import FormSubmitButton from '../../../components/FormSubmitButton.vue';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

const store = useOrganization();
const route = useRoute();
const toast = useToast();
const { t } = useI18n();

const employerName = ref('');

const submitForm = async () => {
  if (!employerName.value.trim()) return;
  const dto: AddEmployerRequestDto = {
    name: employerName.value,
    organizationKey: route.params.organizationKey as string,
  };
  await store.createEmployer(dto);
  toast.add({
    life: 5_000,
    severity: 'success',
    summary: t('admin_board.add_employer_form.employer_added_message'),
  });
  employerName.value = '';
};

const { exec: createEmployer, isLoading } = useNetworkRequest(async () => {
  await submitForm();
});
</script>
