<template>
  <div
    class="relative mx-2 my-4 w-full rounded-xl border bg-white px-5 py-2 drop-shadow-md"
  >
    <AddOrganizationForm v-if="isShowingOrgForm" />
    <FormSubmitButton
      v-if="!isShowingOrgForm"
      :label="t('admin_board.add_org_form.submit_button')"
      class="mt-2 h-10 text-white"
      @click="isShowingOrgForm = true"
    />
    <Divider />
    <CareosTable
      v-model:filters="filters"
      :loading="!!isLoading"
      :value="store.organizations"
      :paginator="true"
      :rows="10"
      :rows-per-page-options="[5, 10, 15]"
      filter-display="menu"
      sort-field="createdAt"
      :sort-order="-1"
      @row-click="viewOrganization"
    >
      <Column
        field="name"
        :show-filter-match-modes="false"
        :header="t('admin_board.table.headers.name')"
        sortable
        data-type="string"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="t('admin_board.table.headers.name')"
            @input="filterCallback()"
            @keypress.enter="filterApply && filterApply.$el.click()"
          />
        </template>
        <template #filterclear="{ filterCallback }">
          <CareosSecondaryButton
            ref="filterCancel"
            :label="t('admin_board.table.filters.clear')"
            class="mr-5"
            @click="filterCallback()"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <CareosButton
            ref="filterApply"
            :label="t('admin_board.table.filters.apply')"
            type="button"
            class="ml-5"
            @click="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="createdAt"
        sortable
        :show-filter-match-modes="false"
        data-type="date"
      >
        <template #header>
          {{ t('admin_board.table.headers.created_at') }}
          <div
            v-tooltip.right="{
              value: t('admin_board.table.headers.created_at_tooltip'),
              class: 'tooltip-style',
            }"
            class="flex"
          >
            <PrimeIcon icon="INFO_CIRCLE" size="XS" class="ml-2" />
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar
            v-model="filterModel.value"
            :inline="true"
            :show-week="true"
            :max-date="new Date()"
            selection-mode="range"
            @keydown.enter="filterCallback()"
          />
        </template>
        <template #filterclear="{ filterCallback }">
          <CareosSecondaryButton
            :label="t('admin_board.table.filters.clear')"
            class="mr-5"
            @click="filterCallback()"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <CareosButton
            :label="t('admin_board.table.filters.apply')"
            type="button"
            class="ml-5"
            @click="filterCallback()"
          />
        </template>
        <template #body="{ data }">{{
          dayjs(data.createdAt).format('YYYY-MM-DD')
        }}</template>
      </Column>
      <Column
        field="loginName"
        :show-filter-match-modes="false"
        :header="t('admin_board.table.headers.login_name')"
        sortable
        data-type="string"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="t('admin_board.table.headers.login_name')"
            @input="filterCallback()"
            @keypress.enter="filterApply && filterApply.$el.click()"
          />
        </template>
        <template #filterclear="{ filterCallback }">
          <CareosSecondaryButton
            ref="filterCancel"
            :label="t('admin_board.table.filters.clear')"
            class="mr-5"
            @click="filterCallback()"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <CareosButton
            ref="filterApply"
            :label="t('admin_board.table.filters.apply')"
            type="button"
            class="ml-5"
            @click="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="type"
        :show-filter-match-modes="false"
        :header="t('admin_board.table.headers.type')"
        data-type="text"
      >
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="
              [...new Set(store.organizations.map((it) => it.type))].map(
                (it) => ({
                  value: it,
                  label: t(`admin_board.org_types.${it}`),
                }),
              )
            "
            option-label="label"
            option-value="value"
            class="p-column-filter"
            :placeholder="t('admin_board.table.headers.type')"
            @change="filterCallback"
          />
        </template>
        <template #filterclear="{ filterCallback }">
          <CareosSecondaryButton
            :label="t('admin_board.table.filters.clear')"
            class="mr-5"
            @click="filterCallback()"
          />
        </template>
        <template #filterapply="{ filterCallback }">
          <CareosButton
            :label="t('admin_board.table.filters.apply')"
            type="button"
            class="ml-5"
            @click="filterCallback()"
          />
        </template>
        <template #body="{ data }">
          {{ t(`admin_board.org_types.${data.type}`) }}
        </template>
      </Column>
      <Column :header="TestType.DoA" style="width: 200px" field="type">
        <template #body="{ data }">
          <Button
            v-tooltip.top="{
              value: samplingKitsTooltipMessage(
                data.enabledDoaSamplingKits,
                data.enabledDoaOrderType,
              ),
            }"
            class="test-type-button"
            :pt="{
              root: !data.enabledDoaOrderType ? 'disabled' : 'enabled',
            }"
            :label="
              testTypeLabel(TestType.DoA, data.type, data.enabledDoaOrderType)
            "
            :icon="!data.enabledDoaOrderType ? 'pi pi-exclamation-circle' : ''"
            @click="openDialog(data, TestType.DoA)"
            @click.stop
          />
        </template>
      </Column>
      <Column :header="TestType.PEth" style="width: 200px" field="type">
        <template #body="{ data }">
          <Button
            v-tooltip.top="{
              value: samplingKitsTooltipMessage(
                data.enabledPethSamplingKits,
                data.enabledPethOrderType,
              ),
            }"
            class="test-type-button"
            :icon="!data.enabledPethOrderType ? 'pi pi-exclamation-circle' : ''"
            :pt="{
              root: !data.enabledPethOrderType ? 'disabled' : 'enabled',
            }"
            :label="
              testTypeLabel(TestType.PEth, data.type, data.enabledPethOrderType)
            "
            @click.stop
            @click="openDialog(data, TestType.PEth)"
          />
        </template>
      </Column>
    </CareosTable>
    <Dialog
      v-model:visible="showDialog"
      modal
      :closable="false"
      :pt="{
        mask: {
          style: 'backdrop-filter: blur(2px)',
        },
      }"
    >
      <OrganizationTestTypeDialog
        v-if="organization && testType"
        :test-type="testType"
        :initial-organization="organization"
        @close="showDialog = false"
        @submit="handleConfirmOrganizationUpdates"
      />
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import CareosTable from '@/components/CareosTable.vue';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Divider from 'primevue/divider';
import AddOrganizationForm from './AddOrganizationForm.vue';
import InputText from 'primevue/inputtext';
import { router } from '@/router';
import {
  DataTableFilterMeta,
  DataTableRowClickEvent,
} from 'primevue/datatable';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import {
  TestType,
  OrganizationWithEnabledTestTypes,
  OrderType,
  UpdateOrganizationEnabledTestTypesRequestDto,
  OrganizationType,
} from '@careos/organization-api-types';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { useOrganizations } from '../../stores/organizations';
import { ComponentPublicInstance, ref, watch } from 'vue';
import Calendar from 'primevue/calendar';
import { FilterMatchMode, FilterService } from 'primevue/api';
import { usePrimeVue } from 'primevue/config';
import isBetween from 'dayjs/plugin/isBetween';
import FormSubmitButton from '../../components/FormSubmitButton.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { PrimeIcon } from 'careos-vue-components';
import OrganizationTestTypeDialog from '@/components/AdminBoard/OrganizationTestTypeDialog.vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

dayjs.extend(isBetween);

const { t, locale } = useI18n();
const primeVue = usePrimeVue();
const store = useOrganizations();
const isShowingOrgForm = ref(false);
const filterApply = ref<ComponentPublicInstance | undefined>(undefined);
const toast = useToast();
const organization = ref<OrganizationWithEnabledTestTypes>();
const testType = ref<TestType>();
const showDialog = ref(false);

const openDialog = (
  row: OrganizationWithEnabledTestTypes,
  testTypeToBeChanged: TestType,
) => {
  organization.value = row;
  testType.value = testTypeToBeChanged;
  showDialog.value = true;
};

FilterService.register(
  'dateInRange',
  (value: string | null, filter: [Date, Date] | null): boolean => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const isRange = filter.at(0) && filter.at(1);
    return isRange
      ? dayjs(value).isBetween(filter[0], filter[1], 'date', '[]')
      : dayjs(value).isSame(filter[0], 'date');
  },
);

const filterDefaults: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: { value: null, matchMode: 'dateInRange' },
  loginName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  type: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

const filterDefaultClone = () => {
  const defaultFilters: DataTableFilterMeta = {};
  Object.keys(filterDefaults).forEach((key) => {
    defaultFilters[key] = structuredClone(filterDefaults[key]);
  });
  return defaultFilters;
};
const filters = ref<DataTableFilterMeta>(filterDefaultClone());

const viewOrganization = ($event: DataTableRowClickEvent) => {
  const { key, type } = $event.data;
  router.push({
    path: `/admin/organizations/${type.toLowerCase()}/${key}`,
  });
};

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('admin_board.table.filters.january'),
      t('admin_board.table.filters.february'),
      t('admin_board.table.filters.march'),
      t('admin_board.table.filters.april'),
      t('admin_board.table.filters.may'),
      t('admin_board.table.filters.june'),
      t('admin_board.table.filters.july'),
      t('admin_board.table.filters.august'),
      t('admin_board.table.filters.september'),
      t('admin_board.table.filters.october'),
      t('admin_board.table.filters.november'),
      t('admin_board.table.filters.december'),
    ],
    dayNamesMin: [
      t('admin_board.table.filters.sunday'),
      t('admin_board.table.filters.monday'),
      t('admin_board.table.filters.tuesday'),
      t('admin_board.table.filters.wednesday'),
      t('admin_board.table.filters.thursday'),
      t('admin_board.table.filters.friday'),
      t('admin_board.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);

const { exec: fetchOrganizations, isLoading } = useNetworkRequest(() =>
  store.fetchOrganizations(),
);

fetchOrganizations();

onBeforeRouteLeave(() => {
  store.$reset();
});

const handleConfirmOrganizationUpdates = async (
  dto: UpdateOrganizationEnabledTestTypesRequestDto,
) => {
  try {
    await store.updateOrganizationEnabledTestTypes(dto);
    await fetchOrganizations();
  } catch (e) {
    toast.add({
      life: 12_000,
      severity: 'error',
      summary: `Unable to edit organization: ${dto.organizationKey}`,
      detail: e,
    });
  } finally {
    showDialog.value = false;
  }
};

const samplingKitsTooltipMessage = (
  enabledSamplingKits: string[],
  orderType?: string,
) => {
  if (orderType) {
    return enabledSamplingKits.map((it) => t(`samplingKits.${it}`)).join(', ');
  }
};

const testTypeLabel = (
  testType: TestType,
  type: OrganizationType,
  orderType?: OrderType,
) => {
  if (!orderType) {
    return t('admin_board.table.not_configured');
  }

  if (
    testType === TestType.PEth &&
    orderType === OrderType['non-CoC'] &&
    type === 'workplace'
  ) {
    return `${testType}*`;
  }

  return testType;
};
</script>
<style scoped>
.test-type-button {
  @apply w-[200px] py-2;
}
.enabled {
  @apply border-careos-purple bg-careos-purple  hover:border-careos-lavendel hover:bg-careos-lavendel;
}
.disabled {
  @apply border-yellow-500 bg-yellow-500 text-xs text-yellow-700 hover:border-yellow-400 hover:bg-yellow-400;
}
</style>
