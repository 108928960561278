import { z } from 'zod';
import {
  AttachmentSchema,
  BarcodeSchema,
  CodeableConceptSchema,
  IdentifierSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ProcedureFinishedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ProcedureFinishedEvent'),
  eventData: z.object({
    procedure: z.object({
      /**
       * Unique procedure id. Usually generated when the event is fired.
       */
      procedureId: z.string(),
      /**
       * Additional external identifiers.
       * Is usually an ID generated by the machine which performed the procedure.
       */
      identifiers: z.array(IdentifierSchema),
      /**
       * When the procedure finished.
       */
      finishedAt: z.string(),
      /**
       * The barcodes involved in the procedure
       */
      barcodes: z.array(BarcodeSchema),
      /**
       * The specific procedure that was performed. Use text if the exact
       * nature of the procedure cannot be coded (e.g. "Laparoscopic Appendectomy").
       */
      code: CodeableConceptSchema,
      /**
       * Any report resulting from the procedure
       */
      report: AttachmentSchema.optional(),
    }),
  }),
});

/**
 * An action that is or was performed on or for a patient, practitioner, device, organization, or location.
 * For example, this can be a physical intervention on a patient like an operation, or less invasive like
 * long term services, counseling, or hypnotherapy. This can be a quality or safety inspection for a
 * location, organization, or device. This can be an accreditation procedure on a practitioner for licensing.
 */
export class ProcedureFinishedEvent extends createSchemaClass(
  ProcedureFinishedEventSchema,
) {}
