import { z } from 'zod';
import { CharacterEncodingSchema } from './character-encoding.type';
import { CodingSchema } from './coding.interface';
import { IsoDateTimeSchema } from './iso-date.type';
import { MimeTypeSchema } from './mime-type.type';
import { ReferenceSchema } from './reference.interface';

export const SignatureSchema = z.object({
  /**
   * Indication of the reason the entity signed the object(s)
   */
  type: CodingSchema,
  /**
   * When the signature was created
   */
  when: IsoDateTimeSchema,
  /**
   * Who signed, this is only the personalnumber.
   */
  who: ReferenceSchema(z.enum(['Patient', 'Practitioner'])),
  /**
   * The technical format of the signed resources
   */
  targetFormat: MimeTypeSchema,
  /**
   * The technical format of the signature
   */
  sigFormat: MimeTypeSchema,
  /**
   * The actual signature content (XML DigSig. JWS, picture, etc.).
   * Can also be a base64 encoded string from the image of the signature.
   */
  data: z.object({
    /**
     * The string encoding used for the data.
     */
    encoding: CharacterEncodingSchema,
    /**
     * The raw data string
     */
    raw: z.string(),
  }),
});

/**
 * A Signature holds an electronic representation of a signature and its supporting context in a FHIR accessible form.
 * The signature may either be a cryptographic type (XML DigSig or a JWS), which is able to provide non-repudiation proof,
 * or it may be a graphical image that represents a signature or a digital-signature process.
 *
 * Resource: https://build.fhir.org/branches/kp-molseq-qa/datatypes.html#signature
 */
export type Signature = z.infer<typeof SignatureSchema>;
