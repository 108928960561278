import { z } from 'zod';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { CodingSchema } from './coding.interface';

export enum InterpretationSystemURI {
  /**
   * ObservationInterpretationCoding system for HL7.
   * Reference {@link(http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation|HL7)}
   */
  Hl7Interpretation = 'urn:oid:2.16.840.1.113883.5.83',
  /**
   * ObservationInterpretationCoding system for Volven.
   * This is used by the Kith adapter serving Synlab Sweden's requisitions for example.
   * Reference {@link(https://volven.no/produkt.asp?id=475445&catID=3&subID=8|Volven)}
   */
  VolvenInterpretation = 'urn:oid:2.16.578.1.12.4.1.1.8244',
  /**
   * ObservationInterpretationCoding system for Werlabs.
   */
  WerlabsInterpretation = 'uri:careos.io/interpretation/werlabs',
  /**
   * System for the fast finder system interpretation
   */
  FastFinderInterpretation = 'uri:careos.io/interpretation/fast-finder',
  /**
   * System for the CGM Analytix LIMS interpretation
   */
  CgmAnalytixInterpretation = 'uri:careos.io/interpretation/cgm-analytix',
}

export const InterpretationCodingSchema = CodingSchema.extend({
  /**
   * System in which the intepretation code should be unique.
   */
  system: z.nativeEnum(InterpretationSystemURI),
});

/**
 * A categorical assessment of an observation value. For example, high, low, normal.
 */
export type InterpretationCoding = z.infer<typeof InterpretationCodingSchema>;

export const InterpretationCodableConceptSchema = CodeableConceptSchema.extend({
  /**
   * A categorical assessment of an observation value. For example, high, low, normal.
   */
  coding: z.array(InterpretationCodingSchema),
});

export type InterpretationCodableConcept = z.infer<
  typeof InterpretationCodableConceptSchema
>;
