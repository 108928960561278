import { z } from 'zod';

export const DonorLoginCredentialsRequestDtoSchema = z.object({
  credentials: z.object({
    phoneNumber: z.string(),
    password: z.string(),
  }),
});

export type DonorLoginCredentialsRequestDto = z.infer<
  typeof DonorLoginCredentialsRequestDtoSchema
>;
