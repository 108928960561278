import { z } from 'zod';
import { AnnotationSchema } from './annotation.interface';
import { BarcodeSchema } from './barcode.interface';
import { ContactPointSchema } from './contact-point.interface';
import { HumanNameSchema } from './human-name.interface';
import { IdentifierSchema } from './identifier.interface';
import { IsoDateTimeSchema } from './iso-date.type';
import { OrganizationSchema } from './organization.interface';
import { PatientSchema } from './patient.interface';
import { ReferenceSchema } from './reference.interface';

export const RequisitionSchema = z.object({
  /**
   * Unique resource ID.
   */
  requisitionId: z.string(),

  /**
   * Requisition identifiers.
   */
  identifiers: z.array(IdentifierSchema),

  /**
   * When the requisition was created.
   */
  authoredOn: IsoDateTimeSchema,

  /**
   * When the sampling was done.
   */
  sampledAt: IsoDateTimeSchema.optional(),

  /**
   * If VAT is applicable during invoicing of the requisition.
   */
  useVat: z.boolean(),

  /**
   * {@link Patient} that the requisition is for.
   */
  subject: PatientSchema,

  /**
   * Practitioner(s) that initiated the requisition.
   */
  referringPractitioners: z.array(
    z.object({
      identifiers: z.array(IdentifierSchema),
      names: z.array(HumanNameSchema),
      telecom: z.array(ContactPointSchema),
    }),
  ),

  /**
   * {@link Organization} that initiated the requisition.
   */
  requester: OrganizationSchema,

  /**
   * {@link Organization} that should pay for the requisition.
   */
  payer: OrganizationSchema,

  /**
   * User defined key-value properties.
   *
   * Max 25 char keys, 100 char values and limit to 50 pairs.
   */
  customProperties: z.record(z.string()),

  /**
   * {@link PlanDefinition}(s) describing the requested activity.
   */
  planDefinitions: z.array(ReferenceSchema(z.enum(['PlanDefinition']))),

  /**
   * Barcodes that will be used for the samples in this requisition.
   */
  barcodes: z.array(BarcodeSchema).optional(),

  /**
   * Any other notes and comments made about the requisition.
   */
  notes: z.array(AnnotationSchema).optional(),
});

/**
 * Represents the details of a request for a medical test or procedure ordered for a patient.
 */
export type Requisition = z.infer<typeof RequisitionSchema>;
