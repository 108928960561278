import { z } from 'zod';
import { SimpleQuantitySchema } from './simple-quantity.type';
import { QuantitySchema } from './quantity.interface';

export const RatioSchema = z.object({
  /**
   * Numerator value.
   */
  numerator: QuantitySchema,

  /**
   * Denominator value.
   */
  denominator: SimpleQuantitySchema,
});

/**
 * A ratio of two Quantity values.
 * A numerator {@link QuantitySchema} and a denominator {@link SimpleQuantitySchema}
 */
export type Ratio = z.infer<typeof RatioSchema>;
