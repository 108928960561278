<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="employer-view"
      :forward-label="t('general.next_step')"
      :disable-forwards="isInvalidForm"
      :title="t('employer.title')"
      @action-right="nextStep()"
      @action-left="previousStep()"
    >
      <FormKit v-model="samplingLocationStore.formDataWorkplace" type="group">
        <div class="flex min-w-[250px] flex-col lg:flex-row">
          <div class="w-full">
            <FormKit
              :placeholder="t('employer.selectEmployer')"
              :label="t('employer.employer')"
              :custom-options="employerOptions"
              :filter="employerOptions.length > 5"
              type="dropdown"
              data-test-id="employer-dropdown"
              name="employer"
              validation="required"
              option-label="label"
            />
            <div
              v-tooltip.right="{
                value: t('employer.no_subdivisions_tooltip'),
                disabled: hasSubdivisions,
              }"
            >
              <FormKit
                :disabled="!hasSubdivisions"
                :placeholder="t('employer.subdivision')"
                :label="t('employer.subdivision')"
                :custom-options="subdivisionOptions"
                type="dropdown"
                data-test-id="subdivision-dropdown"
                name="subdivision"
                option-label="label"
              />
            </div>

            <FormKit
              :label="t('employer.subcontractor')"
              :placeholder="t('employer.subcontractor')"
              :validation-messages="{
                alpha: t('personalDetails.validation.charactersNotLatin'),
              }"
              type="text"
              name="subcontractor"
              validation="alpha_spaces:default"
            />
          </div>
        </div>
      </FormKit>
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { router } from '@/router';
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { useToast } from 'primevue/usetoast';
import { useSamplingLocation } from '@/composables/useSamplingLocation';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';

const toast = useToast();
const { t } = useI18n();
const orderViewStore = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();

const { fetchEmployers, employerOptions, subdivisionOptions, hasSubdivisions } =
  useSamplingLocation();

const nextStep = () => {
  if (orderViewStore.testToOrderTypes.options.length > 1) {
    router.push({
      path: '/order/test-type',
    });
  } else if (orderViewStore.testToOrderTypes.options.length === 1) {
    orderViewStore.testToOrderTypes.selectedTestToOrderType =
      orderViewStore.testToOrderTypes.options[0];
    router.push({
      path: '/order/personal-details',
    });
  } else {
    toast.add({
      life: 10_000,
      severity: 'error',
      summary: t('general.no_available_test_types'),
    });
  }
};

const previousStep = () => {
  router.go(-1);
};

// INFO: Form is invalid if there is no employer selected and if there are subdivisions available but no subdivision is selected
const isInvalidForm = computed(
  () =>
    !samplingLocationStore.formDataWorkplace?.employer?.value ||
    (!samplingLocationStore.formDataWorkplace?.subdivision?.value &&
      hasSubdivisions.value),
);

fetchEmployers();
</script>

<style>
.p-dropdown-item:not(:last-of-type) {
  border-bottom: 1.5px solid #dcdeff !important;
}
.p-dropdown-item:hover {
  background-color: #dcdeff !important;
}
</style>
