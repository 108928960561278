import { z } from 'zod';

export const RackCoordinatesSchema = z.object({
  row: z.number(),
  column: z.number(),
});

/**
 * One coordinate of a rack.
 */
export type RackCoordinates = z.infer<typeof RackCoordinatesSchema>;
