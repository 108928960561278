<template>
  <Dialog
    v-model:visible="visible"
    modal
    :header="t('barcode.cameraScanner.modalHeader')"
  >
    <div class="my-3 rounded border-l-4 border-yellow-600">
      <InlineMessage severity="warn">
        {{ cameraErrorMessage }}
      </InlineMessage>
    </div>

    <template #footer>
      <Button
        :label="t('barcode.cameraScanner.modalAccept')"
        autofocus
        class="!bg-careos-purple"
        @click="visible = false"
      />
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InlineMessage from 'primevue/inlinemessage';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const props = defineProps<{
  showModal: boolean;
  cameraErrorMessage: string;
}>();

const emit = defineEmits<{
  (e: 'update:showModal', value: boolean): void;
}>();

const visible = computed({
  get() {
    return props.showModal;
  },
  set(value) {
    emit('update:showModal', value);
  },
});
</script>
