import { bankidMessagesEn } from './en-US';

export const bankidMessagesSv: typeof bankidMessagesEn = {
  'hintcode-qr-outstandingTransaction':
    'Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.',
  'hintcode-desktop-outstandingTransaction':
    'Försöker starta BankID Säkerhetsprogram',
  'hintcode-mobile-outstandingTransaction':
    'Klicka på knappen nedan för att starta BankID-appen.',

  'hintcode-qr-started':
    'Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.',
  'hintcode-desktop-started': 'Försöker starta BankID Säkerhetsprogram',
  'hintcode-mobile-started':
    'Klicka på knappen nedan för att starta BankID-appen.',

  'hintcode-userSign':
    'Skriv din säkerhetskod i BankID- appen och välj Identifiera eller Skriv under.',
  'hintcode-desktop-userSign':
    'Skriv din säkerhetskod i BankID Säkerhetsprogram och välj Identifiera eller Skriv under.',

  'hintcode-unknown': 'Något gick fel. Försök igen.',
  'hintcode-userCancel': 'Åtgärden avbröts.',

  'hintcode-startFailed':
    'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
  'hintcode-qr-startFailed':
    'Skanningen misslyckades. Starta BankID-appen och skanna QR koden. Kontrollera att appen är uppdaterad. Om du inte har appen måste du installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',

  'hintcode-expiredTransaction':
    'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa det hos din bank. Försök sedan igen.',
  'hintcode-certificateErr':
    'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din internetbank.',
  'hintcode-alreadyInProgress':
    'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
  'hintcode-requestTimeout': 'Något gick fel. Försök igen.',
  'hintcode-maintenance': 'Något gick fel. Försök igen.',
  'hintcode-internalError': 'Något gick fel. Försök igen.',

  'error-message-invalidCsrfToken': 'Din session har löpt ut. Ladda om sidan.',
};
