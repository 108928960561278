import { z } from 'zod';
import {
  AnnotationSchema,
  BarcodeSchema,
  IdentifierSchema,
  InterpretationCodableConceptSchema,
  IsoDateTimeSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsFinalizedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsFinalizedEvent'),
  eventData: z.object({
    observations: z.array(
      z.object({
        /**
         * Any note about this observation
         */
        note: AnnotationSchema.optional(),
        /**
         * Identifier for linked {@link Observation}.
         */
        observationId: z.string(),

        /**
         * Identifier for linked {@link ObservationDefinition}.
         */
        observationDefinitionId: IdentifierSchema,

        /**
         * Interpretation of the observation. Consists of the system it was performed and code of the interpretation.
         */
        interpretation: InterpretationCodableConceptSchema,

        /**
         * When the observation was made.
         */
        effectiveDateTime: IsoDateTimeSchema,
      }),
    ),
    /**
     * {@link Barcode} of the specimen.
     */
    barcode: BarcodeSchema,
    /**
     * {@link PlanDefinitions} for the specimen.
     */
    planDefinitions: IdentifierSchema.array(),
    /**
     * Result file name(s) can be used. Optional.
     */
    experimentNames: z.string().array().optional(),
    /**
     * {@link Requisition} that were linked to the {@link Observations}.
     */
    requisition: IdentifierSchema,
    /**
     * Any holistic notes about the observations
     */
    notes: AnnotationSchema.array().optional(),
  }),
});

/**
 * A number of {@link Observation} are finalized.
 */
export class ObservationsFinalizedEvent extends createSchemaClass(
  ObservationsFinalizedEventSchema,
) {}

export type ObservationsFinalizedEventType = z.infer<
  typeof ObservationsFinalizedEventSchema
>;
