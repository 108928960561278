import { useSamplingKitStore } from '@/stores/order/samplingKitStore';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import {
  ActivityDefinitionIdentifier,
  SamplingKitIdentifier,
  ToxicologyActivityIdentifier,
  isUnaccreditedPethSamplingKit,
} from '@careos/identifiers';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNetworkRequest } from './useNetworkRequest';

export const useSamplingKit = () => {
  const { t } = useI18n();
  const samplingKitStore = useSamplingKitStore();
  const orderViewStore = useOrderViewStore();
  const samplingLocationStore = useSamplingLocationStore();
  const { selectedSpecimen, selectedTestType } = toRefs(orderViewStore);
  const { selectedRequisitionType } = toRefs(samplingLocationStore);
  const specimenTypeToSamplingKits = ref(
    new Map<ToxicologyActivityIdentifier, SamplingKitIdentifier[]>(),
  );
  const samplingKits = ref<SamplingKitIdentifier[]>();
  const isSwedacAccredited = ref(false);

  const { exec: fetchSamplingKits } = useNetworkRequest(async () => {
    const res = await samplingKitStore.fetchSamplingKits(
      selectedRequisitionType.value,
      selectedSpecimen.value,
    );

    samplingKits.value = res.enabledSamplingKits;
    isSwedacAccredited.value = getSwedacInfo();
  });

  const getDefaultSamplingKit = (
    activityDefinition: ToxicologyActivityIdentifier,
  ) => {
    const res = specimenTypeToSamplingKits.value.get(activityDefinition);

    if (res?.length === 1) {
      return res[0];
    }
  };

  const getDefaultSamplingKitLabel = (
    activityDefinition: ToxicologyActivityIdentifier,
  ) => {
    // INFO: Currently, Dried Blood Spots (DBS) are the only type of blood sampling available for DoA.
    // Therefore, we want to display "Dried Blood Spots" in the label in the specimen type view.
    // In the future, when multiple blood sampling types are supported, we should update the label to display "Blood Sampling" instead of specifying "Dried Blood Spots".
    if (isDoaBloodSamplingType(activityDefinition)) {
      return ` (${t(`samplingKits.${SamplingKitIdentifier.DRIED_BLOOD_SPOTS}`)})`;
    }

    const defaultSamplingKit = getDefaultSamplingKit(activityDefinition);
    return defaultSamplingKit
      ? ` (${t(`samplingKits.${defaultSamplingKit}`)})`
      : '';
  };

  const getSpecimenTypeOptions = () => {
    return orderViewStore.specimenTypes.map((option) => {
      return {
        label: `${t(`specimenType.types.${option}`)}${getDefaultSamplingKitLabel(option)}`,
        value: option as ToxicologyActivityIdentifier,
      };
    });
  };

  const handleSamplingKitChange = (samplingKit: SamplingKitIdentifier) => {
    samplingKitStore.selectedSamplingKit = samplingKit;
  };

  const getSwedacInfo = () => {
    if (selectedTestType.value !== 'PEth') return false; // Since PEth is only one substance, the check is on sampling kit level.
    if (!samplingKits.value) return false;

    return !samplingKits.value.every((it) => isUnaccreditedPethSamplingKit(it));
  };

  const isDoaBloodSamplingType = (
    activityDefinition: ActivityDefinitionIdentifier,
  ) => activityDefinition === 'DOA_BLOOD_SAMPLING';

  return {
    fetchSamplingKits,
    getDefaultSamplingKit,
    handleSamplingKitChange,
    getSpecimenTypeOptions,
    samplingKits,
    isSwedacAccredited,
  };
};
