import { z } from 'zod';
import { AddressSchema } from './address.interface';
import { ReferenceSchema } from './reference.interface';

export const LocationSchema = z.object({
  /**
   * Unique resource ID.
   */
  id: z.string(),

  /**
   * Common name of the location.
   */
  name: z.string(),

  /**
   * Address of the location.
   */
  address: AddressSchema,

  /**
   * {@link Organization} responsible for the location.
   */
  managingOrganization: ReferenceSchema(z.enum(['Organization'])),
});

/**
 * Details and position information for a physical place.
 */
export type Location = z.infer<typeof LocationSchema>;
