<template>
  <button
    class="group flex cursor-pointer items-center justify-between gap-3 rounded bg-careos-purple px-3 py-3 text-base font-medium text-white focus:ring-4 focus:ring-careos-purple-light active:text-careos-lavendel"
    :class="{
      'pointer-events-none bg-careos-lavendel/50': disabled || loading,
    }"
  >
    <span>
      {{ label }}
    </span>

    <PrimeIcon
      :icon="icon"
      :show-spinner="loading"
      class="cursor-pointer transition group-hover:scale-110"
      size="SM"
    />
  </button>
</template>

<script lang="ts" setup>
import { PrimeIcon } from 'careos-vue-components';
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  actionType: {
    type: String,
    default: 'show',
  },
});

const icon = computed(() => {
  return props.actionType === 'download' ? 'ARROW_CIRCLE_DOWN' : 'EYE';
});
</script>
