import { NavigationGuard } from 'vue-router';
import { RouteRecordMeta } from './types';
import { useAuthStore } from '@/stores/auth';
import { getAccessToken } from '@/authentication/auth0';

export const permissionsGuard: NavigationGuard = async (to, _, next) => {
  const authStore = useAuthStore();
  // INFO: This will only fetch the access token if it's expired or missing.
  await getAccessToken();
  const meta = to.meta as RouteRecordMeta;

  const hasAuthority = meta?.permissions?.some((permission) =>
    authStore.permissions.includes(permission),
  );
  if (hasAuthority) {
    return next();
  }

  return next({ name: 'Not found' });
};
