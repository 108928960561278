<template>
  <router-view v-slot="{ Component, route }">
    <transition
      :name="routeTransitionName(route) || 'slide-right'"
      :mode="'out-in'"
    >
      <component :is="Component" :key="route.path" />
    </transition>
  </router-view>
</template>
<script setup lang="ts">
import { onUnmounted } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { useOrderViewStore } from './store/order-view-store';
import { router } from '@/router';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { usePreventOrderNavigation } from '@/composables/usePreventOrderNavigation';

const routeTransitionName = (route: RouteLocationNormalizedLoaded) =>
  route.meta.transitionName as string;

const store = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();

if (!samplingLocationStore.selectedRequisitionType) {
  router.push('/order/requisition-type');
}

onUnmounted(() => {
  store.clearOrder();
});
usePreventOrderNavigation();
</script>
