import { z } from 'zod';

export const OrganizationSchema = z.enum([
  'ABC_LABS',
  'LEGEHUSET',
  'ABBOTT',
  'LANTMANNEN',
  'NDP',
  'LJUNG_SJOBERG_ARBETSPLATSTESTER',
  'LJUNG_SJOBERG',
  'LS-NYKOPING_KOMMUN',
  'LS-SAVSJOVIK',
  'LS-LEVA_GRUPPEN',
  'LS-ICURO_SJOVIK',
  'LS-9VB',
  'LS-KRINOLINEN',
  'LS-MUGGEBO_HVB',
  'LS-FRODEA',
  'LS-GULDKORNET',
  'LS-LARO_LEJON',
  'LS-FRIAB_OLLESTAD',
  'LS-PERSONLIG_UTVECKLING_OCH_REHAB',
  'LS-SVEDALA_KOMMUN',
  'LS-REGION_DALARNA',
  'LS-HASSELA_SKANE',
  'LS-TALLGARDEN',
  'LS-HUDIKSVALLS_UNGDOMSHEM',
  'LS-RATTSPSYK_DALARNA',
  'LS-LANTMANNEN_MASKIN',
  'LS-KOLAMORA',
  'LS-KARLSKRONA',
  'LS-KARLSKRONABEHANDLINGSHEM',
  'NDP-AVA',
  'LS-ACADEMIC_WORK',
  'LS-PRIMA_VARD_LARO_SUND_MALMO',
  'LS-PRIMA_VARD_LARO_SUND_LIMHAMN',
  'LS-PRIMA_VARD_LARO_SUND_SODER',
  'LS-PRIMA_VARD_LARO_SUND_LANDSKRONA',
  'LS-PRIMA_VARD_LARO_SUND_RAMLOSA',
  'NDP_SIMP',
  'LS-KOLBACKEN',
  'LS-NYKOPING_RODA_VILLAN',
  'LS-REGION_DALARNA_BORLANGE',
  'LS-TEAMOLIVIA_SELFHELP',
  'LS-TEAMOLIVIA_TALLKULLEN',
  'LS-TIVEDA',
  'INERA',
  'REGION_VASTERNORRLAND',
  'KRY',
  'TEST_ORG',
  'EDTS',
]);

export const Organization = OrganizationSchema.Enum;

export type Organization = z.infer<typeof OrganizationSchema>;

export const isCareosOrganization = (value: unknown): value is Organization =>
  OrganizationSchema.safeParse(value).success;
