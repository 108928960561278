<template>
  <RequisitionWrapper>
    <RequisitionCard class="max-w-5xl pb-4 md:pb-8">
      <h1 class="mb-8 text-2xl">{{ t('signed_consent') }}</h1>

      <div class="mt-8 flex flex-col gap-4 text-left">
        <DetailsItems v-if="patientData" :details-items="patientData" />
        <RequisitionSection
          v-if="substances && store.patientConfirmationData?.testType === 'DoA'"
          :heading="
            t('substances.panelTitle') +
            ' ' +
            t('substances.' + selectedPanelSize)
          "
        >
          <SubstanceList :substances="substances" />
        </RequisitionSection>

        <RequisitionSection
          v-if="store.patientConfirmationData?.testType === 'PEth'"
          :heading="t('summary.alcohol_marker')"
        >
          {{ t('substances.names.PHOSPHATIDYLETHANOL') }}
        </RequisitionSection>

        <RequisitionSection
          v-if="selectedAdditionalSubstances?.length"
          :heading="t('summary.additional_substance')"
        >
          <SubstanceList :substances="selectedAdditionalSubstances" />
        </RequisitionSection>

        <RequisitionSection
          v-if="store.patientConfirmationData"
          :heading="t('comment.title')"
        >
          {{ store.patientConfirmationData.requisitionComment }}
        </RequisitionSection>
      </div>

      <RequisitionConsentSection
        v-if="consentText"
        is-signed
        :consent-text="consentText"
        class="my-8"
      >
        <template #signature>
          <Signature
            v-if="
              patientData &&
              store.patientConfirmationData &&
              signature?.signatureType === 'HANDWRITTEN'
            "
            :signature="signature"
            :patient-name="`${store.patientConfirmationData?.patient.firstName} ${store.patientConfirmationData?.patient.lastName}`"
          />
        </template>
      </RequisitionConsentSection>
    </RequisitionCard>
  </RequisitionWrapper>
</template>
<script setup lang="ts">
import Signature from '@/components/Signature.vue';
import { PersonalDetailsItem } from '@/models/personal-details-item';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useConfirmationViewStore } from '../../store/confirmation-view-store';
import { ActivityDefinitionToTestType } from '@careos/organization-api-types';
import RequisitionConsentSection from '@/components/Requisition/RequisitionConsentSection.vue';
import RequisitionWrapper from '@/components/Requisition/RequisitionWrapper.vue';
import RequisitionCard from '@/components/Requisition/RequisitionCard.vue';
import SubstanceList from '@/components/Requisition/SubstanceList.vue';
import RequisitionSection from '@/components/Requisition/RequisitionSection.vue';
import DetailsItems from '@/views/order-view/components/DetailsItems.vue';
import { SigningDisplay, mapSignatureData } from '@careos/toxicology-types';

const { t } = useI18n();
const store = useConfirmationViewStore();
const router = useRouter();

const consentText = computed(() => {
  const specimenType = store.patientConfirmationData?.specimenType;
  if (!specimenType) return;
  const line = ActivityDefinitionToTestType[specimenType];
  return t(`consent_form.${line}_consent_text`);
});

if (!store.patientConfirmationData) {
  router.replace('/confirmation/requisition-password');
}

const patientData = computed(() => {
  const data = store.patientConfirmationData;

  if (!data) return;
  const specimen = `specimenType.types.${data.specimenType}`;
  const summaryList: PersonalDetailsItem[] = [
    {
      title: t('personalDetails.firstName'),
      value: data.patient.firstName,
    },
    {
      title: t('personalDetails.lastName'),
      value: data.patient.lastName,
    },
    {
      title: t('personalDetails.phoneNumber'),
      value: data.patient.phoneNumber,
    },
    {
      title: t('identifier.identifier'),
      value: data.patient.personalIdentifier,
    },
    {
      title: t('specimenType.specimen_type'),
      value: t(specimen),
    },
    { title: t('barcode.barcode'), value: data.barcode },
  ];
  return summaryList;
});

const signature = computed<SigningDisplay | undefined>(() => {
  if (!store.patientConfirmationData?.signature) return;
  return mapSignatureData(store.patientConfirmationData.signature);
});

const substances =
  store.patientConfirmationData?.panel.panelSubstanceObservations;
const selectedPanelSize = store.patientConfirmationData?.panel.size;
const selectedAdditionalSubstances =
  store.patientConfirmationData?.panel.additionalObservations;
</script>
