import { z } from 'zod';
import { BarcodeSchema, IdentifierSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils/create-schema-class';
import { BaseEventSchema } from './base-event';

export const CompiledResultFilePublishedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('CompiledResultFilePublishedEvent'),
  eventData: z.object({
    /**
     * Application that triggers the event.
     */
    originSystem: IdentifierSchema,
    /**
     * Reference to the system which will have store the result file.
     */
    targetSystem: IdentifierSchema,
    /**
     * CareOS requisitions that result concerns.
     */
    requisitions: z.array(IdentifierSchema),
    /**
     * Barcodes that result concerns.
     */
    barcodes: z.array(BarcodeSchema),
    /**
     * The URL pointing to the file(s).
     */
    fileNames: z.array(z.string()),
    /**
     * The type of the report file {@link ReportType}
     */
    reportType: z.string().optional(),
    /**
     * Session that result concern.
     */
    session: IdentifierSchema,
  }),
});

/**
 * A result file which contains compiled results from multiple requisitions
 *  has been sent to be stored at an external system.
 */
export class CompiledResultFilePublishedEvent extends createSchemaClass(
  CompiledResultFilePublishedEventSchema,
) {}
