import { z } from 'zod';

export const DonorInitiateBankidSigningRequestDtoSchema = z.object({
  transactionId: z.string().uuid(),
  visibleText: z.string(),
  pinCode: z.string(),
  returnUrl: z.string().optional(),
});

export type DonorInitiateBankidSigningRequestDto = z.infer<
  typeof DonorInitiateBankidSigningRequestDtoSchema
>;

export type InitiateBankidSigningRequestDto = Omit<
  DonorInitiateBankidSigningRequestDto,
  'pinCode'
>;
