import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

import { IdentifierSystem } from '@abc-labs-ab/ts-events';

export const DonorSchema = z.object({
  firstName: nonEmptyString,
  lastName: nonEmptyString,
  // TODO - We want to use IdentifierPatientSchema but there seems to be and issue with circular imports.
  personalIdentifier: z.object({
    system: z.union([
      z.literal(IdentifierSystem.PersonalIdentityNumber),
      z.literal(IdentifierSystem.PatientReference),
    ]),
    value: nonEmptyString,
  }),
  phoneNumber: nonEmptyString,
  email: z.union([z.string().email(), z.literal('').nullish()]),
});

export type Donor = z.infer<typeof DonorSchema>;
