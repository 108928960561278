import { EmployerSchema, OrganizationSchema } from '../../../organization';

import { z } from 'zod';

export const GetEmployersResponseDtoSchema = z.object({
  organizationName: OrganizationSchema.shape.name,
  organizationLoginName: OrganizationSchema.shape.loginName,
  employers: z.array(EmployerSchema),
});

export type GetEmployersResponseDto = z.infer<
  typeof GetEmployersResponseDtoSchema
>;
