import { z } from 'zod';
import { BaseOrganizationSchema } from '../../../organization';

export const ToxicologyEmployerSchema = z.object({
  head: BaseOrganizationSchema,
  divisions: BaseOrganizationSchema.array(),
});

export type ToxicologyEmployer = z.infer<typeof ToxicologyEmployerSchema>;

export const GetToxicologyEmployersResponseDtoSchema =
  ToxicologyEmployerSchema.array();

export type GetToxicologyEmployersResponseDto = z.infer<
  typeof GetToxicologyEmployersResponseDtoSchema
>;
