import { z } from 'zod';
import { CodeableConceptSchema } from '../codeable-concept.interface';
import { ReferenceSchema } from '../reference.interface';

export const ObservationDefinitionSchema = z.object({
  /**
   * A logical identifier assigned to this ObservationDefinition.
   */
  observationDefinitionId: z.string(),
  /**
   * Type of observation.
   */
  code: CodeableConceptSchema,
  /**
   * Kind of specimens used by this type of observation
   */
  specimens: ReferenceSchema(z.enum(['SpecimenDefinition'])).optional(),
  /**
   * Preparations of the patient, required by this type of observation.
   */
  patientPreparations: z.array(CodeableConceptSchema).optional(),

  /**
   * Characteristics of quantitative results
   */
  quantitativeDetails: z
    .object({
      /**
       * Primary unit for quantitative results
       */
      unit: CodeableConceptSchema,
    })
    .optional(),
});

/**
 * Representation of the definitional aspects of a kind of observation.
 */
export type ObservationDefinition = z.infer<typeof ObservationDefinitionSchema>;
