import { z } from 'zod';
import { IsoDateTimeSchema, ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsTransmittedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsTransmittedEvent'),
  eventData: z.object({
    /**
     * {@link Observation} generated by a lab to capture the result of a test.
     */
    observations: z.array(ReferenceSchema(z.literal('Observation'))),
    /**
     * {@link Requisition} which these observations are based on.
     */
    requisition: ReferenceSchema(z.literal('Requisition')),
    /**
     * Unique id of the transmission.
     */
    transmissionId: z.string(),
    /**
     * When the observations were transmitted.
     */
    transmittedAt: IsoDateTimeSchema,
    /**
     * (Base64 encoded) original observations which were transmitted
     */
    b64transmittedObservations: z.string().array().optional(),
  }),
});

/**
 * An {@link Observation} has been transmitted to an external party.
 */
export class ObservationsTransmittedEvent extends createSchemaClass(
  ObservationsTransmittedEventSchema,
) {}
