import { z } from 'zod';
import { BarcodeSchema, IdentifierSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils/create-schema-class';
import { BaseEventSchema } from './base-event';

export const ResultFilePublishedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ResultFilePublishedEvent'),
  eventData: z.object({
    /**
     * Application that triggers the event.
     */
    originSystem: IdentifierSchema,
    /**
     * Reference to the system which will have store the result file.
     */
    targetSystem: IdentifierSchema,
    /**
     * CareOS requisition that result concerns.
     */
    requisition: IdentifierSchema,
    /**
     * Barcode that result concerns.
     */
    barcode: BarcodeSchema,
    /**
     * The URL pointing to the file(s).
     */
    fileNames: z.array(z.string()),
    /**
     * The type of the report file.
     */
    reportType: z.string().optional(),
  }),
});

/**
 * A result file has been sent to be stored at an external system.
 */
export class ResultFilePublishedEvent extends createSchemaClass(
  ResultFilePublishedEventSchema,
) {}
