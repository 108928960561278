import { router } from '@/router';
import { InputDevice, useBarcodeStore } from '@/stores/order/barcode';
import type {
  barcodeReason,
  BarcodeResponseBody,
} from '@careos/toxicology-types';
import { AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useNetworkRequest } from './useNetworkRequest';
import { useScanner } from './useScanner';
import * as Sentry from '@sentry/vue';
import { CameraStatus } from '@/views/order-view/child-views/barcode-view/components/CameraScanner.vue';

export type BarcodeCheckStatus = 'idle' | 'loading' | 'error' | 'success';

export const useBarcodeInput = () => {
  const barcodeReaderValue = useScanner();

  const barcodeStore = useBarcodeStore();
  const { inputDevice, barcodeValue, checkedBarcodeValue } =
    storeToRefs(barcodeStore);
  const barcodeCheckStatus = ref<BarcodeCheckStatus>('idle');
  const errorMessage = ref<barcodeReason | null>(null);
  const isDeviceCameraOn = ref(false);

  const handleInputOptionClick = (inputMethod: InputDevice) => {
    barcodeCheckStatus.value = 'idle';
    inputDevice.value = inputMethod;
    isDeviceCameraOn.value = false;
    barcodeValue.value = '';
    if (inputMethod === 'deviceCamera') {
      isDeviceCameraOn.value = true;
    }
  };

  const { exec: checkIfValidBarcode } = useNetworkRequest(async (barcode) => {
    try {
      barcodeCheckStatus.value = 'loading';
      await barcodeStore.checkBarcode(barcode);
      checkedBarcodeValue.value = barcode;
      barcodeCheckStatus.value = 'success';
    } catch (e) {
      Sentry.captureException(e);
      Sentry.captureMessage('This is checkIfValidBarcode error', 'warning');
      if (e instanceof AxiosError) {
        const { reason } = e.response?.data as BarcodeResponseBody;
        errorMessage.value = reason;
      }
      barcodeCheckStatus.value = 'error';
    }
  });

  const handleCameraScanningInput = async (barcode: string) => {
    isDeviceCameraOn.value = false;
    await checkIfValidBarcode(barcode);
    barcodeValue.value = barcode;

    if (barcodeCheckStatus.value === 'success') {
      await router.push({ path: '/order/summary' });
    }
  };

  const handleCameraStatus = (cameraStatus: CameraStatus) => {
    if (cameraStatus === 'off') {
      isDeviceCameraOn.value = false;
      inputDevice.value = 'barcodeReader';
    }
  };

  const handleKeyboardInput = (barcode: string) => {
    barcodeCheckStatus.value = 'idle';
    barcodeValue.value = barcode;
  };

  watch(
    () => inputDevice.value,
    () => {
      barcodeCheckStatus.value = 'idle';
      barcodeReaderValue.value = '';
      barcodeValue.value = '';
      errorMessage.value = null;
    },
  );

  watch(barcodeReaderValue, async (barcode) => {
    if (!barcode) return;

    await checkIfValidBarcode(barcode);
    barcodeValue.value = barcode;

    if (barcodeCheckStatus.value === 'success') {
      await router.push({ path: '/order/summary' });
    }
  });

  return {
    barcodeCheckStatus,
    checkIfValidBarcode,
    isDeviceCameraOn,
    errorMessage,
    handleInputOptionClick,
    handleKeyboardInput,
    handleCameraScanningInput,
    handleCameraStatus,
  };
};
