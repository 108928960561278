import { z } from 'zod';

export const MimeTypeSchema = z.enum([
  'image/svg+xml',
  'image/png',
  'application/pdf',
  'application/json',
  'application/xml',
  'text/plain',
  'text/csv'
]);
/**
 * MIME types are based on the IANA registry and can be found at https://www.iana.org/assignments/media-types/media-types.xhtml.
 * This list can be infinitely long and this type should be extended if more MIME types are used for signatures
 */
export type MimeType = z.infer<typeof MimeTypeSchema>;
