import { z } from 'zod';
import { BarcodeSchema, createSchemaClass } from '@abc-labs-ab/ts-events';

export const DeleteResultRemarkRequestDtoSchema = z.object({
  barcode: BarcodeSchema,
  requisitionId: z.string().max(36),
  remarkId: z.string().uuid(),
});

export class DeleteResultRemarkRequestDto extends createSchemaClass(
  DeleteResultRemarkRequestDtoSchema,
) {}
