import { z } from 'zod';
import { DocumentWithContentSchema } from '.';

export const GetAllPublishedDocumentsWithContentResponseDtoSchema = z.object({
  documents: DocumentWithContentSchema.array(),
});

export type GetAllPublishedDocumentsWithContentResponseDto = z.infer<
  typeof GetAllPublishedDocumentsWithContentResponseDtoSchema
>;
