import { AssessmentSchema, createSchemaClass } from '@abc-labs-ab/ts-events';
import { TestTypeSchema } from '@careos/organization-api-types';
import { z } from 'zod';

export const SimplifiedPreviewReportRequestDtoSchema = z.object({
  barcode: z.string(),
  personId: z.string(),
  reasonForTesting: z.string(),
  sampleType: z.string(),
  sampledAt: z.string(),
  reportedAt: z.string(),
  testType: TestTypeSchema,
  validationObservationAssessment: AssessmentSchema.nullish(),
  positiveObservations: z
    .array(
      z.object({
        observationDefinitionId: z.string(),
        displayName: z.string().nullish(),
      }),
    )
    .optional(),
});

export class SimplifiedPreviewReportRequestDto extends createSchemaClass(
  SimplifiedPreviewReportRequestDtoSchema,
) {}
