<template>
  <DataTable filter-display="menu" class="base-table" :loading="loading">
    <slot />
    <template v-if="$slots.header" #header>
      <slot name="header" />
    </template>
    <template #loading>
      <ProgressSpinner
        :pt="{
          spinner: { style: { animationDuration: '1s' } },
          circle: {
            style: { stroke: '#4031C6', strokeWidth: 3, animation: 'none' },
          },
        }"
      />
    </template>
    <template #empty>
      <h4 v-if="loading" class="base-table__empty">
        {{ t('careos_table.loading') }}
      </h4>
      <h4 v-else-if="emptyMsg" class="base-table__empty">
        {{ emptyMsg }}
      </h4>
      <h4 v-else class="base-table__empty">
        {{ t('careos_table.default_empty_msg') }}
      </h4>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import ProgressSpinner from 'primevue/progressspinner';
import { useI18n } from 'vue-i18n';

defineProps<{
  emptyMsg?: string;
  loading?: boolean;
}>();

const { t } = useI18n();
</script>
<style scoped lang="scss">
.base-table__empty {
  text-align: center;
}
</style>

<style lang="scss">
.base-table.p-datatable {
  table {
    @apply border-separate;
    border-spacing: 0 1em;
  }
  .p-datatable-thead {
    @apply bg-transparent;
  }

  .p-datatable-header {
    @apply bg-transparent;
    border: none;
  }

  .p-datatable-thead > tr > th {
    @apply border-none bg-transparent py-4;

    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;

    &:hover,
    &.p-sortable-column.p-highlight,
    &.p-sortable-column.p-highlight:hover {
      @apply bg-transparent text-black;
      .p-sortable-column-icon {
        @apply text-black;
      }
    }
  }

  .p-datatable-tbody > tr {
    @apply border border-careos-purple-light bg-careos-purple-light text-careos-purple;
    border: 4px solid black;
    height: 3.5rem;

    & > td {
      @apply whitespace-nowrap border border-careos-purple-light;
      // Corresponding to p3-bg-white
      font-weight: 300;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.2px;

      // Set border-radius on the top-right and bottom-right of the last table data on the table row
      &:first-child {
        @apply rounded-bl-lg rounded-tl-lg;
      }
      &:last-child {
        @apply rounded-br-lg rounded-tr-lg;
      }
      &:first-child:last-child {
        @apply rounded-lg;
      }
    }
  }

  .p-datatable-row-expansion {
    @apply bg-white;
  }

  .p-component-overlay {
    @apply bg-[#ffffff99];
  }

  .p-paginator-bottom {
    @apply border-0;
  }
}

.base-table.p-datatable .p-datatable-tbody > tr {
  @apply cursor-pointer;
}

.p-datatable .p-datatable-tbody > tr > td {
  @apply p-2;
}
.p-datatable .p-datatable-thead .p-column-filter {
  @apply ml-0;
}
.p-column-filter-menu {
  @apply ml-0;
}
</style>
