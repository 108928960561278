
import { z } from 'zod';
import { SamplingSessionSchema } from '../../sampling-session';

export const GetSamplingSessionsResponseDtoSchema = z.object({
  samplingSessions: SamplingSessionSchema.array(),
});
export type GetSamplingSessionsResponseDto = z.infer<
  typeof GetSamplingSessionsResponseDtoSchema
>;
