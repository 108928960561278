<template>
  <CareosTable
    v-model:editingRows="editingRows"
    v-model:filters="filters"
    :value="store.employers"
    :loading="!!isLoading"
    edit-mode="row"
    :paginator="true"
    :rows="10"
    :rows-per-page-options="[5, 10, 15]"
    filter-display="menu"
    :sort-order="-1"
    sort-field="createdAt"
    @row-click="viewEmployers"
    @row-edit-init="onRowInit"
    @row-edit-save="onRowEditSave"
    @row-edit-cancel="isEditing = false"
  >
    <Column
      field="name"
      :show-filter-match-modes="false"
      :header="t('admin_board.table.headers.employer')"
      sortable
      data-type="string"
    >
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" />
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="t('admin_board.table.headers.employer')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          ref="filterCancel"
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="createdAt"
      :show-filter-match-modes="false"
      data-type="date"
      sortable
    >
      <template #header>
        {{ t('admin_board.table.headers.added') }}
        <div
          v-tooltip.right="{
            value: t('admin_board.table.headers.created_at_tooltip'),
            class: 'tooltip-style',
          }"
          class="flex"
        >
          <PrimeIcon icon="INFO_CIRCLE" size="XS" class="ml-2" />
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('admin_board.table.filters.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('admin_board.table.filters.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
      <template #body="{ data }">{{
        dayjs(data.createdAt).format('YYYY-MM-DD')
      }}</template>
    </Column>
    <Column data-type="string" style="width: 25%">
      <template #body>
        <span
          class="cursor-pointer rounded border border-careos-purple px-4 py-1 font-medium focus:ring-4 focus:ring-careos-purple-light active:text-careos-lavendel"
        >
          {{ t('admin_board.employer_table.add_subdivision') }}
        </span></template
      >
    </Column>
    <Column
      :row-editor="true"
      style="width: 10%; min-width: 8rem"
      body-style="text-align:center"
    >
      <template #roweditorcancelicon> X </template>
    </Column>
  </CareosTable>
  <TheModal
    :show-modal="showModal"
    :loading="isLoadingEdit"
    @decline="showModal = false"
    @accept="confirmEditEmployer"
  >
    <h1 class="mb-8 font-semibold">
      {{ t('admin_board.edit_employers_modal.title') }}:
    </h1>
    <p class="mb-4">
      {{ t('admin_board.edit_employers_modal.message_1') }}
      <span class="font-bold">{{ currentEmployerName?.name }}</span>
      {{ t('admin_board.edit_employers_modal.message_2') }}
      <span class="font-bold">{{ updatedEmployerName?.name }}</span
      >.
    </p>
  </TheModal>
</template>

<script setup lang="ts">
import CareosTable from '@/components/CareosTable.vue';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import dayjs from 'dayjs';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { router } from '@/router';
import Column from 'primevue/column';
import {
  DataTableFilterMeta,
  DataTableRowClickEvent,
  DataTableRowEditSaveEvent,
} from 'primevue/datatable';
import { ComponentPublicInstance, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { useOrganization } from '../../../stores/organization';
import { useI18n } from 'vue-i18n';
import { FilterMatchMode, FilterService } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import isBetween from 'dayjs/plugin/isBetween';
import { usePrimeVue } from 'primevue/config';
import { PrimeIcon } from 'careos-vue-components';
import TheModal from '@/components/TheModal.vue';
import type {
  UpdateEmployerRequestDto,
  Employer,
} from '@careos/organization-api-types';
import { OrganizationTypeSchema } from '@careos/organization-api-types';

dayjs.extend(isBetween);

const route = useRoute();
const primeVue = usePrimeVue();
const store = useOrganization();
const { t, locale } = useI18n();
const toast = useToast();

const showModal = ref(false);
const editingRows = ref([]);
const isEditing = ref(false);
const updateEmployerDto = ref<UpdateEmployerRequestDto | undefined>();
const currentEmployerName = ref<Employer | undefined>();
const updatedEmployerName = ref<Employer | undefined>();

const filterApply = ref<ComponentPublicInstance | null>(null);

FilterService.register(
  'dateInRange',
  (value: string | null, filter: [Date, Date] | null): boolean => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const isRange = filter.at(0) && filter.at(1);
    return isRange
      ? dayjs(value).isBetween(filter[0], filter[1], 'date', '[]')
      : dayjs(value).isSame(filter[0], 'date');
  },
);

const filterDefaults: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: { value: null, matchMode: 'dateInRange' },
};

const filterDefaultClone = () => {
  const defaultFilters: DataTableFilterMeta = {};
  Object.keys(filterDefaults).forEach((key) => {
    defaultFilters[key] = structuredClone(filterDefaults[key]);
  });
  return defaultFilters;
};
const filters = ref<DataTableFilterMeta>(filterDefaultClone());

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('admin_board.table.filters.january'),
      t('admin_board.table.filters.february'),
      t('admin_board.table.filters.march'),
      t('admin_board.table.filters.april'),
      t('admin_board.table.filters.may'),
      t('admin_board.table.filters.june'),
      t('admin_board.table.filters.july'),
      t('admin_board.table.filters.august'),
      t('admin_board.table.filters.september'),
      t('admin_board.table.filters.october'),
      t('admin_board.table.filters.november'),
      t('admin_board.table.filters.december'),
    ],
    dayNamesMin: [
      t('admin_board.table.filters.sunday'),
      t('admin_board.table.filters.monday'),
      t('admin_board.table.filters.tuesday'),
      t('admin_board.table.filters.wednesday'),
      t('admin_board.table.filters.thursday'),
      t('admin_board.table.filters.friday'),
      t('admin_board.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);
const { organizationKey: orgKey } = route.params;
const { exec: fetchEmployers, isLoading } = useNetworkRequest(() =>
  store.fetchEmployers(orgKey as string),
);
fetchEmployers();

const viewEmployers = ($event: DataTableRowClickEvent) => {
  const { organizationKey, organizationType } = route.params;
  const employerKey = $event.data.key;
  router.push({
    name: 'organizationEmployerSubdivisionsView',
    params: { organizationType, organizationKey, employerKey },
  });
};

const onRowInit = () => {
  isEditing.value = true;
};

const isSameValues = (employer: Employer, newEmployer: Employer) =>
  employer.name === newEmployer.name;

const onRowEditSave = ({
  data: employer,
  newData: newEmployer,
}: DataTableRowEditSaveEvent) => {
  if (isSameValues(employer, newEmployer)) return;
  currentEmployerName.value = employer;
  updatedEmployerName.value = newEmployer;

  showModal.value = true;
  const { organizationKey, organizationType } = route.params;
  const { key, name }: Employer = newEmployer;
  const orgType = OrganizationTypeSchema.parse(organizationType);

  updateEmployerDto.value = {
    employerKey: key,
    organizationKey: organizationKey as string,
    organizationType: orgType,
    newEmployerName: name,
  };
  isEditing.value = false;
};

const editEmployer = async () => {
  const newEmployerName = updateEmployerDto?.value?.newEmployerName;
  if (!newEmployerName?.trim()) {
    toast.add({
      life: 5_000,
      severity: 'error',
      summary: t(
        'admin_board.edit_employers_modal.employer_edited_toast_msg.error',
      ),
    });
    return;
  }

  if (updateEmployerDto.value) {
    await store.updateEmployer(updateEmployerDto.value);
    toast.add({
      life: 5_000,
      severity: 'success',
      summary: t(
        'admin_board.edit_employers_modal.employer_edited_toast_msg.success',
      ),
    });
  }
  showModal.value = false;
  fetchEmployers();
};

const { exec: confirmEditEmployer, isLoading: isLoadingEdit } =
  useNetworkRequest(async () => {
    await editEmployer();
  });

onBeforeRouteLeave(() => {
  store.$reset();
});
</script>
