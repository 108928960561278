import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const AddTreatmentCenterRequestDtoSchema = z.object({
  name: nonEmptyString,
  organizationKey: nonEmptyString,
});

export type AddTreatmentCenterRequestDto = z.infer<
  typeof AddTreatmentCenterRequestDtoSchema
>;
