import { z } from 'zod';

export const UpdateEmployerResponseDtoSchema = z.object({
  employerKey: z.string().trim(),
  name: z.string().trim().min(1),
});

export type UpdateEmployerResponseDto = z.infer<
  typeof UpdateEmployerResponseDtoSchema
>;
