import { z } from 'zod';

export const RackDimensionsSchema = z.object({
  rows: z.number(),
  columns: z.number(),
});

/**
 * Dimensions of a rack.
 */
export type RackDimensions = z.infer<typeof RackDimensionsSchema>;
