import { z } from 'zod';

export const GetOrgDisplayAndLoginNameByOrgKeyResponseDtoSchema = z.object({
  displayName: z.string(),
  loginName: z.string(),
});

export type GetOrgDisplayAndLoginNameByOrgKeyResponseDto = z.infer<
  typeof GetOrgDisplayAndLoginNameByOrgKeyResponseDtoSchema
>;
