import { z } from 'zod';
import { CodingSchema } from './coding.interface';

export const CodeableConceptSchema = z.object({
  /**
   * Codes defined by terminology systems to describe the concept.
   */
  coding: z.array(CodingSchema),

  /**
   * Plain text represenation of the concept.
   */
  text: z.string(),
});

/**
 * A value or a concept that is represented as one or more codes.
 */
export type CodeableConcept = z.infer<typeof CodeableConceptSchema>;
