import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const ReSendUserWelcomeEmailRequestDtoSchema = z.object({
  email: z.string().trim().email(),
  organizationKey: nonEmptyString,
});

export type ReSendUserWelcomeEmailRequestDto = z.infer<
  typeof ReSendUserWelcomeEmailRequestDtoSchema
>;
