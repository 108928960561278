<template>
  <form
    class="mb-8 flex flex-row gap-8"
    @submit.prevent="handleAddOrganization"
  >
    <span class="relative flex flex-col text-left">
      <label for="org-name">{{ t('admin_board.add_org_form.name') }}</label>
      <span class="p-input-icon-left">
        <i
          ref="customerAbbreviationEl"
          class="absolute left-2 w-max leading-10"
          >{{ organizationStore.abbreviation }}</i
        >
        <span>
          <InputText
            id="org-name"
            v-model="orgData.displayName"
            v-focus
            class="h-10 w-72"
            required
            type="text"
            :style="customerAbbreviationWidth"
          />
        </span>
      </span>
    </span>
    <span class="flex flex-col text-left">
      <label for="org-type">{{
        t('admin_board.add_org_form.type.label')
      }}</label>
      <Dropdown
        id="org-type"
        v-model="orgData.type"
        :options="orgTypes"
        option-label="label"
        :placeholder="t('admin_board.add_org_form.type.placeholder')"
        :class="[
          'h-10 w-60 leading-4',
          { 'p-invalid': isOrgTypeError && !orgData.type },
        ]"
      />
    </span>
    <FormSubmitButton
      :loading="isLoading"
      :disabled="isLoading"
      type="submit"
      :label="t('admin_board.add_org_form.submit_button')"
      class="ml-auto h-10 self-end"
    />
  </form>
  <TheModal
    :show-modal="showModal"
    @decline="showModal = false"
    @accept="confirmAddOrganization"
  >
    <h3 class="text-lg font-bold">
      {{ t('admin_board.add_org_form.confirm_add_organization.title') }}
    </h3>
    <br />
    <p>
      {{ t('admin_board.add_org_form.confirm_add_organization.text') }}
      <span class="font-bold"
        >{{ organizationStore.abbreviation }}{{ orgData.displayName }}
      </span>
      <br />
      <span>
        ({{
          t('admin_board.add_org_form.confirm_add_organization.login_name')
        }}
        <span class="font-bold"
          >{{ organizationStore.abbreviation.toLowerCase()
          }}{{ orgData.displayName?.toLowerCase() }}</span
        >)?</span
      >
    </p>
  </TheModal>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from 'vue';

import { useI18n } from 'vue-i18n';

import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TheModal from '@/components/TheModal.vue';
import { useToast } from 'primevue/usetoast';

import { AddOrganizationRequestDto } from '@careos/organization-api-types';
import { OrgType, OrgData } from '../../models/organization';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import FormSubmitButton from '../../components/FormSubmitButton.vue';
import { useOrganizations } from '../../stores/organizations';
import { useElementSize } from '@vueuse/core';

const toast = useToast();
const organizationStore = useOrganizations();

const { t } = useI18n();

const showModal = ref(false);

const vFocus = {
  mounted: (el: HTMLElement) => el.focus(),
};

const orgTypes = computed<OrgType[]>(() => [
  { label: t('admin_board.add_org_form.type.workplace'), key: 'workplace' },
  {
    label: t('admin_board.add_org_form.type.treatment_center'),
    key: 'treatment_center',
  },
]);

const orgData = reactive<OrgData>({
  displayName: '',
  type: null,
});
const isOrgTypeError = ref(false);

const customerAbbreviationEl = ref();
const { width: abbreviationWidth } = useElementSize(customerAbbreviationEl);
const customerAbbreviationWidth = computed(
  () => `padding-left: ${abbreviationWidth.value + 10}px`,
);

const submit = async () => {
  isOrgTypeError.value = false;

  if (!orgData.type) {
    isOrgTypeError.value = true;
    return;
  }
  if (!orgData.displayName || !orgData.type?.key) return;

  const dto: AddOrganizationRequestDto = {
    customerAbbreviation: organizationStore.abbreviation,
    name: orgData.displayName,
    type: orgData.type?.key,
  };

  await organizationStore.createOrganization(dto);
  toast.add({
    life: 5_000,
    severity: 'success',
    summary: t('admin_board.add_org_form.org_added_msg'),
  });

  orgData.displayName = '';
  orgData.type = null;
};

const { exec: createOrganization, isLoading } = useNetworkRequest(async () => {
  await submit();
});

const handleAddOrganization = () => {
  const displayNameUppercase = orgData.displayName?.toUpperCase();
  const customerAbbreviationUppercase =
    organizationStore.abbreviation.toUpperCase();

  if (displayNameUppercase?.startsWith(customerAbbreviationUppercase)) {
    showModal.value = true;
    return;
  }
  createOrganization();
};

const confirmAddOrganization = async () => {
  await createOrganization();
  showModal.value = false;
};
</script>
