import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization-type';

export const UpdateTreatmentCenterRequestDtoSchema = z.object({
  treatmentCenterKey: nonEmptyString,
  newTreatmentCenterName: nonEmptyString,
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
});

export type UpdateTreatmentCenterRequestDto = z.infer<
  typeof UpdateTreatmentCenterRequestDtoSchema
>;
