import { z } from 'zod';

export const GetUsersByOrganizationRoleResponseDtoSchema = z.object({
  users: z
    .object({
      auth0UserId: z.string(),
      name: z.string(),
    })
    .array(),
});

export type GetUsersByOrganizationRoleResponseDto = z.infer<
  typeof GetUsersByOrganizationRoleResponseDtoSchema
>;
