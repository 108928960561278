import { z } from 'zod';
import { CodeableConceptSchema } from '../codeable-concept.interface';
import { DurationSchema } from '../duration.interface';
import { QuantitySchema } from '../quantity.interface';
import { RangeSchema } from '../range.interface';

export const SpecimenDefinitionStatusSchema = z.enum([
  'draft',
  'active',
  'retired',
  'unknown',
]);

/**
 * The current state of a {@link SpecimenDefinition}
 */
export type SpecimenDefinitionStatus = z.infer<
  typeof SpecimenDefinitionStatusSchema
>;

export const HandlingTypeSchema = z.enum([
  'TURN',
  'CENTRIFUGE',
  'STORAGE',
  'GENERAL',
]);

export type HandlingType = z.infer<typeof HandlingTypeSchema>;

export const SpecimenDefinitionHandlingSchema = z.object({
  temperatureQualifier: CodeableConceptSchema.optional(),
  /**
   * The temperature interval for this set of handling instructions.
   */
  temperatureRange: RangeSchema.optional(),
  /**
   * Max preservation time
   */
  maxDuration: DurationSchema.optional(),
  within: DurationSchema.optional(),
  after: DurationSchema.optional(),
  /**
   * Additional textual preservation instructions.
   */
  instruction: z.string(),
  handlingType: HandlingTypeSchema,
});

/**
 * Specimen handling before testing
 */
export type SpecimenDefinitionHandling = z.infer<
  typeof SpecimenDefinitionHandlingSchema
>;

export const SpecimenDefinitionContainerSchema = z.object({
  /**
   * Refers to cap color in hex with a human readable display of color.
   */
  cap: CodeableConceptSchema,
  /**
   * The description of the kind of container
   */
  description: z.string(),
  /**
   * The capacity (volume) of this kind of container.
   */
  capacity: QuantitySchema,
  /**
   * Substance introduced in the kind of container to preserve, maintain or enhance the specimen.
   */
  additive: CodeableConceptSchema,
  /**
   * Criterion for rejection of the specimen in its container by the laboratory.
   */
  rejectionCriterion: CodeableConceptSchema.optional(),
  /**
   * Priority of tubes, 0 = highest prio
   */
  samplingPriority: z.number(),
});

/**
 * The specimen's container
 */
export type SpecimenDefinitionContainer = z.infer<
  typeof SpecimenDefinitionContainerSchema
>;

export const SpecimenDefinitionTypeTestedSchema = z.object({
  /**
   * What type of specimen that is collected.
   */
  type: CodeableConceptSchema,
  /**
   * The specimen's container.
   */
  container: SpecimenDefinitionContainerSchema,
  /**
   * Specimen handling before testing.
   */
  handlings: z.array(SpecimenDefinitionHandlingSchema),
});

/**
 * Specimen in container intended for testing by lab.
 */
export type SpecimenDefinitionTypeTested = z.infer<
  typeof SpecimenDefinitionTypeTestedSchema
>;

export const SpecimenDefinitionSchema = z.object({
  /**
   * A logical identifier assigned to this SpecimenDefinition.
   */
  specimenDefinitionId: z.string(),
  /**
   * Descriptive name of this SpecimenDefinition.
   */
  title: z.string(),
  /**
   * The current state of this SpecimenDefinition.
   */
  status: z.literal('active'),
  /**
   * Specimen in container intended for testing by lab.
   */
  typesTested: z.array(SpecimenDefinitionTypeTestedSchema),
  /**
   * Time aspect of specimen collection.
   * E.g. 'preferably morning time'.
   */
  timeAspect: z.string().optional(),
});
/**
 * A kind of specimen with associated set of requirements.
 */
export type SpecimenDefinition = z.infer<typeof SpecimenDefinitionSchema>;
