<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="barcode-scan-auto"
      :title="t('barcode.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="isDisablingForwardNavigation"
      class="w-full max-w-md"
      @action-right="nextStep"
      @action-left="previousStep"
    >
      <div v-if="!isDeviceCameraOn" class="flex flex-col gap-y-4">
        <ScanningOptions
          :input-device="inputDevice"
          @update-input-option="handleInputOptionClick"
        />
        <div
          v-if="inputDevice !== 'keyboard'"
          class="flex h-[164px] flex-col justify-between"
        >
          <ScannerInput
            :barcode="barcodeValue"
            :status="barcodeCheckStatus"
            :is-valid-careos-barcode="isValidCareosBarcode"
            :scanning-type="inputDevice"
            :error="errorMessage"
          />
          <Button
            text
            :label="t('barcode.manual_input.required')"
            severity="warning"
            size="small"
            @click="inputDevice = 'keyboard'"
          />
        </div>
        <ManualInput
          v-else
          class="h-[164px]"
          :error="errorMessage"
          :barcode="barcodeValue"
          @update-barcode="handleKeyboardInput"
        />
        <InlineErrorMessage
          v-if="barcodeCheckStatus == 'error' && errorMessage"
          :error-message="errorMessage"
        />
      </div>

      <CameraScanner
        v-model:is-stream-active="isDeviceCameraOn"
        @update-barcode-value="handleCameraScanningInput"
        @update-camera-status="handleCameraStatus"
      />
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { router } from '@/router';
import ManualInput from './components/ManualInput.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import Button from 'primevue/button';
import OrderViewCard from '../../components/OrderViewCard.vue';
import { useI18n } from 'vue-i18n';
import ScannerInput from './components/ScannerInput.vue';
import ScanningOptions from './components/ScanningOptions.vue';
import CameraScanner from './components/CameraScanner.vue';
import { useBarcodeStore } from '@/stores/order/barcode';
import { storeToRefs } from 'pinia';
import { useBarcodeInput } from '@/composables/useBarcodeInput';
import { computed, onMounted } from 'vue';

import InlineErrorMessage from './components/InlineErrorMessage.vue';

const { t } = useI18n();

const barcodeStore = useBarcodeStore();
const { barcodeValue, inputDevice, isValidCareosBarcode } =
  storeToRefs(barcodeStore);

const {
  checkIfValidBarcode,
  errorMessage,
  barcodeCheckStatus,
  handleInputOptionClick,
  handleKeyboardInput,
  handleCameraStatus,
  handleCameraScanningInput,
  isDeviceCameraOn,
} = useBarcodeInput();

onMounted(() => {
  if (
    !isDeviceCameraOn.value &&
    inputDevice.value !== 'keyboard' &&
    !barcodeValue.value
  ) {
    inputDevice.value = 'barcodeReader';
  }
});

const nextStep = async () => {
  if (!isValidCareosBarcode.value) {
    await checkIfValidBarcode(barcodeValue.value);
  }
  if (isValidCareosBarcode.value) {
    await router.push({ path: '/order/summary' });
  }
};

const previousStep = () => {
  router.go(-1);
};

const isDisablingForwardNavigation = computed(() =>
  inputDevice.value === 'keyboard'
    ? !barcodeValue.value
    : !isValidCareosBarcode.value,
);
</script>
