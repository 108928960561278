import { z } from 'zod';
import { PanelSizeSchema, PanelSubstanceSchema } from '../../panel';

export const PanelSubstanceDetailsSchema = z.object({
  panelSubstances: PanelSubstanceSchema.array(),
  additionalSubstances: z.array(z.string()).optional(),
});

export type PanelSubstanceDetails = z.infer<typeof PanelSubstanceDetailsSchema>;

export const GetPanelsWithAdditionalSubstancesResponseDtoSchema = z.object({
  panels: z.record(PanelSizeSchema, PanelSubstanceDetailsSchema),
});

export type GetPanelsWithAdditionalSubstancesResponseDto = z.infer<
  typeof GetPanelsWithAdditionalSubstancesResponseDtoSchema
>;
