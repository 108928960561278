<template>
  <div class="flex h-full flex-col items-center justify-center">
    <div class="flex flex-col rounded-lg border bg-white shadow-md">
      <div class="flex items-center justify-center">
        <div class="m-6 w-[80vw] max-w-xs">
          <FormKit
            v-model="code"
            :label="t('patientConfirmation.passcode.inputLabel')"
            :help="t('patientConfirmation.passcode.help')"
            type="text"
            validation="required"
          />
          <CareosErrorMessage
            v-if="isPasswordInvalid"
            :message="t('patientConfirmation.passcode.codeInvalid')"
          />
          <CareosButton
            :label="t('patientConfirmation.passcode.submitButton')"
            @click="submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ToxiHttpClient } from '@/api/toxicology-client';
import CareosErrorMessage from '@/components/CareosErrorMessage.vue';
import CareosButton from '@/components/CareosButton.vue';
import { router } from '@/router';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useConfirmationViewStore } from '../../store/confirmation-view-store';
import { OrderConfirmation } from '@careos/toxicology-types';

const store = useConfirmationViewStore();
const { t } = useI18n();
const route = useRoute();

const code = ref('');
const isPasswordInvalid = ref();

const submit = async () => {
  const requisitionId = `${route.params.partialId}-${code.value}`;
  isPasswordInvalid.value = false;
  if (code.value) {
    try {
      const res = await ToxiHttpClient.get<OrderConfirmation>(
        `/order-confirmation/personal-code/${requisitionId}`,
      );
      store.patientConfirmationData = res.data;
      router.push('/confirmation/requisition');
    } catch {
      isPasswordInvalid.value = true;
    }
  }
};
</script>
