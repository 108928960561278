import { z } from 'zod';
import { UnknownCode } from '../const/unknown';

// INFO: These errors are caused by us. We are not allowed to use their conventions or error codes to communicate to the end user.
export const CareosCauseErrorCodeSchema = z.enum([
  'invalidParameters',
  'unauthorized',
  'notFound',
  'methodNotAllowed',
  'unsupportedMediaType',
]);
export type CareosCauseErrorCode = z.infer<typeof CareosCauseErrorCodeSchema>;

// INFO: These errors could be caused by us or the BankID API. Regardless, their convention must be followed for these codes.
export const ExternalCauseErrorCodeSchema = z.enum([
  'alreadyInProgress',
  'requestTimeout',
  'internalError',
  'maintenance',
]);
export type ExternalCauseErrorCode = z.infer<
  typeof ExternalCauseErrorCodeSchema
>;

export const KnownErrorCodeSchema = z.enum([
  ...CareosCauseErrorCodeSchema.options,
  ...ExternalCauseErrorCodeSchema.options,
]);
export type KnownErrorCode = z.infer<typeof KnownErrorCodeSchema>;

export const BankIdRequestErrorResponseDtoSchema = z.object({
  errorCode: z.enum([...KnownErrorCodeSchema.options, UnknownCode]),
  details: z.string().optional(),
});

export type BankIdRequestErrorResponseDto = z.infer<
  typeof BankIdRequestErrorResponseDtoSchema
>;

export const isKnownErrorCode = (
  errorCode: unknown,
): errorCode is KnownErrorCode =>
  KnownErrorCodeSchema.safeParse(errorCode).success;

export const isExternalCauseErrorCode = (
  errorCode: unknown,
): errorCode is ExternalCauseErrorCode =>
  ExternalCauseErrorCodeSchema.safeParse(errorCode).success;

export const isCareosCauseErrorCode = (
  errorCode: unknown,
): errorCode is CareosCauseErrorCode =>
  CareosCauseErrorCodeSchema.safeParse(errorCode).success;

export const isErrorDto = (
  result: unknown,
): result is BankIdRequestErrorResponseDto =>
  BankIdRequestErrorResponseDtoSchema.safeParse(result).success;
