import { z } from 'zod';

export const GetOrgNameAndCountryCodeByOrgKeyResponseDtoSchema = z.object({
  name: z.string(),
  countryCode: z.string(),
});

export type GetOrgNameAndCountryCodeByOrgKeyResponseDto = z.infer<
  typeof GetOrgNameAndCountryCodeByOrgKeyResponseDtoSchema
>;
