<template>
  <div v-for="option in options" :key="option?.key">
    <SubstanceRadio
      v-if="store.panels && option"
      :data-test-id="`panel-size-${option.key}`"
      :title="option.value"
      :is-selected-panel="selectedPanelSize === option.key"
      :panel-size="option.key"
      :panel-substances="store.panels[option.key] || []"
      :has-accredited-substance="store.hasAccreditedSubstance"
      :has-qualitative-substance="store.hasQualitativeSubstance"
      @update:selected-panel-size="updateSelectedOption"
    />
  </div>
</template>
<script setup lang="ts">
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';

import {
  DoaPanelSize,
  DoaPanelSizeSchema,
  PanelSubstance,
} from '@careos/toxicology-types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { LabelBuilderArgs } from '../types/label-builder';
import SubstanceRadio from './SubstanceRadio.vue';

const store = useOrderViewStore();
const { t } = useI18n();

defineProps<{
  selectedPanelSize: DoaPanelSize;
}>();

const getAccreditedText = (
  numberOfAccredited: number,
  numberOfUnaccredited: number,
) => {
  if (numberOfAccredited && numberOfUnaccredited) {
    return {
      accreditedText: t('substances.panel.accredited', {
        numberOfAccredited,
      }),
      unaccreditedText: `, ${t(
        'substances.panel.unaccredited',
        {
          numberOfUnaccredited,
        },
        numberOfUnaccredited,
      )}`,
    };
  }

  return {
    accreditedText: t(
      'substances.panel.allAccreditedOrUnaccredited',
      {
        numberOfSubstances: numberOfAccredited || numberOfUnaccredited,
      },
      numberOfAccredited || numberOfUnaccredited,
    ),
    unaccreditedText: '',
  };
};
const getPanelLabel = ({
  numberOfAccredited,
  numberOfUnaccredited,
  panelSize,
}: LabelBuilderArgs) => {
  const panelSizeText = t(`substances.${panelSize}`);
  const { accreditedText, unaccreditedText } = getAccreditedText(
    numberOfAccredited,
    numberOfUnaccredited,
  );
  const panelText = `${panelSizeText} (${accreditedText}${unaccreditedText})`;
  return panelText;
};

const getAccreditedAndUnaccreditedCount = (panel: PanelSubstance[]) => {
  const numberOfAccredited = panel.filter(
    (substance) => substance.isSwedacAccredited,
  ).length;
  const numberOfUnaccredited = panel.filter(
    (substance) => !substance.isSwedacAccredited,
  ).length;
  return { numberOfAccredited, numberOfUnaccredited };
};

const options = computed(() =>
  DoaPanelSizeSchema.options.map((it) => {
    if (!store.panels) return;

    const panel = store.panels[it];

    if (!panel) {
      return;
    }

    const { numberOfAccredited, numberOfUnaccredited } =
      getAccreditedAndUnaccreditedCount(panel);

    const label = getPanelLabel({
      numberOfAccredited,
      numberOfUnaccredited,
      panelSize: it,
    });

    return {
      key: it as DoaPanelSize,
      value: label,
    };
  }),
);

const emits = defineEmits(['update:selectedPanelSize']);

const updateSelectedOption = (e: DoaPanelSize) => {
  emits('update:selectedPanelSize', e);
};
</script>
