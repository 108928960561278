export const saveFile = (data: Blob, fileName: string) => {
  const href = URL.createObjectURL(data);
  const a = Object.assign(document.createElement('a'), {
    href,
    style: 'display:none',
    download: fileName,
  });
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(href);
  a.remove();
};
