export const bankidMessagesEn = {
  'hintcode-qr-outstandingTransaction':
    'Start the BankID app and scan the QR code.',
  'hintcode-desktop-outstandingTransaction':
    'Trying to start BankID Security Application.',
  'hintcode-mobile-outstandingTransaction':
    'Please click the button below to start your BankID app.',

  'hintcode-qr-started': 'Start the BankID app and scan the QR code.',
  'hintcode-desktop-started': 'Trying to start BankID Security Application.',
  'hintcode-mobile-started':
    'Please click the button below to start your BankID app.',

  'hintcode-userSign':
    'Enter your security code in the BankID app and select Identify or Sign.',
  'hintcode-desktop-userSign':
    'Enter your security code in the BankID Security Application and select Identify or Sign.',

  'hintcode-unknown': 'Something went wrong. Please try again.',
  'hintcode-userCancel': 'Action cancelled.',

  'hintcode-startFailed':
    "The BankID app couldn't be found on your computer or mobile device. Please install it and get a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",
  'hintcode-qr-startFailed':
    "The scan failed. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and get a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",

  'hintcode-expiredTransaction':
    "The BankID app is not responding. Please check that the program is started and that you have internet access. If you don't have a valid BankID you can get one from your bank. Try again.",
  'hintcode-certificateErr':
    'The BankID you are trying to use is revoked or too old. Please use a different BankID or get a new one from your internet bank.',
  'hintcode-alreadyInProgress':
    'An identification or signing for this personal number is already started. Please try again.',
  'hintcode-requestTimeout': 'Something went wrong. Please try again.',
  'hintcode-maintenance': 'Something went wrong. Please try again.',
  'hintcode-internalError': 'Something went wrong. Please try again.',

  'error-message-invalidCsrfToken':
    'Your session has expired. Please reload the page.',
};
