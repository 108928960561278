import { OrgHttpClient } from '@/api/organization-client';
import {
  AddOrganizationRequestDto,
  AvailableTestTypes,
  GetOrganizationsResponseDto,
  OrganizationType,
  OrganizationWithEnabledTestTypes,
  TestToOrderType,
  TestType,
  UpdateOrganizationEnabledTestTypesRequestDto,
} from '@careos/organization-api-types';
import { defineStore } from 'pinia';

interface OrgsViewState {
  abbreviation: string;
  organizations: OrganizationWithEnabledTestTypes[];
  availableTestTypes: {
    workplace: TestToOrderType[];
    treatment_center: TestToOrderType[];
  };
}

function getAvailableTestTypes() {
  const workplace: TestToOrderType[] = [];
  const treatmentCenter: TestToOrderType[] = [];

  const availableWpTestTypes = AvailableTestTypes[OrganizationType.workplace];
  const availableTcTestTypes =
    AvailableTestTypes[OrganizationType.treatment_center];

  if (availableWpTestTypes?.DoA) {
    availableWpTestTypes.DoA.forEach((orderType) => {
      workplace.push({
        testType: TestType.DoA,
        orderType,
      });
    });
  }

  if (availableWpTestTypes?.PEth) {
    availableWpTestTypes.PEth.forEach((orderType) => {
      workplace.push({
        testType: TestType.PEth,
        orderType,
      });
    });
  }

  if (availableTcTestTypes?.DoA) {
    availableTcTestTypes.DoA.forEach((orderType) => {
      treatmentCenter.push({
        testType: TestType.DoA,
        orderType,
      });
    });
  }

  if (availableTcTestTypes?.PEth) {
    availableTcTestTypes.PEth.forEach((orderType) => {
      treatmentCenter.push({
        testType: TestType.PEth,
        orderType,
      });
    });
  }

  return {
    workplace,
    treatment_center: treatmentCenter,
  };
}

export const useOrganizations = defineStore('organizations-store', {
  state: (): OrgsViewState => ({
    organizations: [],
    abbreviation: '',
    availableTestTypes: getAvailableTestTypes(),
  }),
  actions: {
    async fetchOrganizations() {
      const res = await OrgHttpClient.get<GetOrganizationsResponseDto>(
        '/organization/get-organizations',
      );
      this.organizations = res.data.organizations.reverse();
      this.abbreviation = res.data.abbreviation
        ? `${res.data.abbreviation}-`
        : '';
    },
    async createOrganization(organization: AddOrganizationRequestDto) {
      await OrgHttpClient.post('/organization/add-organization', organization);
      await this.fetchOrganizations();
    },

    async updateOrganizationEnabledTestTypes(
      dto: UpdateOrganizationEnabledTestTypesRequestDto,
    ) {
      await OrgHttpClient.post(
        '/organization/update-organization-enabled-test-types',
        dto,
      );
      await this.fetchOrganizations();
    },
  },
});
