import {
  SamplingKitIdentifierSchema,
  ToxicologyActivityIdentifierSchema,
} from '@careos/identifiers';
import { TestTypeSchema } from '@careos/organization-api-types';
import { z } from 'zod';
import { PanelSizeSchema } from '../../../common';

export const SamplingSessionRequisitionSchema = z.object({
  id: z.string(),
  barcode: z.string(),
  receivedAt: z.string(),
  testType: TestTypeSchema,
  sampleType: ToxicologyActivityIdentifierSchema,
  panelSize: PanelSizeSchema,
  additionalSubstances: z.array(z.string()).nullish(),
  samplingKit: SamplingKitIdentifierSchema.nullish(),
});

export type SamplingSessionRequisition = z.infer<
  typeof SamplingSessionRequisitionSchema
>;
