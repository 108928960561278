import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization-type';

export const UpdateEmployerRequestDtoSchema = z.object({
  employerKey: nonEmptyString,
  newEmployerName: nonEmptyString,
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
});

export type UpdateEmployerRequestDto = z.infer<
  typeof UpdateEmployerRequestDtoSchema
>;
