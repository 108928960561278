<template>
  <i v-if="showSpinner" class="pi pi-spin pi-spinner" :style="computedStyles" />
  <i v-else :class="PrimeIcons[icon]" :style="computedStyles" />
</template>

<script setup lang="ts">
import { PrimeIcons } from 'primevue/api';
import { computed, type PropType } from 'vue';

interface Props {
  icon: keyof typeof PrimeIcons;
  size: '2XS' | 'XS' | 'SM' | 'M' | 'LG' | 'XL';
  showSpinner: boolean;
}

const props = defineProps({
  icon: {
    type: String as PropType<Props['icon']>,
    required: true,
  },
  size: {
    type: String as PropType<Props['size']>,
    default: 'M',
  },
  showSpinner: {
    type: Boolean as PropType<Props['showSpinner']>,
    default: false,
  },
});

const iconSizes: Record<Props['size'], string> = {
  '2XS': '0.70',
  XS: '1',
  SM: '1.25',
  M: '1.5',
  LG: '2',
  XL: '2.5',
};

const computedStyles = computed(() => `font-size: ${iconSizes[props.size]}rem`);
</script>
