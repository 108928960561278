import { ToxicologyActivityIdentifierSchema } from '@careos/identifiers';
import { TestTypeSchema } from '@careos/organization-api-types';
import { z } from 'zod';
import { PanelSchema } from '../../../common';
import { PatientSchema } from '../../../common/types/patient.schema';
import { SignatureDataSchema } from '../../../common/types/signature-data';

export const OrderConfirmationSchema = z.object({
  requisitionId: z.string(),
  barcode: z.string(),
  patient: PatientSchema,
  requisitionComment: z.string().nullable(),
  panel: PanelSchema,
  specimenType: ToxicologyActivityIdentifierSchema,
  signature: SignatureDataSchema,
  testType: TestTypeSchema,
});

export type OrderConfirmation = z.infer<typeof OrderConfirmationSchema>;
