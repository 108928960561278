<template>
  <div>
    <div class="mb-3 h-1 bg-careos-purple-light" />
    <h3 class="mb-4 text-left text-2xl font-medium text-careos-purple">
      {{ isSigned ? t('signed_consent') : t('sign_consent') }}
    </h3>
    <div class="flex flex-col items-end gap-2 text-left md:text-sm lg:flex-row">
      <p class="flex-1">
        {{ consentText }}
      </p>
      <slot name="signature" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  consentText: string;
  isSigned: boolean;
}>();
</script>
