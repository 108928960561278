import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import {
  BarcodeSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { IdentifierSystem } from '../constants';

export const ResultRemarkDeletedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ResultRemarkDeletedEvent'),
  eventData: z.object({
    /**
     * Reference to the barcode associated with the result.
     */
    barcode: BarcodeSchema,
    /**
     * Reference to the requisition associated with the result.
     */
    requisitionId: z.string(),
    /**
     * Reference to the id of the remark that was deleted.
     */
    remarkId: z.string().uuid(),
    /**
     * The time the remark was deleted.
     */
    deletedAt: IsoDateTimeSchema,
    /**
     * Who deleted the remark.
     */
    deletedBy: ReferenceSchema(z.literal('Practitioner')),
    /**
     * The system in which the remark was deleted.
     */
    originSystem: z.nativeEnum(IdentifierSystem),
  }),
});

/**
 * A remark has been deleted about a result.
 */
export class ResultRemarkDeletedEvent extends createSchemaClass(
  ResultRemarkDeletedEventSchema,
) {}
