import { z } from 'zod';
import { ReferenceSchema } from '../fhir-entities';
import { PersonSchema } from '../fhir-entities/person.interface';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const AttestedForDonorEventSchema = BaseEventSchema.extend({
  eventType: z.literal('AttestedForDonorEvent'),
  eventData: z.object({
    /**
     * A reference to the requisition which the donor was vouched for
     */
    requisition: ReferenceSchema(z.literal('Requisition')),
    /**
     * Information about the person who attested for the donor
     */
    attester: PersonSchema,
  }),
});

/**
 * Represents when a person attests for a donor in order to verify their identity.
 * This could happen when a donor is unable to provide sufficient identification.
 */
export class AttestedForDonorEvent extends createSchemaClass(
  AttestedForDonorEventSchema,
) {}
