import { z } from 'zod';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RequisitionAcknowledgementsRetractedEventSchema =
  BaseEventSchema.extend({
    eventType: z.literal('RequisitionAcknowledgementsRetractedEvent'),
    eventData: z.object({
      requisitionIds: z.string().array(),
    }),
  });

export class RequisitionAcknowledgementsRetractedEvent extends createSchemaClass(
  RequisitionAcknowledgementsRetractedEventSchema,
) {}
