<template>
  <div>
    <Dropdown
      v-if="context"
      class="dropdown-style w-full placeholder-gray-400 !border-gray-400 !rounded h-[38px]"
      :placeholder="(context.placeholder as string)"
      :options="(context.customOptions as any[])"
      :option-label="(context.optionLabel as string)"
      :option-value="(context.optionValue as string)"
      :model-value="props.context.value"
      :auto-option-focus="false"
      :filter="(context.filter as boolean | undefined)"
      :disabled="(context.disabled as boolean)"
      @update:model-value="handleInput"
    />
  </div>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core';
import Dropdown from 'primevue/dropdown';

const props = defineProps<{ context: FormKitFrameworkContext }>();

function handleInput(e: string) {
  props.context.node.input(e);
}
</script>

<style lang="scss" scoped>
::v-deep(.p-dropdown) {
  @apply text-left text-gray-700 text-sm;
  box-shadow: none !important;

  .p-dropdown-label.p-placeholder {
    @apply text-gray-400 font-light;
  }
  .p-dropdown-label {
    @apply px-3 flex items-center;
  }
  .p-overlay-open {
    @apply border-[#8f82ff] border-[1px];
    box-shadow: none !important;
  }
}

::v-deep(.p-dropdown:focus-within) {
  // Work in progress
  @apply border-[#8f82ff] border-[1px];
  // border: 1px solid purple !important;
}
</style>
