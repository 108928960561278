import { ToxiHttpClient } from '@/api/toxicology-client';
import { auth0, getOrganizationID } from '@/authentication/auth0';
import {
  ActivityDefinitionIdentifier,
  SamplingKitIdentifier,
} from '@careos/identifiers';
import {
  GetOrganizationEnabledSamplingKitsResponseDto,
  OrganizationType,
} from '@careos/organization-api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSamplingKitStore = defineStore('sampling-kit', () => {
  const selectedSamplingKit = ref<SamplingKitIdentifier>();
  const { user } = auth0;
  const organizationKey = getOrganizationID(user);

  if (!organizationKey) throw new Error('Organization key not found');

  const fetchSamplingKits = async (
    organizationType: OrganizationType | null,
    activityDefinition: ActivityDefinitionIdentifier | null,
  ) => {
    const res =
      await ToxiHttpClient.get<GetOrganizationEnabledSamplingKitsResponseDto>(
        'config/organization-enabled-sampling-kits',
        {
          params: {
            organizationKey,
            organizationType,
            activityDefinition,
          },
        },
      );
    return res.data;
  };

  const $reset = () => {
    selectedSamplingKit.value = undefined;
  };

  return {
    selectedSamplingKit,
    fetchSamplingKits,
    $reset,
  };
});
