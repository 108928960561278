<template>
  <CareosTable
    v-model:filters="filters"
    :value="doaRequisitions"
    :paginator="true"
    :rows="10"
    :filter="storedStateRequisitionTable"
    :rows-per-page-options="[5, 10, 15]"
    filter-display="menu"
    :sort-field="'samplingDate'"
    :sort-order="-1"
    :state-storage="'session'"
    :state-key="`${sessionStorageKey}_${requisitionTableID}`"
    :state="stateRequisitionTable"
    :responsive-layout="responsiveLayout"
    @filter="cacheFilter"
    @row-click="viewCreatedRequisition"
  >
    <Column
      field="samplingDate"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.samplingDate')"
      data-type="date"
      ><template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>

      <template #body="{ data }">{{
        dayjs(data.samplingDate).format('YYYY-MM-DD')
      }}</template></Column
    >
    <Column
      field="receivedAtLabDate"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.receivedAtLabDate')"
      data-type="date"
      ><template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>

      <template #body="{ data }">{{
        data.receivedAtLabDate
          ? dayjs(data.receivedAtLabDate).format('YYYY-MM-DD')
          : '-'
      }}</template></Column
    >

    <Column
      field="barcode"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.barcode')"
      data-type="string"
      ><template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="w-full rounded border border-gray-300 p-2"
          :placeholder="t('table.columns.barcode')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        /> </template
    ></Column>
    <Column
      field="patientIdentifier"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.patientIdentifier')"
      data-type="string"
      ><template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="w-full rounded border border-gray-300 p-2"
          :placeholder="t('table.columns.patientIdentifier')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        /> </template
    ></Column>

    <Column
      v-if="!isUserOrgHasOnlyTreatmentCenter"
      field="employer"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.employer')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="employerFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      v-if="!isUserOrgHasOnlyTreatmentCenter"
      field="subDivision"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.subDivision')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="subDivisionFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="collector"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.collector')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="collectorFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      v-if="isUserOrgHasOnlyTreatmentCenter"
      field="treatmentCenter"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.treatmentCenter')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="treatmentCenterFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      v-if="testTypeFilterOptions.length > 1"
      field="testType"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.testType')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="testTypeFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
  </CareosTable>
</template>

<script setup lang="ts">
import { router } from '@/router';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import CareosTable from '@/components/CareosTable.vue';
import { FilterMatchMode, FilterService } from 'primevue/api';
import Calendar from 'primevue/calendar';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  onMounted,
  onUnmounted,
  PropType,
  ref,
  computed,
  watch,
  onBeforeMount,
  ComponentPublicInstance,
} from 'vue';

import {
  DataTableFilterEvent,
  DataTableRowClickEvent,
} from 'primevue/datatable';

import { useI18n } from 'vue-i18n';
import { useAuth0 } from '@auth0/auth0-vue';

import { getOrganizationTypes } from '../../../../../authentication/auth0';
import { RequisitionListRowDto } from '@careos/toxicology-types';
import { usePrimeVue } from 'primevue/config';

const { t, locale } = useI18n();
const { user } = useAuth0();
const primeVue = usePrimeVue();
const filterApply = ref<ComponentPublicInstance | null>(null);
dayjs.extend(isBetween);

const isUserOrgHasOnlyTreatmentCenter: boolean =
  getOrganizationTypes(user).at(0) === 'treatment_center';

const currentFilteredData = ref<{ requisitionId: string }[]>([]);

const props = defineProps({
  doaRequisitions: {
    type: Array as PropType<RequisitionListRowDto[]>,
    default: () => [],
  },
});

const responsiveLayout = ref<'stack' | 'scroll'>('stack');

const sessionStorageKey = 'dataTableState';
let storedStateRequisitionTable: string | null = '';
const state = ref({});
const requisitionTableID = 'requisitionTable';

const onWidthChange = () => {
  const widthMeasurementTypeOne = document.documentElement.clientWidth || 0;
  const widthMeasurementTypeTwo = window.innerWidth || 0;
   
  Math.max(widthMeasurementTypeOne, widthMeasurementTypeTwo) > 1260
    ? (responsiveLayout.value = 'scroll')
    : (responsiveLayout.value = 'stack');
};

watch(state, (newState) => {
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(newState));
});

onBeforeMount(() => {
  sessionStorage.removeItem(sessionStorageKey);
});

onMounted(() => {
  window.addEventListener('resize', onWidthChange);
  onWidthChange();
  storedStateRequisitionTable = sessionStorage.getItem(
    `${sessionStorageKey}_${requisitionTableID}`,
  );
  if (storedStateRequisitionTable) {
    state.value = JSON.parse(storedStateRequisitionTable);
  }
});
const stateRequisitionTable = ref(
  storedStateRequisitionTable ? JSON.parse(storedStateRequisitionTable) : {},
);
onUnmounted(() => window.removeEventListener('resize', onWidthChange));

FilterService.register(
  'dateInRange',
  (value: string | null, filter: [Date, Date] | null): boolean => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const isRange = filter.at(0) && filter.at(1);
    return isRange
      ? dayjs(value).isBetween(filter[0], filter[1], 'date', '[]')
      : dayjs(value).isSame(filter[0], 'date');
  },
);

const cacheFilter = (filters: DataTableFilterEvent) => {
  if (!filters) return;
  const receivedResults = filters?.filteredValue as {
    requisitionId: string;
    resultReceived?: Date;
  }[];
  currentFilteredData.value = receivedResults.filter(
    (value) => value.resultReceived,
  );
};

const viewCreatedRequisition = ($event: DataTableRowClickEvent) => {
  const pdfParam = $event.data?.hasPdf ? '/pdf' : '';
  const requisitionId = $event.data?.requisitionId;
  const createdRequisitionViewPath = `/requisitions${pdfParam}/${requisitionId}`;

  router.push({
    path: createdRequisitionViewPath,
  });
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  samplingDate: { value: null, matchMode: 'dateInRange' },
  receivedAtLabDate: { value: null, matchMode: 'dateInRange' },
  patientIdentifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
  barcode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  employer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  subDivision: { value: null, matchMode: FilterMatchMode.CONTAINS },
  collector: { value: null, matchMode: FilterMatchMode.CONTAINS },
  treatmentCenter: { value: null, matchMode: FilterMatchMode.CONTAINS },
  testType: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const employerFilterOptions = computed(() =>
  Array.from(
    new Set(props.doaRequisitions.map((doaResult) => doaResult.employer)),
  )
    .filter((employer) => employer !== null && employer !== undefined)
    .map((object) => ({ label: object, value: object })),
);

const subDivisionFilterOptions = computed(() =>
  Array.from(
    new Set(props.doaRequisitions.map((doaResult) => doaResult.subDivision)),
  )
    .filter((subDivision) => subDivision !== null && subDivision !== undefined)
    .map((object) => ({ label: object, value: object })),
);

const collectorFilterOptions = computed(() =>
  Array.from(
    new Set(
      props.doaRequisitions.map((doaRequisition) => doaRequisition.collector),
    ),
  ).map((object) => ({ label: object, value: object })),
);

const treatmentCenterFilterOptions = computed(() =>
  Array.from(
    new Set(
      props.doaRequisitions.map((doaResult) => doaResult.treatmentCenter),
    ),
  ).map((object) => ({ label: object, value: object })),
);

const testTypeFilterOptions = computed(() =>
  Array.from(new Set(props.doaRequisitions.map((it) => it.testType))).map(
    (object) => ({ label: object, value: object }),
  ),
);

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('admin_board.table.filters.january'),
      t('admin_board.table.filters.february'),
      t('admin_board.table.filters.march'),
      t('admin_board.table.filters.april'),
      t('admin_board.table.filters.may'),
      t('admin_board.table.filters.june'),
      t('admin_board.table.filters.july'),
      t('admin_board.table.filters.august'),
      t('admin_board.table.filters.september'),
      t('admin_board.table.filters.october'),
      t('admin_board.table.filters.november'),
      t('admin_board.table.filters.december'),
    ],
    dayNamesMin: [
      t('admin_board.table.filters.sunday'),
      t('admin_board.table.filters.monday'),
      t('admin_board.table.filters.tuesday'),
      t('admin_board.table.filters.wednesday'),
      t('admin_board.table.filters.thursday'),
      t('admin_board.table.filters.friday'),
      t('admin_board.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);
</script>
