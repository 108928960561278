import { z } from 'zod';
import {
  AnnotationSchema,
  IdentifierSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsMarkedForMedicalApprovalEventSchema =
  BaseEventSchema.extend({
    eventType: z.literal('ObservationsMarkedForMedicalApprovalEvent'),
    eventData: z.object({
      /**
       * Barcode of the specimen marked for medical approval.
       */
      barcode: IdentifierSchema,
      /**
       * Ids of observations that are deemed suitable to be sent for medical approval.
       */
      observationIds: z.string().array(),
      /**
       * Technical comment for medical approval.
       */
      note: AnnotationSchema.optional(),
      /**
       * Reference to who marked the specimen to be sent to medical approval
       */
      markedBy: ReferenceSchema(z.literal('Practitioner')),
    }),
  });

/**
 * This event represents that the specimen's result are ready
 * to be sent to medical approval.
 */
export class ObservationsMarkedForMedicalApprovalEvent extends createSchemaClass(
  ObservationsMarkedForMedicalApprovalEventSchema,
) {}
