import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export class SignRequestDto extends createSchemaClass(
  z.object({
    visibleText: z.string(),
    hash: z.string(),
    returnUrl: z.string().optional(),
  }),
) {}
