<template>
  <OrderViewWrapper>
    <OrderViewCard
      :title="t('requisitionType.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!samplingLocationStore.selectedRequisitionType"
      :show-backwards="false"
      @action-right="nextStep"
    >
      <div class="grid gap-4" :class="rowClass">
        <div v-for="requisitionType in typeList" :key="requisitionType">
          <RadioButton
            :label="t('requisitionType.typeOptions.' + requisitionType)"
            :selected="
              requisitionType === samplingLocationStore.selectedRequisitionType
            "
            @click="selectedRequisitionType(requisitionType)"
          />
        </div>
      </div>
    </OrderViewCard>
  </OrderViewWrapper>
</template>

<script setup lang="ts">
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import RadioButton from '../../components/RadioButton.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import { ref } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { getOrganizationTypes } from '@/authentication/auth0';
import { OrganizationType } from '@careos/organization-api-types';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';

const { t } = useI18n();
const store = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();

const { user } = useAuth0();

const typeList = ref(store.requisitionTypes.options);

const userOrgTypes = getOrganizationTypes(user);

if (userOrgTypes.includes('workplace') && userOrgTypes.length === 1) {
  typeList.value = store.requisitionTypes.options.filter(
    (opt) => opt === 'workplace',
  );
}

if (userOrgTypes.includes('treatment_center') && userOrgTypes.length === 1) {
  typeList.value = store.requisitionTypes.options.filter(
    (opt) => opt === 'treatment_center',
  );
}

if (
  userOrgTypes.includes('workplace') &&
  userOrgTypes.includes('treatmentCenter')
) {
  typeList.value = store.requisitionTypes.options;
}

const rowClass = typeList.value.length < 2 ? 'grid-rows-1' : 'grid-rows-2';

const nextStep = () => {
  const page =
    samplingLocationStore.selectedRequisitionType === 'workplace'
      ? 'employer'
      : 'treatment-center';
  router.push({
    path: `/order/${page}`,
  });
};

const selectedRequisitionType = (requisitionType: string) => {
  samplingLocationStore.selectedRequisitionType =
    requisitionType as OrganizationType;
  store.fetchEnabledTestTypes();
  nextStep();
};
</script>
