import { z } from 'zod';
import { DoaPanelSize, DoaPanelSizeSchema } from '../../panel';

const PanelsWithSubstancesSchema = z.object({
  [DoaPanelSize.S]: z.array(z.string()),
  [DoaPanelSize.M]: z.array(z.string()),
  [DoaPanelSize.L]: z.array(z.string()),
});

export type PanelsWithSubstances = z.infer<typeof PanelsWithSubstancesSchema>;

export const PanelSizeWithAdditionalSubstancesSchema = z.object({
  panelsWithAdditionalSubstances: PanelsWithSubstancesSchema,
  panelSizes: z.array(DoaPanelSizeSchema.exclude(['CHIRAL'])),
});

export const GetPanelSizeWithAdditionalSubstancesResponseDtoSchema = z.object({
  panels: PanelSizeWithAdditionalSubstancesSchema,
});

export type GetPanelSizeWithAdditionalSubstancesResponseDto = z.infer<
  typeof GetPanelSizeWithAdditionalSubstancesResponseDtoSchema
>;
