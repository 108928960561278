import { z } from 'zod';
import {
  IsoDateTimeSchema,
  ObservationSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsMadeEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsMadeEvent'),
  eventData: z.object({
    /**
     * The procedure which led to these observations.
     */
    procedure: ReferenceSchema(z.literal('Procedure')).optional(),
    /**
     * Observations data that was generated.
     */
    observations: z.array(ObservationSchema),
    /**
     * When the observation was made.
     */
    observedAt: IsoDateTimeSchema.optional(),
  }),
});

/**
 * {@link Observation}s have been generated in a lab to capture the result of tests.
 */
export class ObservationsMadeEvent extends createSchemaClass(
  ObservationsMadeEventSchema,
) {}
