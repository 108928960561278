import { z } from 'zod';
import { QuantitySchema } from './quantity.interface';

export const SimpleQuantitySchema = QuantitySchema.omit({
  comparator: true,
});
/**
 * SimpleQuantity type is the same as {@link Quantity} but without the comparator property
 */
export type SimpleQuantity = z.infer<typeof SimpleQuantitySchema>;
