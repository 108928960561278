<template>
  <div class="flex h-full flex-1 flex-row items-center justify-between">
    <div class="relative right-0 flex h-full flex-1 flex-row">
      <TabItem
        v-for="route in filteredRoutes"
        :key="route.path"
        :label="t(route.meta?.i18n ?? '')"
        :is-current="$route.path.startsWith(route.path)"
        :to="route.path"
      />
    </div>
    <div class="mr-4">{{ user?.organization?.display_name }}</div>
    <CareosHeaderMenu
      v-if="user"
      :user="user"
      :show-activate-bankid="showActivateBankid"
      @logout="triggerLogout"
      @activate-bankid="emits('activate-bankid', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { CareosRouteRecord } from '@/router/types';

import { useI18n } from 'vue-i18n';
import TabItem from '../TabItem.vue';
import { User } from '@auth0/auth0-vue';

import CareosHeaderMenu from './CareosHeaderUserMenu.vue';
const { t } = useI18n();

defineProps<{
  filteredRoutes: CareosRouteRecord[];
  showActivateBankid: boolean;
  user?: User;
}>();

const emits = defineEmits<{
  logout: [returnTo: string];
  'activate-bankid': [event: unknown];
}>();

const triggerLogout = () => {
  emits('logout', window.location.origin);
};
</script>
