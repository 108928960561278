import { z } from 'zod';
import {
  AnnotationSchema,
  BarcodeSchema,
  IsoDateSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenUnhiddenEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenUnhiddenEvent'),
  eventData: z.object({
    /**
     * Time when the specimen was unhidden.
     */
    date: IsoDateSchema,

    /**
     * SpecimenId, identifier for this unique specimen. Optional because of expected specimens.
     */
    specimenId: z.string().optional(),

    /**
     * {@link Barcode} of the Specimen that was registered.
     */
    barcode: BarcodeSchema,

    /**
     * Note representing the reason for unhiding the specimen.
     */
    reason: AnnotationSchema.optional(),

    /**
     * {@link Practitioner} who unhid the specimen.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});
/**
 * Represents when a user unhide a specimen from the UI.
 */
export class SpecimenUnhiddenEvent extends createSchemaClass(
  SpecimenUnhiddenEventSchema,
) {}
