import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import { createAutoAnimatePlugin } from '@formkit/addons';
import { en, sv } from '@formkit/i18n';
import { generateClasses } from '@formkit/themes';
import { DefaultConfigOptions, createInput, defaultConfig } from '@formkit/vue';
import {
  FormKitDropdown,
  FormKitInputMask,
  SplitRadioButton,
} from 'careos-vue-components';
import CareosClearFormkitCheckbox from '../components/CareosClearFormkitCheckbox.vue';
import CareosFormkitCheckbox from '../components/CareosFormkitCheckbox.vue';
import SubstanceRadio from '../views/order-view/child-views/substances-view/components/SubstanceRadio.vue';
import {
  internationalPhoneNumber,
  nameValidation,
  personalNumber,
  shallowNotEmpty,
} from './formkit-validators';
import { formKitCareos } from './formkit.theme';

export function formKitConfig() {
  const config: DefaultConfigOptions = {
    plugins: [createAutoAnimatePlugin()],
    locales: {
      en,
      sv,
    },
    config: {
      classes: generateClasses(formKitCareos),
    },
    rules: {
      internationalPhoneNumber,
      personalNumber,
      shallowNotEmpty,
      nameValidation,
    },
    inputs: {
      dropdown: createInput(FormKitDropdown, {
        props: [
          'customOptions',
          'optionLabel',
          'optionValue',
          'selectedOption',
          'placeholder',
          'filter',
          'disabled',
        ],
      }),
      mask: createInput(FormKitInputMask, {}),
      splitRadio: createInput(SplitRadioButton, {
        props: ['right', 'left'],
      }),
      formCheckbox: createInput(CareosFormkitCheckbox, {
        props: ['innerLabel'],
      }),
      clearFormCheckbox: createInput(CareosClearFormkitCheckbox, {
        props: ['innerLabel'],
      }),
      substanceRadio: createInput(SubstanceRadio),
      phoneNumberInput: createInput(PhoneNumberInput, {
        props: ['placeholder'],
      }),
    },
  };

  return defaultConfig(config);
}
