<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="substances"
      :title="t('substances.title')"
      class="w-full max-w-3xl"
      :forward-label="t('general.next_step')"
      :disable-forwards="!store.selectedPanelSize"
      :show-swedac-logo="store.hasAccreditedSubstance"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <SubstanceRadioGroup
        v-model:selected-panel-size="store.selectedPanelSize"
      />
      <div
        :class="
          !additionalSubstancesOptions?.length
            ? 'pointer-events-none text-gray-400'
            : 'text-careos-purple'
        "
        class="my-5 flex cursor-pointer items-center"
        @click="showDialog = true"
      >
        <PrimeIcon icon="PLUS_CIRCLE" size="XS" class="m-1" />
        <span>
          {{ t('substances.addAdditional') }}
        </span>
      </div>
      <CareosClearFormCheckbox
        v-if="
          isSamplingValidForChiralOrder &&
          samplingLocationStore.selectedRequisitionType === 'treatment_center'
        "
        v-model="store.isChiralOrdered"
        class="mb-5"
        :disabled="store.selectedPanelSize === 'CHIRAL'"
        :label="t('substances.chiralAnalysis')"
      />
      <div class="mb-5 rounded-lg bg-careos-purple-light px-8 py-6">
        <div class="flex flex-col space-y-2">
          <div class="flex justify-between">
            <span>
              {{ t('substances.panelTitle') }}
              {{ t('substances.' + store.selectedPanelSize) }}
            </span>
            <span>
              {{ productSubstances?.length }}
              {{ t('substances.name') }}
            </span>
          </div>
          <div class="flex justify-between">
            <span>
              {{ t('substances.additional') }}
            </span>
            <span>
              {{ numberOfAdditionalSubstances }}
              {{ t('substances.name') }}
            </span>
          </div>
          <div class="flex justify-between font-bold text-careos-purple">
            <span>
              {{ t('substances.total') }}
            </span>
            <span>
              <span data-test-id="total-number-of-substances">{{
                totalNumberOfSubstances
              }}</span>
              {{ t('substances.name') }}
            </span>
          </div>
        </div>
      </div>
      <AccreditationLabInfo
        :has-qualitative-substance="store.hasQualitativeSubstance"
        :has-accredited-substance="store.hasAccreditedSubstance"
      />
    </OrderViewCard>
    <Dialog
      v-model:visible="showDialog"
      :closable="false"
      :pt="{
        footer: 'flex-col',
      }"
    >
      <template #header>
        <div class="mr-8 flex w-full justify-between">
          <div class="self-end">
            <h1 class="text-2xl text-careos-purple">
              {{ t('substances.addAdditional') }}
            </h1>
            <p class="mt-2 text-xs italic text-black">
              {{ t('substances.panel.cutoffWithinParenthesis') }}
            </p>
          </div>
          <SwedacLogo v-if="store.hasAccreditedSubstance" class="ml-auto" />
        </div>
      </template>
      <div class="max-w-4xl">
        <div class="mb-2">
          <FormKit
            v-if="productSubstancesOptions && store.selectedPanelSize"
            :options="productSubstancesOptions"
            :label="store.selectedPanelSize"
            :value="store.selectedPanel"
            :classes="{
              option: 'w-[275px]',
              options: 'flex flex-wrap gap-x-4',
            }"
            disabled
            type="checkbox"
          >
            <template #label="{ option }">
              <SubstanceListItem
                class="ml-3"
                :has-accredited-substance="store.hasAccreditedSubstance"
                :substance="option.__original"
              />
            </template>
          </FormKit>
        </div>
        <FormKit
          v-if="additionalSubstancesOptions"
          v-model="store.selectedAdditionalSubstances"
          :label="
            t('substances.additionalSelected', {
              selected: numberOfAdditionalSubstances,
            })
          "
          :options="additionalSubstancesOptions"
          :value="additionalSubstancesOptions.map((it) => it.value)"
          :classes="{
            option: 'w-[275px]',
            options: 'flex flex-wrap gap-x-4 ',
          }"
          type="checkbox"
        >
          <template #label="{ option }">
            <SubstanceListItem
              class="ml-3"
              :substance="option.__original"
              :has-accredited-substance="store.hasAccreditedSubstance"
            />
          </template>
        </FormKit>
      </div>
      <template #footer>
        <AccreditationLabInfo
          :has-qualitative-substance="store.hasQualitativeSubstance"
          :has-accredited-substance="store.hasAccreditedSubstance"
          class="mt-4"
        />
        <div class="mt-6 flex justify-between">
          <FormSubmitButton
            :label="t('substances.cancelAdditional')"
            btn-type="outline"
            @click="handleClearAdditionalSubstances"
          />
          <FormSubmitButton
            :label="t('substances.saveAdditional')"
            @click="showDialog = false"
          />
        </div>
      </template>
    </Dialog>
  </OrderViewWrapper>
</template>

<script lang="ts" setup>
import AccreditationLabInfo from '@/components/AccreditationLabInfo.vue';
import CareosClearFormCheckbox from '@/components/CareosClearFormCheckbox.vue';
import SubstanceListItem from '@/components/SubstanceListItem.vue';
import SwedacLogo from '@/components/SwedacLogo.vue';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { router } from '@/router';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import FormSubmitButton from '@/views/admin-view/components/FormSubmitButton.vue';
import { DoaActivityDefinitionIdentifier } from '@careos/identifiers';
import Dialog from 'primevue/dialog';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import SubstanceRadioGroup from './components/SubstanceRadioGroup.vue';
import { PrimeIcon } from 'careos-vue-components';
import { useSamplingKitStore } from '@/stores/order/samplingKitStore';
import { OrganizationType } from '@careos/organization-api-types';
import { isSpecimenTypeAndKitValidForChiralOrder } from '@careos/toxicology-types';

// Composables
const { t } = useI18n();
const store = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();
const samplingKitStore = useSamplingKitStore();

// Variables
const showDialog = ref(false);

// Computed

const isSamplingValidForChiralOrder = computed(() => {
  // TODO: Remove this check once the Quantisal chiral sampling feature is live.
  // This check prevents the use of Quantisal sampling kits for chiral panels
  const ENABLE_QUANTISAL_CHIRAL_SAMPLING = import.meta.env
    .VITE_ENABLE_QUANTISAL_CHIRAL_SAMPLING;

  if (ENABLE_QUANTISAL_CHIRAL_SAMPLING) {
    return isSpecimenTypeAndKitValidForChiralOrder(
      store.selectedSpecimen,
      samplingKitStore.selectedSamplingKit,
    );
  }
  return store.selectedSpecimen === 'DOA_URINE_SAMPLING';
});

const productSubstances = computed(() => store.selectedPanel);
const selectedPanelSize = computed(() => store.selectedPanelSize);

const productSubstancesOptions = computed(() => {
  const selectedPanel = store.selectedPanel;
  const options =
    selectedPanel?.map((substance) => ({
      value: substance,
      label: substance.name,
    })) || [];
  // TODO: Remove this sort function after TPH-309 is merged
  options.sort((a, b) => a.label.localeCompare(b.label));
  return options;
});

const numberOfAdditionalSubstances = computed(
  () => store.selectedAdditionalSubstances.length,
);

const additionalSubstancesOptions = computed(() => {
  if (!store.panels) return;
  if (!store.selectedPanel) return;
  if (store.selectedPanelSize === 'CHIRAL') return [];

  const selectedPanelSubstances = store.selectedPanel.map((substance) => ({
    value: substance,
    label: substance.name,
  }));
  const largePanelSubstances = store.panels.L?.map((substance) => ({
    value: substance,
    label: substance.name,
  }));

  const additionalSubstances = largePanelSubstances?.filter(
    (largePanelSubstance) => {
      const isDuplicate = selectedPanelSubstances.some(
        (selectedSubstance) =>
          selectedSubstance.label === largePanelSubstance.label,
      );
      return !isDuplicate;
    },
  );
  // TODO: Remove this sort function after TPH-309 is merged
  additionalSubstances?.sort((a, b) => a.label.localeCompare(b.label));
  return additionalSubstances || [];
});

const handleClearAdditionalSubstances = () => {
  store.selectedAdditionalSubstances = [];
};

const nextStep = () => {
  router.push({
    path: '/order/comment',
  });
};
const previousStep = () => {
  router.go(-1);
};

// Watchers
// If you switch panel size when you have selected additional substances
// Keep the ones which are not covered by the panel size.
watch(productSubstances, (substances) => {
  if (!substances) return;
  const filteredAdditionalSubstances =
    store.selectedAdditionalSubstances.filter((value) => {
      const substanceToKeep = !substances.find((it) => it.name === value.name);
      return substanceToKeep;
    });
  store.setAdditionalSubstances(filteredAdditionalSubstances);
});

const totalNumberOfSubstances = computed(
  () =>
    (productSubstances.value?.length ?? 0) + numberOfAdditionalSubstances.value,
);

watch(selectedPanelSize, (panel) => {
  if (panel === 'CHIRAL') {
    handleClearAdditionalSubstances();
    store.isChiralOrdered = true;
  } else {
    store.isChiralOrdered = false;
  }
});

// Initialization
useNetworkRequest(async () => {
  store.getPanelsBySpecimenType(
    store.selectedSpecimen as DoaActivityDefinitionIdentifier,
    samplingLocationStore.selectedRequisitionType as OrganizationType,
    samplingKitStore.selectedSamplingKit,
  );
}).exec();
</script>
