import { z } from 'zod';

export type SchemaClass<T> = { new (): T } & { schema: z.ZodType<T> };
export const isSchemaClass = <T>(
  classDef: unknown,
): classDef is SchemaClass<T> =>
  classDef !== null && typeof classDef === 'function' && 'schema' in classDef;

/**
 *
 * @description Creates an anonymous class and attaches the schema as a static property of the class.
 *
 * ```ts
 * const MySchema = z.object({
 *   name: z.string().array(),
 * });
 *
 * class MyClass extends createSchemaClass(MySchema) {}
 *
 * // Prints "MyClass"
 * console.log(MyClass.name);
 *
 * // Prints true
 * const { success } = MyClass.schema.safeParse({ name: ["Harry Potter"] });
 * console.log(success)
 *
 * ```
 * @description
 * This is mostly useful since the consumer of this library is dependant on classes.
 * The schemas can be used independently of the classes
 */
export const createSchemaClass = <T>(type: z.ZodType<T>): SchemaClass<T> => {
  const cls = class {
    public static schema = type;
  } as SchemaClass<T>;
  return cls;
};
