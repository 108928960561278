import { z } from 'zod';
import { IdentifierSchema, SignatureSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RequisitionSignedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RequisitionSignedEvent'),
  eventData: z.object({
    /**
     * Reference to the requisition signed
     */
    requisition: IdentifierSchema,

    /**
     * An ID for connecting the signature to a requisition.
     */
    transactionId: z.string().optional(),

    /**
     * Signature produced
     */
    signature: SignatureSchema,
  }),
});

/**
 * This event represents the signing of a requisition
 */
export class RequisitionSignedEvent extends createSchemaClass(
  RequisitionSignedEventSchema,
) {}
