import { SamplingKitIdentifierSchema } from '@careos/identifiers';
import { z } from 'zod';

export const GetOrganizationEnabledSamplingKitsResponseDtoSchema = z.object({
  enabledSamplingKits: SamplingKitIdentifierSchema.array(),
});

export type GetOrganizationEnabledSamplingKitsResponseDto = z.infer<
  typeof GetOrganizationEnabledSamplingKitsResponseDtoSchema
>;
