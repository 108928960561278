import { z } from 'zod';

export const ToxicologyPlanDefinitionIdentifierSchema = z.enum([
  'DoA-PA-LC-MS',
  'PEth-LCMS-qDBS',
]);
export type ToxicologyPlanDefinitionIdentifier = z.infer<
  typeof ToxicologyPlanDefinitionIdentifierSchema
>;
export const ToxicologyPlanDefinitionIdentifier =
  ToxicologyPlanDefinitionIdentifierSchema.options;

export const PlanDefinitionIdentifierSchema = z.enum([
  ...ToxicologyPlanDefinitionIdentifier,
  'SARS_COV_2',
  'CC-ALI',
  'CC-SYS-WB',
]);

export const PlanDefinitionIdentifier = PlanDefinitionIdentifierSchema.Enum;
export type PlanDefinitionIdentifier = z.infer<
  typeof PlanDefinitionIdentifierSchema
>;

export const isPlanDefinitionIdentifier = (
  value: unknown,
): value is PlanDefinitionIdentifier =>
  PlanDefinitionIdentifierSchema.safeParse(value).success;

export const isToxicologyPlanDefinitionIdentifier = (
  value: unknown,
): value is ToxicologyPlanDefinitionIdentifier =>
  ToxicologyPlanDefinitionIdentifierSchema.safeParse(value).success;
