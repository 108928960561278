<template>
  <div class="flex gap-0">
    <button
      v-tooltip.top="{
        value: t('sessions.table.actions_tooltip.randomizer'),
        disabled: isRandomizerDisabled,
      }"
      :disabled="isRandomizerDisabled"
      class="hover:bg-primary-700 rounded p-2 transition-colors duration-150"
      :class="buttonClass(isRandomizerDisabled)"
      @click="$emit('sessionAction', 'randomize')"
    >
      <PrimeIcon icon="LIST" size="XS" />
    </button>
    <button
      v-tooltip.top="{
        value: t('sessions.table.actions_tooltip.start_session'),
        disabled: isSessionClosed,
      }"
      :disabled="isSessionClosed"
      class="hover:bg-primary-700 rounded p-2 transition-colors duration-150"
      :class="buttonClass(isSessionClosed)"
      @click="$emit('sessionAction', 'startSession')"
    >
      <PrimeIcon icon="PLUS" size="XS" />
    </button>
    <button
      v-tooltip.top="{
        value: t('sessions.table.actions_tooltip.close_session'),
        disabled: isSessionClosed,
      }"
      :disabled="isSessionClosed"
      class="hover:bg-primary-700 rounded p-2 transition-colors duration-150"
      :class="closeButtonClass(isSessionClosed)"
      @click="$emit('sessionAction', 'closeSession')"
    >
      <PrimeIcon icon="LOCK" size="XS" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { PrimeIcon } from 'careos-vue-components';
import { useI18n } from 'vue-i18n';
import type { SessionActions } from '../constants/session-actions';
import { computed } from 'vue';
import { SamplingSessionStatus } from '@careos/toxicology-types';

const { t } = useI18n();

const props = defineProps<{
  reason: string;
  status: SamplingSessionStatus;
}>();

defineEmits<{
  sessionAction: [actionType: SessionActions];
}>();

const isRandomSampling = computed(() => props.reason === 'random_selection');
const isSessionClosed = computed(
  () => props.status === SamplingSessionStatus.finished,
);

const isRandomizerDisabled = computed(
  () => !isRandomSampling.value || isSessionClosed.value,
);

const buttonClass = (disabled: boolean) =>
  disabled ? 'pointer-none text-gray-400' : 'hover:text-white';

const closeButtonClass = (disabled: boolean) =>
  disabled ? 'pointer-none text-gray-400' : 'text-red-500 hover:text-white';
</script>
