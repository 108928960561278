import { defineStore } from 'pinia';
import { FilterMatchMode } from 'primevue/api';

const initialFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  resultReceived: { value: null, matchMode: 'dateInRange' },
  samplingDate: { value: null, matchMode: 'dateInRange' },
  patientIdentifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
  barcode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  employer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  testType: { value: null, matchMode: FilterMatchMode.CONTAINS },
  subDivision: { value: null, matchMode: FilterMatchMode.CONTAINS },
  collector: { value: null, matchMode: FilterMatchMode.CONTAINS },
  treatmentCenter: { value: null, matchMode: FilterMatchMode.CONTAINS },
  result: { value: null, matchMode: FilterMatchMode.CONTAINS },
  acknowledged: { value: false, matchMode: FilterMatchMode.EQUALS },
};

const emptyFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  resultReceived: { value: null, matchMode: 'dateInRange' },
  samplingDate: { value: null, matchMode: 'dateInRange' },
  patientIdentifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
  barcode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  employer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  subDivision: { value: null, matchMode: FilterMatchMode.CONTAINS },
  collector: { value: null, matchMode: FilterMatchMode.CONTAINS },
  treatmentCenter: { value: null, matchMode: FilterMatchMode.CONTAINS },
  testType: { value: null, matchMode: FilterMatchMode.CONTAINS },
  result: { value: null, matchMode: FilterMatchMode.CONTAINS },
  acknowledged: {
    value: null as unknown as boolean,
    matchMode: FilterMatchMode.EQUALS,
  },
};

export const useResultsViewStore = defineStore('results-view-store', {
  state: () => ({
    filters: initialFilters,
  }),
  actions: {
    emptyFilters() {
      this.filters = emptyFilters;
    },
  },
});
