import {
  OrderTypeSchema,
  OrganizationTypeSchema,
  TestTypeSchema,
} from '@careos/organization-api-types';
import { z } from 'zod';

export const RequisitionListRowSchema = z.object({
  requisitionId: z.string(),
  barcode: z.string(),
  patientIdentifier: z.string(),
  customer: z.string(),
  collector: z.string(),
  employer: z.string().nullable(),
  subDivision: z.string().nullable(),
  treatmentCenter: z.string().nullable(),
  samplingDate: z.date().nullable(),
  organizationType: OrganizationTypeSchema.nullable(),
  receivedAtLabDate: z.date().nullable(),
  parcelId: z.string().nullable(),
  hasPdf: z.boolean(),
  testType: TestTypeSchema,
  orderType: OrderTypeSchema.nullish(),
});

export type RequisitionListRowDto = z.infer<typeof RequisitionListRowSchema>;
