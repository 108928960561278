<template>
  <ul>
    <li>
      {{ t('admin_board.user_details.name') }}: <b>{{ userDetails.name }}</b>
    </li>
    <li>
      {{ t('admin_board.user_details.email') }}: <b>{{ userDetails.email }}</b>
    </li>
    <li>
      {{ t('admin_board.user_details.role') }}:
      <b>
        {{
          t(
            `admin_board.user_details.role_${userDetails.roles
              .at(0)
              ?.name.toLowerCase()}_long`,
          )
        }}
      </b>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { UserFromResponseDto } from '@/views/admin-view/stores/users';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
defineProps<{
  userDetails: UserFromResponseDto;
}>();
</script>
