import { z } from 'zod';
import { IsoDateTimeSchema } from './iso-date.type';

export const RegistrationSystemSchema = z.enum(['scan', 'manual-input']);
/**
 * Describes the type of {@link Registration}.
 * @property scan: The registration was done with a scanning device.
 * @property manual-input: The registration was done by manually inputting it into the system
 */
export type RegistrationSystem = z.infer<typeof RegistrationSystemSchema>;

export const RegistrationSchema = z.object({
  /**
   * Which type of registration was used to register. eg. by using a scanning device to scan a barcode
   * or using a keyboard to manually register.
   */
  system: RegistrationSystemSchema,
  /**
   * Timestamp of when the registration took place.
   */
  registeredAt: IsoDateTimeSchema,
});

/**
 * Represent a registration into the CareOS system.
 */
export type Registration = z.infer<typeof RegistrationSchema>;
