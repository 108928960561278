import { z } from 'zod';
import { ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const PatientTreatmentCenterChosenEventSchema = BaseEventSchema.extend({
  eventType: z.literal('PatientTreatmentCenterChosenEvent'),
  eventData: z.object({
    /**
     * Reference to patient
     */
    patient: ReferenceSchema(z.literal('Patient')),
    /**
     * Reference to requisition
     */
    requisition: ReferenceSchema(z.literal('Requisition')),
    /**
     * The treatment center of the patient
     */
    treatmentCenter: z.object({
      /**
       * Reference to the treatment center.
       */
      reference: ReferenceSchema(z.literal('Organization')),
    }),
  }),
});

/**
 * Represents a treatment center chosen for the patient when the patient
 * is being registered for sampling.
 */
export class PatientTreatmentCenterChosenEvent extends createSchemaClass(
  PatientTreatmentCenterChosenEventSchema,
) {}
