import { OrderType } from '@careos/organization-api-types';
import { z } from 'zod';

const CollectorDetailsBaseSchema = z.object({
  collectorName: z.string(),
  careProvider: z.string(),
  collectorEmail: z.string(),
});

const CollectorDetailsNonCocSchema = CollectorDetailsBaseSchema.extend({
  orderType: z.literal(OrderType['non-CoC']),
});

const CollectorDetailsCocSchema = CollectorDetailsBaseSchema.extend({
  orderType: z.literal(OrderType['CoC']),
  donorPassword: z.string().optional(),
  isDonorPasswordGenerated: z.boolean(),
});

export const CollectorDetailsResponseSchema = z.discriminatedUnion(
  'orderType',
  [CollectorDetailsNonCocSchema, CollectorDetailsCocSchema],
);

export type CollectorDetailsResponseDto = z.infer<
  typeof CollectorDetailsResponseSchema
>;
