import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import { onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';

export const usePreventOrderNavigation = () => {
  const { t } = useI18n();
  const store = useOrderViewStore();

  // Warn user about site reload
  const beforeUnloadHandler = (event: Event) => {
    event.preventDefault();

    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  };
  window.addEventListener('beforeunload', beforeUnloadHandler);
  onUnmounted(() => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  });

  onBeforeRouteLeave((to, from) => {
    if (to.path.includes('order') || from.name === 'orderCreated') return;

    const answer = window.confirm(t('system.leave_warning'));
    if (!answer) {
      return false;
    }
    store.clearOrder();
    return true;
  });
};
