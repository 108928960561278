import { createI18n } from 'vue-i18n';
import { messages as enUS } from './en-US';
import { messages as svSE } from './sv-SE';
import { bankidMessages } from '@careos/translations';
import type { MessageSchema } from './types';

//  We define the enUS message schema to be the master message schema.
//  All other translation files should give error when they exclude
//  or have more fields than the enUS schema.

export type SupportedLocales = 'en-US' | 'sv-SE';

const i18n = createI18n<[MessageSchema], SupportedLocales>({
  legacy: false,
  locale: 'sv-SE',
  messages: {
    'en-US': { ...enUS, ...bankidMessages.en },
    'sv-SE': { ...svSE, ...bankidMessages.sv },
  },
});

export { i18n };
