import { z } from 'zod';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RequisitionsAcknowledgedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RequisitionsAcknowledgedEvent'),
  eventData: z.object({
    requisitionIds: z.string().array(),
  }),
});

export class RequisitionsAcknowledgedEvent extends createSchemaClass(
  RequisitionsAcknowledgedEventSchema,
) {}
