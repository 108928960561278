<template>
  <div
    class="relative mx-2 mb-4 mt-12 w-full rounded-b-xl rounded-r-xl border bg-white px-5 py-2 drop-shadow-md"
  >
    <nav
      class="absolute left-0 top-0 -translate-y-[calc(100%_+_8px)] text-white"
    >
      <template v-for="tab in tabs" :key="tab.key">
        <router-link
          class="rounded-t border-2 border-b-0 px-4 py-2 font-semibold"
          :class="[
            tab.name === route.name
              ? 'border-careos-purple bg-careos-purple text-white'
              : 'border-white bg-white text-careos-purple',
          ]"
          :to="{ name: tab.name }"
        >
          {{ tab.label }}
        </router-link>
      </template>
    </nav>

    <div class="mb-2 flex font-bold">
      <span>
        {{ organizationStore.displayNames.organizationDisplayName }}&nbsp;
      </span>
      <span v-if="organizationStore.displayNames.employerDisplayName">
        > {{ organizationStore.displayNames.employerDisplayName }}
      </span>
      <p class="ml-auto font-light">
        {{ t('admin_board.organization_login_name') }}:
        <span class="font-semibold">{{
          organizationStore.organizationLoginName
        }}</span>
      </p>
    </div>
    <component :is="currentComponent" />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed } from 'vue';

import OrganizationWorkplaceView from '@/views/admin-view/organization-view/OrganizationWorkplaceView.vue';
import OrganizationTreatmentCenterView from '@/views/admin-view/organization-view/OrganizationTreatmentCenterView.vue';
import { OrganizationTypeSchema } from '@careos/organization-api-types';
import { useOrganization } from '../stores/organization';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const { organizationType } = route.params;
const organizationStore = useOrganization();
const { t } = useI18n();

const sampleLocation = computed(() => {
  if (organizationType === OrganizationTypeSchema.enum.workplace) {
    return {
      key: 'employers',
      label: t('admin_board.tabs.employers'),
      name: 'organizationEmployerListView',
    };
  }
  return {
    key: 'treatmentCenters',
    label: t('admin_board.tabs.treatment_centers'),
    name: 'organizationTreatmentCentersView',
  };
});

const tabs = computed(() => [
  {
    key: 'users',
    label: t('admin_board.tabs.users'),
    name: 'organizationUsersView',
  },
  {
    key: 'samplingLocation',
    label: sampleLocation.value.label,
    name: sampleLocation.value.name,
  },
]);

const currentComponent = computed(() => {
  if (organizationType === OrganizationTypeSchema.enum.workplace) {
    return OrganizationWorkplaceView;
  }
  return OrganizationTreatmentCenterView;
});
</script>
