<template>
  <div
    data-test-id="scanned-barcode"
    class="my-4 flex h-[44px] items-center text-xl"
  >
    <p
      class="flex flex-1 items-center justify-center self-stretch rounded bg-careos-purple-light"
    >
      <span>{{ barcode }}</span>
    </p>
    <div class="ml-2">
      <SvgIcon
        v-if="isReadyForUseScanner"
        name="barcode-reader"
        class="inline-block h-[38px]"
      />
      <PrimeIcon
        v-else-if="status === 'loading'"
        :icon="'SPINNER'"
        class="inline-block animate-spin"
      />
      <PrimeIcon
        v-else-if="isValidCareosBarcode"
        :icon="'CHECK_CIRCLE'"
        size="LG"
        class="inline-block text-green-500"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SvgIcon from '@/components/SvgIcon.vue';
import { PrimeIcon } from 'careos-vue-components';
import type { BarcodeCheckStatus } from '@/composables/useBarcodeInput';
import type { InputDevice } from '@/stores/order/barcode';
import { computed } from 'vue';

const props = defineProps<{
  barcode: string | undefined | null;
  isValidCareosBarcode: boolean;
  status: BarcodeCheckStatus;
  scanningType: Exclude<InputDevice, 'keyboard'>;
}>();

const isReadyForUseScanner = computed(
  () =>
    props.scanningType === 'barcodeReader' &&
    !props.isValidCareosBarcode &&
    props.status === 'idle',
);
</script>
