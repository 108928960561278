import type { CheckResponseDto, SignResponseDto } from '@careos/types';

import type { InitiateBankidSigningRequestDto } from '@careos/toxicology-types';

import { ofetch } from 'ofetch';
import type { Ref } from 'vue';
import { isProd, isStaging } from '../utils/deployment-environment';

const baseURL = isProd
  ? 'https://api.careos.io/v1/toxi-doa'
  : isStaging
    ? 'https://staging-api.careos.io/v1/toxi-doa'
    : 'http://localhost:5019/v1/toxi-doa';

export const initApi = (accessToken: Ref) => {
  const baseFetch = ofetch.create({
    retry: 0,
    baseURL,
    async onResponse({ response }) {
      const data = await response._data;
      if (data && 'errorCode' in data) {
        throw data;
      }
    },
    async onRequest({ options }) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken.value}`,
      };
    },
  });

  const api = {
    sign: (body: InitiateBankidSigningRequestDto) =>
      baseFetch<SignResponseDto>(`/requisitions/initiate-bankid-signing`, {
        method: 'POST',
        body,
      }),
    check: (orderRef: string) =>
      baseFetch<CheckResponseDto>(
        `/requisitions/check-bankid-signing/${orderRef}`,
      ),
    cancel: (orderRef: string) =>
      baseFetch(`/requisitions/cancel-bankid-signing/${orderRef}`, {
        method: 'DELETE',
      }),
  };
  return api;
};
