import { z } from 'zod';
import { PanelSubstanceSchema } from '../../panel';
export const GetSubstancesByPanelSizeResponseDtoSchema = z.object({
  substances: PanelSubstanceSchema.pick({
    name: true,
    isSwedacAccredited: true,
  }).array(),
});

export type GetSubstancesByPanelSizeResponseDto = z.infer<
  typeof GetSubstancesByPanelSizeResponseDtoSchema
>;
