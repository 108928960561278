import { z } from 'zod';

export const EventMetadataSchema = z.object({
  /**
   * ID used for Kafka partitioning.
   */
  partitionId: z.string().optional(),
  /**
   * When the event was sent from the producer.
   */
  publishedAt: z.string(),
  /**
   * Organization that owns the event.
   * This may be different from the origin organization, especially in cases when data is
   * sent from one organization to another.
   */
  ownership: z
    .object({
      organization: z.string(),
    })
    .optional(),

  /**
   * Information about where the event comes from.
   */
  origin: z.object({
    /**
     * UserId extracted from token when the request was made to produce an event
     */
    userId: z.string().optional(),

    /**
     * Other events that this event originated from.
     */
    eventIds: z.string().array(),

    /**
     * The system that produced the event.
     */
    system: z
      .object({
        id: z.string(),
        version: z.string(),
        parameters: z
          .object({
            requestUrl: z.string().optional(),
          })
          .optional(),
      })
      .optional(),

    /**
     * Part of the medical domain the event originates from.
     */
    domain: z.string().optional(),

    /**
     * Organization that the event originates from.
     */
    organization: z.string().optional(),
  }),
});

export type EventMetadata = z.infer<typeof EventMetadataSchema>;

export const EventVersionSchema = z.object({
  /**
   * Version number eg. 1.6.13
   */
  versionNr: z.string(),
  /**
   * Repository name eg. careos-events or careos-kotlin-events
   */
  repository: z.string(),
});

/**
 * Version information about the event
 */
export type EventVersion = z.infer<typeof EventVersionSchema>;

export const BaseEventSchema = z.object({
  /**
   * ID of the event. Should be unique across all events.
   */
  eventId: z.string(),
  /**
   * Type of event.
   */
  eventType: z.string(),

  /**
   * Version information about the event
   */
  eventVersion: EventVersionSchema,

  /**
   * All event-specific data. The fields are defined by eventType.
   */
  eventData: z.unknown(),

  /**
   * Metadata used in every event produced by careos
   */
  metadata: EventMetadataSchema,
});

/**
 * Common fields for all CareOS events.
 */
export type BaseEvent = z.infer<typeof BaseEventSchema>;
