import { z } from 'zod';

export const CodingSchema = z.object({
  /**
   * Identity of the terminology system.
   */
  system: z.string(),

  /**
   * Value defined by the system.
   */
  code: z.string(),

  /**
   * Text representation of the concept.
   */
  display: z.string().optional(),
});

/**
 * A representation of a concept using a value from a terminology system.
 */
export type Coding = z.infer<typeof CodingSchema>;
