<template>
  <div v-if="isStreamActive" class="flex flex-col gap-y-2">
    <div class="px-2 text-left text-xs">
      <p>
        {{ t('barcode.cameraScanner.barcodeAlignmentMessage') }}
      </p>
      <p class="mt-2">
        {{ t('barcode.cameraScanner.compatibilityMessage') }}
      </p>
    </div>

    <qrcode-stream
      :formats="['code_128']"
      @camera-on="handleCameraOn"
      @detect="handleDetect"
      @error="handleError"
    >
      <p v-if="cameraStatus === 'loading'" class="text-xl text-careos-purple">
        {{ t('barcode.cameraScanner.loading') }}
      </p>
    </qrcode-stream>
    <Button
      outlined
      :label="t('barcode.cameraScanner.cancel')"
      size="small"
      @click="handleCancelStream"
    />
  </div>
  <CameraScannerErrorModal
    v-model:show-modal="showErrorModal"
    :camera-error-message="cameraErrorMessage"
  />
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import { ref, watchEffect, watch } from 'vue';
import { QrcodeStream } from 'vue-qrcode-reader';
import { useI18n } from 'vue-i18n';
import CameraScannerErrorModal from './CameraScannerErrorModal.vue';
import { HANDLED_ERROR_CODES } from '../constants';
export type CameraStatus = 'loading' | 'ready' | 'off' | 'error';
const { t } = useI18n();
const emit = defineEmits<{
  (e: 'updateBarcodeValue', newBarcodeValue: string): void;
  (e: 'updateCameraStatus', newCameraStatus: CameraStatus): void;
  (e: 'update:isStreamActive', isStreamActive: boolean): void;
}>();
const props = defineProps<{
  isStreamActive: boolean;
}>();
const cameraStatus = ref<CameraStatus>('loading');
const cameraErrorMessage = ref('');
const showErrorModal = ref(false);
const handleCameraOn = () => {
  cameraStatus.value = 'ready';
};
const handleCancelStream = () => {
  emit('update:isStreamActive', false);
  cameraStatus.value = 'off';
};
 
const handleDetect = async (detectedCode: any) => {
  const barcodeValue = detectedCode.at(0).rawValue;
  emit('updateBarcodeValue', barcodeValue);
  emit('update:isStreamActive', false);
};
const handleError = (err: Error) => {
  if (HANDLED_ERROR_CODES.includes(err.name)) {
    cameraErrorMessage.value = t(
      `barcode.cameraScanner.errorMessages.${err.name}`,
    );
  } else {
    cameraErrorMessage.value = t(
      'barcode.cameraScanner.errorMessages.unknownError',
    );
  }
  emit('update:isStreamActive', false);
  showErrorModal.value = true;
};
watchEffect(() => {
  emit('updateCameraStatus', cameraStatus.value);
});
watch(
  () => props.isStreamActive,
  (newValue) => {
    if (!newValue) {
      cameraStatus.value = 'loading';
    }
  },
);
</script>
