import { z } from 'zod';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { ReferenceSchema, type ReferenceType } from './reference.interface';


export const CodeableReferenceSchema = <T extends ReferenceType = ReferenceType>(referenceType:z.ZodType<T>) =>  z.object({
  /**
   * Reference to a concept (by class)
   */
   concept: CodeableConceptSchema.optional(),
   /**
    * Reference to a resource (by instance)
    */
   reference: ReferenceSchema(referenceType).optional(),
})

/**
 * A common pattern in healthcare records is that a single element may refer to either a
 * concept in principle, or a specific instance of the concept as seen in practice.
 * For instance, a medication may be prescribed because the patient has a headache - e.g.
 * to refer to a headache by a SNOMED CT code for a kind of headache.
 *
 * Alternatively, the record may refer to a specific observation or problem in the
 * problem list as evidence for the patient's headache, which conveys details specific
 * to the patient. This is a particular example of a more general pattern; e.g. it also
 * applies to locations (something happened 'in a hospital', vs something happened in
 * a particular identified hospital).
 *
 * The CodeableReference data type can refer to another resource,
 * and the list of valid target types for the CodeableReference
 * resource applies to the Reference as described above.
 *
 * In principle, this data type allows for either a reference or a concept, or both.
 * If both are present, they are expected to be consistent with each other - e.g.
 * the concept is to a code for headache, and the resource reference describes a headache.
 * Note that it is not generally computable proveable whether this is true or not.
 *
 * The constraints on the CodeableReference apply to the CodeableReference.reference or
 * the CodeableReference.concept as appropriate and they SHOULD NOT be specified on the
 * .reference or .concept. If they are specified on both, then they SHALL be the same.
 *
 * This data type is mostly used for reason for an action.
 */

export type CodeableReference<T extends ReferenceType> = z.infer<ReturnType<typeof CodeableReferenceSchema<T>>>
