import { z } from 'zod';
import { BarcodeSchema } from './barcode.interface';
import { RackCoordinatesSchema } from './rack-coordinates.interface';

export const SpecimenPositionSchema = z.object({
  specimenId: z.string(),
  barcode: BarcodeSchema,
  /**
   * This represents the new position of the specimen.
   */
  coordinates: RackCoordinatesSchema.optional(),
});

export type SpecimenPosition = z.infer<typeof SpecimenPositionSchema>;
