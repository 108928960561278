export const messages = {
  system: {
    leave_warning: 'Do you really want to leave? You have unsaved changes!',
    order_summary_leave_warning: 'You will have to sign again if you go back',
  },
  footer: {
    appVersion: 'Version',
  },
  personalDetails: {
    firstName: 'First name',
    lastName: 'Last name',
    yes: 'Yes',
    no: 'No',
    personalNumber: 'Personal ID number',
    dateOfBirth: 'Date of birth',
    phoneNumber: 'Phone number',
    phoneNumberOptional: 'Phone number (optional)',
    has_personal_id: 'Does the person have a Swedish personal ID number?',
    validation: {
      charactersNotLatin: 'Please enter only latin characters',
      personalNumber: 'Please input personal number correctly',
      phoneNumberNotValid: 'Please input phone number correctly',
    },
    title: 'Fill in personal details',
    patient_identification: 'Identification',
    patient_identity_verified:
      "I, as the collector, confirm that the person's identity has been verified",
    attester_is_needed: 'Does the person need an attester?',
    attester: {
      label: 'Attester',
      firstName: 'Attester first name',
      lastName: 'Attester last name',
      personalNumber: 'Attester ID number',
    },
  },
  samplingKits: {
    title: 'Choose specific method',
    GREINER: 'Greiner',
    INTERCEPT: 'Intercept',
    DRIED_BLOOD_SPOTS: 'Dried Blood Spots',
    VENOUS_BLOOD: 'Venous Blood',
    QUANTISAL: 'Quantisal',
    B_CAPITAINER: 'B-Capitainer',
  },
  reasons_for_testing: {
    title: 'Reason for testing',
    reason_options: {
      random_selection: 'Random selection',
      new_employee: 'Pre-employment',
      health_check: 'Health check',
      rehabilitation: 'Rehabilitation',
      incident_accident: 'Incident/accident',
      other: {
        label: 'Other',
        placeholder: 'Input reason for testing',
      },
    },
  },
  requisitionType: {
    title: 'Type of testing',
    typeOptions: {
      workplace: 'Workplace Testing',
      treatment_center: 'Treatment Center',
    },
  },
  testType: {
    title: 'Create a requisition for',
    typeOptions: {
      DoA: 'Drug Panel',
      PEth: 'Alcohol (PEth)',
    },
    confirmTestTypeChange:
      'Please note that changing product here will clear all already filled in information, except “Personal Information”.',
  },
  specimenType: {
    specimen_type: 'Specimen type',
    title: 'Specimen type',
    select: 'Select specimen type',
    types: {
      urine: 'Urine',
      saliva: 'Saliva',
      breath: 'Breath',
      DOA_URINE_SAMPLING: 'Urine',
      DOA_SALIVA_SAMPLING: 'Saliva',
      DOA_BLOOD_SAMPLING: 'Blood',
      DOA_BREATH_SAMPLING: 'Breath',
      PETH_DRIED_BLOOD_SPOTS: 'Blood',
    },
  },
  substances: {
    title: 'Choose substance panel',
    panelHeading: 'Substances',
    panel: {
      accredited: '{numberOfAccredited} accredited substances',
      unaccredited:
        '{numberOfUnaccredited} unaccredited substance¹ | {numberOfUnaccredited} unaccredited substances¹',
      allAccreditedOrUnaccredited:
        '{numberOfSubstances} substance | {numberOfSubstances} substances',
      cutoffWithinParenthesis: 'Reporting limit in parentheses.',
    },
    panelTitle: 'Panel',
    name: 'substances',
    additional: 'Additional',
    total: 'Total',
    S: 'Small',
    M: 'Medium',
    L: 'Large',
    SG: 'Marker',
    CHIRAL: 'Chiral{superscript} Amphetamine Standalone Analysis',
    addAdditional: 'Add additional substances',
    additionalSelected: 'Additional ({selected})',
    cancelAdditional: 'Clear additional substances',
    saveAdditional: 'Save',
    names: {
      AMPHETAMINE: 'Amphetamine',
      METHAMPHETAMINE: 'Methamphetamine',
      MDMA: 'MDMA',
      METHYLPHENIDATE: 'Methylphenidate',
      COCAINE: 'Cocaine',
      BENZOYLECGONINE: 'Benzoylecgonine',
      EPHEDRINE: 'Ephedrine',
      '3_CMC': '3-CMC',
      PMMA: 'PMMA',
      '4_FA': '4-FA',
      PENTEDRONE: 'Pentedrone',
      MEPHEDRONE: 'Mephedrone',
      MDPV: 'MDPV',
      ALPHA_PVP: 'Alpha-PVP',
      KETAMINE: 'Ketamine',
      THC: 'THC',
      MORPHINE: 'Morphine',
      '6_MAM': '6-MAM',
      METHADONE: 'Methadone',
      BUPRENORPHINE: 'Buprenorphine',
      TRAMADOL: 'Tramadol',
      CODEINE: 'Codeine',
      OXYCODONE: 'Oxycodone',
      HYDROMORPHONE: 'Hydromorphone',
      DIAZEPAM: 'Diazepam',
      OXAZEPAM: 'Oxazepam',
      ALPRAZOLAM: 'Alprazolam',
      CLONAZEPAM: 'Clonazepam',
      FLUNITRAZEPAM: 'Flunitrazepam',
      TEMAZEPAM: 'Temazepam',
      LORAZEPAM: 'Lorazepam',
      ZOPICLONE: 'Zopiclone',
      ZOLPIDEM: 'Zolpidem',
      PREGABALIN: 'Pregabalin',
      GABAPENTIN: 'Gabapentin',
      FENTANYL: 'Fentanyl',
      DEXTROMETHORPHAN: 'Dextromethorphan',
      MITRAGYNINE: 'Mitragynine',
      OH_PHENAZEPAM: 'OH-Phenazepam',
      RITALINIC_ACID: 'Ritalinic acid',
      NORBUPRENORPHINE: 'Norbuprenorphine',
      NORDIAZEPAM: 'Nordiazepam',
      THC_COOH: 'THC-COOH',
      EDDP: 'EDDP',
      OH_ALPRAZOLAM: 'OH-Alprazolam',
      '7_AMINOCLONAZEPAM': '7-Aminoclonazepam',
      '7_AMINOFLUNITRAZEPAM': '7-Aminoflunitrazepam',
      ZOLPIDEM_ACID: 'Zolpidem acid',
      CREATININE: 'Creatinine',
      AMYLASE: 'Amylase',
      PHOSPHATIDYLETHANOL:
        'Phosphatidylethanol - PEth (long-term alcohol marker)',
    },
    chiralAnalysis:
      'Analyze chiral¹ amphetamine if amphetamine result is positive',
    ACCRED_LAB: 'Accred. lab no 10532 by Swedac for testing in ISO 15189',
    METHOD_NOT_ACCREDITED_QUALITY_ASSURED:
      '¹indicates that the analysis is not included in scope of accreditation, however quality assured.',
    METHOD_REPORTED_QUALITATIVE:
      'indicates that result is reported qualitatively, positive (POS) / negative (NEG), not with a concentration.',
  },
  comment: {
    title: 'Comment',
    prescriptions: 'List information such as prescriptions',
    no_prescriptions: 'The person has no prescriptions',
    no_comments: 'No comment',
    placeholder: 'Enter any comment',
  },
  temperature: {
    title: 'Urine temperature',
    not_normal: 'Not normal',
    range: '32-38',
    degrees_celsius: '°C',
    not_in_range_dialog: {
      title: 'Temperature is not in accepted range',
      help_text:
        'The temperature is not in the accepted temperature range and cannot be sent in for analysis. Please consider retaking the test.',
      dismiss_button: 'Dismiss requisition',
    },
  },
  barcode: {
    barcode: 'Barcode',
    title: 'Scan barcode',
    barcodeReader: {
      label: 'Use barcode reader',
    },
    errorMessages: {
      barcode_duplicate:
        'The barcode has already been used. Scan a new barcode.',
      barcode_does_not_exist:
        'The barcode does not exist. Make sure you have entered the correct barcode',
    },
    manual_input: {
      required: 'I need to enter the barcode manually',
      input_label: 'Input barcode',
      verification_label: 'Verify barcode',
    },
    cameraScanner: {
      cancel: 'Cancel',
      loading: 'Loading camera...',
      label: 'Camera on your device',
      compatibilityMessage:
        'Note: The functionality may not be compatible with all devices/browsers.',
      barcodeAlignmentMessage:
        'Please keep barcode straight and aligned horizontally.',
      errorMessages: {
        NotAllowedError:
          'The settings in your browser do not allow for the camera to open. Adjust your settings and try again.',
        NotFoundError: 'Unable to detect a camera on your device.',
        NotReadableError: 'Unknown error. Unable to load camera.',
        OverconstrainedError: 'The camera on your device is not compatible.',
        StreamApiNotSupportedError:
          'Your browser does not support using the camera on your device. Make sure your browser is updated and try again',
        unknownError: 'Unknown error. Unable to load camera.',
      },
      modalAccept: 'Ok',
      modalHeader: 'Error',
    },
  },
  nationality: {
    placeholder: 'Select nationality',
    swedish: 'Yes',
    non_swedish: 'No',
    other: 'Other',
  },
  identifier: {
    identifier: 'Identification',
    selectIdentifier: 'Select identification',
    typeOfIdentifier: 'Identification type',
    nationalId: 'National ID card',
    workplaceId: 'Workplace identification',
    personalNumber: 'Personal number',
    custom: 'Personal ID',
  },
  treatmentCenter: {
    title: 'Treatment center details',
    selectTreatmentCenter: 'Select treatment center',
    treatmentCenter: 'Treatment center',
  },
  employer: {
    title: 'Fill employer details',
    sessionIdentifier: 'Session ID',
    selectSessionId: 'Select session ID',
    selectEmployer: 'Select employer',
    employer: 'Employer',
    subdivision: 'Subdivision',
    no_subdivisions_tooltip:
      'This field is disabled if there are no subdivisions',
    subcontractor: 'Additional information',
  },
  personalIdentifier: {
    mask: 'YYYYMMDDXXXX',
  },
  general: {
    continue: 'Continue',
    log_out: 'Log out',
    create_requisition: 'Create Requisition',
    view_results: 'View Results',
    view_requisitions: 'View Requisitions',
    view_orders: 'View Requisitions',
    randomizer: 'Randomizer',
    sessions: 'Sessions',
    next_step: 'Next step',
    download: 'Download',
    signing_you_in: 'Signing you in...',
    welcome: 'Welcome!',
    unknown_error: 'Unknown error',
    error_occurred: 'Something went wrong',
    error_is_reported_message: 'Our team has been notified.',
    offline_error:
      'You are currently offline. Please check your internet connection and try again.',
    admin_board: 'Admin Board',
    no_available_test_types:
      'Your organization has no enabled test types. Please contact your organization admin.',
    auth0_mismatch_org_error_key:
      'You seem to be logged into multiple different organizations, but should only be logged into one at a time. Please log out and back in again to continue.',
    language: 'Language',
    swedish: 'Swedish',
    english: 'English',
  },
  authentication: {
    activate_bankid: 'Activate BankID',
    bankid_link_summary: 'Activation Successful',
    bankid_link_error_summary: 'Activation failed',
    bankid_link_error_detail_message:
      'Activation of BankID failed, please try again.',
    modal: {
      abort: 'Cancel',
      no: 'No',
      yes: 'Yes',
      retry: 'Retry',
      link: 'Link',
      continue: 'Continue',
      login: 'Log in',
      unlinked_bankid:
        'You have not linked your BankID account, which is required to be able to access our services, do you wish to link to your existing CareOS account?',
      not_part_of_org:
        'You are not part of the organization that you were trying to access. Please contact your organization administrator, or try again.',
      user_not_found:
        'We could not find your user account in our system, please contact your administrator.',
      link_success:
        'You have successfully linked your BankID account to your CareOS account, to proceed, please log in again.',
      user_cancel_link:
        'It looks like you have cancelled the BankID-identification, do you want to try again?',
      user_cancel_login:
        'It looks like you have cancelled the BankID-identification, do you want to try again?',
      link_proposal:
        'We now offer the possibility to link your BankID to your CareOS account, do you want to do that? If you wish to do this at another time, you can find the option in the menu in the top right.',
      link_error:
        'Something went wrong during the linking of your BankID, do you want to try again?',
      link_expired:
        "The link has expired, please contact your administrator if you haven't been able to setup your account within the given time.",
      update_failed:
        'We could not link your BankID to your CareOS account, this might mean that it has already been done. Try logging in with BankID, and if not successful, contact your administrator.',
      timeout: 'The request has timed out, do you want to try again?',
      register_with_bankid:
        'Welcome to CareOS, to register with BankID, please press continue.',
      unknown: 'An unknown authentication error occurred, please try again.',
    },
  },
  signing: {
    header: 'Signing requisition',
    description:
      'You are now signing requisition with transaction-ID: *{transactionId}*',
    footer:
      'Please make sure that you have read and understood the requisition, and verified that the transaction-ID above is the same as the one in the requisition.',
  },
  sessions: {
    header: {
      sessions: 'Sessions',
      requisitions_without_session: 'Requisitions without session ({count})',
    },
    table: {
      columns: {
        sampling_date: 'Sampling date',
        session_id: 'Session ID',
        employee_name: 'Employee name',
        test_type: 'Test type',
        reason: 'Reason',
        status: 'Status',
        actions: 'Actions',
      },
      filters: {
        clear: 'Clear',
        apply: 'Apply',
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        sunday: 'Su',
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
      },
      employee_name_tooltip:
        'Hover over the personal name to see all personal details',
      reasonForTesting: {
        random_selection: 'Random selection',
        new_employee: 'Pre-employment',
        incident_accident: 'Incident/accident',
      },
      session_status: {
        started: 'Started',
        finished: 'Finished',
        requested: 'Requested',
        reported: 'Reported',
      },
      actions_tooltip: {
        randomizer: 'Generate random numbers',
        start_session: 'Start session',
        session_started_toast_msg: 'Session has been started successfully',
        close_session: 'Close session',
      },
    },
    close_session: {
      modal: {
        message: 'Are you sure you want to close session with ID {sessionId}?',
        note: 'Note:',
        close_button: 'Close session',
        session_closed_toast_msg: 'Session has been closed successfully',
      },
    },
  },
  requisitions_without_session: {
    table: {
      columns: {
        sampling_date: 'Sampling date',
        barcode: 'Barcode',
        employer: 'Employer',
        subDivision: 'Subdivision',
        employee_name: 'Employee name',
        test_type: 'Test type',
        reason: 'Reason',
      },
    },
  },
  results: {
    tableEmpty:
      'No results to show with current filter settings. (Showing {numberOfResults} of {numberOfTotalResults} results).',
    summary_report: 'summary-report',
    download_filtered_content:
      'Download report with {n} result | Download report with {n} results',
    pos: 'See report',
    neg: 'Negative',
    inv: 'Invalid',
    peth_result: 'PEth report',
    received: 'Results reported',
    awaiting: 'Awaiting results',
    pdf_could_not_be_rendered:
      'The report could not be loaded, please refresh the page and try again.',
    anomalies: {
      EMPTY_TUBE: 'Empty tube',
      ABSORBENT_MATERIAL_INSIDE: 'Absorbent material inside',
      INADEQUATE_SAMPLE: 'Inadequate sample',
      TAMPER_SEAL_BROKEN: 'Tamper seal broken',
      LEAKAGE: 'Leakage',
      DEBRIS_IN_SAMPLE: 'Debris in sample',
      UNSCANNABLE_BARCODE: 'Unscannable barcode',
      INCORRECT_BARCODE_PLACEMENT: 'Incorrect barcode placement',
      CONTAMINATED_SAMPLE: 'Contaminated sample',
      NO_BARCODE: 'No barcode',
      DUPLICATE_BARCODE: 'Duplicate barcode',
      MISSING_REQUISITION: 'Missing requisition',
      MISSING_B_SPECIMEN: 'Missing A or B sample',
      MISMATCHED_B_SPECIMEN: 'Mismatched B sample',
      MISMATCHED_SPECIMEN_TYPE: 'Mismatched sample type',
      LATE_REQUISITION: 'Late requisition',
      NO_TAMPER_SEAL: 'No tamper seal',
      INSUFFICIENT_SAMPLING:
        'Insufficient sampling (not enough sampling material)',
      UNKNOWN_SAMPLING_KIT: 'Incorrect sampling kit',
      MISMATCHED_SAMPLING_KIT: 'Mismatched sampling kit',
    },
  },
  summary: {
    confirm_requisition: 'Review requisition',
    additional_substance: 'Additional substances',
    alcohol_marker: 'Alcohol Marker',
    submit_order: 'Submit order',
    submit: 'Submit',
    sign: 'Sign',
    hand_sign: 'Sign by hand',
    bankid_sign: 'Sign with BankID',
    try_again: 'Try again',
    collector_approval_workplace:
      'By submitting you attest that the donor has been identified correctly, that the sampling has been carried out correctly, and that the donor has received relevant information and been observed when signing consent.',
    collector_approval_treatment_center:
      'By submitting you attest that the donor has been identified correctly, that the sampling has been carried out correctly, and that the donor has received relevant information.',
    chiral_header: 'Chiral Amphetamine',
    chiral_text:
      'Chiral analysis of amphetamine if amphetamine result is positive.',
    error_message: {
      creating_order:
        'Unable to create requisition. Please try again or contact your customer representative.',
      personal_number_mismatch: 'Personal number mismatch',
      personal_number_mismatch_description:
        'There is a mismatch between the personal number in the BankID signature and the personal number in the requisition.',
      signing_requisition:
        'There was an error signing the requisition, please try again.',
    },
  },
  created: {
    requisition_created: 'Requisition created successfully!',
    create_new: 'Create new requisition',
    return_to_start: 'Return to start',
    show_details: 'Show details',
    hide_details: 'Hide details',
    requisition_password: 'Requisition password:',
    request_new_password: {
      tooltip: 'Generate new password',
      modal: {
        title:
          'Are you sure you want to proceed with generating a new password?',
        description:
          "You are about to generate a new password for the donor. Generating a new password will permanently replace the donor's current password. You will need to provide the new password to the donor so that they can access their copy of the requisition. You will only see the password momentarily, until leaving the page.",
      },
    },
    remind_donor_note_password:
      'Ensure that the donor notes or photographs this password. This password is needed for the donor to view their requisition confirmation.',
    organization_details: 'Organization details',
    organization: 'Organization',
    collector: 'Collector',
    collector_email: 'Collector email',
  },
  table: {
    fetchingData: 'Fetching data, please wait...',
    apply: 'Apply',
    clear: 'Clear',
    any: 'Any',
    acknowledged: 'Acknowledged',
    drug_panel: 'Drug panel',
    chiral_analysis: 'Chiral analysis',
    see_result: 'See result',
    result_report: 'Result report',
    clear_filter: 'Clear filter',
    columns: {
      patientIdentifier: 'Personal ID',
      samplingDate: 'Sampling date',
      resultReceived: 'Result received',
      result: 'Result',
      organization: 'Organization',
      employer: 'Employer',
      subDivision: 'Subdivision',
      collector: 'Collector',
      barcode: 'Barcode',
      treatmentCenter: 'Treatment Center',
      receivedAtLabDate: 'Received at lab',
      testType: 'Test Type',
    },
  },
  consent_form: {
    requisition_drug_test: 'Requisition for drug test',
    DoA_consent_text:
      'I confirm that I have received information about the meaning and content of the drug test. I confirm that I have provided a freshly voided urine or saliva specimen to the specimen collector. I have observed the specimen being placed and sealed in the specimen tubes and I confirm that the information on this form and on the specimen labels is correct. I hereby give permission for a minimum of two sealed specimen tubes to be sent to the laboratory and I consent that they be tested for evidence of drug use and for the tests to be carried out to confirm the validity of the sample. Furthermore, I understand that the results will be communicated confidentially to the employer or a designated representative.',
    PEth_consent_text:
      'I confirm that I have received information about the meaning and content of the PEth (alcohol) test. I confirm that I have provided a fresh blood sample to the specimen collector. I have observed the specimen being placed and sealed in the specimen cards/tubes and I confirm that the information on this form and on the specimen labels is correct. I hereby give permission for a minimum of one sealed specimen card/tube to be sent to the laboratory and I consent that they be tested for evidence of long-term alcohol consumption and for tests to be carried out to confirm the validity of the sample. Furthermore, I understand that the results will be communicated confidentially to the employer or a designated representative.',
    created: 'Created :',
    signature: 'Signature',
  },
  identifier_system: {
    'urn:oid:127521292131': 'Swedish personal number',
    date_of_birth: 'Date of birth',
  },
  signed_consent: 'Signed consent',
  sign_consent: 'Sign consent',

  patientConfirmation: {
    passcode: {
      inputLabel: 'Requisition password',
      submitButton: 'Show requisition',
      help: 'Input the code you got from the collector at the time of sampling',
      codeInvalid:
        'The requisition password you entered is incorrect. Please try again.',
    },
  },
  form: {
    comment: 'Comment',
  },
  acknowledged: {
    true: 'Handled',
    false: 'Not handled',
  },
  admin_board: {
    tabs: {
      treatment_centers: 'Treatment centers',
      employers: 'Employers',
      users: 'Users',
    },
    table: {
      not_configured: 'Needs configuring',
      headers: {
        name: 'Name',
        added: 'Added',
        login_name: 'Login name',
        type: 'Type',
        created_at: 'Created at',
        created_at_tooltip:
          'The filters on this column can be either a single date or a date range, a range can be specified by pressing two dates.',
        employer: 'Employer',
        treatment_center: 'Treatment center',
        email: 'Email',
        role: 'Role',
        subdivision: 'Subdivision',
        notification: 'Result Notification',
      },
      edit_row: {
        confirm_1: 'You are about to make the following changes:',
        confirm_2: 'Enabled test types for the organization will be:',
        confirm_none:
          'None. Please note that it will not be possible to order any type of test if you accept.',
        enable_doa: 'Drug Panel',
        enable_peth: 'Alcohol (PEth)',
        enable: 'Enable {testType}',
        enable_non_coc: 'Enable {testType} *(non-CoC)',
        disable: 'Disable {testType}',
        confirmation_1:
          'For your organization {organization} you are about to:',
        confirmation_2: 'Do you wish to proceed?',
        enable_sampling_kits_prompt: 'Enable {samplingKits}',
        disable_sampling_kits_prompt: 'Disable {samplingKits}',
        cancel: 'Cancel',
        submit: 'Submit',
        go_back: 'Go back',
        confirm: 'Confirm',
      },
      filters: {
        Collector: 'Collector',
        Admin: 'Admin',
        clear: 'Clear',
        apply: 'Apply',
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        sunday: 'Su',
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
      },
    },
    org_types: {
      workplace: 'Workplace',
      treatment_center: 'Treatment center',
    },
    add_org_form: {
      name: 'Name',
      type: {
        label: 'Type of testing',
        placeholder: 'Choose a type',
        workplace: 'Workplace',
        treatment_center: 'Treatment center',
      },
      submit_button: 'Add organization',
      org_added_msg: 'Organization added!',
      confirm_add_organization: {
        title: 'Confirm name of organization',
        text: 'Are you sure you want to add an organization called',
        login_name: 'Login name:',
      },
    },
    add_employer_form: {
      employer_added_message: 'Employer added!',
      employer_name: 'Employer name',
      add_employer: 'Add employer',
    },
    employer_table: {
      add_subdivision: 'Add subdivision',
    },
    edit_employers_modal: {
      title: 'Confirm changing employer name',
      message_1: 'You are about to change the employer name from',
      message_2: 'to',
      employer_edited_toast_msg: {
        success: 'Employer name has been updated successfully.',
        error: 'Employer name can not be empty.',
      },
    },
    add_tc_form: {
      tc_added_message: 'Treatment center added!',
      tc_name: 'Treatment center name',
      add_tc: 'Add treatment center',
    },
    edit_tc_modal: {
      title: 'Confirm changing treatment center name',
      message_1: 'You are about to change the treatment center name from',
      message_2: 'to',
      tc_edited_toast_msg: {
        success: 'Treatment center name has been updated successfully.',
        error: 'Treatment center name can not be empty.',
      },
    },
    add_user_form: {
      add_user: 'Add user',
      name: 'Name',
      email: 'Email',
      role: 'Role',
      select_role: 'Select a role',
      modal: {
        already_exists: 'The user already exist under a different name:',
        add_anyway: 'Would you like to add the user with existing name',
        contact_text: 'Please contact your admin if you need to edit a user',
      },
      max_length_warning: 'User name can be up to 80 characters.',
    },
    add_subdiv_form: {
      subdiv_added_message: 'Subdivision added!',
    },
    edit_subdiv_modal: {
      title: 'Confirm changing subdivision name',
      message_1: 'You are about to change the subdivision name from',
      message_2: 'to',
      subdiv_edited_toast_msg: {
        success: 'Subdivision name has been updated successfully.',
        error: 'Subdivision name can not be empty.',
      },
    },
    user_already_exists_on_org: 'User already exists in this organization',
    user_added_msg: 'User added!',
    user_details: {
      changes: 'You are about to make the following changes',
      current: 'Current',
      new: 'New',
      name: 'Name',
      email: 'Email',
      role: 'Role',
      role_collector: 'Collector',
      role_collector_long: 'Collector - Only create requisitions',
      role_hr: 'HR WPS',
      role_hr_long: 'HR WPS - Create orders and see results',
      role_admin: 'Admin',
      role_admin_long: 'Admin - See results and create requisitions',
      reset_auth_tooltip: 'Reset MFA',
    },
    notification: {
      modal: {
        msg_prefix: 'You are about to make the following changes:',
        activate: 'activate',
        deactivate: 'deactivate',
        msg_suffix: 'result notifications for user',
      },
      response_msg: {
        success: 'Successfully updated notification setting',
        error: 'Unable to update notification setting',
      },
      tooltip: {
        activate: 'Activate notifications',
        deactivate: 'Deactivate notifications',
      },
      request_update_error: 'Unable to update notification setting. Reason:',
    },
    send_email: {
      tool_tip: 'Send welcome email',
      modal: {
        msg_prefix: 'You are about to resend welcome email for user: ',
        msg_suffix:
          'Follow the instructions in the mail to signup. Signup link is valid in 5 days.',
      },
    },
    mfa_modal: {
      msg_prefix: 'You are about to reset multi-factor authenticator for user',
      msg_suffix:
        'This will require the user to re-enroll to multi-factor authentication.',
    },
    submit_modal: {
      accept: 'Accept',
      decline: 'Cancel',
    },
    user_edited_msg: 'User edited!',
    user_deleted_msg: 'User removed',
    organization_login_name: 'Login name',
    remove_user_heading:
      'You are about to remove the user with the follow information from this organization',
  },
  careos_table: {
    default_empty_msg: 'No data available',
    loading: 'Loading...',
  },
  randomizer_form: {
    total_on_site: 'Employees on site',
    total_to_test: 'Employees to test',
    button_label: 'Generate',
  },
  donor_credentials: {
    phoneNumber: {
      label: 'Phone number',
      invalidPhoneNumber: 'Please input phone number correctly',
    },
    password: {
      label: 'Password',
      help: 'Input the code you got from the collector at the time of sampling',
    },
    credentialsInvalid:
      'Invalid credentials. Please check your phone number and password.',
    submitButton: 'Show requisition',
  },
};
