<template>
  <div class="flex flex-col gap-2">
    <h1 class="mb-8">
      {{
        t('sessions.close_session.modal.message', {
          sessionId: sessionId,
        })
      }}
    </h1>
    <p>
      {{ t('sessions.close_session.modal.note') }}
    </p>
    <TextArea
      v-model="sessionClosureNote"
      class="h-15 flex w-full resize-none rounded-xl"
      type="text"
    />
    <div class="mt-8 flex justify-end gap-2">
      <CareosButton
        :label="t('sessions.close_session.modal.close_button')"
        :class="['bg-careos-purple', 'text-white']"
        @click="$emit('close-session', sessionClosureNote)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TextArea from 'primevue/textarea';
import CareosButton from '@/components/CareosButton.vue';
const { t } = useI18n();

const sessionClosureNote = ref('');

defineProps<{
  sessionId: string;
}>();

defineEmits<{
  'close-session': [notes: string];
}>();
</script>
