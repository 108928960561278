import { useAuthStore } from '@/stores/auth';
import { isProd, isStaging } from '@/utils/deployment-environment';
import { User } from '@auth0/auth0-spa-js';
import { Auth0VueClientOptions, createAuth0 } from '@auth0/auth0-vue';
import * as Sentry from '@sentry/vue';
import type { Ref } from 'vue';

const developmentAuth0: Auth0VueClientOptions = {
  domain: 'dev-careos.eu.auth0.com',
  clientId: 'DNOJxgOmPzOaqZPrEl1Y1hePmtDYC0BH',
  useRefreshTokens: true,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: 'https://dev-api.careos.io/careos-toxi-doa-api',
  },
  cacheLocation: 'localstorage',
  useRefreshTokensFallback: true,
};

const stagingAuth0: Auth0VueClientOptions = {
  domain: 'staging-careos.eu.auth0.com',
  clientId: 'zdxnVV8O9xrKkiQG3fvi4Z7l04BTo9c0',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: 'https://staging-api.careos.io/careos-toxi-doa-api',
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  cacheLocation: 'localstorage',
};

const productionAuth0: Auth0VueClientOptions = {
  domain: 'careos.eu.auth0.com',
  clientId: 'RJSDKMRUxxwULVFGjvG2EeE5F0pp6XBu',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: 'https://api.careos.io/careos-toxi-doa-api',
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  cacheLocation: 'localstorage',
};

export const auth0 = createAuth0(
   
  isProd ? productionAuth0 : isStaging ? stagingAuth0 : developmentAuth0,
);
export interface AccessTokenClaims {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  org_id: string;
  permissions: string[];
}

export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    const authStore = useAuthStore();
    authStore.accessToken = await auth0.getAccessTokenSilently();
    return authStore.accessToken;
  } catch (e) {
    if (
      e instanceof Error &&
      e.message !== 'Login required' &&
      e.message !== 'Multifactor authentication required'
    ) {
      Sentry.captureException(e.message, {
        user: { id: auth0.user.value?.sub },
      });
    }
    const currentPage = window.location.pathname;
    await auth0.logout();
    await auth0.loginWithRedirect({ appState: { target: currentPage } });
  }
};

export const getOrganizationTypes = (user: Ref<User | undefined>): string[] => {
  if (!user.value?.organization.metadata.careos_org_types) return [];
  return user.value?.organization.metadata.careos_org_types.split(',');
};

export const getOrganizationID = (
  user: Ref<User | undefined>,
): string | undefined => user.value?.organization?.metadata?.careos_org_id;
