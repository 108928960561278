import { z } from 'zod';
import { AddressSchema } from './address.interface';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { ContactPointSchema } from './contact-point.interface';
import { HumanNameSchema } from './human-name.interface';
import { IdentifierSchema } from './identifier.interface';
import { IsoDateSchema } from './iso-date.type';
import { ReferenceSchema } from './reference.interface';

export const PatientSchema = z.object({
  /**
   * Unique resource ID.
   */
  patientId: z.string().optional(),

  /**
   * Personal identifiers.
   */
  identifiers: z.array(IdentifierSchema),

  /**
   * Name of the patient.
   */
  name: HumanNameSchema.optional(),

  /**
   * Names of the patient.
   */
  names: HumanNameSchema.array().optional(),

  /**
   * Contact points to the patient.
   */
  telecom: z.array(ContactPointSchema).optional(),

  /**
   * If the patient has protected identity.
   */
  protectedIdentity: z.boolean().optional(),

  /**
   * When the patient was born.
   */
  birthDate: IsoDateSchema.optional(),

  /**
   * Organization that is the custodian of this patient information.
   */
  managingOrganization: ReferenceSchema(z.enum(['Organization'])).optional(),

  /**
   * Nationalities of the patient
   */
  nationalities: z.array(CodeableConceptSchema).optional(),

  /**
   * Address of the patient
   */
  address: AddressSchema.optional(),
});

/**
 * An individual who receives care or other health-related service.
 */
export type Patient = z.infer<typeof PatientSchema>;
