import { z } from 'zod';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationAcknowledgementsRetractedEventSchema =
  BaseEventSchema.extend({
    eventType: z.literal('ObservationAcknowledgementsRetractedEvent'),
    eventData: z.object({
      observationIds: z.string().array(),
    }),
  });

export class ObservationAcknowledgementsRetractedEvent extends createSchemaClass(
  ObservationAcknowledgementsRetractedEventSchema,
) {}
