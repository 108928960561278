<template>
  <div
    class="overflow-hidden rounded bg-careos-purple-light px-2 py-2 text-left child:p-[2px] md:columns-2 md:px-4 lg:min-w-fit"
    :class="{
      'lg:columns-1': size === 'tall',
      'lg:columns-2': size === 'wide',
    }"
  >
    <DetailsItems :details-items="props.detailsItems" />
  </div>
</template>
<script setup lang="ts">
import { PersonalDetailsItem } from '@/models/personal-details-item';
import DetailsItems from './DetailsItems.vue';

const props = defineProps<{
  detailsItems: PersonalDetailsItem[];
  size: 'tall' | 'wide';
}>();
</script>
