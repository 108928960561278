export type ReportType = 'DoA' | 'PEth' | 'chiral';

export interface PdfFileName {
  locale: string;
  reportType: ReportType;
  barcode: string;
}

type ResultReportLanguage = 'swe' | 'eng';

type ResultFileName =
  `result_${PdfFileName['reportType']}_${PdfFileName['barcode']}_${ResultReportLanguage}`;

export function generatePdfFileName({
  locale,
  reportType,
  barcode,
}: PdfFileName): ResultFileName {
  const pdfLanguage: ResultReportLanguage =
    reportType === 'PEth' && locale === 'en-US' ? 'eng' : 'swe';

  const resultFileName: ResultFileName = `result_${reportType}_${barcode}_${pdfLanguage}`;
  return resultFileName;
}
