import { z } from 'zod';
import { ReferenceSchema } from '../reference.interface';
import { CodeableConceptSchema } from './../codeable-concept.interface';

export const ActivityDefinitionSchema = z.object({
  activityDefinitionId: z.string(),
  /**
   * Human readable title
   */
  title: z.string(),
  /**
   * Observations required to complete this activity
   */
  observationResultRequirement: z
    .array(ReferenceSchema(z.enum(['ObservationDefinition'])))
    .optional(),
  /**
   * Detailed description of the type of activity; e.g. What lab test, what procedure, what kind of encounter.
   */
  code: CodeableConceptSchema,
});

export type ActivityDefinition = z.infer<typeof ActivityDefinitionSchema>;
