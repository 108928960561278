import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const HandleResultRemarkResponseDtoSchema = z.object({
  remark: z
    .object({
      id: z.string().uuid(),
      body: z.string(),
    })
    .optional(),
});

export class HandleResultRemarkResponseDto extends createSchemaClass(
  HandleResultRemarkResponseDtoSchema,
) {}
