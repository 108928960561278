import { authGuard } from '@auth0/auth0-vue';
import { createRouter, createWebHistory } from 'vue-router';
import {
  adminPermissionsRedirectGuard,
  customerAdminRolePermissions,
  organizationAdminRolePermissions,
} from './admin-permissions-redirect-guard';
import { permissionsGuard } from './permissions-guard';
import { CareosRouteRecord } from './types';

import OrderView from '../views/order-view/OrderView.vue';
import AdminView from '../views/admin-view/AdminView.vue';
import AddEmployerForm from '../views/admin-view/organization-view/employers-view/components/AddEmployerForm.vue';
import OrganizationEmployersView from '../views/admin-view/organization-view/employers-view/OrganizationEmployersView.vue';
import OrganizationView from '../views/admin-view/organization-view/OrganizationView.vue';
import AdminOrganizationTreatmentCentersView from '../views/admin-view/organization-view/treatmentcenters-view/AdminOrganizationTreatmentCentersView.vue';
import OrganizationsView from '../views/admin-view/organizations-view/components/OrganizationsView.vue';
import OrganizationsRootView from '../views/admin-view/organizations-view/OrganizationsRootView.vue';
import TreatmentCenterView from '../views/order-view/child-views/customer-view/TreatmentCenterView.vue';
import RequisitionTypeView from '../views/order-view/child-views/requisition-type-view/RequisitionTypeView.vue';
import TestTypeView from '../views/order-view/child-views/test-type-view/TestTypeView.vue';
import OrganizationSubdivisionsView from '../views/admin-view/organization-view/employers-view/subdivisions-view/OrganizationSubdivisionsView.vue';
import OrganizationUsersView from '../views/admin-view/organization-view/users-view/OrganizationUsersView.vue';
import RequisitionPasswordView from '../views/confirmation-view/child-views/requisition-password-view/RequisitionPasswordView.vue';
import RequisitionView from '../views/confirmation-view/child-views/requisition-view/RequisitionView.vue';
import ConfirmationView from '../views/confirmation-view/ConfirmationView.vue';
import LandingView from '../views/landing-view/LandingView.vue';
import BarcodeView from '../views/order-view/child-views/barcode-view/BarcodeView.vue';
import CommentView from '../views/order-view/child-views/comment-view/CommentView.vue';
import CreatedView from '../views/order-view/child-views/created-view/CreatedView.vue';
import EmployerView from '../views/order-view/child-views/customer-view/EmployerView.vue';
import PersonalDetailsView from '../views/order-view/child-views/personal-details-view/PersonalDetailsView.vue';
import ReasonForTestingView from '../views/order-view/child-views/reason-for-testing-view/ReasonForTestingView.vue';
import SpecimenTypeView from '../views/order-view/child-views/specimen-type-view/SpecimenTypeView.vue';
import SubstancesView from '../views/order-view/child-views/substances-view/SubstancesView.vue';
import SummaryView from '../views/order-view/child-views/summary-view/SummaryView.vue';
import TemperatureView from '../views/order-view/child-views/temperature-view/TemperatureView.vue';
import ViewCreatedRequisitionsView from '../views/requisitions-view/child-views/requisition-created-view/ViewCreatedRequisitionsView.vue';
import RequisitionListView from '../views/requisitions-view/child-views/requisition-list-view/RequisitionListView.vue';
import ViewRequisitionsView from '../views/requisitions-view/ViewRequisitionsView.vue';
import ResultsView from '../views/results-view/ResultsView.vue';
import SamplingKitView from '../views/order-view/child-views/sampling-kit-view/SamplingKitView.vue';
import SessionsView from '@/views/sessions-view/SessionsView.vue';
import SignedConsent from '@/views/donor-view/SignedConsent.vue';
import SignedRequisition from '@/views/donor-view-with-phone-number/SignedRequisition.vue';
import ViewCreatedRequisitionsViewPdf from '@/views/requisitions-view/child-views/requisition-created-view/ViewCreatedRequisitionsViewPdf.vue';
import RegisterUserView from '@/views/register-user-view/RegisterUserView.vue';

export const routes: CareosRouteRecord[] = [
  {
    path: '/sessions',
    name: 'Sessions',
    beforeEnter: [authGuard, permissionsGuard],
    meta: {
      i18n: 'general.sessions',
      public: true,
      permissions: ['write:sampling-randomizer'],
    },
    component: SessionsView,
  },
  {
    path: '/order',
    name: 'Create DOA Order',
    component: OrderView,
    props: true,
    beforeEnter: [authGuard, permissionsGuard],
    redirect: '/order/requisition-type',
    meta: {
      i18n: 'general.create_requisition',
      public: true,
      permissions: ['write:requisitions', 'write:tc-requisitions'],
    },
    children: [
      {
        path: 'requisition-type',
        component: RequisitionTypeView,
        meta: {
          displayOrder: 0,
        },
      },
      {
        path: 'employer',
        component: EmployerView,
        meta: {
          displayOrder: 1,
        },
      },
      {
        path: 'treatment-center',
        component: TreatmentCenterView,
        meta: {
          displayOrder: 2,
        },
      },
      {
        path: 'test-type',
        component: TestTypeView,
        meta: {
          displayOrder: 3,
        },
      },
      {
        path: 'personal-details',
        component: PersonalDetailsView,
        meta: {
          displayOrder: 4,
        },
      },
      {
        path: 'reason',
        component: ReasonForTestingView,
        meta: {
          displayOrder: 5,
        },
      },
      {
        path: 'specimen-type',
        component: SpecimenTypeView,
        meta: {
          displayOrder: 10,
        },
      },
      {
        path: 'sampling-kit',
        component: SamplingKitView,
        meta: {
          displayOrder: 11,
        },
      },
      {
        path: 'substances',
        component: SubstancesView,
        meta: {
          displayOrder: 20,
        },
      },
      {
        path: 'comment',
        component: CommentView,
        meta: {
          displayOrder: 30,
        },
      },
      {
        path: 'temperature',
        component: TemperatureView,
        meta: {
          displayOrder: 40,
        },
      },

      {
        path: 'barcode',
        component: BarcodeView,
        meta: {
          displayOrder: 50,
        },
      },
      {
        path: 'summary',
        component: SummaryView,
        meta: {
          displayOrder: 60,
        },
      },
      {
        path: 'created',
        name: 'orderCreated',
        component: CreatedView,
        meta: {
          displayOrder: 70,
        },
      },
    ],
  },
  {
    path: '/results',
    name: 'View Results',
    beforeEnter: [authGuard, permissionsGuard],
    meta: {
      i18n: 'general.view_results',
      public: true,
      permissions: ['read:results', 'read:tc-results'],
    },
    component: ResultsView,
  },

  {
    path: '/requisitions',
    name: 'View Requisition',
    beforeEnter: [authGuard, permissionsGuard],
    meta: {
      i18n: 'general.view_requisitions',
      public: true,
      permissions: ['read:results', 'read:tc-results'],
    },
    redirect: '/requisitions',
    component: ViewRequisitionsView,
    children: [
      {
        path: ':requisitionId',
        component: ViewCreatedRequisitionsView,
      },
      {
        path: 'pdf/:requisitionId',
        component: ViewCreatedRequisitionsViewPdf,
      },
      {
        path: '',
        component: RequisitionListView,
        name: 'Requisitions list',
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin Board',
    beforeEnter: [authGuard],
    meta: {
      i18n: 'general.admin_board',
      public: true,
      // TODO Permissions must be adjusted before giving access to ABC's customers
      permissions: [
        ...customerAdminRolePermissions,
        ...organizationAdminRolePermissions,
      ],
    },
    redirect: '/admin/organizations',
    component: AdminView,
    children: [
      {
        path: 'organizations',
        component: OrganizationsRootView,
        children: [
          {
            path: '',
            component: OrganizationsView,
            name: 'organizationsList',
            beforeEnter: [authGuard, adminPermissionsRedirectGuard],
            meta: {
              permissions: [...customerAdminRolePermissions],
            },
          },
          {
            path: ':organizationType/:organizationKey',
            component: OrganizationView,
            name: 'organizationView',
            redirect: { name: 'organizationUsersView' },
            children: [
              {
                path: 'users',
                component: OrganizationUsersView,
                name: 'organizationUsersView',
                beforeEnter: [authGuard, permissionsGuard],
                meta: {
                  permissions: [
                    ...customerAdminRolePermissions,
                    ...organizationAdminRolePermissions,
                  ],
                },
              },
              {
                path: 'employers',
                component: OrganizationEmployersView,
                name: 'organizationEmployersView',
                beforeEnter: [authGuard, permissionsGuard],
                meta: {
                  permissions: [...organizationAdminRolePermissions],
                },
                children: [
                  {
                    path: '',
                    component: AddEmployerForm,
                    name: 'organizationEmployerListView',
                  },
                  {
                    path: ':employerKey',
                    name: 'organizationEmployerView',
                    redirect: { name: 'organizationEmployerSubdivisionsView' },
                    children: [
                      {
                        path: 'subdivisions',
                        component: OrganizationSubdivisionsView,
                        name: 'organizationEmployerSubdivisionsView',
                      },
                    ],
                  },
                ],
              },
              {
                path: 'treatmentcenters',
                component: AdminOrganizationTreatmentCentersView,
                name: 'organizationTreatmentCentersView',
                beforeEnter: [authGuard, permissionsGuard],
                meta: {
                  permissions: [...organizationAdminRolePermissions],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/confirmation',
    name: 'Signed requisition',
    component: ConfirmationView,
    redirect: '/',
    children: [
      {
        path: 'requisition-password/:partialId',
        component: RequisitionPasswordView,
      },
      {
        path: 'requisition',
        component: RequisitionView,
      },
    ],
  },
  {
    path: '/donor/signed-consent/:partialId',
    name: 'Donor Signed Consent',
    component: SignedConsent,
  },
  {
    path: '/donor/copy-of-signed-consent/:requisitionId',
    name: 'Donor Signed Requisition',
    component: SignedRequisition,
  },
  {
    path: '/',
    name: 'ABC Labs Toxicology',
    component: LandingView,
  },
  {
    path: '/register-user/:encryptedUserData',
    name: 'Register User',
    component: RegisterUserView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not found',
    redirect: '/',
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to, from) => {
  to.meta.transitionName =
    (from.meta.displayOrder as number) > (to.meta.displayOrder as number)
      ? 'slide-right'
      : 'slide-left';
});

router.beforeEach((to, from, next) => {
  if (to.name === 'organizationView' && from.name === 'organizationsList') {
    next({ name: 'organizationUsersView', params: to.params });
  } else {
    next();
  }
});

export { router };
