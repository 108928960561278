import onScan from 'onscan.js';
import { onBeforeUnmount, ref } from 'vue';

export function useScanner(initString?: string | null) {
  const scannedString = ref(initString ?? '');
  const keyCodeMapper = (oEvent: KeyboardEvent) => {
    if (oEvent.which === 189) return '-';
    if (oEvent.key === '#') return '';
    return onScan.decodeKeyEvent(oEvent);
  };

  onScan.attachTo(document, {
    onScan: (code: string) => {
      scannedString.value = code;
    },
    keyCodeMapper,
  });

  onBeforeUnmount(() => {
    onScan.detachFrom(document);
  });

  return scannedString;
}
