<template>
  <CareosTable
    v-model:filters="store.filters"
    :value="doaResults"
    :paginator="true"
    :rows="10"
    :rows-per-page-options="[5, 10, 15]"
    filter-display="menu"
    :filter="storedStateResultTable"
    :sort-field="'resultReceived'"
    :sort-order="-1"
    :state-storage="'session'"
    :state-key="`${sessionStorageKey}_${resultTableID}`"
    :state="stateResultTable"
    :responsive-layout="responsiveLayout"
    :empty-msg="emptyMsg"
    :loading="loading"
    @filter="cacheFilter"
    @row-click="viewCreatedRequisition"
  >
    <template #header>
      <div class="flex flex-row justify-between">
        <button
          type="button"
          class="rounded border border-careos-purple bg-white px-6 py-3 text-careos-purple transition-all hover:bg-careos-purple hover:text-white active:text-careos-lavendel"
          @click="store.emptyFilters"
        >
          <PrimeIcon icon="FILTER_SLASH" size="SM" class="mr-2" />
          {{ t('table.clear_filter') }}
        </button>
        <CareosButton
          :disabled="!currentFilteredData.length"
          :label="
            t('results.download_filtered_content', currentFilteredData.length)
          "
          @click="download()"
        />
      </div>
    </template>
    <Column
      field="resultReceived"
      filter-field="resultReceived"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.resultReceived')"
      data-type="date"
      ><template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
      <template #body="slotProps">{{
        dayjs(slotProps.data.resultReceived).format('YYYY-MM-DD')
      }}</template></Column
    >
    <Column
      field="samplingDate"
      filter-field="samplingDate"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.samplingDate')"
      data-type="date"
      ><template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          selection-mode="range"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>

      <template #body="slotProps">{{
        dayjs(slotProps.data.samplingDate).format('YYYY-MM-DD')
      }}</template></Column
    >

    <Column
      field="barcode"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.barcode')"
      data-type="string"
      ><template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="w-full rounded border border-gray-300 p-2"
          :placeholder="t('table.columns.barcode')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        /> </template
    ></Column>

    <Column
      field="patientIdentifier"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.patientIdentifier')"
      data-type="string"
      ><template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="w-full rounded border border-gray-300 p-2"
          :placeholder="t('table.columns.patientIdentifier')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        /> </template
    ></Column>

    <Column
      v-if="!isUserOrgHasOnlyTreatmentCenter"
      field="employer"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.employer')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="employerFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      v-if="!isUserOrgHasOnlyTreatmentCenter"
      field="subDivision"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.subDivision')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="subDivisionFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      field="collector"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.collector')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="collectorFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      v-if="isUserOrgHasOnlyTreatmentCenter"
      field="treatmentCenter"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.treatmentCenter')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="treatmentCenterFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      field="acknowledged"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.acknowledged')"
    >
      <template #body="slotProps">
        <div>
          <Checkbox
            v-if="areAllResultsReported(slotProps.data.extraResults)"
            class="text-careos-purple"
            :model-value="slotProps.data.acknowledged"
            :binary="true"
            @click.stop=""
            @update:model-value="
              handleAcknowledgement(slotProps.data.requisitionId, $event)
            "
          />
        </div>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="
            Array.from(
              new Set(doaResults.map((doaResult) => doaResult.acknowledged)),
            )
          "
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        >
          <template #value="slotProps">
            <div
              class="ml-5 flex w-fit justify-center rounded-full px-5 py-1 text-base font-normal text-white"
              :class="
                slotProps.value
                  ? 'bg-careos-purple'
                  : slotProps.value === null
                    ? 'ml-0 justify-start px-0 text-gray-500'
                    : 'bg-gray-600'
              "
            >
              {{
                slotProps.value !== null
                  ? t('acknowledged.' + slotProps.value)
                  : slotProps.placeholder
              }}
            </div>
          </template>
          <template #option="slotProps">
            <div
              class="ml-5 flex w-fit justify-center rounded-full px-5 py-1 text-base font-medium text-white"
              :class="slotProps.option ? 'bg-careos-purple' : 'bg-gray-600'"
            >
              {{ t('acknowledged.' + slotProps.option) }}
            </div>
          </template>
        </Dropdown>
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>
    <Column
      v-if="testTypeFilterOptions.length > 1"
      field="testType"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.testType')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :filter="true"
          option-value="value"
          option-label="label"
          :options="testTypeFilterOptions"
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
    </Column>

    <Column
      field="result"
      sortable
      :show-filter-match-modes="false"
      :header="t('table.columns.result')"
      data-type="string"
      ><template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="
            Array.from(new Set(doaResults.map((doaResult) => doaResult.result)))
          "
          :placeholder="t('table.any')"
          class="p-column-filter"
          :show-clear="true"
        >
          <template #value="slotProps">
            {{
              slotProps.value
                ? t('results.' + slotProps.value)
                : slotProps.placeholder
            }}
          </template>
          <template #option="slotProps">
            {{ t('results.' + slotProps.option) }}
          </template>
        </Dropdown>
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>
      <template
        #body="{
          data: {
            result,
            deviations,
            extraResults,
            requisitionId,
            testType,
            barcode,
          },
          index,
        }"
      >
        <div
          v-if="result === ResultCodes.inv"
          v-tooltip.top="constructResultInvalidMessage(deviations)"
          class="flex cursor-default place-content-end items-center gap-3 pr-3 text-base font-medium"
        >
          <span>
            {{ t('results.' + result) }}
          </span>
          <PrimeIcon icon="INFO_CIRCLE" size="SM" />
        </div>
        <DoaResultButton
          v-else-if="isOnlyDoaResult(extraResults)"
          data-test-id="show-result-button"
          type="button"
          class="h-8 w-full"
          :loading="isShowingResult && index === activeIndex"
          :label="getDownloadResultLabel(result, testType)"
          @click.capture="showResult(requisitionId, testType, index, barcode)"
        />
        <ResultsPanel
          v-else
          :loading="isShowingResult && index === activeIndex"
          :chiral-status="getChiralStatus(extraResults)"
          @on-click-show-result="
            showResult(requisitionId, $event, index, barcode)
          "
        />
      </template>
    </Column>
  </CareosTable>
  <ResultDialog
    v-if="resultPdfContent && resultPdfFileName"
    v-model:visible="showDialog"
    :pdf-content="resultPdfContent"
    :pdf-file-name="generatePdfFileName(resultPdfFileName)"
    @update:visible="handleCloseDialog"
  />
</template>

<script setup lang="ts">
import { router } from '@/router';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import CareosTable from '@/components/CareosTable.vue';
import ResultsPanel from './ResultsPanel.vue';
import ResultDialog from './ResultDialog.vue';
import { useResultsViewStore } from '../store/results-view-store';
import { FilterService } from 'primevue/api';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import dayjs from 'dayjs';
import {
  onMounted,
  onUnmounted,
  PropType,
  ref,
  computed,
  watch,
  onBeforeMount,
  ComponentPublicInstance,
} from 'vue';
import {
  DataTableFilterEvent,
  DataTableRowClickEvent,
} from 'primevue/datatable';
import { ResultCodes, ExtraResultStatus } from '@careos/toxicology-types';
import { useI18n } from 'vue-i18n';
import { useAuth0 } from '@auth0/auth0-vue';
import { saveFile } from '@/utils/save-file';
import { ToxiHttpClient } from '@/api/toxicology-client';
import { areAllResultsReported } from '../helpers/are-all-results-reported';
import { getOrganizationTypes } from '../../../authentication/auth0';
import { DoaResult } from '../types/dto/results.dto';
import DoaResultButton from './DoaResultButton.vue';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import isBetween from 'dayjs/plugin/isBetween';
import { usePrimeVue } from 'primevue/config';
import { PrimeIcon } from 'careos-vue-components';
import { api } from '@/api/client';
import {
  generatePdfFileName,
  PdfFileName,
  ReportType,
} from '@/utils/generate-pdf-file-name';

const store = useResultsViewStore();
const primeVue = usePrimeVue();
const { t, locale } = useI18n();
const { user } = useAuth0();
const filterApply = ref<ComponentPublicInstance | null>(null);
const showDialog = ref(false);
const resultPdfContent = ref();
const resultPdfFileName = ref<PdfFileName>();
const activeIndex = ref();

dayjs.extend(isBetween);

const isUserOrgHasOnlyTreatmentCenter: boolean =
  getOrganizationTypes(user).at(0) === 'treatment_center';

const currentFilteredData = ref<{ requisitionId: string }[]>([]);

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  doaResults: {
    type: Array as PropType<DoaResult[]>,
    default: () => [],
  },
});

const emit = defineEmits(['requisition-handled', 'filter']);

const responsiveLayout = ref<'stack' | 'scroll'>('stack');

const sessionStorageKey = 'dataTableState';
let storedStateResultTable: string | null = '';
const state = ref({});
const resultTableID = 'resultTable';

const onWidthChange = () => {
  const widthMeasurementTypeOne = document.documentElement.clientWidth || 0;
  const widthMeasurementTypeTwo = window.innerWidth || 0;

  Math.max(widthMeasurementTypeOne, widthMeasurementTypeTwo) > 1260
    ? (responsiveLayout.value = 'scroll')
    : (responsiveLayout.value = 'stack');
};

const handleAcknowledgement = (requisitionId: string, value: boolean) => {
  emit('requisition-handled', {
    value,
    requisitionId,
  });
};

watch(state, (newState) => {
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(newState));
});

onBeforeMount(() => {
  sessionStorage.removeItem(sessionStorageKey);
});

onMounted(() => {
  window.addEventListener('resize', onWidthChange);
  onWidthChange();
  storedStateResultTable = sessionStorage.getItem(
    `${sessionStorageKey}_${resultTableID}`,
  );
  if (storedStateResultTable) {
    state.value = JSON.parse(storedStateResultTable);
  }
});

const stateResultTable = ref(
  storedStateResultTable ? JSON.parse(storedStateResultTable) : {},
);
onUnmounted(() => window.removeEventListener('resize', onWidthChange));

FilterService.register(
  'dateInRange',
  (value: string | null, filter: [Date, Date] | null): boolean => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const isRange = filter.at(0) && filter.at(1);
    return isRange
      ? dayjs(value).isBetween(filter[0], filter[1], 'date', '[]')
      : dayjs(value).isSame(filter[0], 'date');
  },
);

const download = async () => {
  const res = await ToxiHttpClient.post(
    '/summary-report',
    {
      requisitionIds: currentFilteredData.value.map(
        ({ requisitionId }) => requisitionId,
      ),
      localStorageLocale: locale.value,
    },
    { responseType: 'blob' },
  );
  // Download pdf/excel
  saveFile(
    res.data,
    `${dayjs(new Date()).format('YYYY-MM-DD')}-${t('results.summary_report')}`,
  );
};

const cacheFilter = (filters: DataTableFilterEvent) => {
  if (!filters) return;
  const receivedResults = filters?.filteredValue as {
    requisitionId: string;
    resultReceived?: Date;
  }[];
  currentFilteredData.value = receivedResults.filter(
    (value) => value.resultReceived,
  );
};

const viewCreatedRequisition = ($event: DataTableRowClickEvent) => {
  const pdfParam = $event.data?.hasRequisitionPdf ? '/pdf' : '';
  const requisitionId = $event.data?.requisitionId;
  const createdRequisitionViewPath = `/requisitions${pdfParam}/${requisitionId}`;

  router.push({
    path: createdRequisitionViewPath,
  });
};

const constructResultInvalidMessage = (deviations: string[]) => {
  if (!deviations) return;
  const temp = deviations
    .map((deviation) => t(`results.anomalies.${deviation}`))
    .join(', ');
  return temp;
};

const testTypeFilterOptions = computed(() =>
  Array.from(
    new Set(props.doaResults.map((doaResult) => doaResult.testType)),
  ).map((object) => ({ label: object, value: object })),
);

const employerFilterOptions = computed(() =>
  Array.from(new Set(props.doaResults.map((doaResult) => doaResult.employer)))
    .filter((employer) => employer !== null && employer !== undefined)
    .map((object) => ({ label: object, value: object })),
);

const getChiralStatus = (
  extraResults: DoaResult['extraResults'],
): ExtraResultStatus => {
  const chiralOrder = extraResults?.find((it) => it.type === 'Chiral');
  if (!chiralOrder) {
    return 'not-ordered';
  }

  return chiralOrder.status;
};

const subDivisionFilterOptions = computed(() =>
  Array.from(
    new Set(props.doaResults.map((doaResult) => doaResult.subDivision)),
  )
    .filter((subDivision) => subDivision !== null && subDivision !== undefined)
    .map((object) => ({ label: object, value: object })),
);

const collectorFilterOptions = computed(() =>
  Array.from(
    new Set(props.doaResults.map((doaResult) => doaResult.collector)),
  ).map((object) => ({ label: object, value: object })),
);

const treatmentCenterFilterOptions = computed(() =>
  Array.from(
    new Set(props.doaResults.map((doaResult) => doaResult.treatmentCenter)),
  ).map((object) => ({ label: object, value: object })),
);

const emptyMsg = computed(() =>
  t('results.tableEmpty', {
    numberOfResults: '0',
    numberOfTotalResults: props.doaResults.length,
  }),
);

const getDownloadResultLabel = (
  result: DoaResult['result'],
  testType: DoaResult['testType'],
): string => {
  if (testType === 'PEth') {
    return t('results.peth_result');
  }
  return t(`results.${result}`);
};

const isOnlyDoaResult = (extraResults: DoaResult['extraResults']) =>
  !extraResults?.length ||
  extraResults.find(({ status }) => status === 'abandoned');

const { exec: showResult, isLoading: isShowingResult } = useNetworkRequest(
  async (
    requisitionId: string,
    reportType: ReportType,
    index: number,
    barcode: string,
  ) => {
    activeIndex.value = index;

    const { data } = await api.fetchResultPdf({
      requisitionId,
      reportType,
      locale: locale.value,
    });

    const fileName = {
      locale: locale.value,
      reportType,
      barcode,
    };

    handleOpenResultDialog(data, fileName);
  },
);

const handleOpenResultDialog = (pdfContent: string, fileName: PdfFileName) => {
  resultPdfContent.value = pdfContent;
  resultPdfFileName.value = fileName;
  showDialog.value = true;
};

const handleCloseDialog = () => {
  showDialog.value = false;
  resultPdfContent.value = undefined;
  resultPdfFileName.value = undefined;
};

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('admin_board.table.filters.january'),
      t('admin_board.table.filters.february'),
      t('admin_board.table.filters.march'),
      t('admin_board.table.filters.april'),
      t('admin_board.table.filters.may'),
      t('admin_board.table.filters.june'),
      t('admin_board.table.filters.july'),
      t('admin_board.table.filters.august'),
      t('admin_board.table.filters.september'),
      t('admin_board.table.filters.october'),
      t('admin_board.table.filters.november'),
      t('admin_board.table.filters.december'),
    ],
    dayNamesMin: [
      t('admin_board.table.filters.sunday'),
      t('admin_board.table.filters.monday'),
      t('admin_board.table.filters.tuesday'),
      t('admin_board.table.filters.wednesday'),
      t('admin_board.table.filters.thursday'),
      t('admin_board.table.filters.friday'),
      t('admin_board.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.results-view-table {
  &__download-icon {
    @apply text-careos-purple;
    &:hover {
      @apply cursor-pointer text-careos-lavendel;
    }
  }
  &__check-icon {
    @apply mr-4 text-careos-purple;
    &:hover {
      @apply cursor-pointer text-careos-lavendel;
    }
  }
}
</style>
