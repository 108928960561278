import { z } from 'zod';

export const DonorCancelBankidSigningRequestDtoSchema = z.object({
  orderRef: z.string(),
  transactionId: z.string().uuid(),
  pinCode: z.string(),
});

export type DonorCancelBankidSigningRequestDto = z.infer<
  typeof DonorCancelBankidSigningRequestDtoSchema
>;
