<template>
  <div class="ml-2 mr-2 min-w-[300px]">
    <div v-if="!showProceedMessage">
      <p class="font-bold" for="rbSpecimenType">
        {{ enableTestTypeTopic }}
      </p>
      <div class="mb-2 flex flex-wrap gap-4">
        <div
          v-for="option in orderTypeOptions"
          :key="option"
          class="align-items-center flex"
        >
          <RadioButton
            v-model="orderType"
            class="mr-2"
            :value="option"
            :input-id="`rb${option}`"
            :pt="{
              box: ({ context }) => ({
                class: [
                  {
                    'bg-careos-purple border-careos-purple': context.checked,
                  },
                ],
              }),
            }"
          />
          <label :for="`rb${option}`">{{ getOrderTypeLabel(option) }}</label>
        </div>
      </div>
      <div v-if="orderType !== 'disabled'">
        <hr />
        <div
          v-for="activityDefinition in activityDefinitionOptions"
          :key="activityDefinition"
        >
          <ActivityDefinitionItem
            :organization="organization"
            :is-doa="isDoa"
            :activity-definition="activityDefinition"
            @update="handleSamplingKitChange"
          />
        </div>
      </div>
      <div class="mt-6 flex justify-between">
        <Button
          class="cancel-button"
          :label="t('admin_board.table.edit_row.cancel')"
          @click="emit('close')"
        />
        <Button
          class="submit-button"
          :disabled="!isProceedable"
          :label="t('admin_board.table.edit_row.submit')"
          @click="showProceedMessage = true"
        />
      </div>
    </div>
    <div v-else>
      <span class="whitespace-break-spaces">{{ confirmationMessage }}</span>
      <div class="mt-6 flex justify-between">
        <Button
          class="cancel-button"
          :label="t('admin_board.table.edit_row.go_back')"
          @click="showProceedMessage = false"
        />
        <Button
          class="submit-button"
          :label="t('admin_board.table.edit_row.confirm')"
          @click="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { OrganizationWithEnabledTestTypes } from '@careos/organization-api-types';
import { TestType } from '@careos/organization-api-types';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ActivityDefinitionItem from './ActivityDefinitionItem.vue';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import { useOrganizationTestType } from '@/views/admin-view/composables/useOrganizationTestType';

const props = defineProps<{
  testType: TestType;
  initialOrganization: OrganizationWithEnabledTestTypes;
}>();

const { t } = useI18n();

const showProceedMessage = ref(false);

const {
  enableTestTypeTopic,
  orderTypeOptions,
  orderType,
  activityDefinitionOptions,
  organization,
  isDoa,
  isProceedable,
  confirmationMessage,
  handleSamplingKitChange,
  getOrderTypeLabel,
  getDto,
} = useOrganizationTestType(props.testType, props.initialOrganization);

const emit = defineEmits(['close', 'submit']);

const handleConfirm = () => {
  emit('submit', getDto());
};
</script>

<style scoped>
.submit-button {
  @apply border-careos-purple bg-careos-purple px-4 py-2 hover:bg-careos-purple disabled:border-careos-lavendel disabled:bg-careos-lavendel;
}
.cancel-button {
  @apply border-gray-500 bg-gray-500 px-4 py-2 hover:bg-gray-400 active:bg-gray-600;
}
</style>
