import { z } from 'zod';
import { IsoDateSchema } from './iso-date.type';

export const PeriodSchema = z.object({
  start: IsoDateSchema,
  end: IsoDateSchema,
});

/**
 * Time period with start and end time.
 */
export type Period = z.infer<typeof PeriodSchema>;
