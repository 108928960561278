import { z } from 'zod';
import { BarcodeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ResultCanceledEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ResultCanceledEvent'),
  eventData: z.object({
    /**
     * Barcode reference to the result canceled
     */
    barcode: BarcodeSchema,
  }),
});

/**
 * This event represent the canceling of a result which has been previously reported
 */
export class ResultCanceledEvent extends createSchemaClass(
  ResultCanceledEventSchema,
) {}
