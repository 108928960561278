<template>
  <div class="text-left text-xs italic">
    <div v-if="hasAccreditedSubstance">
      <p>{{ t('substances.ACCRED_LAB') }}</p>
      <p>
        {{ t('substances.METHOD_NOT_ACCREDITED_QUALITY_ASSURED') }}
      </p>
    </div>
    <p v-if="hasQualitativeSubstance">
      <sup>*</sup>{{ t('substances.METHOD_REPORTED_QUALITATIVE') }}
    </p>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  hasQualitativeSubstance: boolean;
  hasAccreditedSubstance: boolean;
}>();
</script>
