import { z } from 'zod';

export const DonorGetRequisitionByTransactionIdRequestDtoSchema = z.object({
  pinCode: z.string().length(4),
  transactionId: z.string(),
});

export type DonorGetRequisitionByTransactionIdRequestDto = z.infer<
  typeof DonorGetRequisitionByTransactionIdRequestDtoSchema
>;
