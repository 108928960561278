import {
  DoaPanelSize,
  SpecimenInfoDoa,
  SpecimenInfoPeth,
  UrineTemperature,
} from '@careos/toxicology-types';
import { assertDefined } from '@/utils/assert-defined';
import {
  SamplingKitIdentifier,
  ToxicologyActivityIdentifier,
} from '@careos/identifiers';

export function createDoAPanelInfo(
  panelSize: DoaPanelSize,
  additionalObservations: string[],
) {
  return {
    size: assertDefined(panelSize),
    additionalObservations,
  };
}

export function createDoaSpecimenInfo(
  specimenType: ToxicologyActivityIdentifier,
  urineTemperature: UrineTemperature | null,
  samplingKit?: SamplingKitIdentifier,
): SpecimenInfoDoa {
  const sampledAt = new Date().toISOString();
  if (isUrineSampling(specimenType, urineTemperature)) {
    return {
      specimenType: 'DOA_URINE_SAMPLING',
      sampledAt,
      urineTemperature: urineTemperature as UrineTemperature, //safe to cast because of the check in the if statement
    };
  }
  if (!samplingKit) {
    throw new Error('Sampling kit is required');
  }
  if (isSalivaSampling(specimenType)) {
    return {
      specimenType: 'DOA_SALIVA_SAMPLING',
      sampledAt,
      samplingKit,
    };
  }
  if (isBloodSampling(specimenType)) {
    return {
      specimenType: 'DOA_BLOOD_SAMPLING',
      sampledAt,
      samplingKit,
    };
  }

  throw new Error('Invalid specimen type');
}

export function createPethSpecimenInfo(
  specimenType: ToxicologyActivityIdentifier,
  samplingKit?: SamplingKitIdentifier,
): SpecimenInfoPeth {
  if (isPethSampling(specimenType) && samplingKit) {
    return {
      specimenType: 'PETH_DRIED_BLOOD_SPOTS',
      samplingKit,
      sampledAt: new Date().toISOString(),
    };
  }
  throw new Error('Invalid specimen type');
}

const isUrineSampling = (
  specimenType: ToxicologyActivityIdentifier,
  urineTemperature: UrineTemperature | null,
) => specimenType === 'DOA_URINE_SAMPLING' && urineTemperature;

const isSalivaSampling = (specimenType: ToxicologyActivityIdentifier) =>
  specimenType === 'DOA_SALIVA_SAMPLING';

const isBloodSampling = (specimenType: ToxicologyActivityIdentifier) =>
  specimenType === 'DOA_BLOOD_SAMPLING';

const isPethSampling = (specimenType: ToxicologyActivityIdentifier) =>
  specimenType === 'PETH_DRIED_BLOOD_SPOTS';
