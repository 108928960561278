 
import { getAccessToken } from '@/authentication/auth0';
import { isProd, isStaging } from '@/utils/deployment-environment';
import axios from 'axios';

const baseURL = isProd
  ? 'https://api.careos.io/v1/toxi-doa'
  : isStaging
    ? 'https://staging-api.careos.io/v1/toxi-doa'
    : 'http://localhost:5019/v1/toxi-doa';

const ToxiHttpClient = axios.create({
  baseURL,
});

const PUBLIC_ENDPOINTS = [
  '/order-confirmation/personal-code/',
  '/requisitions/donor-signed-requisition-pdf',
  '/requisitions/donor-login-to-get-requisition-pdf',
];

ToxiHttpClient.interceptors.request.use(
  async (value) => {
    if (PUBLIC_ENDPOINTS.some((route) => value.url?.includes(route))) {
      return value;
    }
    const token = await getAccessToken();
     
    value.headers.Authorization = `Bearer ${token}`;
    return value;
  },
  (res) => res,
);

export { ToxiHttpClient };
