import { AccessTokenClaims } from '@/authentication/auth0';
import { useIdle } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { watch } from 'vue';
import { getAccessToken } from '@/authentication/auth0';
import { useAuth0 } from '@auth0/auth0-vue';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;
const FIFTYNINE_SECONDS_IN_MS = 1000 * 59;

export const useAuthStore = defineStore('auth', () => {
  const timer = ref<NodeJS.Timeout>();
  const accessToken = ref<string>();
  const { isAuthenticated } = useAuth0();

  const { idle } = useIdle(FIVE_MINUTES_IN_MS);

  const claims = computed(() => {
    if (!accessToken.value) return;
    return JSON.parse(
      window.atob(accessToken.value.split('.')[1]),
    ) as AccessTokenClaims;
  });

  const tokenExpiry = computed(() => {
    if (!claims.value) return;
    // INFO: We multiply by 1000 to convert the seconds to milliseconds.
    return claims.value.exp * 1000;
  });

  watch(isAuthenticated, async (newIsAuthenticated) => {
    if (!newIsAuthenticated) return;
    if (!accessToken.value) {
      accessToken.value = await getAccessToken();
    }
  });

  watch(idle, (isIdle, wasIdle) => {
    const resumedActivity = wasIdle && !isIdle;
    // INFO: We check the isAuthenticated value to make sure we don't perform
    // the token refresh when the user is on the login page. It doesn't cause
    // any big problem, but will move the user from our login screen to the
    // auth0 login screen, which just looks a little weird.
    if (resumedActivity && isAuthenticated.value) {
      getAccessToken();
    }
  });

  watch(
    tokenExpiry,
    (expiry) => {
      if (timer.value) clearTimeout(timer.value);
      if (!expiry || idle.value) return;
      // INFO: When there's a token we know
      // that the user has been authenticated.
      const timeUntilExpiry = expiry - Date.now();
      const lessThanOneMinuteToExpiry =
        timeUntilExpiry - FIFTYNINE_SECONDS_IN_MS;

      timer.value = setTimeout(() => {
        // INFO: If the user is idle when the timeout is reached,
        // we should not refresh their token. If they come back
        // to the application, we will refresh the token then,
        // through the idle watcher.
        if (!idle.value) getAccessToken();
      }, lessThanOneMinuteToExpiry);
    },
    { immediate: true },
  );
  return {
    permissions: computed(() => claims.value?.permissions ?? []),
    accessToken,
  };
});
