import { z } from 'zod';
import { NotificationSchema } from './notification';

export const RoleSchema = z.object({
  auth0Id: z.string(),
  name: z.string(),
  notifications: z.array(NotificationSchema).optional(),
});

export type Role = z.infer<typeof RoleSchema>;
