import { SamplingKitIdentifierSchema } from '@careos/identifiers';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization-type';
import { isValidTestType, TestToOrderTypeSchema } from '../../../test-type';

export const UpdateOrganizationEnabledTestTypesRequestDtoSchema = z
  .object({
    enabledTestTypes: z.array(TestToOrderTypeSchema),
    enabledSamplingKits: z.array(SamplingKitIdentifierSchema),
    organizationKey: z.string(),
    organizationType: OrganizationTypeSchema,
  })
  .refine(
    (it) =>
      it.enabledTestTypes.every((testToOrderType) =>
        isValidTestType(it.organizationType, testToOrderType),
      ),
    () => ({ message: 'Invalid test type' }),
  );

export type UpdateOrganizationEnabledTestTypesRequestDto = z.infer<
  typeof UpdateOrganizationEnabledTestTypesRequestDtoSchema
>;
