import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { computed, ref } from 'vue';
import { useNetworkRequest } from './useNetworkRequest';
import type { ToxicologyEmployer } from '@careos/organization-api-types';
import { FormkitSelectOption } from '@/formkit/types';

type WorkplaceFormkitOptions = {
  employer: FormkitSelectOption;
  subDivisions: FormkitSelectOption[];
}[];

type TreatmentCenterFormkitOptions = {
  treatmentCenter: FormkitSelectOption;
}[];

const convertToFormkitOption = ({
  display,
  organizationId,
}: ToxicologyEmployer['head']) => ({ label: display, value: organizationId });

const convertToWorkplaceFormkitOptions = (
  employers: ToxicologyEmployer[],
): WorkplaceFormkitOptions =>
  employers.map((it) => {
    const employerOption = convertToFormkitOption(it.head);
    const subDivisionOptions = it.divisions.map((subdiv) => {
      const subDivisionOption = convertToFormkitOption(subdiv);
      return subDivisionOption;
    });
    return { employer: employerOption, subDivisions: subDivisionOptions };
  });

export const useSamplingLocation = () => {
  const store = useSamplingLocationStore();

  const workplaceSamplingLocations = ref<WorkplaceFormkitOptions>([]);
  const treatmentCenterSamplingLocations = ref<TreatmentCenterFormkitOptions>(
    [],
  );

  const { exec: fetchEmployers } = useNetworkRequest(async () => {
    const employers = await store.fetchEmployers();
    workplaceSamplingLocations.value =
      convertToWorkplaceFormkitOptions(employers);
  });

  const { exec: fetchTreatmentCenters } = useNetworkRequest(async () => {
    const treatmentCenters = await store.fetchTreatmentCenters();
    treatmentCenterSamplingLocations.value = treatmentCenters.map((tc) => ({
      treatmentCenter: convertToFormkitOption(tc),
    }));
  });

  const subdivisionOptions = computed(
    () =>
      workplaceSamplingLocations.value?.find(
        (it) => it.employer.value === store.formDataWorkplace.employer?.value,
      )?.subDivisions,
  );

  return {
    fetchEmployers,
    employerOptions: computed(() =>
      workplaceSamplingLocations.value.map((it) => it.employer),
    ),
    subdivisionOptions,
    hasSubdivisions: computed(() => !!subdivisionOptions.value?.length),
    treatmentCenterOptions: computed(() =>
      treatmentCenterSamplingLocations.value.map((it) => it.treatmentCenter),
    ),
    fetchTreatmentCenters,
  };
};
