import { z } from 'zod';
import { SimpleQuantitySchema } from './simple-quantity.type';

export const RangeSchema = z.object({
  /**
   * Low limit.
   */
  low: SimpleQuantitySchema,

  /**
   * High limit.
   */
  high: SimpleQuantitySchema,

  /**
   * Textual representation of the range
   */
  display: z.string().optional(),
});

/**
 * A range of {@link SimpleQuantity} values which are bounded by a low and a high threshold. Corresponds to values between or equal to the limit values.
 */
export type Range = z.infer<typeof RangeSchema>;
