<template>
  <OrderViewWrapper>
    <OrderViewCard
      class="w-full max-w-md"
      data-test-id="urine-temperature"
      :title="t('temperature.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!store.urineTemperature"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <div class="grid grid-rows-2 gap-4">
        <TemperatureRadioButton
          :label="t('temperature.range') + t('temperature.degrees_celsius')"
          :selected="temperatureRange.high === store.urineTemperature?.high"
          @click="selectedTemp(temperatureRange)"
        />
        <CareosSecondaryButton
          :label="t('temperature.not_normal')"
          @click="tempNotInRange()"
        />
      </div>
      <Dialog
        v-model:visible="notAllowedTemp"
        :draggable="false"
        class="text w-[80vw] max-w-[500px]"
      >
        <template #header>
          <h1 class="text-2xl text-black">
            {{ t('temperature.not_in_range_dialog.title') }}
          </h1>
        </template>
        <p>
          {{ t('temperature.not_in_range_dialog.help_text') }}
        </p>
        <div class="flex flex-row child:mx-10 child:mt-6 child:w-full">
          <CareosButton
            :label="t('temperature.not_in_range_dialog.dismiss_button')"
            @click="dismissRequisition()"
          />
        </div>

        <template #footer />
      </Dialog>
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import { router } from '@/router';
import Dialog from 'primevue/dialog';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import TemperatureRadioButton from '../../components/RadioButton.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';

const { t } = useI18n();
const store = useOrderViewStore();
const temperatureRange = { low: 32, high: 38, unit: '°C' } as const;

const notAllowedTemp = ref(false);
const dismiss = ref(false);

const nextStep = () => {
  router.push('/order/barcode');
};
const previousStep = () => {
  router.go(-1);
};

const selectedTemp = (temperature: typeof temperatureRange) => {
  store.urineTemperature = temperature;
  nextStep();
};

const tempNotInRange = () => {
  store.urineTemperature = null;
  notAllowedTemp.value = true;
};
const dismissRequisition = () => {
  dismiss.value = true;
  store.clearOrder();
  router.push('/');
};
</script>
