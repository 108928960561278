import { bankidMessagesEn } from './bankid/en-US';
import { bankidMessagesSv } from './bankid/sv-SE';
import svSubstances from './substances/sv.json';
import enSubstances from './substances/en.json';

export const substances = {
  sv: svSubstances,
  en: enSubstances,
};

export const bankidMessages = {
  sv: {
    bankid: bankidMessagesSv,
  },
  en: {
    bankid: bankidMessagesEn,
  },
};
