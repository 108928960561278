import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import { AnnotationSchema } from '../fhir-entities/annotation.interface';
import { ReferenceSchema } from '../fhir-entities/reference.interface';
import { IsoDateTimeSchema } from '../fhir-entities/iso-date.type';
import { createSchemaClass } from '../utils';

export const DonorPasswordGeneratedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('DonorPasswordGeneratedEvent'),
  eventData: z.object({
    /**
     * Time when the password was generated.
     */
    generatedAt: IsoDateTimeSchema,
    /**
     * The randomly generated password, encrypted using bcrypt hashing function.
     */
    generatedPassword: z.string(),
    /**
     * Reference to the requisition associated with the generated donor password.
     */
    requisitionId: z.string(),
    /**
     * Any notes or comment associated with the generated donor password.
     */
    notes: AnnotationSchema.optional(),
    /**
     * {@link Practitioner} who requested the generation of the donor's password.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * Represents when a password is generated for a donor.
 */
export class DonorPasswordGeneratedEvent extends createSchemaClass(
  DonorPasswordGeneratedEventSchema,
) {}
