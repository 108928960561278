import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const FinishSamplingSessionRequestDtoSchema = z.object({
  sessionId: z.string().uuid(),
  notes: z.string().optional(),
});

export type FinishSamplingSessionRequestDtoType = z.infer<
  typeof FinishSamplingSessionRequestDtoSchema
>;
export class FinishSamplingSessionRequestDto extends createSchemaClass(
  FinishSamplingSessionRequestDtoSchema,
) {}
