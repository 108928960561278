import { z } from 'zod';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { ReferenceSchema } from './reference.interface';

/**
 * Schema for {@link ProcedureStatus}
 */
export const ProcedureStatusSchema = z.enum([
  'preparation',
  'in-progress',
  'not-done',
  'on-hold',
  'stopped',
  'completed',
  'entered-in-error',
  'unknown',
]);

/**
 * Described the state of a {@link Procedure}.
 */
export type ProcedureStatus = z.infer<typeof ProcedureStatusSchema>;

export const ProcedureSchema = z.object({
  /**
   * Unique resource ID.
   */
  id: z.string(),

  /**
   * {@link ServiceRequestRequisition} for this procedure to take place.
   */
  basedOn: ReferenceSchema(z.enum(['ServiceRequestRequisition'])),

  /**
   * Current state of the procedure.
   */
  status: ProcedureStatusSchema,

  /**
   * Type of procedure.
   */
  code: CodeableConceptSchema,

  /**
   * {@link Encounter} during which the procedure was created or performed.
   */
  encounter: ReferenceSchema(z.enum(['Encounter'])).optional(),
});

/**
 * An action that is performed on or for a patient.
 */
export type Procedure = z.infer<typeof ProcedureSchema>;
