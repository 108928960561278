import { z } from 'zod';
import { BarcodeSystemURI } from '../constants';

export const BarcodeSchema = z.object({
  /**
   * System in which the barcode should be unique.
   */
  system: z.nativeEnum(BarcodeSystemURI),

  /**
   * String representation of the barcode.
   */
  value: z.string(),
});

/**
 * Barcode label to be put on a container or device.
 */
export type Barcode = z.infer<typeof BarcodeSchema>;
