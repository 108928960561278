import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const UpdateUserResponseDtoSchema = z.object({
  id: z.string().trim().uuid(),
  email: z.string().trim().email().optional(),
  name: nonEmptyString
    .max(80, {
      message: 'User name should be less than 80 characters.',
    })
    .optional(),
  doesEmailAlreadyExist: z.boolean().optional(),
});

export type UpdateUserResponseDto = z.infer<typeof UpdateUserResponseDtoSchema>;
