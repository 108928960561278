import { z } from 'zod';
import { DocumentSchema } from '.';

export const GetAllPublishedDocumentsResponseDtoSchema = z.object({
  documents: DocumentSchema.array(),
});

export type GetAllPublishedDocumentsResponseDto = z.infer<
  typeof GetAllPublishedDocumentsResponseDtoSchema
>;
