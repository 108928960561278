import { z } from 'zod';
import { BarcodeSystemURI, IdentifierSystem } from '../constants';
import { CodeableConceptSchema } from './codeable-concept.interface';

export const IdentifierSchema = z.object({
  /**
   * System in which this identifier should be unique.
   */
  system: z
    .union([z.nativeEnum(IdentifierSystem), z.nativeEnum(BarcodeSystemURI)])
    .optional(),
  /**
   * The value that is unique within the defined {@link system}.
   */
  value: z.string(),
  /**
   * A coded type for the identifier that can be used to determine which
   * identifier to use for a specific purpose.
   */
  type: CodeableConceptSchema.optional(),
});

/**
 * Identifier associated with a single object or entity within a given system.
 */
export type Identifier = z.infer<typeof IdentifierSchema>;
