<template>
  <Dialog
    v-model:visible="model"
    :draggable="false"
    :pt="{
      footer: 'flex-col',
      mask: 'backdrop-blur-sm',
    }"
  >
    <template #header />
    <SignaturePadBox v-model="signaturePad" />
    <template #footer>
      <div class="flex justify-between">
        <CareosSecondaryButton
          :label="t('summary.try_again')"
          @click="tryAgain"
        />
        <CareosButton
          :label="t('summary.sign')"
          data-test-id="signature-box-sign"
          @click="updateSignature"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import Dialog from 'primevue/dialog';
import SignaturePad from 'signature_pad';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SignaturePadBox from './SignaturePadBox.vue';

const { t } = useI18n();

const emit = defineEmits<{
  updateSignature: [signature: string];
}>();
const signaturePad = ref<SignaturePad>();

const updateSignature = () => {
  if (!signaturePad.value || signaturePad.value?.isEmpty()) return;
  const signatureData = signaturePad.value.toDataURL().split(',').at(1);
  emit('updateSignature', `data:image/png;base64,${signatureData}`);
  model.value = false;
};

const tryAgain = () => {
  if (!signaturePad.value) return;
  signaturePad.value.clear();
};

const model = defineModel<boolean>({ required: true });
</script>
