import { OrganizationTypeSchema } from '@careos/organization-api-types/src/organization/organization-type';
import { z } from 'zod';
import { ResultCodeSchema } from '../../../common/types/result-code.schema';
import { SpecimenDeviationTypeSchema } from '@abc-labs-ab/ts-events/src/fhir-entities/deviation.interface';
import { TestTypeSchema } from '@careos/organization-api-types';
import { ExtraResultSchema } from '../../../common/types/extra-result.schema';

export const ResultRowSchema = z.object({
  requisitionId: z.string(),
  barcode: z.string(),
  patientIdentifier: z.string(),
  customer: z.string(),
  employer: z.string().nullable(),
  subDivision: z.string().nullable(),
  treatmentCenter: z.string().nullable(),
  samplingDate: z.string(),
  resultReceived: z.string().nullable(),
  acknowledged: z.boolean(),
  organizationType: OrganizationTypeSchema.nullable(),
  result: ResultCodeSchema,
  deviations: z.array(SpecimenDeviationTypeSchema),
  extraResults: z.array(ExtraResultSchema),
  collector: z.string().nullable(),
  testType: TestTypeSchema,
  hasRequisitionPdf: z.boolean(),
  isResultFileGenerated: z.boolean(),
});

export type ResultRowDto = z.infer<typeof ResultRowSchema>;
