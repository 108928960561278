<template>
  <footer data-test-id="footer">
    <div class="flex h-10 items-center justify-between border-t bg-white px-6">
      <div class="text-xs">
        {{ `${t('footer.appVersion')}: ` }} {{ appVersion }}
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ToxiHttpClient } from '@/api/toxicology-client';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const appVersion = ref<string>();

onMounted(async () => {
  const res = await ToxiHttpClient.get<string>(`/version`);
  appVersion.value = `DOA_UI_${res.data}`;
});
</script>
