<script setup lang="ts">
import { ref } from 'vue';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';
import DoaResultButton from './DoaResultButton.vue';
import PdfEmbed from '@/components/PdfEmbed.vue';

defineProps<{
  pdfContent: string;
  pdfFileName: string;
}>();

const emit = defineEmits<{
  'update:visible': [];
}>();

const { t } = useI18n();
const pdfEmbed = ref<InstanceType<typeof PdfEmbed>>();

const didPdfFailToLoad = ref(false);
</script>

<template>
  <Dialog
    modal
    :header="t('table.result_report')"
    :pt="{
      root: 'w-[100%] max-w-[1000px]',
      header: 'h-16',
      icons: '!self-center',
    }"
    @update:visible="emit('update:visible')"
  >
    <DoaResultButton
      v-if="!didPdfFailToLoad && pdfFileName"
      :label="t('general.download')"
      data-test-id="download-result-button"
      type="button"
      class="mb-4 ml-auto h-8"
      action-type="download"
      @click.capture="pdfEmbed?.download(pdfFileName)"
    />
    <PdfEmbed
      :key="pdfContent"
      ref="pdfEmbed"
      :pdf-content="pdfContent"
      :pdf-file-name="pdfFileName"
    />
  </Dialog>
</template>
