<template>
  <div
    v-if="visible"
    class="fixed bottom-0 left-0 right-0 top-0 z-[1] flex items-center justify-center bg-black/50"
  >
    <div
      class="absolute flex max-w-lg flex-col rounded-md bg-white p-8 shadow-md"
    >
      <button class="ml-auto" @click="$emit('close')">
        <PrimeIcon icon="TIMES" class="text-careos-purple" />
      </button>
      <slot />
      <div class="w-full">
        <CareosButton
          :disabled="isLoading"
          :loading="isLoading"
          data-test-id="submit-button"
          :label="t('summary.submit')"
          class="w-full"
          @click="
            $emit('accept');
            $emit('close');
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PrimeIcon } from 'careos-vue-components';
import { useI18n } from 'vue-i18n';
import CareosButton from './CareosButton.vue';

const { t } = useI18n();

defineProps<{ visible: boolean; isLoading?: boolean }>();
defineEmits(['close', 'accept', 'decline']);
</script>
