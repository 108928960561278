import { z } from 'zod';

export const ContactPointSystemSchema = z.enum([
  'phone',
  'fax',
  'email',
  'pager',
  'url',
  'sms',
  'other',
]);

/**
 * @description Details of a Technology mediated contact point (phone, fax, email, etc.)
 * @property phone:	The value is a telephone number used for voice calls. Use of full international numbers starting with + is recommended to enable automatic dialing support but not required.
 * @property fax: The value is a fax machine. Use of full international numbers starting with + is recommended to enable automatic dialing support but not required.
 * @property email: The value is an email address.
 * @property pager: The value is a pager number. These may be local pager numbers that are only usable on a particular pager system.
 * @property url: A contact that is not a phone, fax, pager or email address and is expressed as a URL. This is intended for various institutional or personal contacts including web sites, blogs, Skype, Twitter, Facebook, etc. Do not use for email addresses.
 * @property sms: A contact that can be used for sending an sms message (e.g. mobile phones, some landlines).
 * @property other: A contact that is not a phone, fax, page or email address and is not expressible as a URL. E.g. Internal mail address. This SHOULD NOT be used for contacts that are expressible as a URL (e.g. Skype, Twitter, Facebook, etc.) Extensions may be used to distinguish "other" contact types.
 **/
export type ContactPointSystem = z.infer<typeof ContactPointSystemSchema>;

export const ContactPointSchema = z.object({
  /**
   * Communication form.
   */
  system: ContactPointSystemSchema,

  /**
   * Actual contact point details.
   */
  value: z.string(),
});

/**
 * A contact detail for the individual
 */
export type ContactPoint = z.infer<typeof ContactPointSchema>;
