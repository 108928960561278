import { z } from 'zod';
import { BarcodeSchema, IsoDateTimeSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenNoteRegisteredEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenNoteRegisteredEvent'),
  eventData: z.object({
    //When the note was added by user.
    registeredAt: IsoDateTimeSchema,

    // barcode of the Specimen that was noted.
    barcode: BarcodeSchema,

    // SpecimenId, identifier for this unique specimen.
    specimenId: z.string(),

    //message, the body of message of the added note.
    message: z.string(),

    //username of the practitioner who registered the note
    username: z.string(),
  }),
});

// A note has been added for a specimen.
export class SpecimenNoteRegisteredEvent extends createSchemaClass(
  SpecimenNoteRegisteredEventSchema,
) {}
