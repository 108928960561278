import { z } from 'zod';
import { AddressSchema } from './address.interface';
import { ContactPointSchema } from './contact-point.interface';
import { HumanNameSchema } from './human-name.interface';
import { IdentifierSchema } from './identifier.interface';
import { IsoDateSchema } from './iso-date.type';

export const PersonSchema = z.object({
  /**
   * Identifiers for this person
   */
  identifiers: IdentifierSchema.array(),
  /**
   * A name associated with the person
   */
  name: HumanNameSchema,
  /**
   * Contact details for the person
   */
  telecom: ContactPointSchema.array(),
  /**
   * The date on which the person was born
   */
  birthDate: IsoDateSchema.optional(),
  /**
   * One or more addresses for the person
   */
  addresses: AddressSchema.array().optional(),
});

/**
 * Demographics and administrative information about a person independent of a specific health-related context.
 */
export type Person = z.infer<typeof PersonSchema>;
