import { ToxicologyActivityIdentifierSchema } from '@careos/identifiers';
import { z } from 'zod';

export const GetOrganizationEnabledActivityDefinitionsResponseDtoSchema =
  z.object({
    activityDefinitions: z.array(ToxicologyActivityIdentifierSchema),
  });

export type GetOrganizationEnabledActivityDefinitionsResponseDto = z.infer<
  typeof GetOrganizationEnabledActivityDefinitionsResponseDtoSchema
>;
