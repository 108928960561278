import { z } from 'zod';
import {
  AnnotationSchema,
  BarcodeSchema,
  IsoDateSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenHiddenEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenHiddenEvent'),
  eventData: z.object({
    /**
     * Time when the specimen was hidden.
     */
    date: IsoDateSchema,

    /**
     * SpecimenId, identifier for this unique specimen. Optional because of expected specimens.
     */
    specimenId: z.string().optional(),

    /**
     * {@link Barcode} of the Specimen that was registered.
     */
    barcode: BarcodeSchema,

    /**
     * note representing the reason for hiding specimen.
     */
    reason: AnnotationSchema.optional(),

    /**
     * {@link Practitioner} who hid the specimen.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});
/**
 *  Represents when a user hide a specimen from the UI.
 */
export class SpecimenHiddenEvent extends createSchemaClass(
  SpecimenHiddenEventSchema,
) {}
