import { z } from 'zod';
/**
 * Schema for {@link DataAbsentReason}
 */
export const DataAbsentReasonSchema = z.enum([
  'unknown',
  'masked',
  'error',
  'not-performed',
  'unsupported',
]);
export type DataAbsentReason = z.infer<typeof DataAbsentReasonSchema>;
