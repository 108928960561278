import {
  SamplingKitIdentifierSchema,
  ToxicologyActivityIdentifierSchema,
} from '@careos/identifiers';
import {
  OrderTypeSchema,
  OrganizationTypeSchema,
  TestTypeSchema,
} from '@careos/organization-api-types';
import { z } from 'zod';
import { PanelSchema as PanelBaseSchema } from '../../../common';
import { PatientSchema } from '../../../common/types/patient.schema';
import { SignatureDataSchema } from '../../../common/types/signature-data';

const PatientDetailsSchema = PatientSchema.extend({
  firstName: z.string(),
  lastName: z.string(),
  personalIdentifier: z.string(),
  phoneNumber: z.string(),
  dateOfBirth: z.string().nullable(),
});

const CollectorSchema = z.object({
  name: z.string(),
  email: z.string().nullable(),
});

const AttesterSchema = z.object({
  name: z.string(),
  personalIdentifier: z.string(),
});

const TreatmentCenterSchema = z.object({
  display: z.string().nullable(),
  orgId: z.string(),
});

const EmployerSchema = z.object({
  display: z.string().nullable(),
  orgId: z.string().nullable(),
  subdivision: z.string().nullable(),
  subcontractor: z.string().nullable(),
});

const PanelSchema = PanelBaseSchema.extend({
  isChiralOrdered: z.boolean(),
});

export const RequisitionDetailSchema = z.object({
  requisitionId: z.string(),
  transactionId: z.string().nullish(),
  barcode: z.string(),
  patient: PatientDetailsSchema,
  requesterIdentifier: z.string(),
  collector: CollectorSchema,
  specimenType: ToxicologyActivityIdentifierSchema,
  panel: PanelSchema,
  organizationType: OrganizationTypeSchema,
  employer: z.optional(EmployerSchema),
  treatmentCenter: z.optional(TreatmentCenterSchema),
  collectorComment: z.string(),
  sampleCollectedAt: z.date(),
  reasonForTesting: z.string(),
  attester: z.optional(AttesterSchema),
  signature: SignatureDataSchema.nullish(),
  donorPassword: z.string().optional(), //: INFO property gets appended to type after zod-validation represents suffix from reqId.
  careProvider: z.string().optional(), //: INFO property gets appended to type after zod-validation.
  testType: TestTypeSchema,
  orderType: OrderTypeSchema,
  samplingKit: SamplingKitIdentifierSchema.nullish(),
  isDonorPasswordGenerated: z.boolean(),
});

export type RequisitionDetailDto = z.infer<typeof RequisitionDetailSchema>;
