import { z } from 'zod';
import { TestTypeSchema } from '../../../test-type';
import { SamplingKitIdentifierSchema } from '@careos/identifiers/src/sampling-kit-identifiers';
import { ToxicologyActivityIdentifierSchema } from '@careos/identifiers/src/activity-definition-identifiers';

export const SampleTypeAndEnabledKitsSchema = z.object({
  sampleType: ToxicologyActivityIdentifierSchema,
  samplingKits: z.array(SamplingKitIdentifierSchema),
});
export const TestInfoSchema = z.object({
  testType: TestTypeSchema,
  sampleTypeAndEnabledKits: z.array(SampleTypeAndEnabledKitsSchema),
});

export const GetEmployersAndEnabledTestInfoResponseDtoSchema = z.object({
  employers: z
    .object({
      key: z.string(),
      name: z.string(),
    })
    .array(),
  testInfo: TestInfoSchema.array(),
});

export type GetEmployersAndEnabledTestInfoResponseDto = z.infer<
  typeof GetEmployersAndEnabledTestInfoResponseDtoSchema
>;
