import { z } from 'zod';
import { OrderTypeSchema } from '../../../test-type';

export const GetOrderTypeByOrganizationAndTestTypeResponseDtoSchema = z.object({
  orderType: OrderTypeSchema,
});

export type GetOrderTypeByOrganizationAndTestTypeResponseDto = z.infer<
  typeof GetOrderTypeByOrganizationAndTestTypeResponseDtoSchema
>;
