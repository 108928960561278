<template>
  <div class="flex h-full flex-col items-center justify-center">
    <SvgIcon name="abclabs" class="m-6 h-36 rounded-md p-4" />

    <div v-if="!isLoading" class="text-4xl">{{ t('general.welcome') }}</div>
    <div v-if="!isAuthenticated && !isLoading">
      <CareosButton label="Login" class="m-6 px-16 py-3" @click="login" />
    </div>
    <div v-else-if="!isAuthenticated && isLoading" class="text-2xl">
      {{ t('general.signing_you_in') }}
      <PrimeIcon icon="SPINNER" class="animate-spin" size="LG" />
    </div>
    <div v-else-if="authStore.permissions.length" class="w-80">
      <CareosButton
        v-can:display="['write:requisitions', 'write:tc-requisitions']"
        :label="t('general.create_requisition')"
        class="m-6"
        @click="createRequisition"
      />
      <CareosButton
        v-can:display="['read:results', 'read:tc-results']"
        :label="t('general.view_results')"
        class="m-6 px-16 py-3"
        @click="viewResults"
      />
      <CareosButton
        v-can:display="['read:results', 'read:tc-results']"
        :label="t('general.view_requisitions')"
        class="m-6 px-16 py-3"
        @click="viewRequisitions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CareosButton from '@/components/CareosButton.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { useAuthStore } from '@/stores/auth';
import { useAuth0 } from '@auth0/auth0-vue';
import { PrimeIcon } from 'careos-vue-components';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();
const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
const authStore = useAuthStore();

const login = async () => {
  await loginWithRedirect();
};

const createRequisition = () => {
  router.push('/order/requisition-type');
};

const viewResults = () => {
  router.push('/results');
};
const viewRequisitions = () => {
  router.push('/requisitions');
};
</script>
