import { z } from 'zod';

export const HumanNameSchema = z.object({
  /**
   * Text representation of the full name.
   */
  text: z.string().optional(),

  /**
   * Family name, or surname.
   */
  family: z.string(),

  /**
   * Given names, in correct order for presenting the name.
   */
  given: z.string().array(),
});

/**
 * Full name of an individual.
 */
export type HumanName = z.infer<typeof HumanNameSchema>;
