import { z } from 'zod';
import {
  BarcodeSchema,
  DeviationSchema,
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenDeviatedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenDeviatedEvent'),
  eventData: z.object({
    /**
     * The {@link Barcode} of the Specimen the deviation originated from.
     */
    barcode: BarcodeSchema,
    /**
     * An object holding information of the deviations that occurred.
     */
    deviations: DeviationSchema.array(),
    /**
     * {@link Practitioner} who performed the event preceding the deviation.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')).optional(),
    /**
     * When the deviation(s) was/were registered.
     */
    registeredAt: IsoDateTimeSchema,
    /**
     *  Reference to the {@link Specimen} that deviated.
     */
    specimen: ReferenceSchema(z.literal('Specimen')).optional(),
    /**
     * {@link IdentifierSchema} for system that audited the deviation(s).
     */
    systemOrigin: IdentifierSchema,
  }),
});

/**
 * A {@link Specimen} has deviated in any way.
 */
export class SpecimenDeviatedEvent extends createSchemaClass(
  SpecimenDeviatedEventSchema,
) {}
