import { z } from 'zod';
import { BarcodeSchema, IdentifierSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils/create-schema-class';
import { BaseEventSchema } from './base-event';

export const RequisitionFilePublishedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RequisitionFilePublishedEvent'),
  eventData: z.object({
    /**
     * Application that triggers the event.
     */
    originSystem: IdentifierSchema,
    /**
     * Reference to the system which will perform the requisition.
     */
    targetSystem: IdentifierSchema,
    /**
     * CareOS requisition that was published.
     */
    requisition: IdentifierSchema,
    /**
     * Specimen that requisition concerns.
     */
    specimenId: z.string().optional(),
    /**
     * Barcode that requisition concerns.
     */
    barcode: BarcodeSchema,
    /**
     * The URL pointing to the file.
     */
    filePath: z.string(),
  }),
});

/**
 * A requisition file has been sent to an external system/organization.
 */
export class RequisitionFilePublishedEvent extends createSchemaClass(
  RequisitionFilePublishedEventSchema,
) {}
