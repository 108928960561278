import { z } from 'zod';

export const AccreditationIdentifierSchema = z.enum(['SWEDAC']);
export type AccreditationIdentifier = z.infer<
  typeof AccreditationIdentifierSchema
>;
export const AccreditationIdentifier = AccreditationIdentifierSchema.Enum;

export const isAccreditationIdentifier = (
  value: unknown,
): value is AccreditationIdentifier =>
  AccreditationIdentifierSchema.safeParse(value).success;
