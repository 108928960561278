import { z } from 'zod';
import { AnnotationSchema, IdentifierSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const RequisitionConfirmedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RequisitionConfirmedEvent'),
  eventData: z.object({
    /**
     * Reference to the requisition confirmed
     */
    requisition: IdentifierSchema,

    /**
     * The text used in the confirmation. This is kept for legal purposes.
     */
    confirmationText: AnnotationSchema.array(),

    /**
     * A reference to the one who confirmed the requisition
     */
    practitioner: IdentifierSchema,
  }),
});

/**
 * This event represents the confirmation of a requisition from a collectors perspective
 */
export class RequisitionConfirmedEvent extends createSchemaClass(
  RequisitionConfirmedEventSchema,
) {}
