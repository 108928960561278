import { ref } from 'vue';
import { router } from '@/router';
import { SessionActions } from '@/views/sessions-view/constants/session-actions';
import { ToxiHttpClient } from '@/api/toxicology-client';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export const useSessionActions = () => {
  const displayRandomizerModal = ref(false);
  const displayCloseSessionModal = ref(false);
  const closingSessionId = ref('');
  const toast = useToast();
  const { t } = useI18n();

  const handleSessionAction = async (
    action: SessionActions,
    sessionId: string,
  ) => {
    if (action === 'randomize') {
      openRandomizerModal();
    } else if (action === 'startSession') {
      startSession(sessionId);
      goToOrderRoute(sessionId);
    } else {
      openSessionClosedModal(sessionId);
    }
  };

  const openRandomizerModal = () => {
    displayRandomizerModal.value = true;
  };

  const startSession = async (sessionId: string) => {
    try {
      await ToxiHttpClient.post('/sampling-session/start-sampling-session', {
        sessionId,
      });
      toast.add({
        severity: 'success',
        summary: t('sessions.table.actions_tooltip.session_started_toast_msg'),
        life: 5000,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.add({
          severity: 'error',
          summary: `${t('general.error_occurred')}. ${t(
            'general.error_is_reported_message',
          )}`,
          life: 5000,
        });
      }
    }
  };

  const goToOrderRoute = async (sessionId: string) => {
    await router.push({
      path: '/collector-order/session-information',
      query: { sessionId },
    });
  };

  const openSessionClosedModal = (sessionId: string) => {
    displayCloseSessionModal.value = true;
    closingSessionId.value = sessionId;
  };

  return {
    handleSessionAction,
    displayRandomizerModal,
    displayCloseSessionModal,
    closingSessionId,
  };
};
