import { z } from 'zod';
import {
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ParcelUnpackedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ParcelUnpackedEvent'),
  eventData: z.object({
    /**
     * Unique id for the parcel
     */
    parcelId: z.string(),
    /**
     * Identifiers for the parcel, which can contain ids for various systems. E.g.: A short human readable parcel id.
     */
    identifiers: z.array(IdentifierSchema),
    /**
     * The arrival time of the box to lab (manual input)
     */
    arrivalTime: IsoDateTimeSchema,
    /**
     * The client the box came from (manual input)
     */
    client: z.string(),
    /**
     * {@link Practitioner} whom registered the parcel.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * A transport box has been opened in the lab, will typically be followed by {@link SpecimenRegisteredInLabEvent}s
 */
export class ParcelUnpackedEvent extends createSchemaClass(
  ParcelUnpackedEventSchema,
) {}
