import { ref } from 'vue';
import { FilterMatchMode } from 'primevue/api';

const initialFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  samplingDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
  sessionId: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export function useSessionFilters() {
  const filters = ref(initialFilters);

  function resetFilters() {
    filters.value = initialFilters;
  }

  return {
    filters,
    resetFilters,
  };
}
