import { z } from 'zod';
import { BarcodeSchema, createSchemaClass } from '@abc-labs-ab/ts-events';

export const UpdateResultRemarkRequestDtoSchema = z.object({
  barcode: BarcodeSchema,
  requisitionId: z.string().max(36),
  remark: z.object({
    body: z.string().max(500),
    id: z.string().uuid(),
  }),
});

export class UpdateResultRemarkRequestDto extends createSchemaClass(
  UpdateResultRemarkRequestDtoSchema,
) {}
