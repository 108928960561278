export const formKitCareos = {
  // the global key will apply to all inputs
  global: {
    input: `h-full
    w-full
    text-gray-700
    placeholder-gray-400
    px-3 rounded-[10px]
    focus:border-careos-lavendel`,
    inner: 'focus-within:border-careos-lavendel',
    outer: 'mb-5 formkit-disabled:opacity-50',
    label: 'text-left block mb-1 font-light text-sm',
  },
  textarea: {
    input: 'border rounded  border-gray-400 p-2',
  },
  mask: {
    input: `h-full
    w-full
    text-gray-700
    placeholder-gray-400
    px-3 rounded-[10px]`,
    message: 'text-red-500 mb-1 text-sm text-left',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
  },
  dropdown: {
    message: 'text-red-500 mb-1 text-sm text-left',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
  },
  phoneNumberInput: {
    inner: `
    rounded
    h-[38px]
    mb-1
    `,
    input: `h-full
    w-full
    text-gray-700
    placeholder-gray-400
    px-3 rounded-[10px]`,
    label: 'text-left block mb-1 font-light text-sm',
    outer: 'mb-5 formkit-disabled:opacity-50',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
    message: 'text-red-500 mb-1 text-sm text-left',
  },
  date: {
    inner: `pr-4
    rounded
    h-[38px]
    border-gray-400
    border
    mb-1
    overflow-hidden`,
    input: `h-full
    w-full
    text-gray-700
    placeholder-gray-400
    px-3 rounded-[10px]`,
    label: 'text-left block mb-1 font-light text-sm',
    outer: 'mb-5 formkit-disabled:opacity-50',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
    message: 'text-red-500 mb-1 text-sm text-left',
  },
  checkbox: {
    wrapper: `flex items-center m-1 h-[30px]`,
    inner: `h-full`,
    input: `scale-200 !w-auto`,
    label: `ml-5 text-normal`,
    legend: 'text-left block mb-1 font-light text-sm',
    messages: 'list-none p-0 mt-1 mb-0',
    message: 'text-red-500 mb-1 text-sm text-left',
    outer: 'mb-7',
  },
  radio: {
    label: 'text-left block mb-1 font-light text-sm',
    legend: 'text-left block mb-1 font-light text-sm',

    wrapper: `flex items-center m-1`,
    input: 'scale-125 mr-4',
  },
  button: {
    input: `
      bg-careos-purple
      hover:bg-careos-lavendel
      active:bg-careos-primary
      active:text-neutral-600
      text-white
      text-s
      font-medium
      px-10
      rounded-[80px]
      focus:border-blue-500
      `,
  },
  text: {
    inner: `
    rounded
    h-[38px]
    border-gray-400
    border
    mb-1
    overflow-hidden`,
    input: `h-full
    w-full
    text-gray-700
    placeholder-gray-400
    px-3 `,
    label: 'text-left block mb-1 font-light text-sm',
    outer: 'mb-5 formkit-disabled:opacity-50',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
    message: 'text-red-500 mb-1 text-sm text-left',
  },

  submit: {
    wrapper: 'mb-1',
    input: `
      bg-careos-purple
      hover:bg-careos-lavendel
      active:bg-careos-purple
      active:text-careos-lavendel
      text-white
      text-s
      font-medium
      h-[32px]
      rounded-[80px]
      w-fit
      px-10
      h-full
      w-full
      placeholder-gray-400
      focus:border-blue-500
      `,
    label: 'text-left block mb-1 font-light text-sm',
    outer: 'mb-5 formkit-disabled:opacity-50',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
    message: 'text-red-500 mb-1 text-sm text-left',
  },
};
