import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const UpdateUserNotificationResponseDtoSchema = z.object({
  userOrganizationRoleIdUpdated: nonEmptyString.uuid(),
});

export type UpdateUserNotificationResponseDto = z.infer<
  typeof UpdateUserNotificationResponseDtoSchema
>;
