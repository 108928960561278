<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed';
import { useElementSize } from '@vueuse/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
// essential styles
import 'vue-pdf-embed/dist/style/index.css';
// optional styles
import 'vue-pdf-embed/dist/style/annotationLayer.css';
import 'vue-pdf-embed/dist/style/textLayer.css';
import { captureException, captureMessage } from '@sentry/vue';

export type PdfStatus = 'loading' | 'loaded' | 'rendered' | 'error';

type PublicMethods = {
  download: (fileName: string) => void;
};

const emit = defineEmits<{
  onRendered: [];
  onLoaded: [];
  onUpdateStatus: [status: PdfStatus];
  onFailed: [];
}>();

defineProps<{
  pdfContent: string;
}>();

const containerRef = ref<HTMLDivElement | null>(null);
const pdfRef = ref<PublicMethods>();
const pdfStatus = ref<PdfStatus>('loading');
const { t } = useI18n();
const { width } = useElementSize(containerRef);

const handleRendered = () => {
  pdfStatus.value = 'rendered';
  emit('onRendered');
  emit('onUpdateStatus', pdfStatus.value);
};

const handleLoaded = () => {
  pdfStatus.value = 'loaded';
  emit('onLoaded');
  emit('onUpdateStatus', pdfStatus.value);
};

const logErrorToSentry = (error: Error) => {
  captureException(error);
  captureMessage('PDF could not be rendered', 'error');
};

const handleError = (error: Error) => {
  pdfStatus.value = 'error';
  logErrorToSentry(error);
  emit('onFailed');
};

const download = (fileName: string) => pdfRef.value?.download(fileName);

defineExpose({
  download,
});

emit('onUpdateStatus', pdfStatus.value);
</script>

<template>
  <div ref="containerRef" class="w-full">
    <VuePdfEmbed
      v-if="pdfStatus !== 'error'"
      ref="pdfRef"
      :source="pdfContent"
      :width="width"
      class="space-y-2"
      text-layer
      @rendered="handleRendered"
      @loaded="handleLoaded"
      @rendering-failed="handleError"
      @loading-failed="handleError"
    />
    <div v-else class="rounded bg-red-200 p-2 text-red-700">
      {{ t('results.pdf_could_not_be_rendered') }}
    </div>
  </div>
</template>

<style>
.vue-pdf-embed > div {
  @apply [box-shadow:0_2px_8px_4px_rgba(0,0,0,0.1)];
}
</style>
