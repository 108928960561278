import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const CheckUserForEditingRequestDtoSchema = z.object({
  id: nonEmptyString,
});

export type CheckUserForEditingRequestDto = z.infer<
  typeof CheckUserForEditingRequestDtoSchema
>;
