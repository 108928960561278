import { z } from 'zod';
import { KeyNameSchema } from '@careos/types';

export const GetEmployerResponseDtoSchema = KeyNameSchema.extend({
  organization: z.object({
    name: z.string(),
  }),
});

export type GetEmployerResponseDto = z.infer<
  typeof GetEmployerResponseDtoSchema
>;
