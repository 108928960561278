<script setup lang="ts">
import { useQRCode } from '@vueuse/integrations/useQRCode';

const props = defineProps<{
  qrText: string;
}>();

const qrCodeOptions = {
  width: 200,
  margin: 3,
  errorCorrectionLevel: 'L',
};

const qrCodeImage = useQRCode(() => props.qrText, qrCodeOptions);
</script>

<template>
  <img :src="qrCodeImage" alt="" />
</template>
