import { z } from 'zod';
import {
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ParcelDeregisterReasonTypeSchema = z.enum([
  'ACCIDENTAL_REGISTRATION',
  'RESCAN',
  'OTHER',
]);

export type ParcelDeregisterReasonType = z.infer<
  typeof ParcelDeregisterReasonTypeSchema
>;

export const ParcelDeregisterReasonTypeOptions =
  ParcelDeregisterReasonTypeSchema.options;

export const ParcelDeregisterReasonType = ParcelDeregisterReasonTypeSchema.enum;

export const DeregisterParcelEventSchema = BaseEventSchema.extend({
  eventType: z.literal('DeregisterParcelEvent'),
  eventData: z.object({
    /**
     * Id of deregistered parcel.
     */
    parcelId: z.string(),
    /**
     * Identifiers for the parcel to be deregistered, which can contain ids for various systems. E.g.: A short human readable {@link parcelId}.
     */
    identifiers: z.array(IdentifierSchema),
    /**
     * When the parcel was deregistered by the lab.
     */
    deregisteredAt: IsoDateTimeSchema,
    /**
     * {@link Practitioner} whom deregistered the parcel.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * Reason of de-registration of parcel, provided by lab
     */
    reason: z.string(),
  }),
});

/**
 * A parcel has been deregistered by a lab.
 */
export class DeregisterParcelEvent extends createSchemaClass(
  DeregisterParcelEventSchema,
) {}
