import { z } from 'zod';
import { BarcodeSchema, ReferenceSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const BarcodesPrintJobRequestSentEventSchema = BaseEventSchema.extend({
  eventType: z.literal('BarcodesPrintJobRequestSentEvent'),
  eventData: z.object({
    /**
     * Barcodes which were requested to be printed
     */
    barcodes: z.array(BarcodeSchema),
    /**
     * Information about the device or organization which printed the barcodes
     */
    printer: ReferenceSchema(z.enum(['Device'])), // we can add 'organization' later if we want to
  }),
});

/**
 * Represents when print job is requested by CareOS.
 */
export class BarcodesPrintJobRequestSentEvent extends createSchemaClass(
  BarcodesPrintJobRequestSentEventSchema,
) {}
