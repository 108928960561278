import { z } from 'zod';

export const NoMedicalAssessmentStatusSchema = z.enum([
  'ordered',
  'no-medical-assessment-required',
]);

export const MedicallyAssessmentStatusSchema = z.enum([
  'awaits-medical-assessment',
  'pending-medical-assessment',
  'medically-assessed',
  'medically-rejected',
]);

export const AssessmentStatusSchema = z.union([
  NoMedicalAssessmentStatusSchema,
  MedicallyAssessmentStatusSchema,
]);

export type MedicallyAssessmentStatus = z.infer<
  typeof MedicallyAssessmentStatusSchema
>;
export const MedicallyAssessmentStatus = MedicallyAssessmentStatusSchema.Enum;

export type AssessmentStatus = z.infer<typeof AssessmentStatusSchema>;
