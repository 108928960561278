import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import { createSchemaClass } from '../utils';
import {
  AnnotationSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';

export const SamplingSessionFinishedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SamplingSessionFinishedEvent'),
  eventData: z.object({
    /**
     * Unique identifier for the session being finished.
     */
    sessionId: z.string().uuid(),
    /**
     * When the session was finished.
     */
    finishedAt: IsoDateTimeSchema,
    /**
     * Practitioner who finished the session.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * Any other notes or comment made about the finishing of the session.
     */
    notes: AnnotationSchema.optional(),
  }),
});

/**
 * A sampling session has been finished.
 */
export class SamplingSessionFinishedEvent extends createSchemaClass(
  SamplingSessionFinishedEventSchema,
) {}
