import Personnummer from 'personnummer';

export const formatPersonalNumberIfValid = (value: string) => {
  if (!Personnummer.valid(value)) return value;

  const parsedPersonalNumber = Personnummer.parse(value);
  const longFormat = parsedPersonalNumber.format(true);

  const firstEight = longFormat.slice(0, 8);
  const lastFour = longFormat.slice(8);

  const longFormatWithSeparator = `${firstEight}-${lastFour}`;
  return longFormatWithSeparator;
};
