import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const LinkAccountRequestDtoSchema = z.object({
  primaryAccountId: nonEmptyString,
  secondaryAccountId: nonEmptyString,
});

export type LinkBankIdAccountRequestDto = z.infer<
  typeof LinkAccountRequestDtoSchema
>;
