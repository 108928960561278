<template>
  <div
    v-if="pdf && details"
    class="mx-auto flex max-w-5xl flex-col gap-4 bg-white md:my-4"
  >
    <PasswordComponent
      v-if="details.orderType === 'CoC'"
      :details="details"
      :requisition-id="requisitionId"
    />

    <DetailsSummary
      v-if="organizationDetailItems"
      :details-items="organizationDetailItems"
      size="wide"
    />
    <PdfEmbed :pdf-content="pdf" />
  </div>
</template>

<script setup lang="ts">
import { api } from '@/api/client';
import PdfEmbed from '@/components/PdfEmbed.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { CollectorDetailsResponseDto } from '@careos/toxicology-types';
import { useI18n } from 'vue-i18n';
import { PersonalDetailsItem } from '@/models/personal-details-item';
import DetailsSummary from '@/components/Requisition/DetailsSummary.vue';
import { useToast } from 'primevue/usetoast';
import PasswordComponent from './PasswordComponent.vue';

const { t } = useI18n();
const { params } = useRoute();
const toast = useToast();

const pdf = ref<string>();
const details = ref<CollectorDetailsResponseDto>();
const requisitionId = params.requisitionId as string;

const { exec: fetchRequisition } = useNetworkRequest(async () => {
  const detailsRes = await api.fetchCollectorDetails(requisitionId);
  details.value = detailsRes.data;

  const { data } = await api.fetchRequisitionPdf(requisitionId);

  if (!data) {
    toast.add({
      life: 3000,
      severity: 'error',
      summary: `${t('general.error_occurred')}. ${t(
        'general.error_is_reported_message',
      )}`,
    });
  }

  pdf.value = data;
});

const organizationDetailItems = computed(() => {
  if (!details.value) {
    return;
  }

  const summaryList: PersonalDetailsItem[] = [
    {
      title: t('created.organization'),
      value: details.value.careProvider,
    },
    {
      title: t('created.collector'),
      value: details.value.collectorName,
    },
    {
      title: t('created.collector_email'),
      value: details.value.collectorEmail,
    },
  ];

  summaryList.push();
  return summaryList;
});

fetchRequisition();
</script>
