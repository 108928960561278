import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import { useAuth0 } from '@auth0/auth0-vue';

export interface ErrorMessage {
  message: string | string[];
}

type Options = {
  customSummary?: string;
};

export const isErrorMessage = (value: unknown): value is ErrorMessage =>
  typeof value === 'object' && value !== null && 'message' in value;

 
export const useNetworkRequest = <T extends (...args: any) => Promise<any>>(
  func: T,
  { customSummary }: Options = {},
) => {
  const toast = useToast();
  const { user } = useAuth0();
  const { t } = useI18n();
  const isLoading = ref(false);
  const isError = ref(false);
  const error = ref();

  const networkFunc = async (
    ...args: Parameters<T>
  ): Promise<Awaited<ReturnType<T>> | undefined> => {
    isLoading.value = true;
    try {
      const res = await func(...(args as unknown as Iterable<unknown>));
      return res;
    } catch (e) {
      Sentry.captureException(e, { user: { id: user.value?.sub } });
      isError.value = true;
      error.value = e;

      let summary = customSummary;

      if (!navigator.onLine) {
        summary = t('general.offline_error');
      } else {
        summary =
          summary ??
          `${t('general.error_occurred')}. ${t(
            'general.error_is_reported_message',
          )}`;
      }

      toast.add({
        life: 10_000,
        severity: 'error',
        summary,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    isError,
    error,
    exec: networkFunc,
  };
};
