import { ToxiHttpClient } from '@/api/toxicology-client';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export type InputDevice = 'keyboard' | 'barcodeReader' | 'deviceCamera';
export type InputType = 'manual' | 'scan';

export const useBarcodeStore = defineStore('barcode', () => {
  const barcodeValue = ref('');
  const checkedBarcodeValue = ref<string | null>(null);
  const inputDevice = ref<InputDevice>('barcodeReader');

  const inputType = computed<InputType>(() =>
    inputDevice.value === 'keyboard' ? 'manual' : 'scan',
  );
  const isValidCareosBarcode = computed(
    () => barcodeValue.value === checkedBarcodeValue.value,
  );

  const $reset = () => {
    barcodeValue.value = '';
    checkedBarcodeValue.value = null;
  };

  const checkBarcode = async (barcode: string) => {
    const body = { barcode };
    await ToxiHttpClient.post(`/requisitions/check-barcode`, body);
    barcodeValue.value = barcode;
  };

  return {
    barcodeValue,
    inputDevice,
    inputType,
    checkBarcode,
    $reset,
    checkedBarcodeValue,
    isValidCareosBarcode,
  };
});
