import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization/organization-type';

export const RemoveUsersFromOrganizationRequestDtoSchema = z.object({
  userDbIds: z.array(z.string().trim().uuid()),
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
  userOrganizationRoleId: z.string().uuid().optional(),
});

export type RemoveUsersFromOrganizationRequestDto = z.infer<
  typeof RemoveUsersFromOrganizationRequestDtoSchema
>;
