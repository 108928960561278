<template>
  <CareosFormCheckbox
    :disabled="(context.disabled as boolean)"
    :model-value="context.value"
    :label="(context.innerLabel as string) || ''"
    @update:model-value="
      !context.disabled ? props.context.node.input($event.valueOf()) : null
    "
  />
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';

import CareosFormCheckbox from './CareosFormCheckbox.vue';

const props = defineProps<{ context: FormKitFrameworkContext }>();
</script>
