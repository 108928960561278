<template>
  <Teleport to="body">
    <div
      v-if="showModal"
      class="absolute top-0 z-50 grid h-full w-full place-content-center bg-black bg-opacity-50"
    >
      <div class="max-w-[800px] rounded-xl bg-white p-8 text-sm">
        <slot />
        <div class="mt-14 flex justify-between gap-4">
          <FormSubmitButton
            v-if="!hideDeclineButton"
            btn-type="outline"
            class="bg-white"
            :label="t('admin_board.submit_modal.decline')"
            @click="$emit('decline')"
          />
          <FormSubmitButton
            v-if="!hideAcceptButton"
            :label="t('admin_board.submit_modal.accept')"
            :loading="loading"
            @click="$emit('accept')"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import FormSubmitButton from '@/views/admin-view/components/FormSubmitButton.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  loading?: boolean;
  showModal: boolean;
  hideAcceptButton?: boolean;
  hideDeclineButton?: boolean;
}>();

defineEmits<{
  (e: 'accept'): void;
  (e: 'decline'): void;
}>();
</script>
