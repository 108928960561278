<template>
  <form class="mb-8 flex flex-row gap-8" @submit.prevent="createSubdivision">
    <span class="flex flex-col text-left">
      <label for="employer-name">Subdivision name</label>
      <InputText
        id="subdivison-name"
        v-model.trim="subdivisionName"
        class="h-10 min-w-[296px]"
        type="text"
      />
    </span>
    <FormSubmitButton
      :loading="isLoading"
      :disabled="isLoading"
      type="submit"
      label="Add subdivision"
      class="h-10 self-end"
    />
  </form>
  <Divider />
  <SubdivisionTable />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Divider from 'primevue/divider';
import { useOrganization } from '../../../stores/organization';
import InputText from 'primevue/inputtext';
import { useRoute } from 'vue-router';
import SubdivisionTable from './components/SubdivisionTable.vue';
import FormSubmitButton from '@/views/admin-view/components/FormSubmitButton.vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useNetworkRequest } from '@/composables/useNetworkRequest';

const store = useOrganization();
const route = useRoute();
const toast = useToast();
const { t } = useI18n();

const subdivisionName = ref('');

const submitForm = async () => {
  if (!subdivisionName.value.trim()) return;
  const dto = {
    name: subdivisionName.value,
    employerKey: route.params.employerKey as string,
    organizationKey: route.params.organizationKey as string,
  };
  await store.createSubdivision(dto);
  toast.add({
    life: 5_000,
    severity: 'success',
    summary: t('admin_board.add_subdiv_form.subdiv_added_message'),
  });
  subdivisionName.value = '';
};

const { exec: createSubdivision, isLoading } = useNetworkRequest(async () => {
  await submitForm();
});
</script>
