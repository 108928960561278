import { z } from 'zod';
import { IdentifierSchema } from './identifier.interface';
export const ReferenceSchema = <T extends ReferenceType>(type: z.ZodType<T>) =>
  z.object({
    /**
     * Literal reference, Relative, internal or absolute URL.
     */
    reference: z.string().optional(),

    /**
     * Entity type the reference refers to (e.g. "Patient").
     */
    type,

    /**
     * ID of the resource that is referenced.
     */
    identifier: IdentifierSchema,

    /**
     * Text alternative for the resource.
     */
    display: z.string().optional(),
  });

/**
 * A reference to any resource.
 */
export type Reference<T extends ReferenceType> = z.infer<
  ReturnType<typeof ReferenceSchema<T>>
>;

export const ReferenceTypeSchema = z.enum([
  'SpecimenDefinition',
  'ActivityDefinition',
  'ObservationDefinition',
  'Observation',
  'Organization',
  'ServiceRequest',
  'ServiceRequestRequisition',
  'Requisition',
  'Location',
  'Appointment',
  'Practitioner',
  'Patient',
  'Procedure',
  'Specimen',
  'PlanDefinition',
  'Encounter',
  'Device',
]);

export type ReferenceType = z.infer<typeof ReferenceTypeSchema>;
