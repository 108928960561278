import {
  EmployerSchema,
  OrganizationSchema,
  SubdivisionSchema,
} from '../../../organization';

import { z } from 'zod';

export const GetSubdivisionsResponseDtoSchema = z.object({
  organizationName: OrganizationSchema.shape.name,
  organizationLoginName: OrganizationSchema.shape.loginName,
  employerName: EmployerSchema.shape.name,
  subdivisions: z.array(SubdivisionSchema),
});

export type GetSubdivisionsResponseDto = z.infer<
  typeof GetSubdivisionsResponseDtoSchema
>;
