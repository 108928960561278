import { z } from 'zod';
import { IdentifierSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils/create-schema-class';
import { BaseEventSchema } from './base-event';

export const SignatureFilePublishedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SignatureFilePublishedEvent'),
  eventData: z.object({
    /**
     * Application that triggers the event.
     */
    originSystem: IdentifierSchema,
    /**
     * Reference to the system which will have the signature file.
     */
    targetSystem: IdentifierSchema,
    /**
     * Identifier for document that the signature concerns.
     */
    identifier: IdentifierSchema,
    /**
     * Document type that the signature concerns, for example requisition or result.
     */
    documentType: z.string(),
    /**
     * The URL pointing to the file.
     */
    filePath: z.string(),
  }),
});

/**
 * A signature file has been sent to be stored at an external system.
 */
export class SignatureFilePublishedEvent extends createSchemaClass(
  SignatureFilePublishedEventSchema,
) {}
