import { z } from 'zod';

export const IsoDateSchema = z.string();
/**
 * A date string in the format YYYY-MM-DD.
 */
export type IsoDate = z.infer<typeof IsoDateSchema>;

export const IsoDateTimeSchema = z.string();
/**
 * A date time string in the format YYYY-MM-DDThh:mm:ss(+zz:zz)?
 * Time zone info is optional.
 */
export type IsoDateTime = z.infer<typeof IsoDateTimeSchema>;
