import { z } from 'zod';

export const MoveReasonSchema = z.enum([
  'RECEPTION',
  'STORAGE',
  'PROCESSING',
  'DISCARD',
  'CONFIRMATION',
]);

export type MoveReason = z.infer<typeof MoveReasonSchema>;
