import { z } from 'zod';
import {
  BarcodeSchema,
  IdentifierSchema,
  IsoDateTimeSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const BarcodesGeneratedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('BarcodesGeneratedEvent'),
  eventData: z.object({
    /**
     * Barcodes which were requested to be generated
     */
    barcodes: z.array(BarcodeSchema),
    /**
     * The person who generated the barcodes
     */
    generatedBy: IdentifierSchema.optional(),
    /**
     * When the generation occurred.
     */
    generatedAt: IsoDateTimeSchema.optional(),
  }),
});

/**
 * Represents when barcodes are generated by CareOS.
 */
export class BarcodesGeneratedEvent extends createSchemaClass(
  BarcodesGeneratedEventSchema,
) {}
