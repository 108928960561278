import { z } from 'zod';
import {
  BarcodeSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
  RegistrationSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenCollectedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenCollectedEvent'),
  eventData: z.object({
    /**
     * The {@link Barcode} that of Specimen that was registered.
     */
    barcode: BarcodeSchema,
    /**
     * The {@link Organization} which made the collection
     */
    organization: ReferenceSchema(z.literal('Organization')),
    /**
     * The {@link Location} where the specimen was collected
     */
    location: ReferenceSchema(z.literal('Location')),
    /**
     * Reference to the {@link Practitioner} who scanned the Specimen.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * Time of registration. e.g can be when the sample was scanned
     * Does not have to be the same time as when the sample was collected.
     */
    registration: RegistrationSchema,
    /**
     * Time of collection.
     */
    collectedAt: IsoDateTimeSchema,
  }),
});

/**
 * A {@link Specimen} has been collected.
 */
export class SpecimenCollectedEvent extends createSchemaClass(
  SpecimenCollectedEventSchema,
) {}
