<template>
  <div>
    <span>{{ `${t('specimenType.specimen_type')}: ` }}</span>
    <span class="font-semibold">
      {{ t(`specimenType.types.${activityDefinition}`) }}
    </span>
    <div
      v-for="{ samplingKit, isEnabled } in samplingKitOptions"
      :key="samplingKit"
    >
      <SamplingKitItem
        :sampling-kit="samplingKit"
        :was-already-enabled="isEnabled"
        @update="handleSamplingKitChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  SamplingKitIdentifier,
  SpecimenSamplingKit,
  ToxicologyActivityIdentifier,
} from '@careos/identifiers';
import { OrganizationWithEnabledTestTypes } from '@careos/organization-api-types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SamplingKitItem from '@/components/AdminBoard/SamplingKitItem.vue';
import { SamplingKitArgs } from '@/views/admin-view/composables/useOrganizationTestType';

const { t } = useI18n();

const props = defineProps<{
  isDoa: boolean;
  activityDefinition: ToxicologyActivityIdentifier;
  organization?: OrganizationWithEnabledTestTypes;
}>();

const samplingKitOptions = computed(() => {
  const allOptions = SpecimenSamplingKit[props.activityDefinition];
  return allOptions?.map((it) => ({
    samplingKit: it,
    isEnabled: isSamplingKitEnabled(it),
  }));
});

const emit = defineEmits<{
  update: [value: SamplingKitArgs];
}>();

const isSamplingKitEnabled = (samplingKit: SamplingKitIdentifier) => {
  if (!props.organization) return false;
  return props.isDoa
    ? props.organization.enabledDoaSamplingKits.includes(samplingKit)
    : props.organization.enabledPethSamplingKits.includes(samplingKit);
};

const handleSamplingKitChange = (args: SamplingKitArgs) => {
  emit('update', args);
};
</script>
