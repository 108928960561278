import z from 'zod';
import {
  DonorSchema,
  SamplingSessionReason,
  SamplingSessionStatusSchema,
  ToxicologySamplingSessionDetailsSchema,
} from '../../../common';
import { SamplingSessionRequisitionSchema } from './sampling-session-requisition';
import { KeyNameSchema } from '@careos/types';

export const SamplingSessionSchema = z.object({
  samplingDate: z.string(),
  sessionId: z.string(),
  reasonForTesting: SamplingSessionReason,
  employer: KeyNameSchema,
  notes: z.string().nullish(),
  status: SamplingSessionStatusSchema,
  donor: DonorSchema.optional(),
  sampleCount: z.number().positive(),
  samplingDetails: z.array(ToxicologySamplingSessionDetailsSchema).min(1),
  requisitions: z.array(SamplingSessionRequisitionSchema).min(1).optional(),
});

export type SamplingSession = z.infer<typeof SamplingSessionSchema>;
