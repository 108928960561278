import { z } from 'zod';

export const DocumentSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  version: z.number(),
  fileNamePrefix: z.string(),
  updatedAt: z.date(),
  isReadByUser: z.boolean(),
});

export type DocumentDto = z.infer<typeof DocumentSchema>;

export const DocumentWithContentSchema = z
  .object({
    content: z.string().min(1),
  })
  .merge(DocumentSchema)
  .omit({ fileNamePrefix: true });

export type DocumentWithContentDto = z.infer<typeof DocumentWithContentSchema>;
