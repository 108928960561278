import {
  auth0,
  getOrganizationID,
  getOrganizationTypes,
} from '@/authentication/auth0';
import { useAuthStore } from '@/stores/auth';
import { NavigationGuard } from 'vue-router';

const manageOrganizationsPermissions = [
  'create:organizations',
  'update:organizations',
  'delete:organizations',
  'read:organizations',
];

const manageOrganizationAdminPersmissions = [
  'create:org-admins',
  'update:org-admins',
  'delete:org-admins',
  'read:org-admins',
];
const manageUsersPermissions = [
  'create:org-users',
  'update:org-users',
  'delete:org-users',
  'read:org-users',
];

const manageSamplingLocationsPersmissions = [
  'create:sampling-locations',
  'update:sampling-locations',
  'delete:sampling-locations',
  'read:sampling-locations',
];

export const customerAdminRolePermissions = [
  ...manageOrganizationsPermissions,
  ...manageOrganizationAdminPersmissions,
];

export const organizationAdminRolePermissions = [
  ...manageSamplingLocationsPersmissions,
  ...manageUsersPermissions,
];

export const adminPermissionsRedirectGuard: NavigationGuard = async (
  to,
  _,
  next,
) => {
  const { user } = auth0;
  const authStore = useAuthStore();
  const adminUserOrgKey = getOrganizationID(user);
  const adminUserOrganizationType = getOrganizationTypes(user);

  if (!adminUserOrgKey || !adminUserOrganizationType)
    return next({ name: 'Not found' });

  const isACustomerAdmin = customerAdminRolePermissions.every((permission) =>
    authStore.permissions.includes(permission),
  );
  if (isACustomerAdmin) {
    return next();
  }

  const isOrgAdmin = organizationAdminRolePermissions.every((permission) =>
    authStore.permissions.includes(permission),
  );
  if (isOrgAdmin) {
    return next({
      name: 'organizationView',
      params: {
        organizationType: adminUserOrganizationType.at(0),
        organizationKey: adminUserOrgKey,
      },
    });
  }

  return next({ name: 'Not found' });
};
