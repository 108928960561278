<template>
  <canvas
    id="signing-canvas"
    class="rounded-md border"
    :width="vw * 0.8"
    :height="0.3 * vh"
  />
</template>
<script setup lang="ts">
import SignaturePad from 'signature_pad';
import { onMounted, ref } from 'vue';

const model = defineModel<SignaturePad>();

const vw = ref(
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
);
const vh = ref(
  Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
);

const createCanvas = () => {
  const canvas = document.querySelector('#signing-canvas');
  if (!canvas) return;
  model.value = new SignaturePad(canvas as HTMLCanvasElement);
};

onMounted(() => {
  createCanvas();
});
</script>
