import { z } from 'zod';
import {
  CodeableReferenceSchema,
  IdentifierSchema,
  ReferenceSchema,
  ReferenceTypeSchema,
} from '../fhir-entities';
import { MedicinalProductSchema } from '../fhir-entities/medicinal-product.interface';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ServiceRequestIntentSchema = z.enum([
  'proposal',
  'plan',
  'directive',
  'order',
]);
/**
 * Describes the intent of a {@link ServiceRequest}.
 * @property proposal: The request is a suggestion that does not have an intention to ensure it occurs and without providing an authorization to act.
 * @property plan: The request represents an intention to ensure something occurs without providing an authorization for others to act.
 * @property directive: The request represents a legally binding instruction authored by a Patient or RelatedPerson.
 * @property order: The request represents a request/demand and authorization for action by a Practitioner.
 */
export type ServiceRequestIntent = z.infer<typeof ServiceRequestIntentSchema>;

export const ServiceRequestedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ServiceRequestedEvent'),
  eventData: z.object({
    /**
     * Unique resource ID.
     */
    serviceRequestId: z.string(),

    /**
     * Degree of intentionality of the request.
     */
    intent: ServiceRequestIntentSchema,

    /**
     * Requisition that this request is based on.
     */
    basedOn: ReferenceSchema(z.literal('Requisition')),

    /**
     * Activity to be performed as a result of this service request
     */
    activity: ReferenceSchema(z.literal('ActivityDefinition')),

    /**
     * An explanation or justification for why this service is being requested in coded or textual form.
     */
    reason: CodeableReferenceSchema(ReferenceTypeSchema),

    /**
     * Products for this service
     */
    products: MedicinalProductSchema.array(),

    /**
     * The equipment used to collect the medical sample
     */
    samplingKit: IdentifierSchema.optional(),
  }),
});

/**
 * A service has been requested ({@link ServiceRequest}) for a {@link PlanDefinition}.
 */
export class ServiceRequestedEvent extends createSchemaClass(
  ServiceRequestedEventSchema,
) {}
