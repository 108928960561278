import { z } from 'zod';
import { ReasonsForTestingEnum } from '../config/requisition-reason-code.types';

export const SamplingSessionReason = ReasonsForTestingEnum.extract([
  'random_selection',
  'new_employee',
  'incident_accident',
]);

export type SamplingSessionReason = z.infer<typeof SamplingSessionReason>;
