import { z } from 'zod';

export const ReportTypeSchema = z.enum([
  'LABORATORY_REPORT',
  'SIMPLIFIED_REPORT',
  'COMPILED_REPORT',
]);

export const ReportType = ReportTypeSchema.enum;
export type ReportType = z.infer<typeof ReportTypeSchema>;

export const isSimplifiedReportType = (reportType: unknown) =>
  reportType === ReportType.SIMPLIFIED_REPORT;
