import { z } from 'zod';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { IdentifierSchema } from './identifier.interface';

export const ProductNameSchema = z.object({
  productName: z.string(),
  type: CodeableConceptSchema.optional(),
});

export const AccreditedProductSchema = IdentifierSchema.extend({
  /**
   * Allows the product to be classified by various systems
   */
  conformsTo: z.array(IdentifierSchema).optional(),
});

export type ProductName = z.infer<typeof ProductNameSchema>;

export const MedicinalProductSchema = z.object({
  /**
   * External identifiers for this product
   */
  identifiers: z.array(AccreditedProductSchema),
  /**
   * The product's name, including full name and possibly coded parts
   */
  names: z.array(ProductNameSchema),
  /**
   * Allows the product to be classified by various systems
   */
  classifications: z.array(CodeableConceptSchema),
});

/**
 * A medicinal product, being a substance or combination of substances that is intended to treat,
 * prevent or diagnose a disease, or to restore, correct or modify physiological functions by
 * exerting a pharmacological, immunological or metabolic action. This resource is intended to
 * define and detail such products and their properties, for uses other than direct patient care (e.g. regulatory use, or drug catalogs).
 *
 * Based on fhir resource:
 * @link http://build.fhir.org/medicinalproductdefinition.html
 */
export type MedicinalProduct = z.infer<typeof MedicinalProductSchema>;

export enum ClassificationSystem {
  Doa = 'uri:careos.io/classification/products/doa',
  DoaPanel = 'uri:careos.io/classification/products/doa/panel',
  PethPanel = 'uri:careos.io/classification/products/peth/panel',
}
