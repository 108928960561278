import { z } from 'zod';

export const BankIdSignResponseDtoSchema = z.object({
  orderRef: z.string(),
  autoStartToken: z.string(),
  qrStartToken: z.string(),
  qrStartSecret: z.string(),
});

export type BankIdSignResponseDto = z.infer<typeof BankIdSignResponseDtoSchema>;
