import { z } from 'zod';
import { IdentifierSchema } from './identifier.interface';

export const DeviceSchema = z.object({
  /**
   * Internal id currently not used since printers are fetched from printnode
   */
  id: z.string().optional(),

  /**
   * Identifier of the device.
   */
  identifier: z.array(IdentifierSchema),

  /**
   * Name to display when the device is referenced.
   */
  displayName: z.string(),
});

/**
 * A device that is used in the provisioning of healthcare.
 * Can be medical or non-medical.
 */
export type Device = z.infer<typeof DeviceSchema>;
