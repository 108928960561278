import { z } from 'zod';

export const ExtraResultTypeSchema = z.enum(['Chiral']);
export type ExtraResultType = z.infer<typeof ExtraResultTypeSchema>;
export const ExtraResultType = ExtraResultTypeSchema.Values;

export const ExtraResultStatusSchema = z.enum([
  'not-ordered',
  'ordered',
  'reported',
  'abandoned',
]);
export type ExtraResultStatus = z.infer<typeof ExtraResultStatusSchema>;
export const ExtraResultStatus = ExtraResultStatusSchema.Values;

export const ExtraResultAbandonedReasonSchema = z.enum([
  'AMPHETAMINE_CONCENTRATION_LOW',
  'AMPHETAMINE_NEGATIVE',
  'METHAMPHETAMINE_POSITIVE',
]);

export type ExtraResultAbandonedReason = z.infer<
  typeof ExtraResultAbandonedReasonSchema
>;

export const ExtraResultAbandonedReason =
  ExtraResultAbandonedReasonSchema.Values;

export const ExtraResultSchema = z.object({
  type: ExtraResultTypeSchema,
  status: ExtraResultStatusSchema,
  reason: ExtraResultAbandonedReasonSchema.nullish(),
});
export type ExtraResult = z.infer<typeof ExtraResultSchema>;
