import { z } from 'zod';
import { IsoDateTimeSchema } from './iso-date.type';
import { ReferenceSchema } from './reference.interface';

export const AnnotationSchema = z.object({
  /**
   * The annotation text
   */
  text: z.string(),
  /**
   * The time the annotation was made
   */
  time: IsoDateTimeSchema.optional(),
  /**
   * The individual responsible for the annotation
   */
  authorReference: ReferenceSchema(
    z.enum(['Organization', 'Practitioner', 'Patient']),
  ).optional(),
});

/**
 * A text note which also contains information about who made the statement and when.
 */
export type Annotation = z.infer<typeof AnnotationSchema>;
