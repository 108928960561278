<template>
  <form
    class="mb-8 flex flex-row gap-8"
    @submit.prevent="createTreatmentCenter"
  >
    <span class="flex flex-col text-left">
      <label for="employer-name">{{
        t('admin_board.add_tc_form.tc_name')
      }}</label>
      <InputText
        id="treatment-center-name"
        v-model.trim="treatmentCenterName"
        class="h-10 min-w-[296px]"
        type="text"
        required
      />
    </span>

    <FormSubmitButton
      :loading="isLoading"
      :disabled="isLoading"
      type="submit"
      :label="t('admin_board.add_tc_form.add_tc')"
      class="h-10 self-end"
    />
  </form>
  <Divider />
  <TreatmentCenterTable />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Divider from 'primevue/divider';
import { useOrganization } from '../../stores/organization';

import InputText from 'primevue/inputtext';
import { useRoute } from 'vue-router';
import TreatmentCenterTable from './components/TreatmentCenterTable.vue';
import FormSubmitButton from '../../components/FormSubmitButton.vue';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

const store = useOrganization();
const route = useRoute();
const toast = useToast();
const { t } = useI18n();

const treatmentCenterName = ref('');

const submitForm = async () => {
  if (!treatmentCenterName.value.trim()) return;
  const dto = {
    name: treatmentCenterName.value,
    organizationKey: route.params.organizationKey as string,
  };
  await store.createTreatmentCenter(dto);
  toast.add({
    life: 5_000,
    severity: 'success',
    summary: t('admin_board.add_tc_form.tc_added_message'),
  });
  treatmentCenterName.value = '';
};

const { exec: createTreatmentCenter, isLoading } = useNetworkRequest(
  async () => {
    await submitForm();
  },
);
</script>
