import { z } from 'zod';
import {
  DoaPethDiscriminatedUnionSchema,
  GenerateRequisitionRequestDtoBaseSchema,
} from '../../../requisition';
import { OrderType } from '@careos/organization-api-types';
import { SignatureDataSchema } from '../../../../common';

export const CoCSpecificSchema = z.object({
  orderType: z.literal(OrderType['CoC']),
  signature: SignatureDataSchema,
});

export const NonCoCSpecificSchema = z.object({
  orderType: z.literal(OrderType['non-CoC']),
});

export const OrderTypeDiscriminatedUnionSchema = z.discriminatedUnion(
  'orderType',
  [CoCSpecificSchema, NonCoCSpecificSchema],
);

const CreateOrderRequestDtoBaseWithoutOrderTypeSchema =
  GenerateRequisitionRequestDtoBaseSchema.omit({ orderType: true }).and(
    DoaPethDiscriminatedUnionSchema,
  );

export const CreateOrderRequestCocDtoSchema =
  CreateOrderRequestDtoBaseWithoutOrderTypeSchema.and(CoCSpecificSchema);

export const CreateOrderRequestNonCocDtoSchema =
  CreateOrderRequestDtoBaseWithoutOrderTypeSchema.and(NonCoCSpecificSchema);

export const CreateOrderRequestDtoSchema =
  CreateOrderRequestDtoBaseWithoutOrderTypeSchema.and(
    OrderTypeDiscriminatedUnionSchema,
  );

export type CreateOrderRequestCocDto = z.infer<
  typeof CreateOrderRequestCocDtoSchema
>;

export type CreateOrderRequestDto = z.infer<typeof CreateOrderRequestDtoSchema>;
