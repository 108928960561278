import { z } from 'zod';
import { BaseEventSchema } from './base-event';
import { createSchemaClass } from '../utils';
import { IsoDateTimeSchema, ReferenceSchema } from '../fhir-entities';

export const SamplingSessionStartedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SamplingSessionStartedEvent'),
  eventData: z.object({
    /**
     * Unique identifier for the session being started.
     */
    sessionId: z.string().uuid(),
    /**
     * When the session was started.
     */
    startedAt: IsoDateTimeSchema,
    /**
     * Practitioner who started the session.
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
  }),
});

/**
 * A sampling session has been started.
 */
export class SamplingSessionStartedEvent extends createSchemaClass(
  SamplingSessionStartedEventSchema,
) {}
