import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization-type';

export const AddOrganizationRequestDtoSchema = z.object({
  customerAbbreviation: z.string(),
  name: z.string().trim().min(3),
  type: OrganizationTypeSchema,
});

export type AddOrganizationRequestDto = z.infer<
  typeof AddOrganizationRequestDtoSchema
>;
