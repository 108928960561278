import { z } from 'zod';

export const DonorCheckBankidSigningRequestDtoSchema = z.object({
  orderRef: z.string(),
  transactionId: z.string().uuid(),
  pinCode: z.string(),
});

export type DonorCheckBankidSigningRequestDto = z.infer<
  typeof DonorCheckBankidSigningRequestDtoSchema
>;
