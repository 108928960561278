import { z } from 'zod';
import {
  AnnotationSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const ObservationsMedicallyApprovedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('ObservationsMedicallyApprovedEvent'),
  eventData: z.object({
    /**
     * Observations which were approved.
     */
    observations: z.array(ReferenceSchema(z.literal('Observation'))),
    /**
     * When the observations were approved.
     */
    medicallyApprovedAt: IsoDateTimeSchema,
    /**
     * Who medically approved these observations
     */
    medicallyApprovedBy: ReferenceSchema(z.literal('Practitioner')).optional(),
    /**
     * Any note or comment made about the observations.
     */
    note: AnnotationSchema.optional(),
    /**
     * Optional reference to the requisition these observations belong to.
     * If omitted, it is assumed that the data was not available or the
     * observations belong to several different requisitions
     */
    requisition: ReferenceSchema(z.literal('Requisition')).optional(),
  }),
});

/**
 * A doctor has medically approved {@link Observation}s.
 */
export class ObservationsMedicallyApprovedEvent extends createSchemaClass(
  ObservationsMedicallyApprovedEventSchema,
) {}
