<template>
  <div class="my-3 justify-start rounded border-l-4 border-red-600">
    <InlineMessage class="w-full justify-start" severity="error">{{
      t(`barcode.errorMessages.${errorMessage.toLowerCase()}`)
    }}</InlineMessage>
  </div>
</template>

<script setup lang="ts">
import type { barcodeReason } from '@careos/toxicology-types';
import InlineMessage from 'primevue/inlinemessage';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
defineProps<{
  errorMessage: barcodeReason;
}>();
</script>
