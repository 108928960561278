<template>
  <form
    class="mb-8 flex flex-row gap-8 align-baseline"
    @submit.prevent="createUser(userFormValues)"
  >
    <span class="flex flex-1 flex-col text-left">
      <label for="user-name">{{ t('admin_board.add_user_form.name') }}</label>
      <InputText
        id="user-name"
        v-model.trim="userFormValues.name"
        required
        class="h-10"
        type="text"
        aria-describedby="username-help"
        :maxlength="80"
      />
      <small
        v-show="userFormValues.name.length === 80"
        id="username-help"
        class="text-orange-500"
      >
        {{ t('admin_board.add_user_form.max_length_warning') }}</small
      >
    </span>
    <span class="flex flex-1 flex-col text-left">
      <label for="user-email">{{ t('admin_board.add_user_form.email') }}</label>
      <InputText
        id="user-email"
        v-model.trim="userFormValues.email"
        required
        class="h-10"
        type="email"
      />
    </span>
    <span class="flex flex-1 flex-col text-left">
      <label for="org-type">{{ t('admin_board.add_user_form.role') }}</label>
      <Dropdown
        id="org-type"
        v-model="userFormValues.role"
        :options="auth0Roles"
        :option-label="
          ({ label }) =>
            t(`admin_board.user_details.role_${label.toLowerCase()}_long`)
        "
        :placeholder="t('admin_board.add_user_form.select_role')"
        :class="[
          'h-10 leading-4',
          { 'p-invalid': roleError && !userFormValues.role.key },
        ]"
      />
    </span>
    <FormSubmitButton
      :loading="isLoading"
      :disabled="isLoading"
      :label="t('admin_board.add_user_form.add_user')"
      class="ml-auto h-10 self-end whitespace-nowrap"
      :class="{
        'self-end': !userFormValues.name.length,
        'self-center': userFormValues.name.length === 80,
      }"
    />
  </form>
  <TheModal
    :show-modal="showModal"
    :loading="isLoadingConfirm"
    @decline="showModal = false"
    @accept="createUserConfirm(userFormValues)"
  >
    <p>
      {{ t('admin_board.add_user_form.modal.already_exists') }}
      <b>{{ existingUserName }}.</b>
      {{ t('admin_board.add_user_form.modal.add_anyway') }}
      <b>{{ existingUserName }}?</b>
    </p>
    <p class="mt-4">{{ t('admin_board.add_user_form.modal.contact_text') }}</p>
  </TheModal>
</template>
<script setup lang="ts">
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { computed, reactive, ref } from 'vue';
import { OrgUser } from '../../../models/user';

import {
  AddUserRequestDto,
  GetRolesResponseDto,
  OrganizationTypeSchema,
} from '@careos/organization-api-types';
import FormSubmitButton from '@/views/admin-view/components/FormSubmitButton.vue';
import TheModal from '@/components/TheModal.vue';
import { useRoute } from 'vue-router';
import { useUsersStore } from '@/views/admin-view/stores/users';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { Role } from '@careos/organization-api-types/src/user/dto/role';

const route = useRoute();
const usersStore = useUsersStore();
const toast = useToast();
const { t } = useI18n();

const props = defineProps<{
  roles: GetRolesResponseDto['roles'];
}>();

const initialUserFormState = {
  name: '',
  email: '',
  role: {
    label: '',
    key: '',
  },
};

const userFormValues = reactive<OrgUser>({ ...initialUserFormState });
const { organizationKey, organizationType } = route.params;
const auth0Roles = computed(() =>
  props.roles.map((role) => ({ label: role.name, key: role.auth0Id })),
);

const showModal = ref(false);
const existingUserName = ref('');
const roleError = ref(false);

const checkAndAddUser = async (newOrgUser: OrgUser) => {
  const { email, name, role } = newOrgUser;
  roleError.value = false;
  if (!role.key) {
    roleError.value = true;
    return;
  }

  const newUserCheck = await usersStore.checkUser({
    email,
    name,
    organizationKey: organizationKey as string,
    organizationType: OrganizationTypeSchema.parse(organizationType),
  });

  if (newUserCheck.isUserExistingInThisOrg) {
    throw new Error(t('admin_board.user_already_exists_on_org'));
  } else if (
    !newUserCheck.isUserExisting ||
    newUserCheck.isUserExistingWithSameName
  ) {
    const newUserRoles: Role = {
      auth0Id: role.key,
      name: role.label,
    };
    const dto: AddUserRequestDto = {
      email: email.toLowerCase(),
      name,
      roles: [newUserRoles],
      organizationKey: organizationKey as string,
      organizationType: OrganizationTypeSchema.parse(organizationType),
    };
    await usersStore.createUser(dto);
    toast.add({
      life: 5_000,
      severity: 'success',
      summary: t('admin_board.user_added_msg'),
    });
    Object.assign(userFormValues, initialUserFormState);
  } else {
    showModal.value = true;
    existingUserName.value = newUserCheck.userNameInDatabase as string;
  }
};
const { exec: createUser, isLoading } = useNetworkRequest(
  async (newOrgUser: OrgUser) => {
    await checkAndAddUser(newOrgUser);
  },
);

const confirmAndAddUser = async (newOrgUser: OrgUser) => {
  const { email, role } = newOrgUser;

  const newUserRoles: Role = {
    auth0Id: role.key,
    name: role.label,
  };
  const dto: AddUserRequestDto = {
    email: email.toLowerCase(),
    name: existingUserName.value,
    roles: [newUserRoles],
    organizationKey: organizationKey as string,
    organizationType: OrganizationTypeSchema.parse(organizationType),
  };

  await usersStore.createUser(dto);
  toast.add({
    life: 5_000,
    severity: 'success',
    summary: t('admin_board.user_added_msg'),
  });
  Object.assign(userFormValues, initialUserFormState);
  showModal.value = false;
};

const { exec: createUserConfirm, isLoading: isLoadingConfirm } =
  useNetworkRequest(async (newOrgUser: OrgUser) => {
    await confirmAndAddUser(newOrgUser);
  });
</script>
