<template>
  <li class="list-none whitespace-nowrap">
    <template
      v-if="
        typeof props.substance === 'object' &&
        'isSwedacAccredited' in props.substance
      "
    >
      {{ t(`substances.names.${props.substance.name}`)
      }}<sup
        v-if="
          !props.substance.isSwedacAccredited ||
          props.substance.isReportedQualitative
        "
        class="text-md font-extrabold"
      >
        <template
          v-if="
            props.hasAccreditedSubstance && !props.substance.isSwedacAccredited
          "
          >1</template
        >
        <template v-if="props.substance.isReportedQualitative">*</template>
      </sup>
      <span class="ml-1 text-xs text-slate-500">
        {{ `(${getLowCutoff(props.substance.cutoffs)})` }}
      </span>
    </template>
    <template v-else>
      {{ t(`substances.names.${props.substance.name}`) }}
    </template>
  </li>
</template>

<script setup lang="ts">
import { PanelSubstance } from '@careos/toxicology-types';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';

export type Substance = { name: string } | PanelSubstance;

const { t } = useI18n();

const getLowCutoff = (cutoffs: PanelSubstance['cutoffs']) => {
  // INFO: Backend is already sending only one cutoff. Added this to be safer.
  const lowCutoff = cutoffs?.find((it) => it.type === 'low');
  return `${lowCutoff?.value} ${lowCutoff?.unit}`;
};

const props = defineProps({
  substance: {
    type: Object as PropType<Substance>,
    required: true,
  },
  hasAccreditedSubstance: {
    type: Boolean,
    default: true,
  },
});
</script>
