import { z } from 'zod';
import {
  ToxicologyActivityIdentifier,
  ToxicologyActivityIdentifierSchema,
} from '.';

export const SamplingKitIdentifierSchema = z.enum([
  'GREINER',
  'INTERCEPT',
  'DRIED_BLOOD_SPOTS',
  'VENOUS_BLOOD',
  'QUANTISAL',
  'UNKNOWN_SAMPLING_KIT',
  'B_CAPITAINER',
]);
export type SamplingKitIdentifier = z.infer<typeof SamplingKitIdentifierSchema>;
export const SamplingKitIdentifier = SamplingKitIdentifierSchema.Values;

export const isSamplingKitIdentifier = (
  value: unknown,
): value is SamplingKitIdentifier =>
  SamplingKitIdentifierSchema.safeParse(value).success;

export const SpecimenSamplingKitSchema = z.record(
  ToxicologyActivityIdentifierSchema,
  SamplingKitIdentifierSchema.array(),
);
export type SpecimenSamplingKit = z.infer<typeof SpecimenSamplingKitSchema>;

// INFO: VENOUS_BLOOD IS DISABLED UNTIL FURTHER NOTICE.
export const SpecimenSamplingKit: SpecimenSamplingKit = {
  DOA_SALIVA_SAMPLING: ['GREINER', 'INTERCEPT', 'QUANTISAL'],
  PETH_DRIED_BLOOD_SPOTS: ['DRIED_BLOOD_SPOTS'],
  DOA_BLOOD_SAMPLING: ['B_CAPITAINER'],
};

export const getActivityDefinitionBySamplingKit = (
  samplingKit: SamplingKitIdentifier,
) => {
  const activityDefinitions = Object.keys(
    SpecimenSamplingKit,
  ) as ToxicologyActivityIdentifier[];
  return activityDefinitions.find((key) =>
    SpecimenSamplingKit[key]?.includes(samplingKit),
  );
};

// INFO: We have to take the old requisitions into account still. That's why we check samplingKit undefined.
export const isQualitativeSalivaSamplingKit = (
  value: unknown,
): value is SamplingKitIdentifier =>
  value === SamplingKitIdentifier.GREINER || value === undefined;

export const isUnaccreditedPethSamplingKit = (
  value: unknown,
): value is SamplingKitIdentifier =>
  value === SamplingKitIdentifier.VENOUS_BLOOD;
