<template>
  <div class="flex justify-between gap-x-8">
    <ScanningOptionsItem
      class="flex-1"
      :label="t('barcode.barcodeReader.label')"
      icon-path="barcode-reader"
      :is-selected="inputDevice === 'barcodeReader'"
      @click="$emit('updateInputOption', 'barcodeReader')"
    />
    <ScanningOptionsItem
      class="flex-1"
      :label="t('barcode.cameraScanner.label')"
      icon-path="scan-camera"
      @click="$emit('updateInputOption', 'deviceCamera')"
    />
  </div>
</template>

<script setup lang="ts">
import type { InputDevice } from '@/stores/order/barcode';
import { useI18n } from 'vue-i18n';
import ScanningOptionsItem from './ScanningOptionsItem.vue';

const { t } = useI18n();

defineEmits<{
  (e: 'updateInputOption', inputDevice: InputDevice): void;
}>();

defineProps<{
  inputDevice: InputDevice;
}>();
</script>
