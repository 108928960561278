import {
  SamplingKitIdentifier,
  ToxicologyActivityIdentifier,
  ToxicologyActivityIdentifierSchema,
} from '@careos/identifiers';
import { z } from 'zod';

export const ValidationSubstanceSchema = z.enum([
  'CREATININE',
  'AMYLASE',
  'CHIRAL',
]);

export type ValidationSubstance = z.infer<typeof ValidationSubstanceSchema>;
export const ValidationSubstance = ValidationSubstanceSchema.Enum;

export const SpecimenValidationSubstanceSchema = z.record(
  ToxicologyActivityIdentifierSchema,
  ValidationSubstanceSchema,
);
export const ResultValidationSubstanceSchema = z.record(
  ToxicologyActivityIdentifierSchema,
  ValidationSubstanceSchema,
);

export type SpecimenValidationSubstance = z.infer<
  typeof SpecimenValidationSubstanceSchema
>;

export type ResultValidationSubstance = z.infer<
  typeof ResultValidationSubstanceSchema
>;

export const SpecimenValidationSubstances: SpecimenValidationSubstance = {
  DOA_URINE_SAMPLING: 'CREATININE',
  DOA_SALIVA_SAMPLING: 'AMYLASE',
};

export const ResultValidationSubstances: ResultValidationSubstance = {
  DOA_URINE_SAMPLING: 'CHIRAL',
  DOA_SALIVA_SAMPLING: 'CHIRAL',
};

export const isSpecimenValidationSubstance = (
  substance: unknown,
): substance is SpecimenValidationSubstance =>
  SpecimenValidationSubstanceSchema.safeParse(substance).success;

export const isValidationSubstance = (
  substance: unknown,
): substance is ValidationSubstance =>
  ValidationSubstanceSchema.safeParse(substance).success;

export const isAmylaseSubstance = (substance: string): boolean =>
  substance === ValidationSubstance.AMYLASE;

export const isSpecimenTypeAndKitValidForChiralOrder = (
  specimenType: unknown,
  samplingKit: unknown,
): boolean =>
  specimenType === ToxicologyActivityIdentifier.DOA_URINE_SAMPLING ||
  (specimenType === ToxicologyActivityIdentifier.DOA_SALIVA_SAMPLING &&
    samplingKit === SamplingKitIdentifier.QUANTISAL);
