import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const CheckUserInDatabaseRequestDtoSchema = z.object({
  email: z.string().trim().email(),
  name: nonEmptyString,
  organizationKey: nonEmptyString,
  organizationType: nonEmptyString,
});

export type CheckUserInDatabaseRequestDto = z.infer<
  typeof CheckUserInDatabaseRequestDtoSchema
>;
