import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const StartSamplingSessionRequestDtoSchema = z.object({
  sessionId: z.string().uuid(),
});

export type StartSamplingSessionRequestDtoType = z.infer<
  typeof StartSamplingSessionRequestDtoSchema
>;
export class StartSamplingSessionRequestDto extends createSchemaClass(
  StartSamplingSessionRequestDtoSchema,
) {}
